
import { Vue, Options, Prop, Watch } from "vue-property-decorator";
import CryptTransferModel from "@/views/models/Transfer/CryptTransferModel";
import AdapterTransferService from "../../adapter/AdapterTransferService";
import ModelTransferCryptPayout from "@/api/main/dataSource/api/i-public-service/aba-finance/models/ModelTransferCryptPayout";
import CryptPayoutRequest from "@/api/main/dataSource/api/transaction-services/models/transaction-operations/CryptPayoutRequest";
import { useRoute, useRouter } from "vue-router";
import * as Routes from "@/router/routerNames";
@Options({
	name: "crypt-transfer-confrim",
})
export default class CryptTransferConfrim extends Vue {
	@Prop({ default: false }) visible: boolean;
	@Prop({ default: null }) formData: FormData;

	pinCode: string = "";
	router = useRouter();
	ErrMessage: string = "";
	IsSuccess: boolean = false;
	IsLoad: boolean = false;

	model: CryptTransferModel = null;

	created() {
		this.model = new CryptTransferModel();
	}

	@Watch("formData", { deep: true })
	onChangeFormData() {
		if (!this.formData) return;
		this.model = new CryptTransferModel({
			ToIban: this.formData.get("ToIban").toString(),
			CryptCurrencyName: this.formData.get("CryptCurrencyName").toString(),
			CryptNetworkName: this.formData.get("CryptNetworkName").toString(),
			Amount: this.formData.get("Amount").toString(),
			Commission: this.formData.get("Commission").toString(),
			Description: this.formData.get("Description").toString(),
		});
	}

	async Transfer() {
		if (this.IsLoad) return false;
		this.ErrMessage = "";
		if (this.pinCode.length < 6) {
			this.ErrMessage = this.translation("EnterPINСode");
			return false;
		}

		var request = new FormData();
		let entries = this.formData.entries();
		this.formData.forEach((item) => {
			let ent = entries.next().value;
			request.append(`Transfer[${ent[0]}]`, ent[1]);
		});
		request.append("Pin", this.pinCode);

		this.IsLoad = true;
		this.ErrMessage = "";
		const adapter = AdapterTransferService.getInstance();
		const req = new ModelTransferCryptPayout({
			Pin: this.pinCode,
			Transfer: new CryptPayoutRequest({
				Amount: Number(this.model.Amount),
				CryptCurrencyName: this.model.CryptCurrencyName,
				CryptNetworkName: this.model.CryptNetworkName,
				Description: this.model.Description,
				ToIban: this.model.ToIban,
			}),
		});
		const res = await adapter.transferCrypto(req);
		this.IsLoad = false;

		if (res.IsSuccess) {
			this.IsSuccess = res.IsSuccess;
			return;
		}
		this.ErrMessage = res.ErrorMessage;
		// this.$api.Main.CryptPayoutService.Transfer((res) => {
		//   this.IsLoad = false;

		//   if ((res as any).IsError) {
		//     this.$store.state.modalDialogAlert("Неизвестная ошибка при загрузке");
		//     return;
		//   }

		//   if (res.IsSuccess) this.IsSuccess = res.IsSuccess;
		//   else
		//     this.ErrMessage = this.$localization.translateResponseCode(
		//       res.ResponseCode
		//     );
		// }, request);//TODO API
	}

	onInputPin(pin: string) {
		this.pinCode = pin;
	}

	close() {
		this.ErrMessage = "";
		if (this.IsSuccess) this.router.push({ name: Routes.TRANSUCTIONS });
		this.$emit("close");
	}

	translation(key: string) {
		return this.$localization.getTranslate(key);
	}
}
