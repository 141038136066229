import moment from "moment";
export { }

declare global {
    interface Date {
        MomentFormat(format: string): string;
    }
}

Date.prototype.MomentFormat = function (format: string): string {
    return moment(this)
        .format(format);
}