
import { Vue, Options, Prop, Watch } from "vue-property-decorator";
import AccountModel from "@/views/models/Transfer/AccountModel";
import AdapterTransferService from "../../adapter/AdapterTransferService";
import InternalTransferRequest from "@/api/main/dataSource/api/transaction-services/models/transaction-operations/InternalTransferRequest";
import CheckTransferPinT from "@/api/main/dataSource/api/aba/cabinet/web/models/CheckTransferPinT";
import { useRoute, useRouter } from "vue-router";
import * as Routes from "@/router/routerNames";
@Options({
	name: "transfer-confrim",
})
export default class TransferConfirm extends Vue {
	@Prop({ default: false }) visible: boolean;
	@Prop({ default: null }) formData: FormData;

	pinCode: string = "";
	router = useRouter();
	ErrMessage: string = "";
	IsSuccess: boolean = false;
	IsLoad: boolean = false;

	model: AccountModel = null;

	created() {
		this.model = new AccountModel();
	}

	@Watch("formData", { deep: true })
	onChangeFormData() {
		if (!this.formData) return;
		this.model = new AccountModel({
			Recipient: this.formData.get("Recipient").toString(),
			AccountNumber: this.formData.get("AccountNumber").toString(),
			Amount: this.formData.get("Amount").toString(),
			PurposePayment: this.formData.get("PurposePayment").toString(),
		});
	}

	async Transfer() {
		if (this.IsLoad) return false;
		this.ErrMessage = "";
		if (this.pinCode.length < 6) {
			this.ErrMessage = this.translation("EnterPINСode");
			return false;
		}
		var request = this.formData;
		request.append("Pin", this.pinCode);
		this.IsLoad = true;

		const adapter = AdapterTransferService.getInstance();

		const req = new CheckTransferPinT<InternalTransferRequest>({
			PaymentData: new InternalTransferRequest({
				Amount: Number(this.model.Amount),
				Description: this.model.PurposePayment,
				ToIban: this.model.AccountNumber,
				ToName: this.model.Recipient,
				SaveTemplate: this.formData.get("IsSave")?.toString() == "false" ? false : true,
				TemplateName: this.formData.get("TemplateName")?.toString(),
			}),
			Pin: this.pinCode,
		});
		const res = await adapter.internalTransfer(req);
		this.IsLoad = false;
		if (res.IsSuccess) {
			this.IsSuccess = res.IsSuccess;
			return;
		}
		this.ErrMessage = res.ErrorMessage;
		// this.$api.Main.NaturalPersonInternalBalanceService.Transfer((res) => {
		//   this.IsLoad = false;

		//   if ((res as any).IsError) {
		//     this.$store.state.modalDialogAlert("Неизвестная ошибка при загрузке");
		//     return;
		//   }

		//   if (res.IsSuccess) this.IsSuccess = res.IsSuccess;
		//   else
		//     this.ErrMessage = this.$localization.translateResponseCode(
		//       res.ResponseCode
		//     );
		// }, request);//TODO api
	}

	onInputPin(pin: string) {
		this.pinCode = pin;
	}
	close() {
		this.ErrMessage = "";
		if (this.IsSuccess) this.router.push({ name: Routes.TRANSUCTIONS });
		this.$emit("close");
	}

	translation(key: string) {
		return this.$localization.getTranslate(key);
	}
}
