import EnumTypeIdentified from '../../../../aba/data/models/models/EnumTypeIdentified';
import ModelRegisteredAddress from '../../../../i-public-service/aba-finance/models/user/ModelRegisteredAddress';
import ModelPersonBalance from '../../../../i-public-service/aba-finance/models/ModelPersonBalance';
import ModelPersonCard from '../../../../i-public-service/aba-finance/models/card/ModelPersonCard';
import ModelUserBase from '../../../../i-public-service/aba-finance/models/user/ModelUserBase';

export default class ModelNaturalPerson extends ModelUserBase {
	Identified!: EnumTypeIdentified;
	IdentifiedId!: number;
	ConfrimHash!: string;
	IsConfirmPhone: boolean = false;
	CardId?: number;
	NumberCard!: string;
	NumberPassport!: string;
	RegistrationAddress!: ModelRegisteredAddress;
	SearchFilter!: string;
	Search!: string;
	Appeal!: string;
	Balance!: ModelPersonBalance;
	Cards!: Array<ModelPersonCard>;
	BirthDate?: Date;
	AcquiringCardMask!: string;
	AddressState!: string;
	AddressCountry!: string;
	AddressCity!: string;
	AddressStreet!: string;
	AddressZip!: string;
	WalletsCount!: number;
	constructor(obj?: Partial<ModelNaturalPerson>) {
		super(obj);
		if (obj) {
			Object.assign(this, obj);
		}
	}
}
