
import NaturalPersonModel from "@/views/models/NaturalPersonModel";
import TabModel from "@/views/models/TabModel";
import TemplateModel from "@/views/models/TemplatesTrasnfer/TemplateModel";
import { Vue, Options, Watch } from "vue-property-decorator";
import { AdapterTransferService } from "./adapter";
import ModelNaturalPerson from "@/api/main/dataSource/api/i-public-service/aba-finance/models/user/ModelNaturalPerson";
import EnumUserPaymentsType from "@/api/main/dataSource/api/aba/data/enums/EnumUserPaymentsType";
import { AdapterApiService } from "../_adapter";
import UserType from "@/api/main/models/Enums/UserType";
import ModelLegalPerson from '@/api/main/dataSource/api/i-public-service/aba-finance/models/user/ModelLegalPerson';
import { PersonModel } from '@/views/models';
@Options({
	name: "naturalperson-payments-transferToCardAccountNumber",
})
export default class Transfers extends Vue {
	Template: any = null;
	IsLoadPerson: boolean = false;
	IsSelectTemplate: boolean = false;
	IsActiveCards: boolean = false;
	Person: PersonModel = new PersonModel();
	ActiveTemplate: string = "Employee";
	Employee: any = null;
	Card: any = null;
	modelAba: any = null;
	SelectedTemplate: TemplateModel = null;
	SelectedAccount: TemplateModel = null;
	SelectedSepa: TemplateModel = null;
	SelectedSwift: TemplateModel = null;
	SelectedTemplateAcquiringToCard: TemplateModel = null;
	modelAccount: FormData = null;
	modelSepa: FormData = null;
	modelSwift: FormData = null;
	modelCardTransfer: any = null;
	modelCryptTransfer: any = null;
	modelAcquiringToCard: any = null;
	Type: number = 3;
	IsConfirmCondition: boolean = false;
	tabs: Array<TabModel> = [];
	isSetTabs: boolean = false;

	isOpenSepaConfirm: boolean = false;
	isOpenSwiftConfirm: boolean = false;
	isOpenTransferConfrim: boolean = false;
	isOpenTransferAcquiringConfrim: boolean = false;
	isOpenCardTransferConfrim: boolean = false;
	isCryptTransferConfrim: boolean = false;

	adapter: AdapterTransferService;
	get isAllowSwift() {
		return (
			this.Person &&
			(this.Person.PaymentsType == EnumUserPaymentsType.Offline ||
				this.Person.PaymentsType == 2)
		); //only for Offline and dynapay
	}
	get isAllowSepa() {
		return true; //TODO временно
		const res = this.Person?.PaymentsType != EnumUserPaymentsType.Payneteasy;
		return res;
	}
	get isAllowInternal() {
		return true; //TODO временно
		return this.Person.PaymentsType != EnumUserPaymentsType.Payneteasy;
	}
	get isAllowAcquiring() {
		return true; //TODO временно
		return this.Person && this.Person.PaymentsType == EnumUserPaymentsType.Payneteasy;
	}
	get isAllowCardTransfer() {
		return true;
		return this.Person && this.Person.PaymentsType == EnumUserPaymentsType.Offline; //only for Offline
	}
	get isAllowCryptTransfer() {
		return true; //TODO временно
		return this.Person && this.Person.PaymentsType == EnumUserPaymentsType.Offline; //only for Offline
	}

	async mounted() {
		this.adapter = AdapterTransferService.getInstance();
		this.init();
		await this.getPerson();
	}

	async getPerson() {
		const resp = await this.adapter.getPerson();
		if (resp) {
			this.Person = resp;
			this.IsLoadPerson = true;
		}
	
	}

	init() {
		this.tabs = [
			new TabModel({ Id: 5, Name: "TransferAcquiringToCard", Active: false }),
			new TabModel({ Id: 3, Name: "TransferToECInEUR", Active: false }),
			new TabModel({ Id: 4, Name: "TransferSwift", Active: false }),
			new TabModel({ Id: 2, Name: "ByAccountNumber", Active: false }),
			// new TabModel({ Id: 13, Name: "TransferCard", Active: false }),
			// new TabModel({ Id: 12, Name: "TransferCrypt", Active: false }),
		];

		this.initType();
		this.onChangeTab(this.tabs.find((i) => i.Active === true).Id);
		this.filterTabs();
	}

	@Watch("Person", { deep: true })
	filterTabs() {
		if (!this.Person) return;
		if (!this.isAllowSepa) this.tabs = this.tabs.filter((t) => t.Id !== 3);
		if (!this.isAllowSwift) this.tabs = this.tabs.filter((t) => t.Id !== 4);
		if (!this.isAllowAcquiring) this.tabs = this.tabs.filter((t) => t.Id !== 5);
		if (!this.isAllowInternal) this.tabs = this.tabs.filter((t) => t.Id !== 2);
		if (!this.isAllowCardTransfer) this.tabs = this.tabs.filter((t) => t.Id !== 13);
		if (!this.isAllowCryptTransfer) this.tabs = this.tabs.filter((t) => t.Id !== 12);
		this.isSetTabs = true;
	}

	onChangeTab(id: number) {
		this.Type = id;
		this.UseTemplateAba(new TemplateModel());
	}

	// @Watch("isAllowAcquiring")
	initType() {
		if (this.isAllowAcquiring) this.Type = 5;
		else this.Type = 3;
		const res = this.tabs.find((t) => t.Id === this.Type);
		if (res) res.Active = true;
	}

	GoTransferAccount(model: FormData) {
		this.modelAccount = model;
		this.isOpenTransferConfrim = true;
	}
	GoTransferSepa(model: FormData) {
		this.modelSepa = model;
		this.isOpenSepaConfirm = true;
	}
	GoTransferSwift(model: FormData) {
		this.modelSwift = model;
		this.isOpenSwiftConfirm = true;
	}
	GoTransferCard(model: FormData) {
		this.modelCardTransfer = model;
		this.isOpenCardTransferConfrim = true;
	}
	GoTransferCrypt(model: FormData) {
		this.modelCryptTransfer = model;
		this.isCryptTransferConfrim = true;
	}
	AcquiringToCard(model: FormData) {
		this.modelAcquiringToCard = model;
		this.isOpenTransferAcquiringConfrim = true;
	}
	UseTemplateAba(model: TemplateModel) {
		this.clearTemplates();
		switch (this.Type) {
			case 1:
				this.SelectedTemplate = model;
				break;
			case 2:
				this.SelectedAccount = model;
				break;
			case 4:
				this.SelectedSwift = model;
				break;
			case 5:
				this.SelectedTemplateAcquiringToCard = model;
				break;
			default:
				this.SelectedSepa = model;
				break;
		}
	}

	clearTemplates() {
		setTimeout(() => {
			this.SelectedTemplate = this.SelectedAccount = this.SelectedSwift = this.SelectedTemplateAcquiringToCard = this.SelectedSepa = null;
		});
	}
}
