
import MessageItemModel from "@/api/main/services/NaturalPersonProfile/models/MessageItemModel";
import { Vue, Options, Watch } from "vue-property-decorator";
import { AdapterProfileService } from "../profile/adapter";
@Options({
	name: "naturalperson-profile-message",
})
export default class ProfileMessage extends Vue {
	Messages: Array<MessageItemModel> = [];
	TextMessage: string = "";
	options: object = {
		year: "numeric",
		month: "numeric",
		day: "numeric",
		timezone: "UTC",
	};
	intervalTemp: any = null;

	async mounted() {
		await this.GetMessage();
		// this.updaterMessages();
	}

	unmounted() {
		// clearInterval(this.intervalTemp);
	}

	@Watch("Messages", { deep: true })
	onChangeMessages() {
		setTimeout(() => {
			document.getElementById("content-messages").scrollTop = 99999999;
		}, 100);
	}
	adapter = AdapterProfileService.getInstance();
	async SendMessage() {
		if (this.TextMessage.length === 0) return;
		console.log(`нет api`);

		const res = await this.adapter.addMessage(this.TextMessage);
		if (res.IsSuccess) {
			this.AddMessage();
		}
		//TODO нет api
		// let formData = new FormData();
		// formData.append("text", this.TextMessage);
		// this.$api.NaturalPaymentsWsController.AddMessage((res) => {
		// 	this.AddMessage();
		// }, formData);
	}
	async GetMessage() {
		const res = await this.adapter.getMessages();
		if (res.IsSuccess) {
			this.Messages = this.adapter.mapModelMessagesToMessageItemModel(res.Value);
		}
	}

	AddMessage() {
		this.Messages.push(
			new MessageItemModel({
				Text: this.TextMessage,
				DateCreateJs: new Date(),
				IsPersonMessage: true,
			})
		);
		this.TextMessage = "";
	}

	updaterMessages() {
		this.intervalTemp = setInterval(() => {
			this.GetMessage();
		}, 5000);
	}
}
