import { WebApiService } from '../../../../../../plugins/webApiService'
import { Events } from '../../../../../../plugins/events'
import { SendRequestModel } from '../../../../../../plugins/models/SendRequestModel'
import AcquiringInitPayoutRequest from '../../../../../../transaction-services/models/acquirings/AcquiringInitPayoutRequest';
import WsResponseModelT from '../../../../../../schema-builder/server/models/WsResponseModelT';
import InfoForAcquiringIntPayin from '../../../../../../transaction-services/models/transfer-info-service/InfoForAcquiringIntPayin';

export default class NaturalInternalBalanceWsController {
	private url?: string;
	private cookieName: string;
	private appWebSettingsPath?: string;
	private apiUrlProperty?: string;

	CheckTransferAcquiringEvent = new Events<WsResponseModelT<InfoForAcquiringIntPayin>>();
	TransferAcquiringToCardEvent = new Events<WsResponseModelT<Boolean>>();

	subscribeEvents = async () => {
		const self = this;
		const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
		webApiService.on('NaturalInternalBalanceWsController', 'CheckTransferAcquiring', 'Main', (res: WsResponseModelT<InfoForAcquiringIntPayin>) => {
			self.CheckTransferAcquiringEvent.trigger(res);
		});
		webApiService.on('NaturalInternalBalanceWsController', 'TransferAcquiringToCard', 'Main', (res: WsResponseModelT<Boolean>) => {
			self.TransferAcquiringToCardEvent.trigger(res);
		});
	}

	constructor(url?: string, cookieName?: string, appWebSettingsPath?: string, apiUrlProperty?: string) {
		this.url = url;
		this.cookieName = cookieName ?? "Auth-Token";
		this.appWebSettingsPath = appWebSettingsPath && [location.host, appWebSettingsPath].join("/");
		this.apiUrlProperty = apiUrlProperty;
		this.subscribeEvents();
	}

	async CheckTransferAcquiring(request: AcquiringInitPayoutRequest) {
		const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
		const sendRequest = new SendRequestModel({
			ProjectName: 'Main',
			Controller: 'NaturalInternalBalanceWsController',
			Method: 'CheckTransferAcquiring',
			Value: typeof(request) === 'object' ? JSON.stringify(request) : request,
		})

		webApiService.send(sendRequest);
	}

	async TransferAcquiringToCard(request: AcquiringInitPayoutRequest) {
		const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
		const sendRequest = new SendRequestModel({
			ProjectName: 'Main',
			Controller: 'NaturalInternalBalanceWsController',
			Method: 'TransferAcquiringToCard',
			Value: typeof(request) === 'object' ? JSON.stringify(request) : request,
		})

		webApiService.send(sendRequest);
	}

	 // async methods
	async checkTransferAcquiringAsync(request: AcquiringInitPayoutRequest): Promise<InfoForAcquiringIntPayin> {
		return new Promise<InfoForAcquiringIntPayin>(async (resolve, reject) => {
			const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
			const sendRequest = new SendRequestModel({
				ProjectName: 'Main',
				Controller: 'NaturalInternalBalanceWsController',
				Method: 'CheckTransferAcquiring',
				Value: typeof(request) === 'object' ? JSON.stringify(request) : request,
				Callback: (response: WsResponseModelT<InfoForAcquiringIntPayin>) => {
					const isGeneric = typeof response.IsSuccess == 'boolean'
					const result = response.IsSuccess ? response.Value : response

					if (!isGeneric || response.IsSuccess) resolve(result as InfoForAcquiringIntPayin)
					else reject(result)
				}
			})

			webApiService.send(sendRequest);
		});
	}

	async transferAcquiringToCardAsync(request: AcquiringInitPayoutRequest): Promise<Boolean> {
		return new Promise<Boolean>(async (resolve, reject) => {
			const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
			const sendRequest = new SendRequestModel({
				ProjectName: 'Main',
				Controller: 'NaturalInternalBalanceWsController',
				Method: 'TransferAcquiringToCard',
				Value: typeof(request) === 'object' ? JSON.stringify(request) : request,
				Callback: (response: WsResponseModelT<Boolean>) => {
					const isGeneric = typeof response.IsSuccess == 'boolean'
					const result = response.IsSuccess ? response.Value : response

					if (!isGeneric || response.IsSuccess) resolve(result as Boolean)
					else reject(result)
				}
			})

			webApiService.send(sendRequest);
		});
	}

}