import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "select_appeal iso-apell" }
const _hoisted_2 = { class: "select_box iso-select" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_select = _resolveComponent("ui-select")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_ui_select, {
        style: { minWidth: `400px` },
        items: _ctx.itemsSelect,
        modelValue: _ctx.currentCountry,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.currentCountry) = $event))
      }, null, 8, ["items", "modelValue"])
    ])
  ]))
}