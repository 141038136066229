import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "ui_input_wrapper" }
const _hoisted_2 = ["readonly", "placeholder"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("label", _hoisted_1, [
    (_ctx.headerText.length != 0)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["ui_input_header", [_ctx.styleForWrong ? 'wrong_text' : '']])
        }, _toDisplayString(_ctx.headerText), 3))
      : _createCommentVNode("", true),
    _withDirectives(_createElementVNode("input", {
      type: "text",
      class: _normalizeClass(["ui_input", [_ctx.styleForWrong ? 'wrong' : '']]),
      readonly: _ctx.readOnly,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.innerValue) = $event)),
      "on:update:modelValue": _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onUpdateText && _ctx.onUpdateText(...args))),
      placeholder: _ctx.placeholder,
      onKeyup: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.keyUp && _ctx.keyUp(...args)))
    }, null, 42, _hoisted_2), [
      [_vModelText, _ctx.innerValue]
    ])
  ]))
}