import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@assets/img/Icons/close-icon2.svg'


const _withScopeId = (n: any) => (_pushScopeId("data-v-0d2074f9"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "dialog-ms_content_body" }
const _hoisted_2 = { class: "dialog-ms_content_body_message" }
const _hoisted_3 = { class: "dialog-ms_content_body_close" }
const _hoisted_4 = { class: "dialog-ms_content_footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_click_outside = _resolveDirective("click-outside")!

  return (_openBlock(), _createElementBlock("div", {
    class: "dialog-ms",
    style: _normalizeStyle(_ctx.isVisibleBlock(_ctx.$store.state.modalDialogVisible))
  }, [
    _withDirectives((_openBlock(), _createElementBlock("div", {
      class: "dialog-ms_content",
      style: _normalizeStyle(_ctx.translatePosition(_ctx.$store.state.modalDialogVisible))
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$store.state.modalDialogMessage), 1),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("img", {
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args))),
            src: _imports_0,
            alt: ""
          })
        ])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("button", {
          class: "button-ok",
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)))
        }, "OK")
      ])
    ], 4)), [
      [_directive_click_outside, _ctx.close]
    ])
  ], 4))
}