
import EnumTypeIdentified from '@/api/main/models/Enums/EnumTypeIdentified';
import EnumTypeUser from "@/api/main/models/Enums/EnumTypeUser";
import ModelPersonBalance from "@/api/main/services/NaturalPersonProfile/models/response/GetNewUser/ModelPersonBalance";
import ModelPersonCard from "@/api/main/services/NaturalPersonProfile/models/response/GetNewUser/ModelPersonCard";
import ModelRegisteredAddress from "@/api/main/services/NaturalPersonProfile/models/response/GetNewUser/ModelRegisteredAddress";

export default class NaturalPersonModel {
  AcquiringCardMask: string = "";
  Address: string = "";
  AllowMassTransfers: boolean = false;
  Appeal: string = "";
  Balance: ModelPersonBalance = new ModelPersonBalance();
  BirthDate: string = "";
  CardId: number = 0;
  Cards: Array<ModelPersonCard> = [];
  CompanyName: string = "";
  ConfrimHash: string = "";
  DateLastMessage: string = "";
  DateViewMessage: string = "";
  Email: string = "";
  Id: number = 0;
  Identified: EnumTypeIdentified;
  IdentifiedId: EnumTypeIdentified ;
  IsConfirmPhone: boolean = false;
  IsHaveCards: boolean = false;
  IsTwoFactorAuthentication: boolean = false;
  LastName: string = "";
  Login: string = "";
  Name: string ="";
  NumberCard: string ="";
  NumberPassport: string ="";
  Password: string ="";
  PaymentsType: EnumUserPaymentsType = 0;
  Phone: string ="";
  RegistrationAddress: ModelRegisteredAddress = null;
  Role: string ="";
  Search: string ="";
  SearchFilter: string ="";
  Token: string ="";
  TypeIdentified: EnumTypeIdentified = 0;
  TypeUser: string ="";
  TypeUserId: EnumTypeUser = EnumTypeUser.NaturalPerson;

  constructor(obj?: Partial<NaturalPersonModel>) {
    if (obj) {
      Object.assign(this, obj)
    }
  }
}
