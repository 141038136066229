
import { Vue, Options, Prop } from "vue-property-decorator";
import { AdapterProfileService } from "../adapter";
@Options({
	name: "two-factor-authentication",
})
export default class TwoFactorAuthentication extends Vue {
	@Prop({ default: null, type: String }) srcQr: string;
	@Prop({ default: false, type: Boolean }) visible: boolean;
	adapter = AdapterProfileService.getInstance();
	isTwoAuth = true;
	changeDefense() {
		// this.isTwoAuth = !this.isTwoAuth;
		const res = this.adapter.changeDefense(this.isTwoAuth);
		// location.reload();
		this.close();
	}

	close() {
		this.$emit("close");
	}
}
