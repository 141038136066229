import EnumBeneficiaryType from "@/api/main/models/Enums/EnumBeneficiaryType";
import EnumTypeTemplate from "@/api/main/models/Enums/EnumTypeTemplate";

export default class TemplateModel {
  Id: number = null;
  PersonId: number = null;
  CardNumber: string = null;
  Amount: number = null;
  PurposePayment: string = null;
  Name: string = null;
  Type: EnumTypeTemplate = null;
  Recipient: string = null;
  BeneficiaryAddress: string = null;
  BeneficiaryCity: string = null;
  BeneficiaryPostalCode: string = null;
  BeneficiaryState: string = null;
  BeneficiaryCountryCode: string = null;
  BeneficiaryType: EnumBeneficiaryType = null;
  BeneficiaryIban: string = null
  BeneficiaryName: string = null
  Description: string = null
  SwiftCode: string = null
  TemplateName: string = null
  UserId: number = null;
  DateCreate: string = null
  ToCardNumber: string = null
  ToFirstName: string = null
  ToLastName: string = null

  constructor(obj?: Partial<TemplateModel>) {
    if (obj) {
      Object.assign(this, obj)
    }
  }
}