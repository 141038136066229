import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["tabindex"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["ui-button", { active: _ctx.active, disabled: _ctx.disabled, border: _ctx.border }]),
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.emitClick && _ctx.emitClick(...args))),
    tabindex: _ctx.tabindex
  }, [
    _createElementVNode("span", {
      class: _normalizeClass([_ctx.icon, "icon"])
    }, null, 2),
    _renderSlot(_ctx.$slots, "default")
  ], 10, _hoisted_1))
}