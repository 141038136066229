import ModelStreamFile from '../../../../../i-public-service/aba-finance/models/ModelStreamFile';

export default class PassportIdentificationData  {
	UserId!: number;
	AddressState!: string;
	AddressCountry!: string;
	AddressCity!: string;
	AddressStreet!: string;
	AddressZip!: string;
	DocumentNumber!: string;
	DocumentIssuedCountryCode!: string;
	DocumentIssuedBy!: string;
	DocumentIssuedDate!: Date;
	PassportScan!: ModelStreamFile;
	UtilityBillScan!: ModelStreamFile;
	constructor(obj?: Partial<PassportIdentificationData>) {
		if (obj) {
			Object.assign(this, obj);
		}
	}
}
