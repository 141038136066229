import EnumCurrency from '../../../aba/data/enums/EnumCurrency';

export default class CryptPayoutRequest  {
	FromUserId!: number;
	Amount!: number;
	Currency!: EnumCurrency;
	ToIban!: string;
	Description!: string;
	CryptNetworkName!: string;
	CryptCurrencyName!: string;
	CurrentUrl!: string;
	constructor(obj?: Partial<CryptPayoutRequest>) {
		if (obj) {
			Object.assign(this, obj);
		}
	}
}
