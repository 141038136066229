enum EnumTransactionType {
  /*
  / Внутренняя
  */
  Internal = 0,
  /*
  / Сепа входящая
  */
  SepaIncoming = 1,
  /*
  / Сепа исходящая
  */
  SepaOutgoing = 2,
  /*
  / Свифт входящая
  */
  SwiftIncoming = 3,
  /*
  / Свифт исходящая
  */
  SwiftOutgoing = 4,
  /*
  / Комиссия
  */
  Commission = 5,
  /*
  / Ежемесячный платёж за использование системы
  */
  AccountFee = 6,
  /*
  / Заказ карты
  */
  CardOrder = 7,
  /*
  / Пополнение карты
  */
  CardRecharge = 8,
  /*
  / Запрос пина карты
  */
  GetCardPin = 9,
  /*
  / Входящий платеж по системе эквайринга (пополнить счет) у физ лиц типа Payneteasy
  */
  AcquiringIncoming = 10,
  /*а 
  / Исходящий платеж по системе эквайринга (перевод на карту не нашу) у физ лиц типа Payneteasy
  */
  AcquiringOutgoing = 11,
  /*
  / исходящий платеж на кошелект криптовалюты
  */
  CryptOutgoing = 12,
  /*
  / исходящий платеж на кошелект криптовалюты
  */
  CardOutgoing = 13,

  /*
  / Пополнение Virtpay
  */
  VirtpayPayin = 14,
  /*
  / Вывод Virtpay
  */
  VirtpayPayout = 15,
}

export default EnumTransactionType;