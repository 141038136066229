
import ModelTransferInternal from "@/api/main/dataSource/api/i-public-service/aba-finance/models/ModelTransferInternal";
import NaturalPersonModel from "@/views/models/NaturalPersonModel";
import AccountNumberTemplateModel from "@/views/models/TemplatesTrasnfer/AccountNumberTemplateModel";
import ErrorModel from "@/views/pages/login/adapter/models/errorModel";
import { Vue, Options, Prop, Watch } from "vue-property-decorator";
import AdapterTransferService from "../../adapter/AdapterTransferService";
@Options({
	name: "account-number",
})
export default class AccountNumber extends Vue {
	@Prop({ type: Object, default: null }) profile: NaturalPersonModel;
	@Prop({ type: Number, default: null }) Type: number;
	@Prop({ type: Object, default: null })
	SelectedTemplate: AccountNumberTemplateModel;
	IsConfirmCondition: boolean = false;
	Recipient: string = null;
	IBAN: string = null;
	Amount: string = null;
	PurposePayment: string = null;
	NameTemplate: string = null;
	// Name: string = null;

	ErrorMessage: string = null;

	IsSave: boolean = false;
	IsLoad: boolean = false;

	@Watch("SelectedTemplate", { deep: true })
	onChangeTemplate() {
		if (!this.SelectedTemplate) return;
		this.IBAN = this.SelectedTemplate.CardNumber;
		this.Amount = this.SelectedTemplate.Amount;
		this.PurposePayment = this.SelectedTemplate.PurposePayment;
		this.Recipient = this.SelectedTemplate.Recipient;
	}

	async InitTransfer() {
		if (this.IsLoad) return false;
		this.ErrorMessage = "";
		var validation = this.Validation();
		if (!validation) return;

		let formData = new FormData();
		formData.append("AccountNumber", this.IBAN);
		formData.append("Amount", this.$store.state.getAmountForSend(this.Amount));
		formData.append("PurposePayment", this.PurposePayment);
		//@ts-ignore
		formData.append("IsSave", this.IsSave);
		formData.append("Recipient", this.Recipient);
		formData.append("FullName", this.Recipient);
		formData.append("NameTemplate", this.NameTemplate);
		const adapter = AdapterTransferService.getInstance();
		const req = new ModelTransferInternal({
			AccountNumber: this.IBAN,
			Amount: Number(this.Amount),
			PurposePayment: this.PurposePayment,
			IsSave: this.IsSave,
			FullName: this.Recipient,
			NameTemplate: this.NameTemplate,
		});
		this.IsLoad = true;
		const res = await adapter.checkInternalTransfer(req);
		this.IsLoad = false;

		if (res.IsSuccess) {
			this.ErrorMessage = "";
			this.$emit("GoTransferAccount", formData);
		} else {
			this.ErrorMessage = res.ErrorMessage;
		}
		// this.$api.Main.NaturalPersonInternalBalanceService.CheckTransfer((res) => {
		//   this.IsLoad = false;
		//   if (res.IsSuccess) {
		//     this.ErrorMessage = "";
		//     this.$emit("GoTransferAccount", formData);
		//   } else {
		//     this.ErrorMessage = res.Message;
		//   }
		// }, formData);//TODO api
	}

	Validation() {
		if (!this.IsConfirmCondition) {
			this.ErrorMessage =
				"You need agree with the Terms and Conditions, Privacy Policy and User Items";
			return false;
		}
		var russian = /^[а-яА-Я]*$/;
		if (
			this.IBAN == "" ||
			this.Amount == "" ||
			this.PurposePayment == "" ||
			this.Recipient == "" ||
			((this.NameTemplate == undefined || this.NameTemplate == "") && this.IsSave)
		) {
			this.ErrorMessage = "Fill in all the fields";
			return false;
		} else if (this.IBAN == this.profile.Balance.Number) {
			this.ErrorMessage = "Please enter a different account number";
			return false;
		} else if (russian.test(this.PurposePayment)) {
			this.ErrorMessage = "The text should consist only of English letters";
			return false;
		} else if (russian.test(this.Recipient)) {
			this.ErrorMessage = "The text should consist only of English letters";
			return false;
		}
		return true;
	}
}
