export default class SwiftModel {
  ToName: string = null
  ToIban: string = null
  Amount: string = null
  Commission: string = null
  BeneficiaryAddress: string = null
  BeneficiaryCountryCode: string = null
  BeneficiaryCity: string = null
  BeneficiaryState: string = null
  BeneficiaryPostalCode: string = null
  SwiftCode: string = null
  Description: string = null

  constructor(obj?: Partial<SwiftModel>) {
    if (obj) {
      Object.assign(this, obj)
    }
  }
}