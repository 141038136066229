import { AdapterApiService } from '@/views/pages';
import * as Routes from "./routerNames";
import UserType from '@/api/main/models/Enums/UserType';
import EnumTypeIdentified from '@/api/main/models/Enums/EnumTypeIdentified';

export async function authGuard(to, from, next) {
  // const { requiresAuth } = to.meta || {};
  // this.$router.beforeEach((to, from, next) => {
  //   if (this.isNext) {
  //     let languages: Array<string> = this.$localization
  //       .getLanguages()
  //       .map((lang) => lang.langCode);
  //     let currentLang: string = languages.find(
  //       (lang) => lang == (to.params.lang || "en")
  //     );
  //     if (currentLang && currentLang.length != 0) {
  //       this.$localization.setCurrentLang(currentLang); //this.$localization.currentLang)
  //       next();
  //       return;
  //       // this.$localization.currentLang = currentLang;
  //       // to.params.
  //       // this.$router.push({
  //       //   name: to.name,
  //       //   params: { lang: currentLang },
  //       // });
  //     } else {
  //       this.$localization.setCurrentLang("ru"); //this.$localization.currentLang)
  //       to.params.lang = "en";
  //       next(to);
  //       return;
  //       // this.$router.push({
  //       //   name: USERIDENTIFICATION,
  //       //   params: { lang: "en" },
  //       // });
  //     }
  //     this.isNext = false;
  //   }
  //   next();
  // });
  // Если маршрут не требует авторизации
  // if (!requiresAuth && requiresAuth !== undefined) {
  //   next();
  //   return;
  // }

  // // Получаем текущего пользователя
  // const response = await AdapterApiService.getInstance().getCurrentUser();

  // // Проверяем, если пользователь не авторизован
  // if (!response && (to.name === 'Login' || to.name === 'Signup')) {
  //   next();
  //   return;
  // }

  // // Если пользователь не авторизован и не на странице логина, перенаправляем на логин
  // if (!response) {
  //   next({ name: 'Login' });
  //   return;
  // }

  // // Деструктуризация данных пользователя
  // const { UserRole, IdentifiedStatus,FirstName,LastName } = response.Value;

  // if (FirstName == "" && LastName == "" && to.name != Routes.USERIDENTIFICATIONSIMPLE) {
  //   next({ name: Routes.USERIDENTIFICATIONSIMPLE });
  //   return;
  // }

  // // Логика для перенаправления в зависимости от статуса пользователя
  // if (to.name === undefined || to.name === "Login") {
  //   if (UserRole === UserType.Natural) {
  //     switch (IdentifiedStatus) {
  //       case EnumTypeIdentified.Confirmed:
  //         next({ name: Routes.NATURALPERSON_BALANCE });
  //         break;
  //       // case EnumTypeIdentified.NotIdentified:
  //       //   next({ name: Routes.USERIDENTIFICATIONSIMPLE });
  //       //   break;
  //       case EnumTypeIdentified.Consideration:
  //         next({ name: Routes.NATURALPERSON_PROFILE_IDENTIFICATION });
  //         break;
  //       default:
  //         next({ name: Routes.USERIDENTIFICATIONSIMPLE });
  //         break;
  //     }
  //   }
  // }

  // next();
  
  const response = await AdapterApiService.getInstance().getCurrentUser();

  // Проверяем, требует ли маршрут авторизации
  if (to.matched.some(record => record.meta.requiresAuth)) {

    // Если пользователь не авторизован — перенаправляем на страницу входа
    if (!response.IsSuccess) {
      return next({ name: 'Login' });
    }
    const { UserRole, IdentifiedStatus, FirstName, LastName } = response.Value;
    const UserRoleText = (UserRole == UserType.Legal) ? 'legal' : 'natural';


    // Проверяем наличие specialAccess для типа пользователя
    const specialAccess = to.meta.specialAccess && to.meta.specialAccess[UserRoleText];

    // Если specialAccess существует и текущий статус идентификации пользователя не соответствует требуемому
    if (specialAccess && !specialAccess.includes(IdentifiedStatus)) {
      // Перенаправляем пользователя на страницу по умолчанию в зависимости от типа пользователя
      if (UserRole == UserType.Legal && IdentifiedStatus != 2)
        return next({
          name: "profile-identification-legal",
        });
      if (UserRole == UserType.Legal)
        return next({
          name: Routes.BALANCE,
        });
      if (UserRole == UserType.Natural && !(FirstName && LastName))
        return next({
          name: Routes.USERIDENTIFICATIONSIMPLE,
        });
      else {
        return next({
          name: Routes.PROFILE_IDENTIFICATION,
        });
      }
    }

    if (IdentifiedStatus == 0 && to.name == "naturalperson-balance") {
      return next({ name: Routes.PROFILE_IDENTIFICATION })
    }
  }

  // Если маршрут не требует авторизации или все условия выполнены, разрешаем переход
  next();
};

