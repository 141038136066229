export default class Language {
  Id: number = 0;
  Name: string = null;
  ImageName: string = null;
  HtmlId: string = null;

  constructor(obj?: Partial<Language>) {
    if (obj) {
      Object.assign(this, obj)
    }
  }
}