
import { Vue, Options, Prop, Watch } from "vue-property-decorator";
import SwiftModel from "@/views/models/Transfer/SwiftModel";
import { useRoute, useRouter } from "vue-router";
import * as Routes from "@/router/routerNames";
@Options({
	name: "swift-confrim",
})
export default class SwiftConfrim extends Vue {
	@Prop({ default: false }) visible: boolean;
	@Prop({ default: null }) formData: FormData;

	pinCode: string = "";
	router = useRouter();
	ErrMessage: string = "";
	IsSuccess: boolean = false;
	IsLoad: boolean = false;

	model: SwiftModel = null;

	created() {
		this.model = new SwiftModel();
	}

	@Watch("formData", { deep: true })
	onChangeFormData() {
		if (!this.formData) return;
		this.model = new SwiftModel({
			ToName: this.formData.get("ToName").toString(),
			ToIban: this.formData.get("ToIban").toString(),
			Amount: this.formData.get("Amount").toString(),
			Commission: this.formData.get("Commission").toString(),
			BeneficiaryAddress: this.formData.get("BeneficiaryAddress").toString(),
			BeneficiaryCountryCode: this.formData.get("BeneficiaryCountryCode").toString(),
			BeneficiaryCity: this.formData.get("BeneficiaryCity").toString(),
			BeneficiaryState: this.formData.get("BeneficiaryState").toString(),
			BeneficiaryPostalCode: this.formData.get("BeneficiaryPostalCode").toString(),
			SwiftCode: this.formData.get("SwiftCode").toString(),
			Description: this.formData.get("Description").toString(),
		});
	}

	onInputPin(pin: string) {
		this.pinCode = pin;
	}

	Transfer() {
		if (this.IsLoad) return false;
		this.ErrMessage = "";
		if (this.pinCode.length < 6) {
			this.ErrMessage = this.translation("EnterPINСode");
			return false;
		}
		var request = new FormData();
		let entries = this.formData.entries();
		this.formData.forEach((item) => {
			let ent = entries.next().value;
			request.append(`TransferSwift[${ent[0]}]`, ent[1]);
		});
		request.append("Pin", this.pinCode);
		this.IsLoad = true;

		// this.$api.Main.SwiftService.TransferSwift((res) => {
		//   this.IsLoad = false;

		//   if ((res as any).IsError) {
		//     this.$store.state.modalDialogAlert("Неизвестная ошибка при загрузке");
		//     return;
		//   }

		//   if (res.IsSuccess) this.IsSuccess = res.IsSuccess;
		//   else
		//     this.ErrMessage = this.$localization.translateResponseCode(
		//       res.ResponseCode
		//     );
		// }, request);//TODO api
	}

	close() {
		this.ErrMessage = "";
		if (this.IsSuccess) this.router.push({ name: Routes.TRANSUCTIONS });
		this.$emit("close");
	}

	translation(key: string) {
		return this.$localization.getTranslate(key);
	}
}
