// @ts-ignore
import { WebApiService } from './webApiService'

/**
 * * Сервис отправки пингов, для проверки подключения
 */
export class PingService {
  /**
   * * Объект сокета в который отправляются пинги
   */
  apiService!: WebApiService
  /**
   * * Объект запущенного сокета
   */
  websocket!: WebSocket
  /**
   * * Получен ли ответ от пинга
   */
  recievedPing = false
  /**
   * * Таймаут отправки пинга
   */
  pingIntervalTimeout = 15 * 1000
  /**
   * * Интервал пинга
   */
  intervalPing?: ReturnType<typeof setTimeout>
  /**
   * * Таймаут пинга
   */
  timeoutPing?: ReturnType<typeof setTimeout>
  /**
   * * Кол-во неудачных попыток пинга
   */
  failedPingsCount = 0
  /**
   * * Таймаут проверки ответа от пинга
   */
  pingCheckedTime = 5 * 1000
  /**
   * * Пинг запрос
   */
  request = {
    ProjectName: 'MainWs',
    Controller: 'WsStateWsController',
    Method: 'Ping',
  }

  /*
   * Отправляет пинг вебсокету каждые 20 сек и проверяет ответ спустя 5 сек, закрывает сокет при отсутствии ответа
   */
  private startSendingPing() {
    this.disposePing()
    window.addEventListener('focus', this.sendPing.bind(this))
    this.apiService?.on(
      this.request.Controller,
      this.request.Method,
      this.request.ProjectName,
      this.checkStatusConnect.bind(this)
    )
    this.intervalPing = setInterval(() => {
      this.sendPing()
      this.timeoutPing = setTimeout(() => {
        if (!this.recievedPing) {
          this.websocket?.close()
          this.failedPingsCount++
          if (this.failedPingsCount > 25) this.disposePing()
        } else this.recievedPing = false
      }, this.pingCheckedTime)
    }, this.pingIntervalTimeout)
  }
  /**
   * * Отписка от всех подписок
   */
  disposePing() {
    window.removeEventListener('focus', this.sendPing.bind(this))
    this.apiService?.unsubscribe(
      this.request.ProjectName,
      this.request.Controller,
      this.request.Method
    )
    clearInterval(this.intervalPing)
    clearTimeout(this.timeoutPing)
  }
  /**
   * * Получение пинга
   */
  checkStatusConnect() {
    this.recievedPing = true
    this.failedPingsCount = 0
  }
  /**
   * * Отправить пинг
   */
  sendPing() {
    this.apiService?.send(this.request)
  }

  /**
   * * Инициализация сервиса
   * @param apiService API сервис
   * @param websocket Объект веб сокета
   */
  constructor(apiService: WebApiService, websocket: WebSocket) {
    if (!apiService || !websocket) return

    this.apiService = apiService
    this.websocket = websocket
    this.startSendingPing()
  }
}
