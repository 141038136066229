
import EnumCompanyDocumentType from "@/api/main/dataSource/api/aba/data/enums/EnumCompanyDocumentType";
import UploadPhotoCropRequest from "@/api/main/dataSource/api/file-services/models/UploadPhotoCropRequest";
import CompanyDocumentModel from "@/views/pages/user-identification/models/CompanyDocumentModel";
import { Vue, Prop, Watch, Options } from "vue-property-decorator";
import UiSelectComponent from "../ui-select.vue";
@Options({
	name: "ui-company-document-adding",
})
export default class UiCompanyDocumentAdding extends Vue {
	@Prop({ default: false }) isMultiple: boolean;
	@Prop({ default: "" }) headerText: string;
	@Prop({ default: "#6f8fb3" }) buttonColor: string;
	@Prop({ default: "" }) buttonText: string;
	@Prop({ default: [] }) documents: CompanyDocumentModel[];
	@Prop({ default: false }) isSendMode: boolean;
	@Prop({ default: new Array<string>() }) items: Array<string>;
	@Prop({ default: false }) isWrong: boolean;
	@Prop({ default: null }) id: number;

	buttonStyle: Object = new Object();
	styleForWrong: boolean = false;
	chooseFileType: EnumCompanyDocumentType = null;
	documentTypes: Array<{ type: EnumCompanyDocumentType; text: string }> = new Array<{
		type: number;
		text: string;
	}>();

	get isDisabled(): boolean {
		return this.chooseFileType === null;
	}
	get allowedDocumentTypes(): Array<{
		type: EnumCompanyDocumentType;
		text: string;
	}> {
		const self = this;
		return this.documentTypes.filter((x) => {
			return (
				self.isMultipleFiles(x.type) || !self.documents.some((i) => i.Type === x.type)
			);
		});
	}

	isMultipleFiles(type: EnumCompanyDocumentType) {
		switch (type) {
			case EnumCompanyDocumentType.RegistrationDocument:
				return false;
			case EnumCompanyDocumentType.ExtractFromRegister:
				return false;
			case EnumCompanyDocumentType.CompanyCharter:
				return false;
			default:
				return true;
		}
	}
	addedFile(event) {
		let doc: CompanyDocumentModel = new CompanyDocumentModel();
		doc.Type = this.chooseFileType;
		const res =new UploadPhotoCropRequest({
			FileName: event.name,
			FileBase64: event.fileBase64,
		});
		doc.File = res.FileBase64;
		doc.FileName = res.FileName;
		this.$emit("addedFile", doc);
		this.chooseFileType = null;
		this.resetSelectDocs();
	}

	created() {
		for (let item in EnumCompanyDocumentType) {
			if (isNaN(Number(item))) {
				let itemEnum: any = EnumCompanyDocumentType[item];
				this.documentTypes.push({
					type: itemEnum,
					text: this.$localization.getTranslate(this.setHeader(itemEnum)),
				});
			}
		}
		this.mouseLeaveButton();
	}

	setHeader(type: EnumCompanyDocumentType) {
		switch (type) {
			case 0:
				return "Header.RegisterDocument";
			case 1:
				return "Header.ExtractFromTheRegister";
			case 2:
				return "Header.CompanyCharter";
			case 3:
				return "Header.PowerOfAttorney";
			case 4:
				return "Header.TrustDeclaration";
			case 5:
				return "Header.NomineeRegistrationDocument";
			default:
				return type.toString();
		}
	}

	@Watch("isWrong")
	onUpdateIsWrong(newValue: boolean) {
		this.styleForWrong = newValue;
	}

	setDisabledStyle() {
		this.buttonStyle = {
			backgroundColor: "#8e97a1 !important",
			color: "#fff !important",
			border: "1px solid #8e97a1 !important",
			transition: "0.3s",
		};
	}

	@Watch("isDisabled")
	onUpdateIsDisabled(newValue: boolean) {
		if (newValue) {
			this.setDisabledStyle();
		} else {
			this.mouseLeaveButton();
		}
	}

	mouseLeaveButton() {
		if (this.isDisabled) {
			this.setDisabledStyle();
		} else {
			this.buttonStyle = {
				backgroundColor: this.buttonColor,
				border: `1px solid ${this.buttonColor}`,
				color: "#fff",
				transition: "0.3s",
			};
		}
	}

	mouseOverButton() {
		if (this.isDisabled) {
			this.setDisabledStyle();
		} else {
			this.buttonStyle = {
				backgroundColor: "#fff",
				border: `1px solid ${this.buttonColor}`,
				color: this.buttonColor,
				transition: "0.3s",
			};
		}
	}

	resetSelectDocs() {
		(this.$refs.uiSelectDocs as UiSelectComponent).innerValue = null;
	}
}
