import EnumStatusUser from '../../../../aba/data/enums/EnumStatusUser';
import ModelPersonCard from '../../../../i-public-service/aba-finance/models/card/ModelPersonCard';
import ModelLegalPersonBalance from '../../../../i-public-service/aba-finance/models/user/ModelLegalPersonBalance';
import ModelPersonBalance from '../../../../i-public-service/aba-finance/models/ModelPersonBalance';
import ModelUserBase from '../../../../i-public-service/aba-finance/models/user/ModelUserBase';

export default class ModelLegalPerson extends ModelUserBase {
	Status!: EnumStatusUser;
	IdentifiedId!: number;
	AddressCompany!: string;
	Cards!: Array<ModelPersonCard>;
	CountEmployees!: number;
	UsernameApi!: string;
	PasswordApi!: string;
	NumberPassport!: string;
	Account!: ModelLegalPersonBalance;
	RegistrationNumber!: string;
	Balance!: ModelPersonBalance;
	OnlineShopId?: string;
	constructor(obj?: Partial<ModelLegalPerson>) {
		super(obj);
		if (obj) {
			Object.assign(this, obj);
		}
	}
}
