import { WebApiService } from '../../../../../plugins/webApiService'
import { Events } from '../../../../../plugins/events'
import { SendRequestModel } from '../../../../../plugins/models/SendRequestModel'
import LegalBusinessProfileSaveDataRequest from '../../../../../identification-services/models/legal-business-profile/LegalBusinessProfileSaveDataRequest';
import WsResponseModelT from '../../../../../schema-builder/server/models/WsResponseModelT';
import LegalBusinessProfileGetDataResponse from '../../../../../identification-services/models/legal-business-profile/LegalBusinessProfileGetDataResponse';

export default class LegalBusinessProfileWsController {
	private url?: string;
	private cookieName: string;
	private appWebSettingsPath?: string;
	private apiUrlProperty?: string;

	GetBusinessProfileEvent = new Events<WsResponseModelT<LegalBusinessProfileGetDataResponse>>();
	SaveBusinessProfileDataEvent = new Events<WsResponseModelT<Boolean>>();

	subscribeEvents = async () => {
		const self = this;
		const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
		webApiService.on('LegalBusinessProfileWsController', 'GetBusinessProfile', 'Main', (res: WsResponseModelT<LegalBusinessProfileGetDataResponse>) => {
			self.GetBusinessProfileEvent.trigger(res);
		});
		webApiService.on('LegalBusinessProfileWsController', 'SaveBusinessProfileData', 'Main', (res: WsResponseModelT<Boolean>) => {
			self.SaveBusinessProfileDataEvent.trigger(res);
		});
	}

	constructor(url?: string, cookieName?: string, appWebSettingsPath?: string, apiUrlProperty?: string) {
		this.url = url;
		this.cookieName = cookieName ?? "Auth-Token";
		this.appWebSettingsPath = appWebSettingsPath && [location.host, appWebSettingsPath].join("/");
		this.apiUrlProperty = apiUrlProperty;
		this.subscribeEvents();
	}

	async GetBusinessProfile() {
		const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
		const sendRequest = new SendRequestModel({
			ProjectName: 'Main',
			Controller: 'LegalBusinessProfileWsController',
			Method: 'GetBusinessProfile',
		})

		webApiService.send(sendRequest);
	}

	async SaveBusinessProfileData(request: LegalBusinessProfileSaveDataRequest) {
		const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
		const sendRequest = new SendRequestModel({
			ProjectName: 'Main',
			Controller: 'LegalBusinessProfileWsController',
			Method: 'SaveBusinessProfileData',
			Value: typeof(request) === 'object' ? JSON.stringify(request) : request,
		})

		webApiService.send(sendRequest);
	}

	 // async methods
	async getBusinessProfileAsync(): Promise<LegalBusinessProfileGetDataResponse> {
		return new Promise<LegalBusinessProfileGetDataResponse>(async (resolve, reject) => {
			const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
			const sendRequest = new SendRequestModel({
				ProjectName: 'Main',
				Controller: 'LegalBusinessProfileWsController',
				Method: 'GetBusinessProfile',
				Callback: (response: WsResponseModelT<LegalBusinessProfileGetDataResponse>) => {
					const isGeneric = typeof response.IsSuccess == 'boolean'
					const result = response.IsSuccess ? response.Value : response

					if (!isGeneric || response.IsSuccess) resolve(result as LegalBusinessProfileGetDataResponse)
					else reject(result)
				}
			})

			webApiService.send(sendRequest);
		});
	}

	async saveBusinessProfileDataAsync(request: LegalBusinessProfileSaveDataRequest): Promise<Boolean> {
		return new Promise<Boolean>(async (resolve, reject) => {
			const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
			const sendRequest = new SendRequestModel({
				ProjectName: 'Main',
				Controller: 'LegalBusinessProfileWsController',
				Method: 'SaveBusinessProfileData',
				Value: typeof(request) === 'object' ? JSON.stringify(request) : request,
				Callback: (response: WsResponseModelT<Boolean>) => {
					const isGeneric = typeof response.IsSuccess == 'boolean'
					const result = response.IsSuccess ? response.Value : response

					if (!isGeneric || response.IsSuccess) resolve(result as Boolean)
					else reject(result)
				}
			})

			webApiService.send(sendRequest);
		});
	}

}