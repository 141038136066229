
import NaturalPersonModel from "@/views/models/NaturalPersonModel";
import { Vue, Options, Prop, Watch } from "vue-property-decorator";
import { AdapterProfileService } from "../adapter";
@Options({
	name: "identification-data",
})
export default class IdentificationData extends Vue {
	@Prop({ type: Object, default: null }) Person!: NaturalPersonModel;

	Identified: number = 2;
	NumberPassport: string = "";
	Passport: string = "";
	Photos: Array<string> = [];
	scanThePassport: string = "";
	scanningAccountForUtilities: string = "";
	adapter = AdapterProfileService.getInstance();

	@Watch("Person", { deep: true })
	onChangePerson() {
		if (this.Person) {
			this.Identified = this.Person.IdentifiedId;
			this.NumberPassport = this.Person.NumberPassport;
		}
	}

	created() {
		this.init();
		this.onChangePerson();
		this.GetPhotos();
	}

	init() {
		this.scanThePassport = this.$localization.getTranslate("ScanThePassport");
		this.scanningAccountForUtilities = this.$localization.getTranslate(
			"ScanningAccountForUtilities"
		);
	}

	async GetPhotos() {
		var self = this;
		const res = await this.adapter.getPhotos();

		this.Photos = res as any; //TODO странный ответ с сервера приходит
		if (this.Photos.length < 2) {
			self.Photos = ["not found", "not found"];
		}
	}
}
