import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "data_input_wrapper" }
const _hoisted_2 = { class: "field_wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("label", {
        class: "field_file_wrapper",
        style: _normalizeStyle({ pointerEvents: _ctx.isDisabled ? 'none' : 'unset' })
      }, [
        _createElementVNode("input", {
          type: "file",
          class: "file_input",
          onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.addFile && _ctx.addFile(...args))),
          ref: "file"
        }, null, 544),
        _createElementVNode("div", {
          class: _normalizeClass([_ctx.isWrong ? 'wrong' : '', 'field_file_button']),
          style: _normalizeStyle(_ctx.buttonStyle),
          onMouseover: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.mouseOverButton && _ctx.mouseOverButton(...args))),
          onMouseleave: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.mouseLeaveButton && _ctx.mouseLeaveButton(...args)))
        }, _toDisplayString(_ctx.buttonText || _ctx.$localization.getTranslate("Button.AddDocument")), 39)
      ], 4)
    ])
  ]))
}