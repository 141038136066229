
// import EnumLegalPartnerType from "@/api/dataSource/api/Aba/Data/Enums/EnumLegalPartnerType";
// import LegalBusinessProfileGetDataResponse from "@/api/dataSource/api/IdentificationServices/Models/LegalBusinessProfile/LegalBusinessProfileGetDataResponse";
import { Vue, Options, Prop, Watch } from "vue-property-decorator";
import BusinessPartnerModel from "../models/BusinessPartnerModel";
import ContinueButton from "./../components/continue-button.vue";
import BusinessProfileViewModel from "./../models/BusinessProfileViewModel";
import { Countries } from "@/models/const/countries";
// import LegalPartnersGetResponse from "@/api/dataSource/api/IdentificationServices/Models/LegalBusinessProfile/LegalPartnersGetResponse";
// import UploadPhotoCropRequest from "@/api/dataSource/api/fileServices/models/UploadPhotoCropRequest";
import EnumApiErrors from "@models/enums/EnumApiError";
import { isDevelopment } from "@/helper/helpers";
// import GetBusinessActivityResponse from "@/api/dataSource/api/IdentificationServices/Models/BusinessActivity/GetBusinessActivityResponse";

import GetBusinessActivityResponse from "@/api/main/dataSource/api/identification-services/models/business-activity/GetBusinessActivityResponse";
import UploadPhotoCropRequest from "@/api/main/dataSource/api/file-services/models/UploadPhotoCropRequest";
import LegalPartnersGetResponse from "@/api/main/dataSource/api/identification-services/models/legal-business-profile/LegalPartnersGetResponse";
import LegalBusinessProfileGetDataResponse from "@/api/main/dataSource/api/identification-services/models/legal-business-profile/LegalBusinessProfileGetDataResponse";
import EnumLegalPartnerType from "@/api/main/dataSource/api/aba/data/enums/EnumLegalPartnerType";
import { AdapterApiService } from "../../_adapter";
@Options({
	components: {
		"continue-button": ContinueButton,
	},
	emits: ["check", "changeTab", "end"],
})
export default class BusinessProfileTab extends Vue {
	@Prop({ default: null }) userId: number;
	countries: Array<{ value: string; text: string }> = Countries;
	businessProfileData: BusinessProfileViewModel = null;
	bisnessActivityDictionary: GetBusinessActivityResponse[] = [];
	errorText: string = "";
	isLoading: boolean = false;
	errorMessage: string = "";
	isAnother: boolean = false;
	currentLang: string = "";
	checkStart: boolean = false;
	adapter = AdapterApiService.getInstance();
	itemsPer1MLN = [
		{
			value: 0,
			text: "до 100к",
		},
		{
			value: 1,
			text: "100к - 250к",
		},
		{
			value: 2,
			text: "250к - 500к",
		},
		{
			value: 3,
			text: "500к - 1млн",
		},
		{
			value: 4,
			text: "более 1млн",
		},
	];

	itemsPer250K = [
		{
			value: 0,
			text: "до 10к",
		},
		{
			value: 1,
			text: "10к - 50к",
		},
		{
			value: 2,
			text: "50к - 100к",
		},
		{
			value: 3,
			text: "100к - 250к",
		},
		{
			value: 4,
			text: "более 250к",
		},
	];

	@Watch("errorText")
	onUpdateButtonText(newValue: string) {
		if (newValue.length) {
			this.errorMessage = this.$localization.getTranslate(`EnumApiError.${newValue}`);
		}
	}

	@Watch("isAnother")
	onUpdateIsAnother() {
		if (!this.isAnother) {
			this.businessProfileData.BusinessActivityOtherText = "";
		}
	}

	onAddedFile(partner: BusinessPartnerModel, file) {
		partner.File = new UploadPhotoCropRequest({
			FileBase64: file.fileBase64,
			FileName: file.name,
		});
		partner.FileUrl = null;
	}
	onClearFile(partner: BusinessPartnerModel) {
		partner.File = new UploadPhotoCropRequest();
		partner.FileUrl = null;
	}
	removePartner(partner: BusinessPartnerModel) {
		this.businessProfileData.LegalPartners = this.businessProfileData.LegalPartners.filter(
			(x) => x !== partner
		);
	}

	async saveAndContinue() {
		this.checkStart = true;
		const s = this;
		if (this.businessProfileData.isValid()) {
			this.errorText = "";
			this.isLoading = true;
			if (!this.isAnother) {
				this.businessProfileData.BusinessActivityOtherText = "";
			}
			
			await this.adapter
				.saveBusinessProfileDataAsync(
					this.businessProfileData.toLegalBusinessProfileSaveDataRequest()
				)
				.then(async () => {
					// this.$store.state.setBusinessProfileData(null);
					// await this.adapter.getBusinessProfileAsync();
					this.$emit("check", 6, true);
					this.isLoading = false;
					//http://localhost:8080/en/LegalIdentification?errorStart=Error%20start%20finchecker%20identification
					this.$emit("changeTab", 4);
					//this.$emit("end");
					//if (isDevelopment()) alert("Конец");
				})
				.catch(this.catchError);
		} else {
			// this.$emit("check", 3, false);
			this.errorText = "IncompleteInformation";
		}
	}

	deleteFile(file: BusinessPartnerModel) {
		this.businessProfileData.LegalPartners.find((x) => x == file).File = null;
		this.businessProfileData.LegalPartners.find((x) => x == file).FileUrl = null;
	}

	takePartner(partner: LegalPartnersGetResponse) {
		return this.businessProfileData.LegalPartners.find((i) => i == partner);
	}

	takePartners(num: EnumLegalPartnerType) {
		if (!this.businessProfileData) {
			return;
		}
		if (num == EnumLegalPartnerType.CompanyIncomingPayments) {
			return this.businessProfileData.LegalPartners.filter(
				(x) => x.PartnerType == EnumLegalPartnerType.CompanyIncomingPayments
			);
		} else if (num == EnumLegalPartnerType.CompanyOutgoingPayments) {
			return this.businessProfileData.LegalPartners.filter(
				(x) => x.PartnerType == EnumLegalPartnerType.CompanyOutgoingPayments
			);
		}
	}

	addPartner(key: string) {
		let partnerType: number = null;
		if (key == "in") {
			partnerType = EnumLegalPartnerType.CompanyIncomingPayments;
		} else if (key == "out") {
			partnerType = EnumLegalPartnerType.CompanyOutgoingPayments;
		}
		this.businessProfileData.LegalPartners.push(
			new BusinessPartnerModel({ PartnerType: partnerType })
		);
	}

	async getBusinessActivityDictionary() {
		await this.adapter
			.getBusinessActivitiesListAsync()
			.then((data) => {
				this.bisnessActivityDictionary = data;
			})
			.catch((error) => {
				console.log("getBusinessActivityDictionary error", error);
			});
	}

	getDictionariesLang() {
		if (this.$localization.currentLang) {
			switch (this.$localization.currentLang) {
				case "en":
					this.currentLang = "NameEn";
					break;

				case "ru":
					this.currentLang = "NameRu";
					break;

				case "cz":
					this.currentLang = "NameCz";
					break;

				default:
					this.currentLang = "NameEn";
					break;
			}
		} else {
			this.currentLang = "NameEn";
		}
	}

	onCheck(id: number) {
		if (this.businessProfileData.BusinessActivity.find((x) => x == id)) {
			this.businessProfileData.BusinessActivity = this.businessProfileData.BusinessActivity.filter(
				(x) => x != id
			);
		} else {
			this.businessProfileData.BusinessActivity.push(id);
		}
	}

	checkAvailability(id: number) {
		if (
			this.bisnessActivityDictionary &&
			this.businessProfileData.BusinessActivity.find((x) => x == id)
		) {
			return true;
		} else {
			return false;
		}
	}

	async mounted() {
		this.getDictionariesLang();
		await this.getBusinessActivityDictionary();
		await this.getBusinessProfile();

		this.itemsPer1MLN[0].text =
			this.$localization.getTranslate("Before") +
			" 10" +
			this.$localization.getTranslate("K");

		this.itemsPer1MLN[1].text =
			"100" +
			this.$localization.getTranslate("K") +
			" - " +
			"250" +
			this.$localization.getTranslate("K");

		this.itemsPer1MLN[2].text =
			"250" +
			this.$localization.getTranslate("K") +
			" - " +
			"500" +
			this.$localization.getTranslate("K");

		this.itemsPer1MLN[3].text =
			"500" +
			this.$localization.getTranslate("K") +
			" - " +
			"1" +
			this.$localization.getTranslate("MLN");

		this.itemsPer1MLN[4].text =
			this.$localization.getTranslate("More") +
			" 1" +
			this.$localization.getTranslate("MLN");

		this.itemsPer250K[0].text =
			this.$localization.getTranslate("Before") +
			" 10" +
			this.$localization.getTranslate("K");

		this.itemsPer250K[1].text =
			"10" +
			this.$localization.getTranslate("K") +
			" - " +
			"50" +
			this.$localization.getTranslate("K");

		this.itemsPer250K[2].text =
			"50" +
			this.$localization.getTranslate("K") +
			" - " +
			"100" +
			this.$localization.getTranslate("K");

		this.itemsPer250K[3].text =
			"100" +
			this.$localization.getTranslate("K") +
			" - " +
			"250" +
			this.$localization.getTranslate("K");

		this.itemsPer250K[4].text =
			this.$localization.getTranslate("More") +
			" 250" +
			this.$localization.getTranslate("K");

		
	}

	async getBusinessProfile() {
		if (this.$store.state.businessProfileData) {
			this.businessProfileData = this.$store.state.businessProfileData;
		} else {
			await this.adapter
				.getBusinessProfileAsync()
				.then(this.onSuccessGetBusinessProfile)
				.catch(this.catchError);
		}
	}

	onSuccessGetBusinessProfile(response: LegalBusinessProfileGetDataResponse) {
		this.businessProfileData = new BusinessProfileViewModel(response);
		this.businessProfileData.BusinessActivity = response.BusinessActivity.map(
			(x) => x.Id
		);
		if (
			response.BusinessActivityOtherText &&
			response.BusinessActivityOtherText.length != 0
		) {
			this.isAnother = true;
		} else {
			this.businessProfileData.BusinessActivityOtherText = "";
		}
		// this.$store.state.setBusinessProfileData(this.businessProfileData);
	}

	catchError(err) {
		if (EnumApiErrors[err.ErrorCode]) {
			this.errorText = EnumApiErrors[err.ErrorCode];
		} else {
			this.errorText = "ErrorOccured";
		}
		// this.$emit("check", 3, false);
		this.isLoading = false;
	}
}
