
import NaturalPersonModel from "@/views/models/NaturalPersonModel";
import { Vue, Options, Prop } from "vue-property-decorator";
@Options({
  name: "card-transfer",
})
export default class CardTransfer extends Vue {
  @Prop({ type: Object, default: null }) profile: NaturalPersonModel;
  @Prop({ type: Number, default: null }) Type: number;
  IsConfirmCondition: boolean = false;
  Recipient: string = "";
  IBAN: string = "";
  Amount: string = "";
  PurposePayment: string = "";
  ErrorMessage: string = "";
  Name: string = "";
  Commission: number = 0;

  IsLoad: boolean = false;
  IsSave: boolean = false;

  getCommission() {
    var validation = this.Validation();
    if (!validation) return;

    let formData = new FormData();
    formData.append("Type", "30");
    formData.append("Amount", this.$store.state.getAmountForSend(this.Amount));

    // this.$api.Main.CommissionsService.GetCommission((res) => {
    // 	if (res.IsSuccess) {
    // 		this.Commission = res.Value;
    // 		this.InitTransfer();
    // 	}
    // }, formData);//TODO api
  }

  InitTransfer() {
    if (this.IsLoad) return false;
    this.ErrorMessage = "";
    var validation = this.Validation();
    if (!validation) return;
    let formData = new FormData();
    formData.append("ToIban", this.IBAN);
    formData.append("Amount", this.$store.state.getAmountForSend(this.Amount));
    formData.append("Description", this.PurposePayment);
    formData.append("ToName", this.Recipient);
    formData.append("Commission", this.Commission.toString());

    this.IsLoad = true;
    // this.$api.Main.CardPayoutService.CheckTransfer((res) => {
    //   this.IsLoad = false;
    //   if (res.IsSuccess) {
    //     this.ErrorMessage = "";
    //     this.$emit("GoTransferCardPayout", formData);
    //   } else {
    //     this.ErrorMessage = res.Message;
    //   }
    // }, formData);//TODO api
  }
  Validation() {
    if (!this.IsConfirmCondition) {
      this.ErrorMessage =
        "You need agree with the Terms and Conditions, Privacy Policy and User Items";
      return false;
    }
    var russian = /^[а-яА-Я]*$/;
    if (
      this.IBAN == "" ||
      this.Amount == "" ||
      this.PurposePayment == "" ||
      this.Recipient == "" ||
      ((this.Name == undefined || this.Name == "") && this.IsSave)
    ) {
      this.ErrorMessage = "Fill in all the fields";
      return false;
    } else if (this.IBAN == this.profile.Balance.Number) {
      this.ErrorMessage = "Please enter a different account number";
      return false;
    } else if (
      russian.test(this.PurposePayment) ||
      russian.test(this.Recipient)
    ) {
      this.ErrorMessage = "The text should consist only of English letters";
      return false;
    }
    return true;
  }
}
