import EnumCommission from '../../../../aba/data/enums/EnumCommission';

export default class GetCommissionRequest  {
	Amount!: number;
	Type!: EnumCommission;
	constructor(obj?: Partial<GetCommissionRequest>) {
		if (obj) {
			Object.assign(this, obj);
		}
	}
}
