import ApiService from '@/api/index';
import CurrentUserResponse from '@/api/main/dataSource/api/aba/cabinet/web/models/CurrentUserResponse';
import ModelNaturalPerson from '@/api/main/dataSource/api/i-public-service/aba-finance/models/user/ModelNaturalPerson';
import GetNaturalPersonResponse from '@/api/main/services/NaturalPersonHome/models/response/GetNaturalPersonResponse';

import { notify } from '@/app/notifications';
import NaturalPersonModel from '@/views/models/NaturalPersonModel';
import moment from 'moment';


import { ref } from 'vue';
import ErrorModel from './models/errorModel';
import ModelPersonBalance from '@/api/main/services/NaturalPersonProfile/models/response/GetNewUser/ModelPersonBalance';
import EnumTypeUser from '@/api/main/models/Enums/EnumTypeUser';
import UserType from '@/api/main/models/Enums/UserType';
import GetRefillRequisitesResponse from '@/api/main/dataSource/api/aba/cabinet/web/models/GetRefillRequisitesResponse';
import WsResponseModel from '@/api/main/SchemaBuilderServer/Models/WsResponseModel';
import DownloadTransactionsForNaturalRequest from '@/api/main/dataSource/api/i-public-service/aba-finance/models/transaction/DownloadTransactionsForNaturalRequest';
import ModelLegalPerson from '@/api/main/dataSource/api/i-public-service/aba-finance/models/user/ModelLegalPerson';


export default class AdapterNaturalBalansService {

  /**
 * Сервис
 */
  private static instance: AdapterNaturalBalansService;
  /**
 * API
 */
  private static api: ApiService;
  isLoading: boolean = false;
  isInited = ref(false);
  api: ApiService;

  constructor() {
    AdapterNaturalBalansService.api = new ApiService();
  }

  public static getInstance() {
    if (!AdapterNaturalBalansService.instance) {
      AdapterNaturalBalansService.instance = new AdapterNaturalBalansService();
    }
    return AdapterNaturalBalansService.instance;
  }

 
  // DownloadTransactionInfo

  async downloadTransactionInfo(req: DownloadTransactionsForNaturalRequest): Promise<WsResponseModel<String>> {
    const result = new WsResponseModel<String>();
    try {
      const res = await AdapterNaturalBalansService.api.Main.FileWsController.downloadTransactionsForNaturalAsync(req);
      result.Value = res;
      result.IsSuccess = true;
    } catch (e) {
      this.handleError(e, "ERROR downloadTransactionInfo")
    }
    return result;
  }
  
  toGetNaturalPersonResponse(value: ModelNaturalPerson): GetNaturalPersonResponse {


    return new GetNaturalPersonResponse({
      Identified: value.Identified,
      IdentifiedId: value.IdentifiedId,
      ConfrimHash: value.ConfrimHash,
      IsConfirmPhone: value.IsConfirmPhone,
      CardId: value.CardId,
      NumberCard: value.NumberCard,
      NumberPassport: value.NumberPassport,
      RegistrationAddress: value.RegistrationAddress,
      SearchFilter: value.SearchFilter,
      Search: value.Search,
      Appeal: value.Appeal,
      Balance: value.Balance,
      Cards: value.Cards,
      BirthDate: value.BirthDate ? value.BirthDate.toISOString() : null, // Преобразование даты в строку, если требуется
      AcquiringCardMask: value.AcquiringCardMask,
    });

  }

  async getCurrentUser(): Promise<CurrentUserResponse | ErrorModel> {
    try {
      const res = await AdapterNaturalBalansService.api.Main.AuthWsController.getCurrentUserAsync()
      return res
    } catch (err: any) {
      this.showError(err)
      return new ErrorModel({ ErrorCode: err.ErrorCode, ErrorMessage: err.ErrorMessage })
    }
  }
  
  convertModelNaturalPersonToNaturalPersonModel(req: ModelNaturalPerson): NaturalPersonModel{
    const result = new NaturalPersonModel({
      AcquiringCardMask: req.AcquiringCardMask,
      Address: req.Address,
      AllowMassTransfers: req.AllowMassTransfers,
      Appeal: req.Appeal,
      Balance: new ModelPersonBalance(req.Balance),
      BirthDate: this.dateToString(req.BirthDate),
      CardId: req.CardId,
      Cards: req.Cards,
      CompanyName: req.CompanyName,
      ConfrimHash: req.ConfrimHash,
      DateLastMessage: this.dateToString(req.DateLastMessage),
      DateViewMessage: this.dateToString(req.DateViewMessage),
      Email: req.Email,
      Id: req.Id,
      Identified: req.Identified, IdentifiedId: req.IdentifiedId,
      IsConfirmPhone: req.IsConfirmPhone, IsHaveCards: req.IsHaveCards, IsTwoFactorAuthentication: req.IsTwoFactorAuthentication,
      LastName: req.LastName,
      Login: req.Login,
      Name: req.Name, NumberCard: req.NumberCard, NumberPassport: req.NumberPassport, Password: req.Password, PaymentsType: req.PaymentsType,
      Phone: req.Phone, RegistrationAddress: req.RegistrationAddress,
      Role: req.Role,
      Search: req.Search,
      SearchFilter: req.SearchFilter,
      Token: req.Token,
      TypeIdentified: req.TypeIdentified, TypeUser: req.TypeUser,
      TypeUserId: this.convertUserTypeEnumTypeUser(req.TypeUserId),
    })
    return result
    
  }
  dateToString(req: Date): string {
    return moment(req).format("DD.mm.YYYY HH:MM")
  }

  convertUserTypeEnumTypeUser(req: UserType | undefined): EnumTypeUser{
    if (req == undefined || req==null) return EnumTypeUser.LegalPerson;
    switch (req) {
      case UserType.Legal:
        return EnumTypeUser.LegalPerson
      case UserType.Natural:
        return EnumTypeUser.NaturalPerson
      default:
        break;
    }
  }
  naturalPerson = ref(new ModelNaturalPerson())
  legalPerson = ref(new ModelLegalPerson())

  
  async getNaturalPerson(): Promise<ModelNaturalPerson | ErrorModel> {
    try {
      const res = await AdapterNaturalBalansService.api.Main.ProfileWsController.getNaturalPersonAsync()
      this.naturalPerson.value = res;
      return new ModelNaturalPerson(res)
    } catch (err: any) {
      this.showError(err)
      return new ErrorModel({ ErrorCode: err.ErrorCode, ErrorMessage: err.ErrorMessage })
    }
  }

  // async getLegalPerson(): Promise<ModelNaturalPerson | ErrorModel> {
  //   try {
  //     const res = await AdapterNaturalBalansService.api.Main.ProfileWsController.getLegalPersonAsync()
  //     this.naturalPerson.value = res;
  //     return new ModelNaturalPerson(res)
  //   } catch (err: any) {
  //     this.showError(err)
  //     return new ErrorModel({ ErrorCode: err.ErrorCode, ErrorMessage: err.ErrorMessage })
  //   }
  // }


  async getLegalPerson(): Promise<WsResponseModel<ModelLegalPerson>> {
    const result = new WsResponseModel<ModelLegalPerson>();
    try {
      const res = await AdapterNaturalBalansService.api.Main.ProfileWsController.getLegalPersonAsync()
      this.legalPerson.value = res;
      result.Value = res;
      result.IsSuccess = true;
    } catch (e) {
      this.handleError(e, "ERROR getLegalPerson")
    }
    return result;
  }
  

  //ProfileWsController.getRefillRequisitesAsync()
  async getRefillRequisites(): Promise<GetRefillRequisitesResponse | ErrorModel> {
    try {
      const res = await AdapterNaturalBalansService.api.Main.ProfileWsController.getRefillRequisitesAsync()
      return new GetRefillRequisitesResponse(res)
    } catch (err: any) {
      this.showError(err)
      return new ErrorModel({ ErrorCode: err.ErrorCode, ErrorMessage: err.ErrorMessage })
    }
  }
  
  /**
 * Универсальный обработчик ошибок
 * @param e 
 * @param context 
 * @returns 
 */
  handleError(e: any, context: string): WsResponseModel<any> {
    console.error(`ERROR ${context}`, e);
    return {
      IsSuccess: false,
      ErrorCode: e?.ErrorCode || 500,
      ErrorMessage: e?.ErrorMessage || 'Internal Server Error',
      Value: null
    };
  }
  /**
   * показать информацию об ошибке
   * @param err 
   */
  showError(err: any) {
    console.error(err?.ErrorMessage);
    notify({ text: err?.ErrorMessage as any, title: "Error", type: 'error' })
  }
}
