import { WebApiService } from '../../../../plugins/webApiService'
import { Events } from '../../../../plugins/events'
import { SendRequestModel } from '../../../../plugins/models/SendRequestModel'
import WsResponseModelT from '../../../../schema-builder/server/models/WsResponseModelT';
import GetBusinessActivityResponse from '../../../../identification-services/models/business-activity/GetBusinessActivityResponse';
import CustomizationResponse from '../../../../aba/cabinet/web/models/CustomizationResponse';
import DictionaryItem from '../../../../dictionary-services/models/DictionaryItem';

export default class DictionaryWsController {
	private url?: string;
	private cookieName: string;
	private appWebSettingsPath?: string;
	private apiUrlProperty?: string;

	GetBusinessActivitiesListEvent = new Events<WsResponseModelT<Array<GetBusinessActivityResponse>>>();
	GetCustomizationEvent = new Events<WsResponseModelT<CustomizationResponse>>();
	GetCryptNetworkListEvent = new Events<WsResponseModelT<Array<DictionaryItem>>>();
	GetCryptCurrencyListEvent = new Events<WsResponseModelT<Array<DictionaryItem>>>();

	subscribeEvents = async () => {
		const self = this;
		const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
		webApiService.on('DictionaryWsController', 'GetBusinessActivitiesList', 'Main', (res: WsResponseModelT<Array<GetBusinessActivityResponse>>) => {
			self.GetBusinessActivitiesListEvent.trigger(res);
		});
		webApiService.on('DictionaryWsController', 'GetCustomization', 'Main', (res: WsResponseModelT<CustomizationResponse>) => {
			self.GetCustomizationEvent.trigger(res);
		});
		webApiService.on('DictionaryWsController', 'GetCryptNetworkList', 'Main', (res: WsResponseModelT<Array<DictionaryItem>>) => {
			self.GetCryptNetworkListEvent.trigger(res);
		});
		webApiService.on('DictionaryWsController', 'GetCryptCurrencyList', 'Main', (res: WsResponseModelT<Array<DictionaryItem>>) => {
			self.GetCryptCurrencyListEvent.trigger(res);
		});
	}

	constructor(url?: string, cookieName?: string, appWebSettingsPath?: string, apiUrlProperty?: string) {
		this.url = url;
		this.cookieName = cookieName ?? "Auth-Token";
		this.appWebSettingsPath = appWebSettingsPath && [location.host, appWebSettingsPath].join("/");
		this.apiUrlProperty = apiUrlProperty;
		this.subscribeEvents();
	}

	async GetBusinessActivitiesList() {
		const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
		const sendRequest = new SendRequestModel({
			ProjectName: 'Main',
			Controller: 'DictionaryWsController',
			Method: 'GetBusinessActivitiesList',
		})

		webApiService.send(sendRequest);
	}

	async GetCustomization() {
		const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
		const sendRequest = new SendRequestModel({
			ProjectName: 'Main',
			Controller: 'DictionaryWsController',
			Method: 'GetCustomization',
		})

		webApiService.send(sendRequest);
	}

	async GetCryptNetworkList() {
		const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
		const sendRequest = new SendRequestModel({
			ProjectName: 'Main',
			Controller: 'DictionaryWsController',
			Method: 'GetCryptNetworkList',
		})

		webApiService.send(sendRequest);
	}

	async GetCryptCurrencyList() {
		const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
		const sendRequest = new SendRequestModel({
			ProjectName: 'Main',
			Controller: 'DictionaryWsController',
			Method: 'GetCryptCurrencyList',
		})

		webApiService.send(sendRequest);
	}

	 // async methods
	async getBusinessActivitiesListAsync(): Promise<Array<GetBusinessActivityResponse>> {
		return new Promise<Array<GetBusinessActivityResponse>>(async (resolve, reject) => {
			const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
			const sendRequest = new SendRequestModel({
				ProjectName: 'Main',
				Controller: 'DictionaryWsController',
				Method: 'GetBusinessActivitiesList',
				Callback: (response: WsResponseModelT<Array<GetBusinessActivityResponse>>) => {
					const isGeneric = typeof response.IsSuccess == 'boolean'
					const result = response.IsSuccess ? response.Value : response

					if (!isGeneric || response.IsSuccess) resolve(result as Array<GetBusinessActivityResponse>)
					else reject(result)
				}
			})

			webApiService.send(sendRequest);
		});
	}

	async getCustomizationAsync(): Promise<CustomizationResponse> {
		return new Promise<CustomizationResponse>(async (resolve, reject) => {
			const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
			const sendRequest = new SendRequestModel({
				ProjectName: 'Main',
				Controller: 'DictionaryWsController',
				Method: 'GetCustomization',
				Callback: (response: WsResponseModelT<CustomizationResponse>) => {
					const isGeneric = typeof response.IsSuccess == 'boolean'
					const result = response.IsSuccess ? response.Value : response

					if (!isGeneric || response.IsSuccess) resolve(result as CustomizationResponse)
					else reject(result)
				}
			})

			webApiService.send(sendRequest);
		});
	}

	async getCryptNetworkListAsync(): Promise<Array<DictionaryItem>> {
		return new Promise<Array<DictionaryItem>>(async (resolve, reject) => {
			const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
			const sendRequest = new SendRequestModel({
				ProjectName: 'Main',
				Controller: 'DictionaryWsController',
				Method: 'GetCryptNetworkList',
				Callback: (response: WsResponseModelT<Array<DictionaryItem>>) => {
					const isGeneric = typeof response.IsSuccess == 'boolean'
					const result = response.IsSuccess ? response.Value : response

					if (!isGeneric || response.IsSuccess) resolve(result as Array<DictionaryItem>)
					else reject(result)
				}
			})

			webApiService.send(sendRequest);
		});
	}

	async getCryptCurrencyListAsync(): Promise<Array<DictionaryItem>> {
		return new Promise<Array<DictionaryItem>>(async (resolve, reject) => {
			const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
			const sendRequest = new SendRequestModel({
				ProjectName: 'Main',
				Controller: 'DictionaryWsController',
				Method: 'GetCryptCurrencyList',
				Callback: (response: WsResponseModelT<Array<DictionaryItem>>) => {
					const isGeneric = typeof response.IsSuccess == 'boolean'
					const result = response.IsSuccess ? response.Value : response

					if (!isGeneric || response.IsSuccess) resolve(result as Array<DictionaryItem>)
					else reject(result)
				}
			})

			webApiService.send(sendRequest);
		});
	}

}