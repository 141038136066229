
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Options<UiButton>({
  props: {
    padding: { type: String, default: "0.40em 0.75em" },
    color: { type: String, default: "" },
    active: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    border: { type: Boolean, default: false },
    width: { type: Number, default: 100 },
    icon: { type: String, default: "" },
    tabindex: { type: String, default: "" },
  },
})
export default class UiButton extends Vue {
  private disabled: boolean = false;
  private active: boolean = false;
  private border: boolean = false;
  private color: string = "";
  private width: number = 100;
  private icon: string = "";
  private tabindex: string = "";
  private padding: string = "0.40em 0.75em";

  emitClick() {
    if (!this.disabled) this.$emit("onClick");
  }
  get innerStyle(): Object {
    return {
      backgroundColor: this.color,
      padding: this.padding,
      minWidth: this.width + "px",
    };
  }
}
