
import { Vue, Options } from "vue-property-decorator";
@Options({
  name: "modal-dialog",
})
export default class ModalDialog extends Vue {
  isVisibleBlock(isVisible: boolean) {
    return {
      opacity: isVisible ? `1` : `0`,
      visibility: isVisible ? `visible` : `hidden`,
    };
  }
  translatePosition(isVisible: boolean) {
    return {
      transform: isVisible ? `translate(0, 0)` : `translate(0, -20%)`,
    };
  }

  close() {
    this.$store.state.modalDialogVisible = false;
    this.$store.state.modalDialogMessage = "";
  }
}
