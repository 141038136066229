
import { Options, Vue } from "vue-class-component";

@Options<UiTableHeaderItemComponent>({
  props: {
    styleCustom: Object,
    sortName: { default: null, type: String },
    defaultSort: { default: null, type: String },
    iconAsc: { default: "asc", type: String },
    iconDesk: { default: "desc", type: String },
  },
  watch: {},
  emits: ['onSort']
})
export default class UiTableHeaderItemComponent extends Vue {
  readonly styleCustom: Object = {};
  readonly sortName: string = "";
  readonly defaultSort: String = "";
  readonly iconAsc: string = "";
  readonly iconDesk: string = "";

  sortType =
    this.defaultSort === null ? null : this.defaultSort === "asc" ? 0 : 1;

  created() {
    this.sortType =
      this.defaultSort === null ? null : this.defaultSort === "asc" ? 0 : 1;
    this.sortExecute(false);
  }
  sortExecute(value = true) {
    if (!this.sortName) return;
    if (this.sortType === null && !value) return;
    if (this.sortType === null) this.sortType = 0;
    else if (this.sortType === 0) this.sortType = 1;
    else if (this.sortType === 1) this.sortType = 0;
  
    this.$emit("onSort", this.sortName, this.sortType);
  }
}
