
import { Vue, Options, Prop, Watch } from "vue-property-decorator";
@Options({
  name: "ui-pin-code",
})
export default class UiPinCode extends Vue {
  @Prop({ default: false }) isClearPin: boolean;

  Pin1: string = "";
  Pin2: string = "";
  Pin3: string = "";
  Pin4: string = "";
  Pin5: string = "";
  Pin6: string = "";

  @Watch("isClearPin")
  clearPinCode() {
    if (this.isClearPin) {
      this.Pin1 =
        this.Pin2 =
        this.Pin3 =
        this.Pin4 =
        this.Pin5 =
        this.Pin6 =
          "";
    }
  }

  onInputPin() {
    let pin =
      this.Pin1.toString() +
      this.Pin2.toString() +
      this.Pin3.toString() +
      this.Pin4.toString() +
      this.Pin5.toString() +
      this.Pin6.toString();
    this.$emit("onInputPin", pin);
  }
}
