
//import { ResponseModel } from "@dataSource/models/responseModels.ts";
import { Options, Vue, Prop, Watch } from "vue-property-decorator";
@Options({
  props: {
    position: String,
    shadow: Boolean,
  },
  name:"ui-box-menu",
})
export default class UiBoxMenuComponent extends Vue {
  @Prop({ default: "", type: String }) position: string; // left centre right

  @Prop({ default: false, type: Boolean }) shadow: boolean; // left centre right
  // position: string = "";
  // shadow: boolean = false;
  getPosition(): string {
    if (this.position == "left") return "left";
    if (this.position == "right") return "right";
    if (this.position == "centre") return "centre";
    // return "centre";
  }
}
