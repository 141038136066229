import { resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-11ff886b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "identification_title" }
const _hoisted_2 = { class: "info_box" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_continue_button = _resolveComponent("continue-button")!
  const _directive_localization = _resolveDirective("localization")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _withDirectives(_createElementVNode("span", null, null, 512), [
        [_directive_localization, 'Identification.NewCompany.Header']
      ])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _withDirectives(_createElementVNode("p", null, null, 512), [
        [_directive_localization, 'Identification.NewCompany.Message']
      ])
    ]),
    _createVNode(_component_continue_button, {
      class: "company_continue",
      onClicked: _ctx.startContinue,
      buttonText: _ctx.$localization.getTranslate('Button.Continue'),
      isLoading: _ctx.isLoading
    }, null, 8, ["onClicked", "buttonText", "isLoading"])
  ]))
}