import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "minutes" }
const _hoisted_2 = { class: "navigation" }
const _hoisted_3 = { class: "items" }
const _hoisted_4 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: "icon-arrow-left",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.backward && _ctx.backward(...args)))
      }),
      _createElementVNode("div", {
        class: "title_date-picker minutes",
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.changeHours && _ctx.changeHours(...args)))
      }, _toDisplayString(this.getHourName()), 1),
      _createElementVNode("div", {
        class: "icon-arrow-right",
        onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.next && _ctx.next(...args)))
      })
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.innerMinutes, (minute, key) => {
        return (_openBlock(), _createElementBlock("div", {
          key: key,
          class: _normalizeClass(["minute", minute.type]),
          onClick: ($event: any) => (_ctx.changeMinute(minute))
        }, [
          _createElementVNode("div", null, _toDisplayString(minute.name), 1)
        ], 10, _hoisted_4))
      }), 128))
    ])
  ]))
}