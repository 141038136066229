
import { Options, Vue } from "vue-class-component";
@Options<UiLoading>({
  props: {
    loading: Boolean,
    error: Boolean,
    ok: Boolean,
    errorMessage: String,
    okMessage: String,
    loadingMessage: String,
  },
})
export default class UiLoading extends Vue {
  loading: { default: false };
  error: { default: false };
  ok: { default: false };
  errorMessage: { default: "Ошибка" };
  okMessage: { default: "Операция прошла успешно" };
  loadingMessage: { default: "Идет загрузка" };
  // @Prop({ default: false }) loading!: boolean;
  // @Prop({ default: false }) error!: boolean;
  // @Prop({ default: false }) ok!: boolean;
  // @Prop({ default: "Ошибка" }) errorMessage: string;
  // @Prop({ default: "Операция прошла успешно" }) okMessage: string;
  // @Prop({ default: "Идет загрузка" }) loadingMessage: string;

  mounted() {
    (this.$el as any).parentElement.style.position = "relative";
  }
}
