import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "ui-time-range"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_hour_selector = _resolveComponent("ui-hour-selector")!

  return (!_ctx.linkedDatePickerName)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.innerCurrentView == 'hours')
          ? (_openBlock(), _createBlock(_component_ui_hour_selector, {
              key: 0,
              onOnNavigation: _cache[0] || (_cache[0] = ($event: any) => (_ctx.NavigatorDatetime('days'))),
              onChangeHour: _cache[1] || (_cache[1] = ($event: any) => (_ctx.ChangeDatetime('minutes', 'hours'))),
              onChangeCurrentView: _ctx.ChangeCurrentView,
              allowedToShowView: _ctx.allowedToShowView,
              date: _ctx.innerDate,
              "onUpdate:date": [
                _cache[2] || (_cache[2] = ($event: any) => ((_ctx.innerDate) = $event)),
                _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('update:date', $event)))
              ],
              onChange: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('change', $event))),
              dateSecond: this.dateSecond,
              "onUpdate:dateSecond": [
                _cache[5] || (_cache[5] = ($event: any) => ((this.dateSecond) = $event)),
                _cache[7] || (_cache[7] = ($event: any) => (_ctx.$emit('update:dateSecond', $event)))
              ],
              onErrorDate: _cache[6] || (_cache[6] = ($event: any) => (_ctx.$emit('errorDate', $event)))
            }, null, 8, ["onChangeCurrentView", "allowedToShowView", "date", "dateSecond"]))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}