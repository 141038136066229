import EnumLegalPartnerType from '@/api/main/dataSource/api/aba/data/enums/EnumLegalPartnerType';
import UploadPhotoCropRequest from '@/api/main/dataSource/api/file-services/models/UploadPhotoCropRequest';


export default class BusinessPartnerModel {
    Id?: number = null;
    Name: string = '';
    RegistrationCountryCode= null;
    PartnerType: EnumLegalPartnerType = null;
    File: UploadPhotoCropRequest = null;
    FileUrl?: string;

    constructor(obj?: Partial<BusinessPartnerModel>) {
        if (obj) {
            Object.assign(this, obj)
        }
    }

    public isValid(): boolean {
        let self = this;
        if (self.PartnerType === null ||
            self.Name.length == 0 ||
            self.RegistrationCountryCode &&
            self.RegistrationCountryCode.length == 0 ||
            !self.FileUrl && (!self.File || !self.File.FileBase64)
        ) {
            return false;
        } else {
            return true;
        }
    }
}
