
import ModelPersonCard from "@/api/main/services/NaturalPersonProfile/models/response/GetNewUser/ModelPersonCard";
import { Vue, Options, Prop } from "vue-property-decorator";
@Options({
  name: "card-block",
})
export default class CardBlock extends Vue {
  @Prop({ default: [], type: Array }) Cards: Array<ModelPersonCard>;

  get cardsEnabled(): boolean {
    console.log("loc", location.host);
    if (location.host.startsWith("localhost")) return false;
    if (location.host.indexOf("abapay.eu") >= 0) return false;
    return true;
  }

  GetPin() {
    this.$emit("GetPin");
  }

  HideNumber(str: string) {
    var newString = "";
    for (var i = 0; i < str.length; i++) {
      if (i === 4 || i === 8 || i === 12) newString += " ";
      if (i > 3 && i < 12) newString += "•";
      else newString += str[i];
    }
    return newString;
  }
  OrderCard() {
    this.$emit("OrderCard");
  }
}
