

export default class CropSettings  {
	X!: number;
	Y!: number;
	Width!: number;
	Height!: number;
	constructor(obj?: Partial<CropSettings>) {
		if (obj) {
			Object.assign(this, obj);
		}
	}
}
