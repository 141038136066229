
import { Options, Vue } from "vue-class-component";

@Options<UiButtonDelete>({
  name: "ui-button-delete",
})
export default class UiButtonDelete extends Vue {
  private disabled: boolean = false;

  emitClick(event) {
    if (!this.disabled) this.$emit("click", event);
  }
}
