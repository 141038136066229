
import ModelPersonTemplate from "@/api/main/dataSource/api/i-public-service/aba-finance/models/ModelPersonTemplate";
import TemplateModel from "@/views/models/TemplatesTrasnfer/TemplateModel";
import { Vue, Options, Prop, Watch } from "vue-property-decorator";

import AdapterTransferService from "../../adapter/AdapterTransferService";

@Options({
	name: "user-contacts",
})
export default class UserContacts extends Vue {
	@Prop({ default: null }) type: number;

	Templates: Array<TemplateModel> = null;
	FilterTemplates: Array<TemplateModel> = [];
	adapter: AdapterTransferService;
	@Watch("type")
	filterTemplates() {
		if (!this.Templates) return;
		this.FilterTemplates = this.Templates.filter((x) => {
			return x.Type == this.type;
		});
	}

	async created() {
		this.adapter = AdapterTransferService.getInstance();
		await this.GetTemplates();
	}

	async GetTemplates() {
		const res = await this.adapter.getTemplates();
		if (res.IsSuccess) {
			this.Templates = res.Value as any;

			this.filterTemplates();
		}
	}

	UseTemplate(template: TemplateModel) {
		this.$emit("UseTemplate", template);
	}
}
