
import UploadPhotoCropRequest from "@/api/main/dataSource/api/file-services/models/UploadPhotoCropRequest";
import CompanyDocumentModel from "@/views/pages/user-identification/models/CompanyDocumentModel";
import { Vue, Prop, Watch, Options } from "vue-property-decorator";
@Options({
	name: "ui-company-document",
})
export default class UiCompanyDocument extends Vue {
	@Prop({ default: "" }) headerText: string;
	@Prop({ default: new CompanyDocumentModel() }) document: any;
	@Prop({ default: false }) isWrong: boolean;
	@Prop({ default: null }) modelValue: any;
	@Prop({ default: false }) buttonHide: boolean;
	@Prop({ default: true }) showDeleteButton: boolean;
	@Prop({ default: false }) showFile: boolean;
	@Prop({ default: "" }) buttonText: string;

	innerValue: any = null;
	styleForWrong: boolean = false;

	@Watch("isWrong")
	onUpdateIsWrong(newValue: boolean) {
		this.styleForWrong = newValue;
	}

	@Watch("innerValue")
	onUpdateInnerValue() {
		this.$emit("update:modelValue", this.innerValue);
	}

	created() {
		this.innerValue = this.modelValue;
	}
	OnAddedFile(event) {
		if (this.document.Id) {
			this.document.Id = null;
		}
		
		// this.document.FileUrl = null;
		// this.document.File = event.fileBase64;
		// this.document.FileName = event.name
		
	}
}
