import { App } from "vue";
import "@/extensions/DateExtensions"

export class VueCustomFilters {
  MomentFormat(date: Date, format: string) {    
    return new Date(date).MomentFormat(format);
  }
}

declare module '@vue/runtime-core' {
  export interface ComponentCustomProperties {
    $filters: VueCustomFilters;// ApiDataSource;
  }
}

const install = (app: App) => {
  app.config.globalProperties.$filters = new VueCustomFilters();
};

export default {
  install,
};
