import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelDynamic as _vModelDynamic, withKeys as _withKeys, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-3e988d60"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ui_input_body" }
const _hoisted_2 = ["type", "readonly", "placeholder", "required"]
const _hoisted_3 = ["src"]
const _hoisted_4 = {
  key: 1,
  class: "ui_currency"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_fillInput = _resolveDirective("fillInput")!

  return (_openBlock(), _createElementBlock("div", {
    class: "ui_input_wrapper",
    style: _normalizeStyle({ maxWidth: !!_ctx.currency ? '200px' : 'auto' })
  }, [
    (_ctx.headerText.length != 0)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["ui_input_header", [_ctx.styleForWrong ? 'wrong_text' : '']])
        }, _toDisplayString(_ctx.headerText), 3))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      _withDirectives(_createElementVNode("input", {
        type: _ctx.type,
        class: _normalizeClass(["ui_input", [_ctx.styleForWrong ? 'wrong' : '']]),
        readonly: _ctx.readOnly,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.innerValue) = $event)),
        onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onInput && _ctx.onInput(...args))),
        style: _normalizeStyle({
					padding: !!_ctx.iconName ? '0 20px 0 50px' : '0 20px',
					paddingRight: !!_ctx.currency ? '63px' : '20px',
				}),
        placeholder: _ctx.placeholder,
        onKeyup: [
          _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.keyUp && _ctx.keyUp(...args))),
          _cache[3] || (_cache[3] = _withKeys(($event: any) => (_ctx.$emit('keyupEnter')), ["enter"]))
        ],
        required: _ctx.required
      }, null, 46, _hoisted_2), [
        [_vModelDynamic, _ctx.innerValue],
        [_directive_fillInput, _ctx.warningMsg]
      ]),
      (!!_ctx.iconName)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            class: "icon-input",
            src: require(`@assets/img/Icons/${_ctx.iconName}.svg`),
            alt: ""
          }, null, 8, _hoisted_3))
        : _createCommentVNode("", true),
      (!!_ctx.currency)
        ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.currency), 1))
        : _createCommentVNode("", true)
    ])
  ], 4))
}