import UserType from '../../../../../common/enums/UserType';

export default class RegistrationRequest  {
	Email!: string;
	PhoneNumber!: string;
	TypeUser!: UserType;
	CompanyName!: string;
	AgentCode!: string;
	PinCode!: string;
	constructor(obj?: Partial<RegistrationRequest>) {
		if (obj) {
			Object.assign(this, obj);
		}
	}
}
