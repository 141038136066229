import ApiService from '@/api/index';
import ChangePasswordRequest from '@/api/main/dataSource/api/aba/cabinet/web/models/ChangePasswordRequest';
import CurrentUserResponse from '@/api/main/dataSource/api/aba/cabinet/web/models/CurrentUserResponse';
import GetPhotosResponse from '@/api/main/dataSource/api/aba/cabinet/web/models/GetPhotosResponse';
import CheckUniqueEmailRequest from '@/api/main/dataSource/api/aba/public/services/models/registration/CheckUniqueEmailRequest';
import RegistertrationStartRequest from '@/api/main/dataSource/api/aba/public/services/models/registration/RegistertrationStartRequest';
import RegistrationRequest from '@/api/main/dataSource/api/aba/public/services/models/registration/RegistrationRequest';
import RestorePasswordRequest from '@/api/main/dataSource/api/aba/public/services/models/restore-password/RestorePasswordRequest';
import EnumLang from '@/api/main/dataSource/api/i-public-service/aba-finance/models/enums/EnumLang';
import ModelSystemMessages from '@/api/main/dataSource/api/i-public-service/aba-finance/models/ModelSystemMessages';
import MailBankModel from '@/api/main/dataSource/api/i-public-service/aba-finance/models/support/MailBankModel';
import ModelNaturalPerson from '@/api/main/dataSource/api/i-public-service/aba-finance/models/user/ModelNaturalPerson';
import AcquiringInitPayinRequest from '@/api/main/dataSource/api/transaction-services/models/acquirings/AcquiringInitPayinRequest';
import AcquiringInitPayinResponse from '@/api/main/dataSource/api/transaction-services/models/acquirings/AcquiringInitPayinResponse';
import MoneyTransactionPersonResponse from '@/api/main/dataSource/api/transaction-services/models/MoneyTransactionPersonResponse';
import TransactionPersonRequest from '@/api/main/dataSource/api/transaction-services/models/TransactionPersonRequest';
import GetNaturalPersonResponse from '@/api/main/services/NaturalPersonHome/models/response/GetNaturalPersonResponse';
import GetSystemMessages from '@/api/main/services/NaturalPersonProfile/models/response/GetSystemMessages';
import FAQQuestionModel from '@/api/main/services/NaturalPersonSupport/models/FAQQuestionModel';

import { notify } from '@/app/notifications';
import FAQCategoryModel from '@/views/models/FAQCategoryModel';
import NaturalPersonModel from '@/views/models/NaturalPersonModel';
import moment from 'moment';


import { ref } from 'vue';


import LoginRequest from '@/api/main/dataSource/api/aba/public/services/models/login/LoginRequest';
import LoginResponse from '@/api/main/dataSource/api/aba/public/services/models/login/LoginResponse';
import ModelPersonIdentification from '@/api/main/dataSource/api/i-public-service/aba-finance/models/user/ModelPersonIdentification';
import PersonalIdentificationData from '@/api/main/dataSource/api/i-public-service/aba-finance/models/user/identification/PersonalIdentificationData';
import WsResponseModel from '@/api/main/SchemaBuilderServer/Models/WsResponseModel';
import AcquiringPayoutTemplateListResponse from '@/api/main/dataSource/api/transaction-services/models/transfer-template-service/AcquiringPayoutTemplateListResponse';
import SetProfileModel from '../models/SetProfileModel';
import PassportIdentificationData from '@/api/main/dataSource/api/i-public-service/aba-finance/models/user/identification/PassportIdentificationData';
import IdentificationModel from '../models/IdentificationModel';
import LegalCompanyGetDataResponse from '@/api/main/dataSource/api/identification-services/models/legal-company/LegalCompanyGetDataResponse';
import LegalCompanySaveDataRequest from '@/api/main/dataSource/api/identification-services/models/legal-company/LegalCompanySaveDataRequest';
import LegalBeneficiarySaveDataRequest from '@/api/main/dataSource/api/identification-services/models/legal-beneficiary/LegalBeneficiarySaveDataRequest';
import LegalBeneficiaryGetDataResponse from '@/api/main/dataSource/api/identification-services/models/legal-beneficiary/LegalBeneficiaryGetDataResponse';
import LegalDirectorsSaveDataRequest from '@/api/main/dataSource/api/identification-services/models/legal-directors/LegalDirectorsSaveDataRequest';
import LegalDirectorsGetDataResponse from '@/api/main/dataSource/api/identification-services/models/legal-directors/LegalDirectorsGetDataResponse';
import CompanyDataViewModel from '../../user-identification/models/CompanyDataViewModel';
import LegalCompanyDocumentSaveRequest from '@/api/main/dataSource/api/identification-services/models/legal-company-document/LegalCompanyDocumentSaveRequest';
import UploadPhotoCropRequest from '@/api/main/dataSource/api/file-services/models/UploadPhotoCropRequest';
import EnumLegalIdentificationStageType from '@/api/main/dataSource/api/common/enums/EnumLegalIdentificationStageType';
import { seedValue } from 'faker/locale/cz';
import CompanyDocumentModel from '../../user-identification/models/CompanyDocumentModel';


export default class AdapterIdentificationService {

  /**
 * Сервис
 */
  private static instance: AdapterIdentificationService;
  /**
 * API
 */
  private static api: ApiService;
  isLoading: boolean = false;
  isInited = ref(false);
  api: ApiService;

  constructor() {
    AdapterIdentificationService.api = new ApiService();
  }

  public static getInstance() {
    if (!AdapterIdentificationService.instance) {
      AdapterIdentificationService.instance = new AdapterIdentificationService();
    }
    return AdapterIdentificationService.instance;
  }


  /** строку в дату */
  convertStringToDate(s: string): Date {
    const futureStartAtDate = new Date(moment(s).locale("en").add(1, 'd').format("MMM DD, YYYY HH:MM"))
    return futureStartAtDate
  }
  // comvertIdentificationModelToPassportIdentificationData(v: IdentificationModel) {
  //   const res = new PassportIdentificationData({
  //     AddressCity: v.City,
  //     AddressCountry: v.CountryCode,
  //     AddressState: v.State, AddressStreet: v.State,
  //     AddressZip: v.IndexCode,
  //     DocumentIssuedBy: v.PassportIssuedBy,
  //     DocumentIssuedCountryCode: v.PassportCountryCode,
  //     DocumentIssuedDate: this.convertStringToDate(v.PassportIssuedDate),
  //     DocumentNumber: v.PassportNumber,
  //     PassportScan: v.ScanPassport,//TODO могут быть ошибки
  //     UtilityBillScan: v.ScanUtilityBill
  //     // ScanPassport:,
  //     // ScanUtilityBill: v.ScanUtilityBill as any,//TODO могут быть ошибки c типами с сервреа непорядок
  //     // UserId:v.
  //     // UtilityBillScan:v.ScanUtilityBill



  //   })
  //   return res;
  // }

  // async savePassportIdentificationData(req: PassportIdentificationData): Promise<WsResponseModel<Boolean>> {
  //   const result = new WsResponseModel<Boolean>();
  //   try {
  //     const resp = await AdapterIdentificationService.api.Main.ProfileWsController.savePassportIdentificationDataAsync(req)
  //     result.Value = resp;
  //     result.IsSuccess = true;
  //   } catch (e) {
  //     return this.handleError(e, "ERROR savePassportIdentificationData")
  //   }
  //   return result;
  // }


  // mapSetProfileModelToPersonalIdentificationData(req: SetProfileModel): PersonalIdentificationData {
  //   const res = new PersonalIdentificationData({
  //     UserId: req.UserId,
  //     FirstName: req.FirstName,
  //     SecondName: req.SecondName,
  //     Appeal: req.Appeal,
  //     BirthPlace: req.BirthPlace,
  //     BirthDate: new Date(req.BirthDate)
  //   })
  //   return res
  // }

  // async savePersonalIdentificationData(req: PersonalIdentificationData): Promise<WsResponseModel<Boolean>> {
  //   const result = new WsResponseModel<Boolean>();
  //   try {
  //     const resp = await AdapterIdentificationService.api.Main.ProfileWsController.savePersonalIdentificationDataAsync(req);
  //     result.Value = resp;
  //     result.IsSuccess = true;
  //   } catch (e) {
  //     this.handleError(e, "ERROR savePersonalIdentificationData")
  //   }
  //   return result;
  // }

  // async getUserIdentification(): Promise<WsResponseModel<ModelPersonIdentification>> {
  //   const result = new WsResponseModel<ModelPersonIdentification>();
  //   try {
  //     const resp = await AdapterIdentificationService.api.Main.ProfileWsController.getUserIdentificationAsync();
  //     result.Value = resp;
  //     result.IsSuccess = true;
  //   } catch (e) {
  //     this.handleError(e, "ERROR getUserIdentification")
  //   }
  //   return result;
  // }



  //******************************************************************************************************************************* */
  async saveLegalDirectorsData(req: LegalDirectorsSaveDataRequest): Promise<WsResponseModel<Boolean>> {
    const result = new WsResponseModel<Boolean>();
    try {
      const resp = await AdapterIdentificationService.api.Main.LegalDirectorsWsController.saveLegalDirectorsDataAsync(req);
      result.Value = resp;
      result.IsSuccess = true;
    } catch (e) {
      return this.handleError(e, "ERROR saveLegalDirectorsData")
    }
    return result;
  }


  async getLegalDirectors(): Promise<WsResponseModel<LegalDirectorsGetDataResponse>> {
    const result = new WsResponseModel<LegalDirectorsGetDataResponse>();
    try {
      const resp = await AdapterIdentificationService.api.Main.LegalDirectorsWsController.getLegalDirectorsAsync()
      result.Value = resp;
      result.IsSuccess = true;
    } catch (e) {
      return this.handleError(e, "ERROR getLegalDirectors")
    }
    return result;
  }
  //******************************************************************************************************************************* */
  async saveBeneficiaryData(req: LegalBeneficiarySaveDataRequest): Promise<WsResponseModel<Boolean>> {
    const result = new WsResponseModel<Boolean>();
    try {
      const resp = await AdapterIdentificationService.api.Main.LegalBeneficiaryWsController.saveBeneficiaryDataAsync(req);
      result.Value = resp;
      result.IsSuccess = true;
    } catch (e) {
      return this.handleError(e, "ERROR saveBeneficiaryData")
    }
    return result;
  }

  BeneficiaryInfoModel: LegalBeneficiaryGetDataResponse

  async getBeneficiaryData(): Promise<WsResponseModel<LegalBeneficiaryGetDataResponse>> {
    const result = new WsResponseModel<LegalBeneficiaryGetDataResponse>();
    try {
      const res = await AdapterIdentificationService.api.Main.LegalBeneficiaryWsController.getBeneficiaryDataAsync();
      result.Value = res;
      this.BeneficiaryInfoModel = new LegalBeneficiaryGetDataResponse(res);
      result.IsSuccess = true;
    } catch (e) {
      return this.handleError(e, "ERROR getBeneficiaryData")
    }
    return result;
  }
  //******************************************************************************************************************************* */
  /**
   * Получить этап идентификации юр лица на котором остановились
   * @param value //!TODO  временно, параметров не должно быть
   * @returns 
   */
  async getMaxStage(): Promise<WsResponseModel<EnumLegalIdentificationStageType>> {
    const result = new WsResponseModel<EnumLegalIdentificationStageType>();
    try {
      const res = await AdapterIdentificationService.api.Main.ProfileWsController.getLastCompleteStageAsync();
      result.Value = res;
      result.IsSuccess = true;
    } catch (e) {
      return this.handleError(e, "ERROR saveCompanyData")
    }
    return result;
  }
  convertEnumLegalIdentificationStageTypeToNumber(value: EnumLegalIdentificationStageType): number {
    switch (seedValue) {
      case EnumLegalIdentificationStageType.None:
        return 0;
      case EnumLegalIdentificationStageType.Company:
        return 1;
      case EnumLegalIdentificationStageType.Beneficiary:
        return 1;
      case EnumLegalIdentificationStageType.Director:
        return 1;
      case EnumLegalIdentificationStageType.BusinessProfile:
        return 1;
      case EnumLegalIdentificationStageType.Liveness:
        return 1;
      default:
        return 0;
    }
  }
  //******************************************************************************************************************************* */
  async saveCompanyData(req: CompanyDataViewModel): Promise<WsResponseModel<Boolean>> {
    const result = new WsResponseModel<Boolean>();
    try {
      const reqNew = this.convertCompanyDataViewModelToLegalCompanySaveDataRequest(req)
      const resp = await AdapterIdentificationService.api.Main.LegalCompanyDataWsController.saveCompanyDataAsync(reqNew);
      result.Value = resp;
      result.IsSuccess = true;
    } catch (e) {
      return this.handleError(e, "ERROR saveCompanyData")
    }
    return result;
  }


  async getCompanyData(): Promise<WsResponseModel<LegalCompanyGetDataResponse>> {
    const result = new WsResponseModel<LegalCompanyGetDataResponse>();
    try {
      const resp = await AdapterIdentificationService.api.Main.LegalCompanyDataWsController.getCompanyDataAsync();
      result.Value = resp;
      result.IsSuccess = true;
    } catch (e) {
      return this.handleError(e, "ERROR getCompanyData")
    }
    return result;
  }

  convertCompanyDataViewModelToLegalCompanySaveDataRequest(val: CompanyDataViewModel) {

    const res = new LegalCompanySaveDataRequest({
      AddressApartment: val.AddressApartment,
      AddressCity: val.AddressCity,
      AddressCountryCode: val.AddressCountryCode,
      AddressHome: val.AddressHome,
      AddressPostalCode: val.AddressPostalCode,
      AddressState: val.AddressState,
      AddressStreet: val.AddressStreet,
      CompanyDocumentsIdsForCreate:this.fileAr(val.CompanyDocuments),
      Website: val.Website,
      RegistrationNumber: val.RegistrationNumber,
      LegalUserId: val.LegalUserId,
      IncorporationDate: val.IncorporationDate,
      CompanyDocumentsIdsForDelete: val.OldCompanyDocumentsIds
    });
    return res;

  }

  fileAr(val:CompanyDocumentModel[]): LegalCompanyDocumentSaveRequest[]{
    let res: LegalCompanyDocumentSaveRequest[] = [];
    val.forEach(el => {
      if (!el.Id) {
       res.push( new LegalCompanyDocumentSaveRequest({
          File: new UploadPhotoCropRequest({
            FileBase64: el.File,
            FileName: el.FileName,
          }), Type: el.Type
        }))
      } 
    }) 
    return res;
  }

  /**************************************************************************************************************************************** */

  async livenessSaveResult(): Promise<WsResponseModel<Boolean>> {
    const result = new WsResponseModel<Boolean>();
    try {
      const resp = await AdapterIdentificationService.api.Main.FincheckerWsController.livenessSaveResultAsync();
      result.Value = resp;
      result.IsSuccess = true;
    } catch (e) {
      return this.handleError(e, "ERROR livenessSaveResult")
    }
    return result;
  }


  /**
   * livenessStart
   * @returns 
   */
  async livenessStart(req: string): Promise<WsResponseModel<String>> {
    const result = new WsResponseModel<String>();
    try {
      const resp = await AdapterIdentificationService.api.Main.FincheckerWsController.livenessStartAsync(req);
      result.Value = resp;
      result.IsSuccess = true;
    } catch (e) {
      return this.handleError(e, "ERROR livenessStart")
    }
    return result;
  }

  /**************************************************************************************************************************************** */
  /**
   * Универсальный обработчик ошибок
   * @param e 
   * @param context 
   * @returns 
   */
  handleError(e: any, context: string): WsResponseModel<any> {
    console.error(`ERROR ${context}`, e);
    return {
      IsSuccess: false,
      ErrorCode: e?.ErrorCode || 500,
      ErrorMessage: e?.ErrorMessage || 'Internal Server Error',
      Value: null
    };
  }
  /**
   * показать информацию об ошибке
   * @param err 
   */
  showError(err: any) {
    console.error(err?.ErrorMessage);
    notify({ text: err?.ErrorMessage as any, title: "Error", type: 'error' })
  }
}
