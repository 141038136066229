
import { Vue, Options, Prop, Watch, Ref } from "vue-property-decorator";
@Options({
  name: "ui-text-editor",
  emits: ["update:modelValue"],
})
export default class UiTextEditorComponent extends Vue {
  @Prop({ type: String, default: "extended" }) type: string;
  @Prop({ type: String, default: "" }) placeholder: string;
  @Prop({ type: String, default: "" }) modelValue: string;
  @Prop({ type: Boolean, default: true }) stripHtml: boolean;
  @Prop({ type: String, default: "100px" }) minHeight: string;
  @Prop({ type: Boolean, default: true }) isToolbar: boolean;
  // @Prop({ type: Boolean, default: true }) hasImage: boolean;
  // @Prop({ type: String }) savedImage: string;
  // pasteImageRange: Range = null;
  stretchDraft = false;
  innerValue = "";
  scrollStyle = {
    bottom: "0px",
  };

  moveCaretTo(element, index) {
    const range = document.createRange();
    const selection = window.getSelection();

    range.setStart(element, index);
    range.collapse(true);
    selection.removeAllRanges();
    selection.addRange(range);
    console.log("moveCaretTo");
  }
  created() {
    this.innerValue = this.modelValue;
    var self = this;
    document.addEventListener("selectionchange", function () {
      const sel = window.getSelection();
      if ((sel.focusNode as any).id == "workaround") {
        self.moveCaretTo(sel.focusNode.childNodes[sel.focusOffset - 1], 0);
      }
    });

    //this.fetchData();
    // console.log("Йа эдитор");
  }
  @Watch("modelValue")
  onValueChanged() {
    if ((this.$refs.editor as HTMLElement).innerHTML !== this.modelValue) {
      this.innerValue = this.modelValue;
    }
  }
  // @Watch("innerValue")
  // onInnerValueChanged() {
  //   let editor = this.$refs.editor as HTMLElement;
  //   // this.placeCaretAtEnd(document.getElementById("editor"));
  //   // if (editor) {
  //   //   this.placeCaretAtEnd(editor);
  //   // }
  // }
  fetch() {}
  onPressCtrEnter() {
    this.$emit("ctrlEnter", this.modelValue);
  }
  handlePaste(e: ClipboardEvent) {
    if (window.getSelection) {
      window.getSelection().getRangeAt(0).deleteContents();
    }
    if (this.stripHtml) {
      this.pasteText(e);
      this.update(e);
    }
  }
  pasteText(e: ClipboardEvent) {
    var clipboardData, pastedData;
    e.stopPropagation();
    e.preventDefault();
    clipboardData = e.clipboardData || (window as any).clipboardData;
    pastedData = clipboardData.getData("text");
    this.processPaste(pastedData);
  }
  processPaste(pastedData: string) {
    const textFragments = pastedData.split(/\r?\n/);
    const textFragment = document.createDocumentFragment();
    textFragments.forEach((text, index) => {
      const textNode = document.createTextNode(text);
      textFragment.appendChild(textNode);
      if (index < textFragments.length - 1) {
        textFragment.appendChild(document.createElement("br"));
      }
    });
    if (window.getSelection) {
      const selection = window.getSelection();
      const range = selection.getRangeAt(0);
      range.insertNode(textFragment);
      range.collapse(false);
    }
  }

  wysiwygFormat(command: string) {
    if (command == "h3" || command == "h2") {
      document.execCommand("formatBlock", false, command);
    }
    // if (command == "insertImage") {
    //   this.loadImage();
    //   return;
    // }
    if (command == "createlink") {
      let url = prompt("Enter the link here: ", "http://");
      document.execCommand(command, false, url);
    } else document.execCommand(command, false, null);
  }
  stretchWysiwyg() {
    this.stretchDraft = !this.stretchDraft;
  }
  manualUpdate(text: string) {
    this.$emit("input", text);
  }
  update(event: ClipboardEvent) {
    this.innerValue = document.getElementById("editor").innerHTML;
    this.$emit("update:modelValue", this.innerValue);
    setTimeout(() => {
      this.placeCaretAtEnd(document.getElementById("editor"));      
    });
    // this.manualUpdate(document.getElementById("editor").innerHTML);
  }
  setActive() {
    (this.$refs.editor as HTMLElement).focus();
  }
  placeCaretAtEnd(el) {
    var node = el,
      textNode = node.firstChild,
      caret = textNode.length,
      range = document.createRange(),
      sel = window.getSelection();

    node.focus();

    //console.log("range Before", range, textNode, caret);

    range.setStart(textNode, caret);
    // range.setEnd(textNode, caret);

    //console.log("range After", range);

    sel.removeAllRanges();
    sel.addRange(range);
  }
}
