import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "popup_content__wrapper" }
const _hoisted_2 = ["src"]
const _hoisted_3 = {
  key: 0,
  class: "popup_content_footer"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_click_outside = _resolveDirective("click-outside")!

  return (_openBlock(), _createElementBlock("div", {
    class: "popup",
    style: _normalizeStyle(_ctx.isVisibleBlock(_ctx.visible))
  }, [
    _createElementVNode("div", {
      class: "popup_content",
      style: _normalizeStyle(_ctx.getScale)
    }, [
      _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", {
          class: _normalizeClass(["popup_content_header", { white: _ctx.hideHeader }])
        }, [
          _createElementVNode("h3", null, [
            _renderSlot(_ctx.$slots, "header")
          ]),
          _createElementVNode("img", {
            class: "close-btn",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args))),
            src: require(`@assets/img/Icons/close-${_ctx.hideHeader ? 'icon2' : 'icon'}.svg`),
            alt: ""
          }, null, 8, _hoisted_2)
        ], 2),
        _createElementVNode("div", {
          class: "popup_content_body",
          style: _normalizeStyle({ paddingTop: _ctx.hideHeader ? '0' : '30px' })
        }, [
          _renderSlot(_ctx.$slots, "body")
        ], 4),
        (!_ctx.hideFooter)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _renderSlot(_ctx.$slots, "footer")
            ]))
          : _createCommentVNode("", true)
      ])), [
        [_directive_click_outside, _ctx.close]
      ])
    ], 4)
  ], 4))
}