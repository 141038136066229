import { WebApiService } from './plugins/webApiService';
import DictionaryWsController from './aba/cabinet/web/ws-controllers/DictionaryWsController';
import FileWsController from './aba/cabinet/web/ws-controllers/FileWsController';
import SupportWsController from './aba/cabinet/web/ws-controllers/SupportWsController';
import AuthWsController from './aba/cabinet/web/ws-controllers/identifications/AuthWsController';
import FincheckerWsController from './aba/cabinet/web/ws-controllers/identifications/FincheckerWsController';
import LegalBeneficiaryWsController from './aba/cabinet/web/ws-controllers/identifications/LegalBeneficiaryWsController';
import LegalBusinessProfileWsController from './aba/cabinet/web/ws-controllers/identifications/LegalBusinessProfileWsController';
import LegalCompanyDataWsController from './aba/cabinet/web/ws-controllers/identifications/LegalCompanyDataWsController';
import LegalDirectorsWsController from './aba/cabinet/web/ws-controllers/identifications/LegalDirectorsWsController';
import CommissionsWsController from './aba/cabinet/web/ws-controllers/accounts/CommissionsWsController';
import CryptPayoutWsController from './aba/cabinet/web/ws-controllers/accounts/CryptPayoutWsController';
import InternalBalanceWsController from './aba/cabinet/web/ws-controllers/accounts/InternalBalanceWsController';
import PaymentSettingsWsController from './aba/cabinet/web/ws-controllers/accounts/PaymentSettingsWsController';
import PaymentsWsController from './aba/cabinet/web/ws-controllers/accounts/PaymentsWsController';
import ProfileWsController from './aba/cabinet/web/ws-controllers/accounts/ProfileWsController';
import SecurityWsController from './aba/cabinet/web/ws-controllers/accounts/SecurityWsController';
import SwiftWsController from './aba/cabinet/web/ws-controllers/accounts/SwiftWsController';
import VirtpayWsController from './aba/cabinet/web/ws-controllers/accounts/VirtpayWsController';
import WalletWsController from './aba/cabinet/web/ws-controllers/accounts/WalletWsController';
import NaturalInternalBalanceWsController from './aba/cabinet/web/ws-controllers/accounts/natural-person/NaturalInternalBalanceWsController';
import NaturalPaymentsWsController from './aba/cabinet/web/ws-controllers/accounts/natural-person/NaturalPaymentsWsController';
import LegalPaymentWsController from './aba/cabinet/web/ws-controllers/accounts/legal-person/LegalPaymentWsController'

export default class apiDataSourceMain {
	DictionaryWsController: DictionaryWsController;
	FileWsController: FileWsController;
	SupportWsController: SupportWsController;
	AuthWsController: AuthWsController;
	FincheckerWsController: FincheckerWsController;
	LegalBeneficiaryWsController: LegalBeneficiaryWsController;
	LegalBusinessProfileWsController: LegalBusinessProfileWsController;
	LegalCompanyDataWsController: LegalCompanyDataWsController;
	LegalDirectorsWsController: LegalDirectorsWsController;
	CommissionsWsController: CommissionsWsController;
	CryptPayoutWsController: CryptPayoutWsController;
	InternalBalanceWsController: InternalBalanceWsController;
	PaymentSettingsWsController: PaymentSettingsWsController;
	PaymentsWsController: PaymentsWsController;
	ProfileWsController: ProfileWsController;
	SecurityWsController: SecurityWsController;
	SwiftWsController: SwiftWsController;
	VirtpayWsController: VirtpayWsController;
	WalletWsController: WalletWsController;
	NaturalInternalBalanceWsController: NaturalInternalBalanceWsController;
	NaturalPaymentsWsController: NaturalPaymentsWsController;
	LegalPaymentWsController: LegalPaymentWsController;
	constructor(cookieName?: string) {
		this.DictionaryWsController = new DictionaryWsController(undefined, cookieName, "appWebSettings.json", "apiUrl");
		this.FileWsController = new FileWsController(undefined, cookieName, "appWebSettings.json", "apiUrl");
		this.SupportWsController = new SupportWsController(undefined, cookieName, "appWebSettings.json", "apiUrl");
		this.AuthWsController = new AuthWsController(undefined, cookieName, "appWebSettings.json", "apiUrl");
		this.FincheckerWsController = new FincheckerWsController(undefined, cookieName, "appWebSettings.json", "apiUrl");
		this.LegalBeneficiaryWsController = new LegalBeneficiaryWsController(undefined, cookieName, "appWebSettings.json", "apiUrl");
		this.LegalBusinessProfileWsController = new LegalBusinessProfileWsController(undefined, cookieName, "appWebSettings.json", "apiUrl");
		this.LegalCompanyDataWsController = new LegalCompanyDataWsController(undefined, cookieName, "appWebSettings.json", "apiUrl");
		this.LegalDirectorsWsController = new LegalDirectorsWsController(undefined, cookieName, "appWebSettings.json", "apiUrl");
		this.CommissionsWsController = new CommissionsWsController(undefined, cookieName, "appWebSettings.json", "apiUrl");
		this.CryptPayoutWsController = new CryptPayoutWsController(undefined, cookieName, "appWebSettings.json", "apiUrl");
		this.InternalBalanceWsController = new InternalBalanceWsController(undefined, cookieName, "appWebSettings.json", "apiUrl");
		this.PaymentSettingsWsController = new PaymentSettingsWsController(undefined, cookieName, "appWebSettings.json", "apiUrl");
		this.PaymentsWsController = new PaymentsWsController(undefined, cookieName, "appWebSettings.json", "apiUrl");
		this.ProfileWsController = new ProfileWsController(undefined, cookieName, "appWebSettings.json", "apiUrl");
		this.SecurityWsController = new SecurityWsController(undefined, cookieName, "appWebSettings.json", "apiUrl");
		this.SwiftWsController = new SwiftWsController(undefined, cookieName, "appWebSettings.json", "apiUrl");
		this.VirtpayWsController = new VirtpayWsController(undefined, cookieName, "appWebSettings.json", "apiUrl");
		this.WalletWsController = new WalletWsController(undefined, cookieName, "appWebSettings.json", "apiUrl");
		this.NaturalInternalBalanceWsController = new NaturalInternalBalanceWsController(undefined, cookieName, "appWebSettings.json", "apiUrl");
		this.NaturalPaymentsWsController = new NaturalPaymentsWsController(undefined, cookieName, "appWebSettings.json", "apiUrl");
		this.LegalPaymentWsController = new LegalPaymentWsController(undefined, cookieName, "appWebSettings.json", "apiUrl");
	}

}
