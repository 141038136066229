
import { Options, Prop, Vue, Watch } from "vue-property-decorator";
interface Items {
  FromHour: number;
  FromMinutes: number;
  ToHour: number;
  ToMinutes: number;
}
interface DoctorScheduleDay {
  Day: Date;
  DayOfWeek: number;
  IsDisabled: boolean;
  Items: Array<Items>;
}
@Options<UiSchedule>({
  emits: ["update:modelValue"],
})
export default class UiSchedule extends Vue {
  @Prop({ type: String, default: "День недели" }) weekDay: string;
  @Prop({ type: [String, Number, Date, Object, Array] })
  modelValue: any | any[] | String | Number | string | number | Date;
  // todo - сделать разделение modalValue на время с и до
  innerValue: DoctorScheduleDay = null;
  aboutTime = false;
  testDate: any;
  itemTime: Items = {
    FromHour: 0,
    FromMinutes: 0,
    ToHour: 0,
    ToMinutes: 0,
  };
  firstData: Date = new Date();
  secondDate: Date = new Date();
  created() {
    this.innerValue = this.modelValue;
  }
  // обычное значение
  @Watch("modelValue")
  onValueChanged() {
    this.innerValue = this.modelValue;
  }
  // внутреннее значение
  @Watch("innerValue", { deep: true })
  onInnerValueChanged() {
    // console.log("update",this.innerValue);
    this.$emit("update:modelValue", this.innerValue);
  }
  showTime() {
    this.aboutTime = true;
  }
  AddTime() {
    this.innerValue.Items.push(this.itemTime);
  }
  removeTime(item: any) {
    console.log(item);
  }
}
