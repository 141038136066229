
import { Vue, Options, Prop, Watch } from "vue-property-decorator";
import Loading from "./Loading.vue";
import ProfileModel from "../../../models/ProfileModel";
import { AdapterNaturalIdentificationService } from "../adapter";
import { useRoute, useRouter } from "vue-router";
import * as Routes from "@/router/routerNames";
@Options({
	name: "identification-liveness",
	components: {
		Loading,
	},
})
export default class IdentificationLiveness extends Vue {
	@Prop({ default: null, type: Object }) profile!: ProfileModel;

	adapter = AdapterNaturalIdentificationService.getInstance();
	ErrorMessage: string = "";
	isLoading: boolean = false;
	isPassed: boolean = false;
	route = useRoute();

	created() {
		let searchParams = new URLSearchParams(window.location.search);
		if (searchParams.get("errorStart")) {
			this.ErrorMessage = searchParams.get("errorStart");
		}
		if (searchParams.get("error")) {
			this.ErrorMessage = searchParams.get("error");
		}
		if (searchParams.get("success")) {
			this.livenessCheck();
		}
	}

	async livenessStart() {
		const url = "";
		const adapter = AdapterNaturalIdentificationService.getInstance();
		const res = await adapter.livenessStart(document.location.href + "?success=True");
		if (res.IsSuccess) {
			location.href = res.Value.toString();
		}
		// var lang = document.location.href.split("/")[3];
		// if (!lang || lang.length !== 2) lang = "en";
		// let returnUrl = location.href.split("?")[0].split("#")[0] + "?success=True";

		// location.href = "/" + lang + "/Finchecker/LivenessStart?returnUrl=" + returnUrl;
	}
	router = useRouter();
	async livenessCheck() {
		if (this.route.query.success == "True") {
			this.isLoading = true;
			this.ErrorMessage = "";
			const res = await this.adapter.livenessSaveResult();
			if (res.IsSuccess) {
				this.isPassed = res.IsSuccess;

				this.isLoading = false;
				this.router.push({ name: Routes.PROFILE_IDENTIFICATION });
			} else {
				this.ErrorMessage = res.ErrorMessage;
			}
		}
		// this.$api.Main.FincheckerService.LivenessSaveResult((res) => {
		// 	this.isPassed = res.IsSuccess;
		// 	if (!res.IsSuccess) console.error(res);
		// 	this.isLoading = false;
		// });
	}
	catchError() {
		this.ErrorMessage = this.$localization.getTranslate("ErrorLivenessResult");
		this.isLoading = false;
	}

	async GoNext() {
		await this.livenessCheck();
		if (!this.isPassed) return;
		this.$emit("complete");
	}

	onSubmit() {
		return false;
	}
}
