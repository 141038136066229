

export default class GetRefillRequisitesResponse  {
	Swift!: string;
	Address!: string;
	PayeeName!: string;
	Iban!: string;
	PaymentDetails!: string;
	Bank!: string;
	constructor(obj?: Partial<GetRefillRequisitesResponse>) {
		if (obj) {
			Object.assign(this, obj);
		}
	}
}
