import ModelTypeCard from "./ModelTypeCard";

export default class ModelPersonCard{
  TrackNumber: string 
  TypeCard: ModelTypeCard = null;
  AddressCard: string = null;
  Balance: number = 0;

  constructor(obj?: Partial<ModelPersonCard>){
    if (obj) {
      Object.assign(this, obj)
    }
  }
}