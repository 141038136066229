
import EnumCompanyDocumentType from '@/api/main/dataSource/api/aba/data/enums/EnumCompanyDocumentType';
import CompanyDocumentModel from "./CompanyDocumentModel";
import UploadPhotoCropRequest from '@/api/main/dataSource/api/file-services/models/UploadPhotoCropRequest';
import LegalCompanySaveDataRequest from '@/api/main/dataSource/api/identification-services/models/legal-company/LegalCompanySaveDataRequest';
import LegalCompanyDocumentSaveRequest from '@/api/main/dataSource/api/identification-services/models/legal-company-document/LegalCompanyDocumentSaveRequest';

export default class CompanyDataViewModel {

    LegalUserId: number = 0;
    RegistrationNumber?: string = '';
    Website?: string = '';
    IncorporationDate: Date = null;
    AddressCountryCode?: string = '';
    AddressState?: string = '';
    AddressCity?: string = '';
    AddressPostalCode?: string = '';
    AddressStreet?: string = '';
    AddressHome?: string = '';
    AddressApartment?: string = '';
    CompanyDocuments: Array<CompanyDocumentModel> = [];
    OldCompanyDocumentsIds: number[] = []
    CompanyName?: string = '';
    Phone?: string = '';
    Email?: string = '';

    constructor(obj?: Partial<CompanyDataViewModel>) {
        if (obj) {
            Object.assign(this, obj);
            var self = this;

            if (new Date(obj.IncorporationDate).getFullYear() == 1) {
                self.IncorporationDate = null;
            }
            // if (!obj.CompanyDocuments || !obj.CompanyDocuments.find(i => i.Type === EnumCompanyDocumentType.RegistrationDocument))
            //     self.CompanyDocuments.push({
            //         Type: EnumCompanyDocumentType.RegistrationDocument,
            //         File: new UploadPhotoCropRequest()
            //     })
            if (obj.CompanyDocuments) {
                obj.CompanyDocuments.forEach((doc) => {
                    self.OldCompanyDocumentsIds.push(doc.Id);
                });
            }
            self.CompanyDocuments = self.CompanyDocuments.sort((a, b) => a.Type < b.Type ? -1 : 1)
        }
    }

    // toLegalCompanySaveDataRequest(): LegalCompanySaveDataRequest {
    //     const result = new LegalCompanySaveDataRequest(this);
    //     const self = this;
    //     result.CompanyDocumentsIdsForDelete = this.OldCompanyDocumentsIds.filter(i => !self.CompanyDocuments.find(s => s.Id === i))
    //     result.CompanyDocumentsIdsForCreate = this.CompanyDocuments.filter(i => !i.Id).map(i => new LegalCompanyDocumentSaveRequest({
    //         Type: i.Type,
    //         File: i.File
    //     }))
    //     return result;
    // }
    isValid() {
        const self = this;
        if (
            self.RegistrationNumber.length == 0 ||
            self.AddressCity.length == 0 ||
            self.AddressPostalCode.length == 0 ||
            self.AddressStreet.length == 0 ||
            self.AddressHome.length == 0 ||
            self.IncorporationDate == null ||
            !self.AddressCountryCode ||
            !self.AddressCountryCode.length) {
            return false;
        }


        if (self.OldCompanyDocumentsIds.length > 0 && self.CompanyDocuments.length == 0) { return true }

        if (self.CompanyDocuments.some(x => !x.FileUrl && (!x.File))) {
            console.log('есть документ с незаполненным контентом файла');

            return false;
        }

        if (!self.CompanyDocuments.some(x => x.Type === EnumCompanyDocumentType.RegistrationDocument && (x.FileUrl || x.File))) {
            console.log('нет документа с регистрационным документом');
            return false;
        }



        if (self.CompanyDocuments.filter(x => x.File).some(x => x.Type != EnumCompanyDocumentType.RegistrationDocument && x.File.length == 0)) {
            return false;
        }
        return true;
    }
}
