import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "months" }
const _hoisted_2 = { class: "navigation" }
const _hoisted_3 = { class: "items" }
const _hoisted_4 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: _normalizeClass(["icon-arrow-left", [_ctx.innerYear <= 1900 ? 'no-active' : '']]),
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.backward && _ctx.backward(...args)))
      }, null, 2),
      _createElementVNode("div", {
        class: "title_date-picker unselectable",
        onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.changeYear && _ctx.changeYear(...args)), ["stop"]))
      }, _toDisplayString(_ctx.innerYear), 1),
      _createElementVNode("div", {
        class: "icon-arrow-right",
        onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.next && _ctx.next(...args)))
      })
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.innerMonths, (month, key) => {
        return (_openBlock(), _createElementBlock("div", {
          key: key,
          class: _normalizeClass(["month", month.type])
        }, [
          _createElementVNode("div", {
            onClick: _withModifiers(($event: any) => (_ctx.changeMonth(month)), ["stop"])
          }, _toDisplayString(month.name), 9, _hoisted_4)
        ], 2))
      }), 128))
    ])
  ]))
}