import { WebApiService } from '../../../../../plugins/webApiService'
import { Events } from '../../../../../plugins/events'
import { SendRequestModel } from '../../../../../plugins/models/SendRequestModel'
import LegalCompanySaveDataRequest from '../../../../../identification-services/models/legal-company/LegalCompanySaveDataRequest';
import WsResponseModelT from '../../../../../schema-builder/server/models/WsResponseModelT';
import LegalCompanyGetDataResponse from '../../../../../identification-services/models/legal-company/LegalCompanyGetDataResponse';

export default class LegalCompanyDataWsController {
	private url?: string;
	private cookieName: string;
	private appWebSettingsPath?: string;
	private apiUrlProperty?: string;

	GetCompanyDataEvent = new Events<WsResponseModelT<LegalCompanyGetDataResponse>>();
	SaveCompanyDataEvent = new Events<WsResponseModelT<Boolean>>();

	subscribeEvents = async () => {
		const self = this;
		const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
		webApiService.on('LegalCompanyDataWsController', 'GetCompanyData', 'Main', (res: WsResponseModelT<LegalCompanyGetDataResponse>) => {
			self.GetCompanyDataEvent.trigger(res);
		});
		webApiService.on('LegalCompanyDataWsController', 'SaveCompanyData', 'Main', (res: WsResponseModelT<Boolean>) => {
			self.SaveCompanyDataEvent.trigger(res);
		});
	}

	constructor(url?: string, cookieName?: string, appWebSettingsPath?: string, apiUrlProperty?: string) {
		this.url = url;
		this.cookieName = cookieName ?? "Auth-Token";
		this.appWebSettingsPath = appWebSettingsPath && [location.host, appWebSettingsPath].join("/");
		this.apiUrlProperty = apiUrlProperty;
		this.subscribeEvents();
	}

	async GetCompanyData() {
		const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
		const sendRequest = new SendRequestModel({
			ProjectName: 'Main',
			Controller: 'LegalCompanyDataWsController',
			Method: 'GetCompanyData',
		})

		webApiService.send(sendRequest);
	}

	async SaveCompanyData(request: LegalCompanySaveDataRequest) {
		const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
		const sendRequest = new SendRequestModel({
			ProjectName: 'Main',
			Controller: 'LegalCompanyDataWsController',
			Method: 'SaveCompanyData',
			Value: typeof(request) === 'object' ? JSON.stringify(request) : request,
		})

		webApiService.send(sendRequest);
	}

	 // async methods
	async getCompanyDataAsync(): Promise<LegalCompanyGetDataResponse> {
		return new Promise<LegalCompanyGetDataResponse>(async (resolve, reject) => {
			const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
			const sendRequest = new SendRequestModel({
				ProjectName: 'Main',
				Controller: 'LegalCompanyDataWsController',
				Method: 'GetCompanyData',
				Callback: (response: WsResponseModelT<LegalCompanyGetDataResponse>) => {
					const isGeneric = typeof response.IsSuccess == 'boolean'
					const result = response.IsSuccess ? response.Value : response

					if (!isGeneric || response.IsSuccess) resolve(result as LegalCompanyGetDataResponse)
					else reject(result)
				}
			})

			webApiService.send(sendRequest);
		});
	}

	async saveCompanyDataAsync(request: LegalCompanySaveDataRequest): Promise<Boolean> {
		return new Promise<Boolean>(async (resolve, reject) => {
			const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
			const sendRequest = new SendRequestModel({
				ProjectName: 'Main',
				Controller: 'LegalCompanyDataWsController',
				Method: 'SaveCompanyData',
				Value: typeof(request) === 'object' ? JSON.stringify(request) : request,
				Callback: (response: WsResponseModelT<Boolean>) => {
					const isGeneric = typeof response.IsSuccess == 'boolean'
					const result = response.IsSuccess ? response.Value : response

					if (!isGeneric || response.IsSuccess) resolve(result as Boolean)
					else reject(result)
				}
			})

			webApiService.send(sendRequest);
		});
	}

}