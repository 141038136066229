import EnumLegalPartnerType from '../../../aba/data/enums/EnumLegalPartnerType';
import UploadPhotoCropRequest from '../../../file-services/models/UploadPhotoCropRequest';

export default class LegalPartnersSaveRequest  {
	Id?: number;
	Name!: string;
	RegistrationCountryCode!: string;
	PartnerType!: EnumLegalPartnerType;
	File!: UploadPhotoCropRequest;
	constructor(obj?: Partial<LegalPartnersSaveRequest>) {
		if (obj) {
			Object.assign(this, obj);
		}
	}
}
