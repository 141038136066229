import { WebApiService } from '../../../../../plugins/webApiService'
import { Events } from '../../../../../plugins/events'
import { SendRequestModel } from '../../../../../plugins/models/SendRequestModel'
import WsResponseModelT from '../../../../../schema-builder/server/models/WsResponseModelT';
import FincheckerLivenessStatusResponse from '../../../../../identification-services/models/finchecker/FincheckerLivenessStatusResponse';

export default class FincheckerWsController {
	private url?: string;
	private cookieName: string;
	private appWebSettingsPath?: string;
	private apiUrlProperty?: string;

	IsLivenessInitEvent = new Events<WsResponseModelT<FincheckerLivenessStatusResponse>>();
	LivenessStartEvent = new Events<WsResponseModelT<String>>();
	LivenessSaveResultEvent = new Events<WsResponseModelT<Boolean>>();

	subscribeEvents = async () => {
		const self = this;
		const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
		webApiService.on('FincheckerWsController', 'IsLivenessInit', 'Main', (res: WsResponseModelT<FincheckerLivenessStatusResponse>) => {
			self.IsLivenessInitEvent.trigger(res);
		});
		webApiService.on('FincheckerWsController', 'LivenessStart', 'Main', (res: WsResponseModelT<String>) => {
			self.LivenessStartEvent.trigger(res);
		});
		webApiService.on('FincheckerWsController', 'LivenessSaveResult', 'Main', (res: WsResponseModelT<Boolean>) => {
			self.LivenessSaveResultEvent.trigger(res);
		});
	}

	constructor(url?: string, cookieName?: string, appWebSettingsPath?: string, apiUrlProperty?: string) {
		this.url = url;
		this.cookieName = cookieName ?? "Auth-Token";
		this.appWebSettingsPath = appWebSettingsPath && [location.host, appWebSettingsPath].join("/");
		this.apiUrlProperty = apiUrlProperty;
		this.subscribeEvents();
	}

	async IsLivenessInit() {
		const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
		const sendRequest = new SendRequestModel({
			ProjectName: 'Main',
			Controller: 'FincheckerWsController',
			Method: 'IsLivenessInit',
		})

		webApiService.send(sendRequest);
	}

	async LivenessStart(returnUrl: string) {
		const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
		const sendRequest = new SendRequestModel({
			ProjectName: 'Main',
			Controller: 'FincheckerWsController',
			Method: 'LivenessStart',
			Value: typeof(returnUrl) === 'object' ? JSON.stringify(returnUrl) : returnUrl,
		})

		webApiService.send(sendRequest);
	}

	async LivenessSaveResult() {
		const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
		const sendRequest = new SendRequestModel({
			ProjectName: 'Main',
			Controller: 'FincheckerWsController',
			Method: 'LivenessSaveResult',
		})

		webApiService.send(sendRequest);
	}

	 // async methods
	async isLivenessInitAsync(): Promise<FincheckerLivenessStatusResponse> {
		return new Promise<FincheckerLivenessStatusResponse>(async (resolve, reject) => {
			const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
			const sendRequest = new SendRequestModel({
				ProjectName: 'Main',
				Controller: 'FincheckerWsController',
				Method: 'IsLivenessInit',
				Callback: (response: WsResponseModelT<FincheckerLivenessStatusResponse>) => {
					const isGeneric = typeof response.IsSuccess == 'boolean'
					const result = response.IsSuccess ? response.Value : response

					if (!isGeneric || response.IsSuccess) resolve(result as FincheckerLivenessStatusResponse)
					else reject(result)
				}
			})

			webApiService.send(sendRequest);
		});
	}

	async livenessStartAsync(returnUrl: string): Promise<String> {
		return new Promise<String>(async (resolve, reject) => {
			const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
			const sendRequest = new SendRequestModel({
				ProjectName: 'Main',
				Controller: 'FincheckerWsController',
				Method: 'LivenessStart',
				Value: typeof(returnUrl) === 'object' ? JSON.stringify(returnUrl) : returnUrl,
				Callback: (response: WsResponseModelT<String>) => {
					const isGeneric = typeof response.IsSuccess == 'boolean'
					const result = response.IsSuccess ? response.Value : response

					if (!isGeneric || response.IsSuccess) resolve(result as String)
					else reject(result)
				}
			})

			webApiService.send(sendRequest);
		});
	}

	async livenessSaveResultAsync(): Promise<Boolean> {
		return new Promise<Boolean>(async (resolve, reject) => {
			const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
			const sendRequest = new SendRequestModel({
				ProjectName: 'Main',
				Controller: 'FincheckerWsController',
				Method: 'LivenessSaveResult',
				Callback: (response: WsResponseModelT<Boolean>) => {
					const isGeneric = typeof response.IsSuccess == 'boolean'
					const result = response.IsSuccess ? response.Value : response

					if (!isGeneric || response.IsSuccess) resolve(result as Boolean)
					else reject(result)
				}
			})

			webApiService.send(sendRequest);
		});
	}

}