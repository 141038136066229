
import { Options, Prop, Vue, Watch } from "vue-property-decorator";
@Options({
	name: "header-menu-hamburger",
})
export default class HeaderMenuHamburgerComponent extends Vue {
	@Prop() active: boolean;
	isActive: boolean = false;

	@Watch("active")
	onUpdateActive(newValue: boolean) {
		this.isActive = newValue;
	}

	onClickHamburgerElement() {
		this.isActive = !this.isActive;
		console.log(`onClickHamburgerElement`, this.isActive);
		this.$emit("onClick", this.isActive);
	}
}
