

export default class PersonalIdentificationData  {
	UserId!: number;
	BirthDate?: Date;
	BirthPlace!: string;
	FirstName!: string;
	SecondName!: string;
	Appeal!: string;
	Country!: string;
	City!: string;
	AddressStreet!: string;
	AddressZip!: string;
	constructor(obj?: Partial<PersonalIdentificationData>) {
		if (obj) {
			Object.assign(this, obj);
		}
	}
}
