
import { Options, Prop, Vue, Watch } from "vue-property-decorator";

@Options<UiLableInput>({
  emits: ["update:modelValue"],
})
export default class UiLableInput extends Vue {
  @Prop({ type: String, default: "lable" }) nameLabel: string;
  @Prop({ type: String, default: "placeholder" }) placeholder: string;

  @Prop({ type: String, default: "id" }) id: string;
  @Prop({ type: [String, Number, Date, Object, Array] })
  modelValue: any | any[] | String | Number | string | number | Date;

  innerValue: any | any[] | String | Number | string | number | Date = null;
  created() {
    this.innerValue = this.modelValue;
  }
  // обычное значение
  @Watch("modelValue")
  onValueChanged() {
    this.innerValue = this.modelValue;
  }
  // внутреннее значение
  @Watch("innerValue")
  onInnerValueChanged() {
    this.$emit("update:modelValue", this.innerValue);
  }
}
