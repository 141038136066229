export default class TransferAcquiringModel {
  ToFirstName: string = null;
  ToLastName: string = null;
  ToCardNumber: string = null;
  Amount: string = null;
  CommissionAmount: string = null;
  Description: string = null;

  constructor(obj?: Partial<TransferAcquiringModel>) {
    if (obj) {
      Object.assign(this, obj)
    }
  }
}