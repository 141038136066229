import EnumDocumentType from '../../../../common/enums/EnumDocumentType';
import EnumTypeTransaction from '../../../../aba/data/enums/EnumTypeTransaction';
import PageRequest from '../../../../pagination-helper/lib/PageRequest';

export default class DownloadTransactionsForNaturalRequest  {
	DocumentType!: EnumDocumentType;
	ByCard: boolean = false;
	TypeTransactionId!: EnumTypeTransaction;
	FromDate!: Date;
	ToDate!: Date;
	Page!: PageRequest;
	constructor(obj?: Partial<DownloadTransactionsForNaturalRequest>) {
		if (obj) {
			Object.assign(this, obj);
		}
	}
}
