export default class MessageItemModel {
  Id: number = 0;
  Text: string = null;
  UserId: number = 0;
  IsPersonMessage: boolean = null;
  DateCreate: string = null;
  DateCreateJs: Date = null;

  constructor(obj?: Partial<MessageItemModel>) {
    if (obj) {
      Object.assign(this, obj)
    }
  }
}