import ModelSystemMessagesLangs from "./ModelSystemMessagesLangs";

export default class GetSystemMessages {
  Id: number = 0;
  DateCreate: string = null;
  Text: string = null;
  IsRead: boolean = false;
  TypeSystemMessageId: number = 0;
  UserId: number = 0;
  SystemMessagesLangs: Array<ModelSystemMessagesLangs> = null;

  constructor(obj?: Partial<GetSystemMessages>) {
    if (obj) {
      Object.assign(this, obj)
    }
  }
}