export default class ProfileModel {
  Id: number = 0;
  Phone: string = null;
  Email: string = null;
  Appeal: string = null;
  Identified: number = 0;
  BirthDate: Date = null;
  BirthPlace: string = null;
  AddressState: string = null;
  AddressCountry: string = null;
  AddressCity: string = null;
  AddressStreet: string = null;
  AddressZip: string = null;
  DocumentType: string = null;
  DocumentNumber: string = null;
  DocumentIssuedCountryCode: string = null;
  DocumentIssuedBy: Date = null;
  FirstName: string = null;
  SecondName: string = null;
  PassportNumber?: string = null;
  Country: string = null;
  Code: string = null;
  City: string = null;
  Street: string = null;
  HouseNumber: string = null;
  ExtraBedForAnApartment: string = null;
  PostCode: string = null;

  constructor(obj?: Partial<ProfileModel>) {
    if (obj) {
      Object.assign(this, obj)
    }
  }
}