import EnumCurrency from "@/api/main/models/Enums/EnumCurrency";
import EnumTransactionCheckResultType from "@/api/main/models/Enums/EnumTransactionCheckResultType";
import EnumTransactionCheckWarnType from "@/api/main/models/Enums/EnumTransactionCheckWarnType";
import EnumTransactionStatus from "@/api/main/models/Enums/EnumTransactionStatus";
import EnumTransactionType from "@/api/main/models/Enums/EnumTransactionType";
import AcquiringPayoutInfoModel from "./AcquiringPayoutInfoModel";
import CryptInfoModel from "./CryptInfoModel";
import ExternalCardInfoModel from "./ExternalCardInfoModel";
import SepaInfoModel from "./SepaInfoModel";
import SwiftInfoModel_ from "./SwiftInfoModel";

export default class MoneyTransactionPersonItemModel {
  Id: number = 0;
  Date: string = null;
  Amount: number = 0;
  Currency: EnumCurrency = null;
  CurrencyName: string = null;
  Description: string = null;
  Type: EnumTransactionType  = null;
  Status: EnumTransactionStatus = null;
  FromUserName: string = null;
  FromUserIban: string = null;
  FromUserId: number = 0;
  ToUserName: string = null;
  ToUserIban: string = null;
  ToUserId: number = 0;
  Sepa: SepaInfoModel = null;
  Swift: SwiftInfoModel_ = null;
  AcquiringPayout: AcquiringPayoutInfoModel = null;
  CheckWarnType: EnumTransactionCheckWarnType = null;
  CheckResultType?: EnumTransactionCheckResultType = null;
  CheckWarnResolve: boolean = false;
  ErrorMessage: string = null;
  Card: ExternalCardInfoModel = null;
  Crypt: CryptInfoModel = null;

  constructor(obj?: Partial<MoneyTransactionPersonItemModel>) {
    if (obj) {
      Object.assign(this, obj)
    }
  }
}
