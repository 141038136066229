
import GetCommissionRequest from "@/api/main/dataSource/api/aba/cabinet/web/models/GetCommissionRequest";
import EnumCommission from "@/api/main/dataSource/api/aba/data/enums/EnumCommission";
import DictionaryItem from "@/api/main/dataSource/api/dictionary-services/models/DictionaryItem";
import CryptPayoutRequest from "@/api/main/dataSource/api/transaction-services/models/transaction-operations/CryptPayoutRequest";
import InfoForCryptPayout from "@/api/main/dataSource/api/transaction-services/models/transfer-info-service/InfoForCryptPayout";
import NaturalPersonModel from "@/views/models/NaturalPersonModel";
import ErrorModel from "@/views/pages/login/adapter/models/errorModel";
import { Vue, Options, Prop } from "vue-property-decorator";
import AdapterTransferService from "../../adapter/AdapterTransferService";
@Options({
	name: "crypt-transfer",
})
export default class CryptTransfer extends Vue {
	@Prop({ type: Object, default: null }) profile: NaturalPersonModel;
	@Prop({ type: Number, default: null }) Type: number;

	CurrenciesList: Array<string> = null;
	NetworksList: Array<string> = null;
	CryptCurrencyName: string = "";
	CryptNetworkName: string = "";
	IBAN: string = "";
	Amount: string = "";
	PurposePayment: string = "";
	ErrorMessage: string = "";
	Name: string = "";

	Commission: number = 0;

	IsLoad: boolean = false;
	IsSave: boolean = false;

	IsLoadData: boolean = false;

	async mounted() {
		await this.getData();
	}

	async getData() {
		this.IsLoadData = true;
		const adapter = await AdapterTransferService.getInstance();
		let res = await adapter.getCryptCurrencyList();
		if ((res as ErrorModel)?.ErrorMessage) {
			return;
		}

		this.CurrenciesList = (res as DictionaryItem[]).map((x) => x.Name) || [];
		res = await adapter.getCryptNetworkList();
		if ((res as ErrorModel)?.ErrorMessage) {
			return;
		}
		this.NetworksList = (res as DictionaryItem[]).map((x) => x.Name);
		this.IsLoadData = false;
		// this.$api.Main.DictionaryService.GetCryptCurrencyList((res) => {
		// 	this.CurrenciesList = res.map((x) => x.Name);

		// 	this.$api.Main.DictionaryService.GetCryptNetworkList((res) => {
		// 		this.NetworksList = res.map((x) => x.Name);
		// 		this.IsLoadData = false;
		// 	});
		// });//TODO api
	}

	async getCommission() {
		var validation = this.Validation();
		if (!validation) return;
		const adapter = await AdapterTransferService.getInstance();
		var formData = new FormData();
		formData.append("Type", "31");
		formData.append("Amount", this.$store.state.getAmountForSend(this.Amount));
		this.IsLoad = true;
		const req = new GetCommissionRequest({
			Amount: Number(this.Amount),
			Type: EnumCommission.CommissionCryptPayout,
		});
		const res = await adapter.getCommission(req);
		this.IsLoad = false;
		if (res.IsSuccess) {
			this.Commission = Number(res.Value);
			this.InitTransfer();
		}

		// this.$api.Main.CommissionsService.GetCommission((res) => {
		// 	if (res.IsSuccess) {
		// 		this.Commission = res.Value;
		// 		this.InitTransfer();
		// 	}
		// }, formData);//TODO API
	}

	async InitTransfer() {
		if (this.IsLoad) return false;

		var validation = this.Validation();
		if (!validation) return;

		let formData = new FormData();
		formData.append("ToIban", this.IBAN);
		formData.append("CryptNetworkName", this.CryptNetworkName);
		formData.append("CryptCurrencyName", this.CryptCurrencyName);
		formData.append("Amount", this.$store.state.getAmountForSend(this.Amount));
		formData.append("Description", this.PurposePayment);
		formData.append("Commission", this.Commission.toString());

		this.IsLoad = true;
		const adapter = AdapterTransferService.getInstance();
		const req = new CryptPayoutRequest({
			ToIban: this.IBAN,
			CryptCurrencyName: this.CryptCurrencyName,
			CryptNetworkName: this.CryptNetworkName,
			Amount: Number(this.Amount),
			Description: this.PurposePayment,
			CurrentUrl: "",
		});
		const res = await adapter.checkTransfer(req);
		this.IsLoad = false;
		if (res.IsSuccess) {
			this.ErrorMessage = "";
			this.$emit("GoTransferCryptPayout", formData);
		} else {
			this.ErrorMessage = (res as ErrorModel).ErrorMessage;
		}

		// this.$api.Main.CryptPayoutService.CheckTransfer((res) => {
		//   this.IsLoad = false;
		//   if (res.IsSuccess) {
		//     this.ErrorMessage = "";
		//     this.$emit("GoTransferCryptPayout", formData);
		//   } else {
		//     this.ErrorMessage = res.Message;
		//   }
		// }, formData);//TODO api
	}
	Validation() {
		var russian = /^[а-яА-Я]*$/;
		if (
			this.IBAN == "" ||
			this.Amount == "" ||
			this.PurposePayment == "" ||
			this.CryptNetworkName == "" ||
			this.CryptCurrencyName == "" ||
			((this.Name == undefined || this.Name == "") && this.IsSave)
		) {
			this.ErrorMessage = "Fill in all the fields";
			return false;
		} else if (this.IBAN == this.profile.Balance.Number) {
			this.ErrorMessage = "Please enter a different account number";
			return false;
		} else if (russian.test(this.PurposePayment)) {
			this.ErrorMessage = "The text should consist only of English letters";
			return false;
		}
		return true;
	}
}
