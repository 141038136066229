import $ = require("jquery");

const fillInput = {
    beforeMount: function (el, binding) {
        var text = binding.value;       
        $(el).on('change invalid', function () {
            var textfield = $(this).get(0);
            textfield.setCustomValidity('');
            if (!textfield.validity.valid) {        
                textfield.setCustomValidity(text);
            }
        });
    }
}

export default fillInput;

