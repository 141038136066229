
import { Vue, Options } from "vue-property-decorator";
import { AdapterProfileService } from "../adapter";

@Options({
	name: "security",
})
export default class Security extends Vue {
	isReadyQR: boolean = false;
	key: string = "";
	TwoAuth: boolean = false;
	Password: string = "";
	NewPassword: string = "";
	NewPasswordReapit: string = "";
	ErrorMessage: string = "";
	ErrorMessage2Factor = "";
	isFirst: boolean = true;
	isNewKey: boolean = false;

	ModalDialog: boolean = false;
	ModalMessage: string = "";

	async mounted() {
		await this.checkDefense();
	}

	adapter = AdapterProfileService.getInstance();
	changeTwoAuth(value: boolean) {
		if (value) {
			this.getQrImage();
		} else {
			this.DisableDefense();
		}
	}
	async ChangePassword() {
		let res;
		this.ErrorMessage = "";
		this.ErrorMessage2Factor = "";
		if (this.NewPassword !== this.NewPasswordReapit)
			this.ErrorMessage = this.$localization.getTranslate("PasswordsDoNotMatch");
		else {
			const res = await this.adapter.chagePassword({
				Password: this.Password,
				NewPassword: this.NewPassword,
			});

			if (!res.IsSuccess) {
				this.ErrorMessage = res.ErrorMessage;
				return;
			}
			this.ErrorMessage = "";
			this.Password = "";
			this.NewPasswordReapit = "";
			this.NewPassword = "";
		}
	}

	async getQrImage() {
		this.ErrorMessage2Factor = "";
		const res = await this.adapter.getQrImage();
		if (res.IsSuccess) {
			this.isReadyQR = true;
			this.$emit("EnableAunth", res.Value);
			return;
		}
		this.ErrorMessage2Factor = res.ErrorMessage;
	}

	async checkDefense() {
		const res = await this.adapter.checkDefense();
		if (res.IsSuccess) {
			this.TwoAuth = res.Value as boolean;
		}
	}

	async DisableDefense() {
		// this.TwoAuth = !this.TwoAuth;
		const res = await this.adapter.changeDefense(this.TwoAuth);
		if (res.IsSuccess) {
		}
	}

	get isValid() {
		return (
			!!this.Password && !!this.NewPassword && this.NewPasswordReapit == this.NewPassword
		);
	}
}
