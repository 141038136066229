
import { Options, Vue, Ref, Prop, Watch } from "vue-property-decorator";
import md5 from "md5";
import UiModalWindow from "../components/ui-modal-window/ui-modal-window.vue";
@Options({
  name: "ui-update-entity-modal",
  emits: ["save","notSave"],
})
export default class UiUpdateEntityModal extends Vue {
  @Ref("add-modal") addModalWindow: UiModalWindow;
  @Prop({ type: Object }) modelValue: { id: string };
   @Prop({ type: Boolean, default: true }) togle: boolean;
  get title(): string {
    return this.modelValue?.id ? "Редактирование" : "Добавление";
  }
  isLoading = false;
  @Prop({ type: Function }) saveFunction: (
    obj: Object
  ) => Promise<any>;
  @Prop({ type: Function }) notSaveFunction: (
    obj: Object
  ) => Promise<any>;
  @Prop({ type: Function, required: false }) mapFunction: (obj: Object) => Object;
  getHash(): string {
    return md5(JSON.stringify(this.modelValue || {}));
  }
  onAddClicked() {
    this.addModalWindow.show();
  }
  initAddEntityHash: string;
  @Watch("modelValue", { deep: false })
  onEntityChanged() {
    if (this.modelValue != null) {
      this.initAddEntityHash = this.getHash();
      this.show();
    }
  }
  show() {
    this.addModalWindow.show();
  }
  hide() {
    this.addModalWindow.hide();
  }
  isSave: boolean;
  async closeHandler(data: boolean): Promise<boolean> {
    return new Promise<boolean>(async (resolve, reject) => {
      const hash = this.getHash();
      // console.log("close handler");
      if (this.initAddEntityHash != hash && this.isSave == false) {
        var res = await (this as any).$dialog.confirm(
          "Есть несохраненные изменения. Хотите продолжить?"
        );
        resolve(res);
        return;
      }
      resolve(true);
    });
  }
  onCancel(data: boolean) {
    this.isSave = data;
    if(this.modelValue?.id){
    this.$emit("notSave");}
  }
  async onOk(data: boolean) {
    this.isSave = data;
    this.isLoading = true;
    try {
      const req =
        this.mapFunction == null
          ? this.modelValue
          : this.mapFunction(this.modelValue);
      const res = await this.saveFunction(req);
      if(this.modelValue?.id){
        this.$emit("save");
      }
     
      this.addModalWindow.hide();
    } catch (e) {
      throw e;
    } finally {
      this.isLoading = false;
    }
    // this.$emit("ok", res);
  }
}
