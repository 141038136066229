
import AcquiringPayoutTemplateListResponse from "@/api/main/dataSource/api/transaction-services/models/transfer-template-service/AcquiringPayoutTemplateListResponse";
import TemplateModel from "@/views/models/TemplatesTrasnfer/TemplateModel";
import moment from "moment";
import { Vue, Options } from "vue-property-decorator";
import AdapterTransferService from "../../adapter/AdapterTransferService";
@Options({
	name: "acquiring-templates",
})
export default class AcquiringTemplates extends Vue {
	Templates: Array<TemplateModel> = null;

	async mounted() {
		await this.GetTemplates();
	}
	adapter = AdapterTransferService.getInstance();
	async GetTemplates() {
		const res = await this.adapter.getAcquiringTemplates();

		if (res.IsSuccess) {
			this.Templates =
				res.Value.Items.map(
					(el) =>
						new TemplateModel({
							Amount: el.Amount,
							UserId: el.UserId,
							Id: el.Id,
							DateCreate: moment(el.DateCreate).format("DD.MM.YYYY mm:hh"),
							ToCardNumber: el.ToCardNumber,
							Description: el.Description,
							ToFirstName: el.ToFirstName,
							ToLastName: el.ToLastName,
						})
				) || [];
		}
		//TODO
	}
	UseTemplate(template: TemplateModel) {
		this.$emit("UseTemplate", template);
	}
}
