
import { Options, Vue } from "vue-class-component";

@Options<UiTableBodyItemComponent>({
  col: { type: Number, default: 0 },
  row: { type: Number, default: 0 },
  sele: { type: Boolean, default: false },
  borderLeft: { default: false },
  borderRight: { default: false },
  borderY: { default: false },
})
export default class UiTableBodyItemComponent extends Vue {
  readonly col = 0;
  readonly row = 0;
  readonly sele = false;
  readonly borderLeft = false;
  readonly borderRight = false;
  readonly borderY = false;
  selectInner = this.sele;
  ClickRow() {
    this.$emit("changeCol", this.row, this.col);
  }
}
