import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "ui_textarea_wrapper" }
const _hoisted_2 = ["readonly", "placeholder"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("label", _hoisted_1, [
    (_ctx.headerText.length != 0)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass([_ctx.styleForWrong ? 'wrong_text' : '', 'ui_input_header'])
        }, _toDisplayString(_ctx.headerText), 3))
      : _createCommentVNode("", true),
    _withDirectives(_createElementVNode("textarea", {
      class: _normalizeClass([_ctx.styleForWrong ? 'wrong' : '', 'ui_textarea']),
      readonly: _ctx.readOnly,
      placeholder: _ctx.placeholder,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.innerValue) = $event))
    }, null, 10, _hoisted_2), [
      [_vModelText, _ctx.innerValue]
    ])
  ]))
}