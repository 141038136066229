import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, vModelText as _vModelText, withKeys as _withKeys, withDirectives as _withDirectives, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-cca059d4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 1 }
const _hoisted_2 = {
  key: 0,
  class: "multiselect d-flex"
}
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "flex-grow-1" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "current_text_wrapper" }
const _hoisted_7 = { class: "current_text" }
const _hoisted_8 = { key: 2 }
const _hoisted_9 = {
  ref: "searchEl",
  style: {"border":"1px solid red"},
  class: "current"
}
const _hoisted_10 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_scroll = _resolveComponent("ui-scroll")!

  return (_openBlock(), _createElementBlock("div", {
    tabindex: "0",
    onFocusin: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.onGetFocus && _ctx.onGetFocus(...args))),
    ref: "root",
    class: "ui-select",
    onBlur: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.onBlur && _ctx.onBlur(...args)))
  }, [
    (_ctx.headerText.length != 0)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["ui_input_header", [_ctx.isWrong ? 'wrong_text' : '']])
        }, _toDisplayString(_ctx.headerText), 3))
      : _createCommentVNode("", true),
    (!_ctx.open || !_ctx.autocomplete)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _renderSlot(_ctx.$slots, "current", {
            key: _ctx.innerValue,
            value: _ctx.active
          }, () => [
            (_ctx.multiselect)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  (_ctx.active.length == 0)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.selectText), 1))
                    : _createCommentVNode("", true),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.active, (item) => {
                    return (_openBlock(), _createElementBlock("div", {
                      class: "multiselect-item d-flex mr-3",
                      key: _ctx.getKey(item)
                    }, [
                      _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.getValue(item)), 1),
                      _createElementVNode("div", {
                        class: "remove",
                        onClick: ($event: any) => (_ctx.select(item))
                      }, "X", 8, _hoisted_5)
                    ]))
                  }), 128))
                ]))
              : (!_ctx.multiselect && _ctx.isSearch)
                ? _withDirectives((_openBlock(), _createElementBlock("input", {
                    key: 1,
                    class: _normalizeClass(["current", [_ctx.isWrong ? 'wrong' : '', { active: _ctx.open }]]),
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
                    onKeypress: _cache[1] || (_cache[1] = _withKeys(
//@ts-ignore
(...args) => (_ctx.onEnter && _ctx.onEnter(...args)), ["enter"])),
                    ref: "currentSearch"
                  }, null, 34)), [
                    [_vModelText, _ctx.search]
                  ])
                : (_openBlock(), _createElementBlock("div", {
                    key: 2,
                    class: _normalizeClass(["current", [_ctx.isWrong ? 'wrong' : '', { active: _ctx.open }]]),
                    onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.currentClick && _ctx.currentClick(...args))),
                    onKeypress: _cache[3] || (_cache[3] = _withKeys(
//@ts-ignore
(...args) => (_ctx.onEnter && _ctx.onEnter(...args)), ["enter"])),
                    onBlur: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.onBlur && _ctx.onBlur(...args))),
                    ref: "currentSearch",
                    tabindex: "111"
                  }, [
                    _createElementVNode("div", _hoisted_6, [
                      _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.innerValue != null ? _ctx.activeText : _ctx.placeholder), 1)
                    ])
                  ], 34))
          ], true)
        ]))
      : _createCommentVNode("", true),
    (_ctx.autocomplete && _ctx.open)
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
          _createElementVNode("input", _hoisted_9, null, 512)
        ]))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "container", { data: _ctx.tempItems }, () => [
      _createElementVNode("div", {
        class: _normalizeClass(["container", { active: _ctx.open }]),
        style: _normalizeStyle(_ctx.containerPosition),
        onFocusout: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.loseFocus && _ctx.loseFocus(...args)))
      }, [
        _createVNode(_component_ui_scroll, { class: "ui_select_scroll" }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tempItems, (item, index) => {
              return (_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(["item", { active: _ctx.isSelect(item) }]),
                key: index,
                onClick: ($event: any) => (_ctx.select(item))
              }, [
                (item === undefined && !_ctx.noUndefined)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      _createTextVNode(_toDisplayString(_ctx.defaultText), 1)
                    ], 64))
                  : _renderSlot(_ctx.$slots, "item", {
                      key: 1,
                      item: item,
                      index: index,
                      active: _ctx.isSelect(item)
                    }, () => [
                      _createTextVNode(_toDisplayString(_ctx.getValue(item)), 1)
                    ], true)
              ], 10, _hoisted_10))
            }), 128))
          ]),
          _: 3
        })
      ], 38)
    ], true)
  ], 544))
}