import { WebApiService } from '../../../../../plugins/webApiService'
import { Events } from '../../../../../plugins/events'
import { SendRequestModel } from '../../../../../plugins/models/SendRequestModel'
import LegalBeneficiarySaveDataRequest from '../../../../../identification-services/models/legal-beneficiary/LegalBeneficiarySaveDataRequest';
import WsResponseModelT from '../../../../../schema-builder/server/models/WsResponseModelT';
import LegalBeneficiaryGetDataResponse from '../../../../../identification-services/models/legal-beneficiary/LegalBeneficiaryGetDataResponse';

export default class LegalBeneficiaryWsController {
	private url?: string;
	private cookieName: string;
	private appWebSettingsPath?: string;
	private apiUrlProperty?: string;

	GetBeneficiaryDataEvent = new Events<WsResponseModelT<LegalBeneficiaryGetDataResponse>>();
	SaveBeneficiaryDataEvent = new Events<WsResponseModelT<Boolean>>();

	subscribeEvents = async () => {
		const self = this;
		const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
		webApiService.on('LegalBeneficiaryWsController', 'GetBeneficiaryData', 'Main', (res: WsResponseModelT<LegalBeneficiaryGetDataResponse>) => {
			self.GetBeneficiaryDataEvent.trigger(res);
		});
		webApiService.on('LegalBeneficiaryWsController', 'SaveBeneficiaryData', 'Main', (res: WsResponseModelT<Boolean>) => {
			self.SaveBeneficiaryDataEvent.trigger(res);
		});
	}

	constructor(url?: string, cookieName?: string, appWebSettingsPath?: string, apiUrlProperty?: string) {
		this.url = url;
		this.cookieName = cookieName ?? "Auth-Token";
		this.appWebSettingsPath = appWebSettingsPath && [location.host, appWebSettingsPath].join("/");
		this.apiUrlProperty = apiUrlProperty;
		this.subscribeEvents();
	}

	async GetBeneficiaryData() {
		const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
		const sendRequest = new SendRequestModel({
			ProjectName: 'Main',
			Controller: 'LegalBeneficiaryWsController',
			Method: 'GetBeneficiaryData',
		})

		webApiService.send(sendRequest);
	}

	async SaveBeneficiaryData(request: LegalBeneficiarySaveDataRequest) {
		const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
		const sendRequest = new SendRequestModel({
			ProjectName: 'Main',
			Controller: 'LegalBeneficiaryWsController',
			Method: 'SaveBeneficiaryData',
			Value: typeof(request) === 'object' ? JSON.stringify(request) : request,
		})

		webApiService.send(sendRequest);
	}

	 // async methods
	async getBeneficiaryDataAsync(): Promise<LegalBeneficiaryGetDataResponse> {
		return new Promise<LegalBeneficiaryGetDataResponse>(async (resolve, reject) => {
			const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
			const sendRequest = new SendRequestModel({
				ProjectName: 'Main',
				Controller: 'LegalBeneficiaryWsController',
				Method: 'GetBeneficiaryData',
				Callback: (response: WsResponseModelT<LegalBeneficiaryGetDataResponse>) => {
					const isGeneric = typeof response.IsSuccess == 'boolean'
					const result = response.IsSuccess ? response.Value : response

					if (!isGeneric || response.IsSuccess) resolve(result as LegalBeneficiaryGetDataResponse)
					else reject(result)
				}
			})

			webApiService.send(sendRequest);
		});
	}

	async saveBeneficiaryDataAsync(request: LegalBeneficiarySaveDataRequest): Promise<Boolean> {
		return new Promise<Boolean>(async (resolve, reject) => {
			const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
			const sendRequest = new SendRequestModel({
				ProjectName: 'Main',
				Controller: 'LegalBeneficiaryWsController',
				Method: 'SaveBeneficiaryData',
				Value: typeof(request) === 'object' ? JSON.stringify(request) : request,
				Callback: (response: WsResponseModelT<Boolean>) => {
					const isGeneric = typeof response.IsSuccess == 'boolean'
					const result = response.IsSuccess ? response.Value : response

					if (!isGeneric || response.IsSuccess) resolve(result as Boolean)
					else reject(result)
				}
			})

			webApiService.send(sendRequest);
		});
	}

}