import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, renderSlot as _renderSlot, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "openimage" }
const _hoisted_2 = { class: "modal-windows" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-auto mx-auto" }
const _hoisted_5 = {
  style: {"justify-content":"flex-end","display":"flex"},
  class: "mt-3 mb-3 ml-3"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_crop_image_preview = _resolveComponent("crop-image-preview")!
  const _component_ui_file_input = _resolveComponent("ui-file-input")!
  const _component_ui_crop_image = _resolveComponent("ui-crop-image")!
  const _component_ui_button = _resolveComponent("ui-button")!
  const _component_ui_modal_window = _resolveComponent("ui-modal-window")!

  return (_openBlock(), _createElementBlock("div", {
    class: "ui-image-preview",
    onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.clicked && _ctx.clicked(...args)))
  }, [
    (!_ctx.oneImage && _ctx.showImage)
      ? (_openBlock(), _createBlock(_component_crop_image_preview, {
          key: 0,
          id: "crop_preview",
          ref: "croppreview",
          "el-height": _ctx.elHight,
          "crop-params": _ctx.cropResult,
          "aspect-ratio": _ctx.aspectRatio,
          blockStyle: _ctx.cropBlockStyle,
          imgStyle: _ctx.imgStyle
        }, null, 8, ["el-height", "crop-params", "aspect-ratio", "blockStyle", "imgStyle"]))
      : _createCommentVNode("", true),
    (_ctx.showError)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass(["error-msg", { hidden: !_ctx.showError || !_ctx.errorMessage }])
        }, _toDisplayString(_ctx.errorMessage), 3))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_ui_file_input, {
        ref: "fileInput",
        "show-name": false,
        placeholder: 'Загрузить',
        onOnChange: _ctx.onLoadFile,
        class: "file-input"
      }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "fileInputSlot")
        ]),
        _: 3
      }, 8, ["onOnChange"])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_ui_modal_window, {
        title: "Форматирование изображения",
        visible: !!_ctx.cropImageUrl,
        width: "600px",
        onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.cropImageUrl = null))
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_ui_crop_image, {
                ref: "crop",
                "image-src": _ctx.cropImageUrl,
                "max-height": 400,
                "max-width": 400,
                "el-height": _ctx.elHight,
                "aspect-ratio": _ctx.aspectRatio,
                onUpdate: _ctx.onCropUpdate
              }, null, 8, ["image-src", "el-height", "aspect-ratio", "onUpdate"])
            ])
          ])
        ]),
        button: _withCtx(() => [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_ui_button, {
              active: "",
              width: 100,
              onOnClick: _ctx.saveCrop,
              class: "mr-3"
            }, {
              default: _withCtx(() => [
                _createTextVNode("Сохранить")
              ]),
              _: 1
            }, 8, ["onOnClick"]),
            _createVNode(_component_ui_button, {
              width: 100,
              onOnClick: _ctx.clearFile
            }, {
              default: _withCtx(() => [
                _createTextVNode("Отменить")
              ]),
              _: 1
            }, 8, ["onOnClick"])
          ])
        ]),
        _: 1
      }, 8, ["visible"])
    ])
  ]))
}