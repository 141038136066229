import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "docs-adder"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_select = _resolveComponent("ui-select")!
  const _component_ui_company_document_adding_btn = _resolveComponent("ui-company-document-adding-btn")!

  return (_ctx.documentTypes.length != 0)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_ui_select, {
          class: "indentification-input-select",
          items: _ctx.allowedDocumentTypes,
          keyField: "type",
          valueField: "text",
          modelValue: _ctx.chooseFileType,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.chooseFileType) = $event)),
          noPlace: true,
          ref: "uiSelectDocs",
          defaultText: _ctx.$localization.getTranslate('Header.DocumentType'),
          noUndefined: true
        }, null, 8, ["items", "modelValue", "defaultText"]),
        _createVNode(_component_ui_company_document_adding_btn, {
          buttonColor: _ctx.buttonColor,
          buttonText: _ctx.buttonText,
          isDisabled: _ctx.isDisabled,
          isWrong: _ctx.isWrong,
          onAddedFile: _ctx.addedFile,
          class: "document_add_button"
        }, null, 8, ["buttonColor", "buttonText", "isDisabled", "isWrong", "onAddedFile"])
      ]))
    : _createCommentVNode("", true)
}