
import { Options, Vue ,Prop} from "vue-property-decorator";
import moment from "moment";
@Options<UiMonths>({
  props: {
    minimumDate: Date,
    months: [],
  },
})
export default class UiMonths extends Vue {
  @Prop({ default: new Date(), type: Date })         date: Date;
  @Prop({ default: null, type: Date })        minimumDate: Date;
  readonly months: string[] = [
    "янв",
    "фев",
    "мар",
    "апр",
    "май",
    "июн",
    "июл",
    "авг",
    "сен",
    "окт",
    "ноя",
    "дек",
  ];

  innerMonths: any[] = [];
 innerMonth:number= null;
 innerYear:number= null;
 innerDay:number = null;
  created(){
     this.innerMonth = this.date.getMonth();
  this.innerYear = this.date.getFullYear();
  this.innerDay = this.date.getDate();
  }
  mounted() {
    this.setMonths();
    this.setMonth(this.date);
  }

  setMonths() {
    var self = this;
    this.innerMonths = this.months.map(function (item, index) {
      return {
        number: index,
        name: item,
        type: !self.checkDateActivity(index)
          ? "no-active"
          : self.innerMonth == index
          ? "current"
          : "",
      };
    });
  }
  setMonth(date: Date) {
    this.innerYear = date.getFullYear();
  }
  next() {
    this.setMonth(new Date(this.innerYear + 1, this.innerMonth, 1));
  }
  backward() {
    this.setMonth(new Date(this.innerYear - 1, this.innerMonth, 1));
  }
  changeMonth(month: any) {
    let mon = parseInt(moment(this.date).format("MM")) - 1;
    let day = parseInt(moment(this.date).format("DD"));
    let hour = parseInt(moment(this.date).format("HH"));
    let min = parseInt(moment(this.date).format("mm"));
    let sek = parseInt(moment(this.date).format("ss"));

    if (month.type != "no-active") {
      this.$emit(
        "changeMonth",
        new Date(this.innerYear, month.number, day, hour, min, sek)
      );
      this.innerMonth = month.number;
      this.setMonths();
    }
  }
  changeYear() {
    this.$emit("onNavigation");
  }
  /**
   * Проверить активность даты
   */
  checkDateActivity(month: any) {
    if (!this.minimumDate) return true;
    return (
      new Date(this.innerYear, month) >
      new Date(this.minimumDate.getFullYear(), this.minimumDate.getMonth(), 0)
    );
  }
}
