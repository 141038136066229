

export default class CheckTransferPinT<T>  {
	Pin!: string;
	PaymentData!: T;
	constructor(obj?: Partial<CheckTransferPinT<T>>) {
		if (obj) {
			Object.assign(this, obj);
		}
	}
}
