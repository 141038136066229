
import EnumApiErrors from "@models/enums/EnumApiError";
import { Vue, Options, Prop, Watch } from "vue-property-decorator";
import { AdapterApiService } from "../../_adapter";
import { useRoute } from 'vue-router';

@Options({
	components: {
		//"continue-button": ContinueButton,
		//"modal-camera": ModalCamera,
	},
	emits: ["check", "end", "changeTab"],
})
export default class LivenessTab extends Vue {
	isLoading: boolean = false;
	errorMessage: string;
	isPassed: boolean = false;
	IsStarted: boolean = false;
	adapter = AdapterApiService.getInstance();
	isDisabled=true
	created() {
		const route = useRoute()
		const router = useRoute()
		
		if (route.query.errorStart) {
			this.errorMessage = this.$route.query.errorStart.toString();
		}

		if (route.query.error) {
			this.errorMessage = this.$route.query.error.toString();
		}

		if (router.query.success) {
			this.livenessCheck();
			this.isPassed = true;
		}
	}
	async livenessStart() {
		let returnUrl = location.href.split("?")[0] + "?success=True";
		this.isLoading = true;
		const res = await this.adapter.livenessStartAsync(returnUrl);
		this.isLoading = false;
		if (!res.IsSuccess) {
			this.errorMessage = res.ErrorMessage;
			return;
		}

		location.href = res.Value as string;
	}

	async livenessCheck() {
		this.errorMessage = "";
		this.isLoading = true;
		const res = await this.adapter.isLivenessInit();
		this.isPassed = res.Value.IsFinished;
		this.IsStarted = res.Value.IsStarted;
		this.isLoading = false;
	}

	catchError(err) {
		if (err.ErrorCode && EnumApiErrors[err.ErrorCode]) {
			this.errorMessage = this.$localization.getTranslate(
				`EnumApiError.${EnumApiErrors[err.ErrorCode]}`
			);
		} else if (err.Status && EnumApiErrors[err.Status])
			this.errorMessage = this.$localization.getTranslate(
				`EnumApiError.${EnumApiErrors[err.Status]}`
			);
		else {
			this.errorMessage = err.Message;
		}
		this.$emit("check", 4, false);
		this.isLoading = false;
	}

	saveAndContinue() {
		if (!this.isPassed) {
			return;
		}
		this.$emit("check", 4, true);
		this.$emit("end");

		//  var currentUrl = location.href.split('?')[0];
		//  location.href = `/${this.$localization.currentLang}/LivenessStart?returnUrl=${currentUrl}`

		//  this.$emit('end');
	}
}
