
import { Vue, Options, Prop, Watch } from "vue-property-decorator";
@Options({
	name: "ui-toggler",
})
export default class UiToggler extends Vue {
	@Prop({ default: "" }) modelValue: string;

	innerValue: boolean = false;

	@Watch("modelValue")
	onUpdateModelValue(newValue: boolean) {
		this.innerValue = newValue;
	}

	onUpdateText() {
		this.$emit("update:modelValue", this.innerValue);
	}
}
