
import BeneficiaryType from "@/api/main/dataSource/api/aba/data/models/models/transactions/BeneficiaryType";
import ModelTransferInternal from "@/api/main/dataSource/api/i-public-service/aba-finance/models/ModelTransferInternal";
import CryptPayoutRequest from "@/api/main/dataSource/api/transaction-services/models/transaction-operations/CryptPayoutRequest";
import EnumBeneficiaryType from "@/api/main/models/Enums/EnumBeneficiaryType";
import ErrorModel from "@/views/pages/login/adapter/models/errorModel";
import { ref } from "vue";
import { Vue, Options, Prop, Watch } from "vue-property-decorator";
import NaturalPersonModel from "../../../../models/NaturalPersonModel";
import SepaTemplateModel from "../../../../models/TemplatesTrasnfer/SepaTemplateModel";
import AdapterTransferService from "../../adapter/AdapterTransferService";
@Options({
	name: "sepa",
})
export default class Sepa extends Vue {
	@Prop({ type: Object, default: null }) profile: NaturalPersonModel;
	@Prop({ type: Number, default: null }) Type: number;
	@Prop({ type: Object, default: null }) SelectedTemplate: SepaTemplateModel;

	Recipient: string = "";
	IBAN: string = "";
	Amount: string = "";
	PurposePayment: string = "";
	ErrorMessage: string = "";
	Name: string = "";
	BeneficiaryAddress: string = "";
	BeneficiaryCity: string = "";
	BeneficiaryCountryCode: string = "";
	BeneficiaryPostalCode: string = "";
	BeneficiaryState: string = "";
	BeneficiaryType: string = ""; //тип получателя 1 - персона 2-компания
	Commission: number = 0;

	itemsBeneficiaryType: Array<string> = ["Person", "Company"];

	IsConfirmCondition: boolean = false;
	IsLoad: boolean = false;
	IsSave: boolean = false;

	adapter: AdapterTransferService;
	sepa = new ModelTransferInternal({
		AccountNumber: "",
		Amount: 0,
		FullName: "",
		NameTemplate: "",
		PurposePayment: "",
		IsSave: false,
		BeneficiaryCountryCode: "",
		BeneficiaryAddress: "",
		BeneficiaryCity: "",
		BeneficiaryPostalCode: "",
		BeneficiaryState: "",
		BeneficiaryType: BeneficiaryType.Person,
	});

	get isOffline(): boolean {
		// return this.profile.PaymentsType === 1;
		return false;
	}
	mounted() {
		this.adapter = AdapterTransferService.getInstance();
		this.loadSettings()
	}
	landingUrl = ref('');
	async loadSettings() {
	try {
		// Запрашиваем файл appWebSettings.json из папки public
		const response = await fetch('/appWebSettings.json');

		// Проверяем, что запрос успешен
		if (!response.ok) {
			throw new Error('Error');
		}

		// Преобразуем ответ в JSON
		const settings = await response.json();
		this.landingUrl.value = settings.landingUrl;
		// Сохраняем нужные параметры в переменные
		
	} catch (error) {
		console.error('Error:', error);
	}
}
	/**
	 * перевод строки в EnumBeneficiaryType
	 * @param val
	 */
	BeneficiaryTypeToEnum(val: string): BeneficiaryType {
		let result = BeneficiaryType.Person;
		switch (val) {
			case "Company":
				result = BeneficiaryType.Company;
				break;
			case "Person":
				result = BeneficiaryType.Person;

				break;

			default:
				result = BeneficiaryType.Person;
				break;
		}
		return result;
	}

	@Watch("SelectedTemplate", { deep: true })
	onChangeTemplate() {
		if (!this.SelectedTemplate) return;
		this.IBAN = this.SelectedTemplate.CardNumber;
		this.Amount = this.SelectedTemplate.Amount;
		this.PurposePayment = this.SelectedTemplate.PurposePayment;
		this.Recipient = this.SelectedTemplate.Recipient;
		this.BeneficiaryAddress = this.SelectedTemplate.BeneficiaryAddress;
		this.selectBeneficiaryCountryCode(this.SelectedTemplate.BeneficiaryCountryCode);
		this.BeneficiaryCity = this.SelectedTemplate.BeneficiaryCity;
		this.BeneficiaryPostalCode = this.SelectedTemplate.BeneficiaryPostalCode;
		this.BeneficiaryState = this.SelectedTemplate.BeneficiaryState;
		this.BeneficiaryType =
			this.SelectedTemplate.BeneficiaryType === 1
				? "Person"
				: this.SelectedTemplate.BeneficiaryType === 2
				? "Company"
				: "";
	}

	selectBeneficiaryCountryCode(code: string) {
		this.BeneficiaryCountryCode = code;
	}

	async getCommission() {
		var validation = this.Validation();
		if (!validation) return;

		var formData = new FormData();
		formData.append("amount", this.$store.state.getAmountForSend(this.Amount));

		const res = await this.adapter.getCommissionForSepaPayment(Number(this.Amount));
		if (!(res instanceof ErrorModel)) {
			this.Commission = res.Value as number;
			this.InitTransfer();
		}
	}

	async InitTransfer() {
		if (this.IsLoad) return false;
		var request = new FormData();
		request.append("AccountNumber", this.IBAN);
		request.append("Amount", this.$store.state.getAmountForSend(this.Amount)),
			request.append("PurposePayment", this.PurposePayment);
		//@ts-ignore
		request.append("IsSave", this.IsSave);
		request.append("NameTemplate", this.Name);
		request.append("Recipient", this.Recipient);
		request.append("FullName", this.Recipient);
		request.append("Commission", this.Commission.toString());
		request.append("BeneficiaryCountryCode", this.BeneficiaryCountryCode);
		request.append("BeneficiaryAddress", this.BeneficiaryAddress);
		request.append("BeneficiaryCity", this.BeneficiaryCity);
		request.append("BeneficiaryPostalCode", this.BeneficiaryPostalCode);
		request.append("BeneficiaryState", this.BeneficiaryState);
		request.append("BeneficiaryType", this.BeneficiaryType);

		var validation = this.Validation();
		if (!validation) return;
		this.IsLoad = true;
		this.sepa.AccountNumber = this.IBAN;
		(this.sepa.Amount = Number(this.Amount)),
			(this.sepa.PurposePayment = this.PurposePayment);
		//@ts-ignore
		this.sepa.IsSave = this.IsSave;
		this.sepa.NameTemplate = this.Name;
		// this.sepa.Recipient = this.Recipient;
		this.sepa.FullName = this.Recipient;
		// this.sepa.Commission= this.Commission;
		this.sepa.BeneficiaryCountryCode = this.BeneficiaryCountryCode;
		this.sepa.BeneficiaryAddress = this.BeneficiaryAddress;
		this.sepa.BeneficiaryCity = this.BeneficiaryCity;
		this.sepa.BeneficiaryPostalCode = this.BeneficiaryPostalCode;
		this.sepa.BeneficiaryState = this.BeneficiaryState;
		this.sepa.BeneficiaryType = this.BeneficiaryTypeToEnum(this.BeneficiaryType);

		const res = await this.adapter.checkTransferSepa(this.sepa);
		this.IsLoad = false;
		if (res.IsSuccess) {
			this.ErrorMessage = "";
			this.$emit("GoTransferSepa", request);
		} else {
			this.ErrorMessage = (res as ErrorModel).ErrorMessage;
		}
	}
	onSubmit() {
		return false;
	}
	Validation() {
		this.ErrorMessage = "";
		if (!this.IsConfirmCondition) {
			this.ErrorMessage =
				"You need agree with the Terms and Conditions, Privacy Policy and User Items";
			return false;
		}
		var russian = /^[а-яА-Я]*$/;
		var country = this.IBAN.substring(0, 2);
		if (
			this.IBAN == "" ||
			this.Amount == "" ||
			this.PurposePayment == "" ||
			this.Recipient == "" ||
			(!this.isOffline &&
				(this.BeneficiaryAddress == "" ||
					this.BeneficiaryCountryCode == "" ||
					this.BeneficiaryCity == "" ||
					this.BeneficiaryPostalCode == "" ||
					this.BeneficiaryState == "" ||
					this.BeneficiaryType == "")) ||
			((this.Name == undefined || this.Name == "") && this.IsSave)
		) {
			this.ErrorMessage = "Fill in all the fields";
			return false;
		} else if (this.IBAN == this.profile.Balance.Number) {
			this.ErrorMessage = "Please enter a different account number";
			return false;
		} else if (
			!this.isOffline &&
			(/[0-9]/.test(country[0]) || /[0-9]/.test(country[1]))
		) {
			this.ErrorMessage =
				"Must contain the first two characters of the designation of the country";
			return false;
		} else if (
			russian.test(this.PurposePayment) ||
			russian.test(this.Recipient) ||
			(!this.isOffline &&
				(russian.test(this.BeneficiaryAddress) ||
					russian.test(this.BeneficiaryCountryCode) ||
					russian.test(this.BeneficiaryCity) ||
					russian.test(this.BeneficiaryPostalCode) ||
					russian.test(this.BeneficiaryState) ||
					russian.test(this.BeneficiaryType) ||
					russian.test(country)))
		) {
			this.ErrorMessage = "The text should consist only of English letters";
			return false;
		}
		return true;
	}
}
