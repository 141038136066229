import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["data-id"]
const _hoisted_2 = ["onClick"]
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "vue-notification-group",
    style: _normalizeStyle(_ctx.styles)
  }, [
    (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.componentName), {
      name: _ctx.animationName,
      onEnter: _ctx.enter,
      onLeave: _ctx.leave,
      onAfterLeave: _ctx.clean
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.active, (item) => {
          return (_openBlock(), _createElementBlock("div", {
            key: item.id,
            class: "vue-notification-wrapper",
            style: _normalizeStyle(_ctx.notifyWrapperStyle(item)),
            "data-id": item.id,
            onMouseenter: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.pauseTimeout && _ctx.pauseTimeout(...args))),
            onMouseleave: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.resumeTimeout && _ctx.resumeTimeout(...args)))
          }, [
            _renderSlot(_ctx.$slots, "body", {
              class: _normalizeClass([_ctx.classes, item.type]),
              item: item,
              close: () => _ctx.destroy(item)
            }, () => [
              _createElementVNode("div", {
                class: _normalizeClass(_ctx.notifyClass(item)),
                onClick: ($event: any) => (_ctx.destroyIfNecessary(item))
              }, [
                (item.title)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      class: "notification-title",
                      innerHTML: item.title
                    }, null, 8, _hoisted_3))
                  : _createCommentVNode("", true),
                _createElementVNode("div", {
                  class: "notification-content",
                  innerHTML: item.text
                }, null, 8, _hoisted_4)
              ], 10, _hoisted_2)
            ])
          ], 44, _hoisted_1))
        }), 128))
      ]),
      _: 3
    }, 40, ["name", "onEnter", "onLeave", "onAfterLeave"]))
  ], 4))
}