import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "date-selector"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_day_selector = _resolveComponent("ui-day-selector")!
  const _component_ui_month_selector = _resolveComponent("ui-month-selector")!
  const _component_ui_year_selector = _resolveComponent("ui-year-selector")!

  return (!_ctx.linkedDatePickerName)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.innerCurrentView == 'days')
          ? (_openBlock(), _createBlock(_component_ui_day_selector, {
              key: 0,
              onOnNavigation: _cache[0] || (_cache[0] = ($event: any) => (_ctx.NavigatorDatetime('months'))),
              onChangeDay: _cache[1] || (_cache[1] = (date) => _ctx.ChangeDatetimes(date, 'days', 'days')),
              onChangeCurrentView: _ctx.ChangeCurrentView,
              allowedToShowView: _ctx.allowedToShowView,
              date: _ctx.innerDate,
              maximumDate: _ctx.maximumDate,
              minimumDate: _ctx.minimumDate,
              showCurrentDay: _ctx.showCurrentDate
            }, null, 8, ["onChangeCurrentView", "allowedToShowView", "date", "maximumDate", "minimumDate", "showCurrentDay"]))
          : _createCommentVNode("", true),
        (_ctx.innerCurrentView == 'months')
          ? (_openBlock(), _createBlock(_component_ui_month_selector, {
              key: 1,
              onOnNavigation: _cache[2] || (_cache[2] = ($event: any) => (_ctx.NavigatorDatetime('years'))),
              onChangeMonth: _cache[3] || (_cache[3] = (date) => _ctx.ChangeDatetime(date, 'days', 'month')),
              date: _ctx.innerDate,
              maximumDate: _ctx.maximumDate,
              minimumDate: _ctx.minimumDate
            }, null, 8, ["date", "maximumDate", "minimumDate"]))
          : _createCommentVNode("", true),
        (_ctx.innerCurrentView == 'years')
          ? (_openBlock(), _createBlock(_component_ui_year_selector, {
              key: 2,
              onChangeYear: _cache[4] || (_cache[4] = (date) => _ctx.ChangeDatetime(date, 'months', 'years')),
              date: _ctx.innerDate,
              maximumDate: _ctx.maximumDate,
              minimumDate: _ctx.minimumDate
            }, null, 8, ["date", "maximumDate", "minimumDate"]))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}