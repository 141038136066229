
import { Vue, Prop, Watch } from "vue-property-decorator";
export default class UiInput extends Vue {
  @Prop({ default: "" }) headerText: string;
  @Prop({ default: false }) readOnly: boolean;
  @Prop({ default: false }) isWrong: boolean;
  @Prop({ default: "" }) modelValue: string;
  @Prop({ default: "" }) placeholder: string;

  innerValue: string = "";
  styleForWrong: boolean = false;

  @Watch("modelValue")
  onUpdateModelValue(newValue: string) {
    this.innerValue = newValue;
  }

  @Watch("innerValue")
  onUpdateInnerValue(newValue: string) {
    this.$emit("update:modelValue", newValue);
  }

  @Watch("isWrong")
  onUpdateIsWrong(newValue: boolean) {
    this.styleForWrong = newValue;
  }

  created() {
    this.onUpdateModelValue(this.modelValue);
    this.onUpdateIsWrong(this.isWrong);
  }
}
