
import { Options, Prop, Vue } from "vue-property-decorator";

@Options({
  name: "pagination",
})
export default class Pagination extends Vue {
  @Prop({
    type: Number,
    required: true,
    default: 1 /*, validator: function(value: number) {return value > 0;}*/,
  })
  currentPage!: number;
  @Prop({ type: Number }) totalCount!: number;
  @Prop({ type: Number, default: 10 }) pageSize!: number;
  get totalPageCount(): number {
    return this.totalCount;
  }
  pageClick(page: Number) {
    this.$emit("pageClick", page);
  }
}
