import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "hours" }
const _hoisted_2 = { class: "items" }
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.allowedToShowView('minutes'))
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "back-to-days",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.openDays && _ctx.openDays(...args)))
        }, " Выбрать дни "))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.innerHours, (hour, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          class: _normalizeClass(["hour", hour.type]),
          onClick: _withModifiers(($event: any) => (_ctx.changeHour(hour)), ["stop"])
        }, [
          _createElementVNode("div", null, _toDisplayString(hour.name), 1)
        ], 10, _hoisted_3))
      }), 128))
    ])
  ]))
}