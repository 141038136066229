import { WebApiService } from '../../../../../../plugins/webApiService'
import { Events } from '../../../../../../plugins/events'
import { SendRequestModel } from '../../../../../../plugins/models/SendRequestModel'
import AcquiringInitPayinRequest from '../../../../../../transaction-services/models/acquirings/AcquiringInitPayinRequest';
import GetTransactionsRequest from '../../../../../../i-public-service/aba-finance/models/transaction/GetTransactionsRequest';
import WsResponseModelT from '../../../../../../schema-builder/server/models/WsResponseModelT';
import AcquiringInitPayinResponse from '../../../../../../transaction-services/models/acquirings/AcquiringInitPayinResponse';
import GetTransactionsResponse from '../../../../../../i-public-service/aba-finance/models/transaction/GetTransactionsResponse';
import ModelTransaction from '../../../../../../i-public-service/aba-finance/models/ModelTransaction';
import AcquiringPayoutTemplateListResponse from '../../../../../../transaction-services/models/transfer-template-service/AcquiringPayoutTemplateListResponse';

export default class NaturalPaymentsWsController {
	private url?: string;
	private cookieName: string;
	private appWebSettingsPath?: string;
	private apiUrlProperty?: string;

	AcquiringPayinEvent = new Events<WsResponseModelT<AcquiringInitPayinResponse>>();
	GetHistoryEvent = new Events<WsResponseModelT<GetTransactionsResponse>>();
	UpdateCardTransactionEvent = new Events<WsResponseModelT<Array<ModelTransaction>>>();
	GetAcquiringTemplatesEvent = new Events<WsResponseModelT<AcquiringPayoutTemplateListResponse>>();

	subscribeEvents = async () => {
		const self = this;
		const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
		webApiService.on('NaturalPaymentsWsController', 'AcquiringPayin', 'Main', (res: WsResponseModelT<AcquiringInitPayinResponse>) => {
			self.AcquiringPayinEvent.trigger(res);
		});
		webApiService.on('NaturalPaymentsWsController', 'GetHistory', 'Main', (res: WsResponseModelT<GetTransactionsResponse>) => {
			self.GetHistoryEvent.trigger(res);
		});
		webApiService.on('NaturalPaymentsWsController', 'UpdateCardTransaction', 'Main', (res: WsResponseModelT<Array<ModelTransaction>>) => {
			self.UpdateCardTransactionEvent.trigger(res);
		});
		webApiService.on('NaturalPaymentsWsController', 'GetAcquiringTemplates', 'Main', (res: WsResponseModelT<AcquiringPayoutTemplateListResponse>) => {
			self.GetAcquiringTemplatesEvent.trigger(res);
		});
	}

	constructor(url?: string, cookieName?: string, appWebSettingsPath?: string, apiUrlProperty?: string) {
		this.url = url;
		this.cookieName = cookieName ?? "Auth-Token";
		this.appWebSettingsPath = appWebSettingsPath && [location.host, appWebSettingsPath].join("/");
		this.apiUrlProperty = apiUrlProperty;
		this.subscribeEvents();
	}

	async AcquiringPayin(request: AcquiringInitPayinRequest) {
		const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
		const sendRequest = new SendRequestModel({
			ProjectName: 'Main',
			Controller: 'NaturalPaymentsWsController',
			Method: 'AcquiringPayin',
			Value: typeof(request) === 'object' ? JSON.stringify(request) : request,
		})

		webApiService.send(sendRequest);
	}

	async GetHistory(request: GetTransactionsRequest) {
		const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
		const sendRequest = new SendRequestModel({
			ProjectName: 'Main',
			Controller: 'NaturalPaymentsWsController',
			Method: 'GetHistory',
			Value: typeof(request) === 'object' ? JSON.stringify(request) : request,
		})

		webApiService.send(sendRequest);
	}

	async UpdateCardTransaction(dateTime: Date) {
		const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
		const sendRequest = new SendRequestModel({
			ProjectName: 'Main',
			Controller: 'NaturalPaymentsWsController',
			Method: 'UpdateCardTransaction',
			Value: typeof(dateTime) === 'object' ? JSON.stringify(dateTime) : dateTime,
		})

		webApiService.send(sendRequest);
	}

	async GetAcquiringTemplates() {
		const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
		const sendRequest = new SendRequestModel({
			ProjectName: 'Main',
			Controller: 'NaturalPaymentsWsController',
			Method: 'GetAcquiringTemplates',
		})

		webApiService.send(sendRequest);
	}

	 // async methods
	async acquiringPayinAsync(request: AcquiringInitPayinRequest): Promise<AcquiringInitPayinResponse> {
		return new Promise<AcquiringInitPayinResponse>(async (resolve, reject) => {
			const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
			const sendRequest = new SendRequestModel({
				ProjectName: 'Main',
				Controller: 'NaturalPaymentsWsController',
				Method: 'AcquiringPayin',
				Value: typeof(request) === 'object' ? JSON.stringify(request) : request,
				Callback: (response: WsResponseModelT<AcquiringInitPayinResponse>) => {
					const isGeneric = typeof response.IsSuccess == 'boolean'
					const result = response.IsSuccess ? response.Value : response

					if (!isGeneric || response.IsSuccess) resolve(result as AcquiringInitPayinResponse)
					else reject(result)
				}
			})

			webApiService.send(sendRequest);
		});
	}

	async getHistoryAsync(request: GetTransactionsRequest): Promise<GetTransactionsResponse> {
		return new Promise<GetTransactionsResponse>(async (resolve, reject) => {
			const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
			const sendRequest = new SendRequestModel({
				ProjectName: 'Main',
				Controller: 'NaturalPaymentsWsController',
				Method: 'GetHistory',
				Value: typeof(request) === 'object' ? JSON.stringify(request) : request,
				Callback: (response: WsResponseModelT<GetTransactionsResponse>) => {
					const isGeneric = typeof response.IsSuccess == 'boolean'
					const result = response.IsSuccess ? response.Value : response

					if (!isGeneric || response.IsSuccess) resolve(result as GetTransactionsResponse)
					else reject(result)
				}
			})

			webApiService.send(sendRequest);
		});
	}

	async updateCardTransactionAsync(dateTime: Date): Promise<Array<ModelTransaction>> {
		return new Promise<Array<ModelTransaction>>(async (resolve, reject) => {
			const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
			const sendRequest = new SendRequestModel({
				ProjectName: 'Main',
				Controller: 'NaturalPaymentsWsController',
				Method: 'UpdateCardTransaction',
				Value: typeof(dateTime) === 'object' ? JSON.stringify(dateTime) : dateTime,
				Callback: (response: WsResponseModelT<Array<ModelTransaction>>) => {
					const isGeneric = typeof response.IsSuccess == 'boolean'
					const result = response.IsSuccess ? response.Value : response

					if (!isGeneric || response.IsSuccess) resolve(result as Array<ModelTransaction>)
					else reject(result)
				}
			})

			webApiService.send(sendRequest);
		});
	}

	async getAcquiringTemplatesAsync(): Promise<AcquiringPayoutTemplateListResponse> {
		return new Promise<AcquiringPayoutTemplateListResponse>(async (resolve, reject) => {
			const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
			const sendRequest = new SendRequestModel({
				ProjectName: 'Main',
				Controller: 'NaturalPaymentsWsController',
				Method: 'GetAcquiringTemplates',
				Callback: (response: WsResponseModelT<AcquiringPayoutTemplateListResponse>) => {
					const isGeneric = typeof response.IsSuccess == 'boolean'
					const result = response.IsSuccess ? response.Value : response

					if (!isGeneric || response.IsSuccess) resolve(result as AcquiringPayoutTemplateListResponse)
					else reject(result)
				}
			})

			webApiService.send(sendRequest);
		});
	}

}