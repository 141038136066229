import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { authGuard } from './authGuard';  // Импортируем authGuard
import DocumentTitleMiddleware from "@/middlewares/documentTitleMiddleware";
import * as Routes from "./routerNames";
import BaseTemplate from "@views/layouts/base-layout.vue";

import LoginPage from "@/views/pages/login/login-page.vue";
import SignupPage from "@/views/pages/login/sign-up-page.vue";
import ForgotPasswordPage from '@/views/pages/login/forgot-password-page.vue';
import TestPage from '@/views/pages/test/test-page.vue';
import { TransfersPage, BalancePage, TrunsactionsPage, ProfileMessagePage, SupportPage } from '@/views/pages';


// import NaturalpersonSupportIndex from '@/views/pages/naturalperson-support-index/support.vue';
import NaturalpersonProfile from '@/views/pages/profile/profile-natural-page.vue';
// import ProfileMessage from '@/views/pages/naturalperson-profile-message/naturalperson-profile-message.vue';
import UserIdentificationSimple from '@/views/pages/user-identification/user-identification-simple.vue';
import Layout from '@/views/layouts/layout.vue';
import NaturalPersonProfileIdentification from '@/views/pages/profile-identification/profile-identification-natural.vue';
import UserType from '@/api/main/models/Enums/UserType';
import EnumTypeIdentified from '@/api/main/models/Enums/EnumTypeIdentified';
import UserIdentificationLegal from '@/views/pages/user-identification/user-identification-legal.vue';
import ProfileIdentificationLegal from "@/views/pages/profile-identification/profile-identification-legal.vue"

// Определяем маршруты
const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    component: Layout,
    meta: {
      title: "",
      middleware: [
        new DocumentTitleMiddleware()
      ]
    },
    children:
      [
        // {
        //   path: "legal",
        //   component: BaseTemplate,
        //   redirect: { name: Routes.USERIDENTIFICATION }, // Добавляем редирект
        //   children: [

        //     {
        //       path: "identification",
        //       component: BaseTemplate,
        //       children: [
        //         {
        //           name: Routes.USERIDENTIFICATION,
        //           path: "",
        //           component: UserIdentificationSimple,
        //           meta: {
        //             title: "Идентификация юр. лица",
        //             requiresAuth: true
        //           },
        //         },
        //       ]

        //     },
        //   ]
        // },
        {
          path: "",
          component: BaseTemplate,
          redirect: { name: Routes.BALANCE }, // Добавляем редирект
          children: [
            {
              name: Routes.BALANCE,
              path: "balance",
              component: BalancePage,
              meta: {
                title: "Баланс",
                requiresAuth: true,
                specialAccess: { // Устанавливаем специальные условия
                  natural: [EnumTypeIdentified.NotIdentified, EnumTypeIdentified.Confirmed, EnumTypeIdentified.Consideration],
                  legal: [EnumTypeIdentified.Confirmed],
                }
              },
            },
            {
              name: Routes.TRANSFERS,
              path: "transfers",
              component: TransfersPage,
              meta: {
                title: "transfers",
                requiresAuth: true,
                specialAccess: { // Устанавливаем специальные условия
                  natural: [EnumTypeIdentified.NotIdentified, EnumTypeIdentified.Confirmed, EnumTypeIdentified.Consideration],
                  legal: [EnumTypeIdentified.Confirmed],
                }
              },
            },
            {
              path: 'Payments/Transfer',
              redirect: 'transfer'  // Путь, на который будет осуществляться перенаправление
            },
            {
              name: Routes.TRANSUCTIONS,
              path: "transaction",
              component: TrunsactionsPage,
              meta: {
                title: "Transactions",
                requiresAuth: true,
                specialAccess: { // Устанавливаем специальные условия
                  natural: [EnumTypeIdentified.NotIdentified, EnumTypeIdentified.Confirmed, EnumTypeIdentified.Consideration],
                  legal: [EnumTypeIdentified.Confirmed],
                }
              },
            },
            {
              name: Routes.SUPPORT,
              path: "support",
              component: SupportPage,
              meta: {
                title: "Поддержка",
                requiresAuth: true,
                specialAccess: { // Устанавливаем специальные условия
                  natural: [EnumTypeIdentified.NotIdentified, EnumTypeIdentified.Confirmed, EnumTypeIdentified.Consideration],
                  legal: [EnumTypeIdentified.Confirmed],
                }
              },
            },
            {
              name: Routes.PROFILE_NATURAL,
              path: "profile",
              component: NaturalpersonProfile,
              meta: {
                title: "Профиль",
                requiresAuth: true,
                specialAccess: { // Устанавливаем специальные условия
                  natural: [EnumTypeIdentified.NotIdentified, EnumTypeIdentified.Confirmed, EnumTypeIdentified.Consideration],
                  legal: [EnumTypeIdentified.Confirmed],
                }
              },
            },
            {
              name: Routes.MESSAGE,
              path: "message",
              component: ProfileMessagePage,
              meta: {
                title: "Сообщения",
                requiresAuth: true,
                specialAccess: { // Устанавливаем специальные условия
                  natural: [EnumTypeIdentified.NotIdentified, EnumTypeIdentified.Confirmed, EnumTypeIdentified.Consideration],
                  legal: [EnumTypeIdentified.Confirmed],
                }
              },
            },
            {
              name: Routes.PROFILE_IDENTIFICATION,
              path: "identification",
              component: NaturalPersonProfileIdentification,
              meta: {
                title: "Идентификация полная физ лица",
                requiresAuth: true,
                specialAccess: { // Устанавливаем специальные условия
                  natural: [EnumTypeIdentified.NotIdentified, EnumTypeIdentified.Consideration],
                  legal: [],
                }
              },
            },
            {
              name: Routes.USERIDENTIFICATIONSIMPLE,
              path: "simple-identification",
              component: UserIdentificationSimple,
              meta: {
                title: "Идентификация простая",
                requiresAuth: true,
                specialAccess: { // Устанавливаем специальные условия
                  natural: [EnumTypeIdentified.NotIdentified],
                  legal: [],
                }
              },
            },
            // {
            //   path: "identification-legal",
            //   name: "identification-legal",
            //   component: UserIdentificationLegal,
            //   meta: {
            //     title: "Идентификация юр. лица",
            //     requiresAuth: true,
            //     specialAccess: { // Устанавливаем специальные условия
            //       natural: [],
            //       legal: [EnumTypeIdentified.NotIdentified, EnumTypeIdentified.Consideration],
            //     }
            //   },


            // },
            {
              path: "profile-identification-legal",
              name: "profile-identification-legal",
              component: ProfileIdentificationLegal,
              meta: {
                title: "Идентификация юр. лица",
                requiresAuth: true,
                specialAccess: { // Устанавливаем специальные условия
                  natural: [],
                  legal: [EnumTypeIdentified.NotIdentified, EnumTypeIdentified.Consideration],
                }
              },


            },

          ]
        }
      ]
  },
  {
    name: 'Login',
    path: '/login',
    component: LoginPage,
    meta: {
      title: "Идентификация юр. лица",
      requiresAuth: false  // Указывает, что для доступа к маршруту не требуется авторизация
    }

  },
  {
    name: 'Signup',
    path: '/signup',
    component: SignupPage,
    meta: {
      title: "Идентификация юр. лица",
      requiresAuth: false  // Указывает, что для доступа к маршруту не требуется авторизация
    }

  },
  {
    name: 'forgot-password',
    path: '/forgot-password',
    component: ForgotPasswordPage,
    meta: {
      requiresAuth: false  // Указывает, что для доступа к маршруту не требуется авторизация
    }

  },
  {
    name: 'Test',
    path: '/test',
    component: TestPage,
    meta: {
      requiresAuth: false
    }

  },

  { path: '/:pathMatch(.*)*', component: LoginPage },
  // { path: '/:pathMatch(.*)*', component: ErrorPageComponent },
];

// Создаем роутер
const router = createRouter({
  history: createWebHistory(),
  routes,
});

// Используем authGuard перед каждым маршрутом
router.beforeEach(authGuard);

export default router;
