
import moment from "moment";
import ClickOutside from "vue-click-outside";
import { Options, Vue, Prop, Watch } from "vue-property-decorator";
interface rangeData {
  firstData: Date;
  SecondData: Date;
}
@Options<UiDatetimePicker>({
  name: "datatime-picker",
  watch: {
    innerShowDatetime() {
      this.iconFocused = this.innerShowDatetime;
    },
  },
  emits: ["update:modelValue", "inputRage", "keyUp"],
  directives: {
    "click-outside": ClickOutside,
  },
})
export default class UiDatetimePicker extends Vue {
  @Prop({ default: "" }) modelValue: Date | string;
  @Prop({ default: "" }) headerText: string;
  @Prop({ type: Date, default: () => new Date() }) value: Date;
  @Prop({ default: "DD.MM.YYYY", type: String }) dateFormat: string;

  isDateRange = true;

  view2 = true;
  // показывать текущую дату
  // @Prop({ default: true, type: Boolean }) showCurrentDate: boolean;
  showCurrentDate = true;
  //показывать рамку только активации элемента
  @Prop({ default: false, type: Boolean }) transformer: boolean;
  @Prop({ default: null }) eventDates: Array<any>; //выделенный промежуток дат
  @Prop({ default: true, type: Boolean }) autoCloseWhenChangeDay: boolean; //закрывать после выбора дня месяца
  @Prop({ default: false }) isWrong: boolean;
  changeDateManual = true; //разрешить редактирование даты вручную

  innerDate: Date = new Date();
  innerDateFormatted: Date | string = "";
  innerSecondDate: Date = null;
  innerSecondDateFormatted: Date = null;
  innerShowDatetime = false;
  isFirstChoice = true;
  iconFocused = false;
  firstClick = true;
  wrong: boolean = false;
  isDay = false;

  @Watch("modelValue")
  onUpdateModelValue(newValue: Date | string) {
    if (newValue) {
      this.innerDate = new Date(newValue);
      this.oninnerDateFormatted();
    }
  }

  keyUp() {
    this.$emit("keyUp");
  }

  changeDayHours() {
    this.isDay = !this.isDay;
  }
  created() {
    if (this.modelValue) {
      this.changeDate(this.modelValue);
    }
    // else {
    //   this.changeDate(new Date());
    // }

    let mv = this.modelValue || new Date();
    let date;
    if (moment(mv).year() == 1) date = new Date();
    else date = moment(mv).toDate();
    // this.innerDateFormatted = this.formatDate(date);
    // this.innerDate = date;
    this.onBlur();
  }
  closeCalendar() {
    this.innerShowDatetime = false;
  }
  errorDate(data: boolean) {
    this.wrong = true;
    let self = this;
    setTimeout(function () {
      self.wrong = false;
    }, 2000);
  }
  oninnerDateFormatted() {
    this.innerDateFormatted = this.formatDate(this.innerDate);
    this.changeDate(this.innerDate);
  }
  //отпраыить изменения
  changeDate(date) {
    if (!date) {
      this.$emit("update:modelValue", null);
      return;
    }
    this.innerShowDatetime = false;
    this.innerDate = date;
    this.innerDateFormatted = this.formatDate(this.innerDate);
    let date1 = moment(date).set({ hour: 0, minute: 0, second: 0 }).format();
    // if (date.split("-")[0] < 1900) {
    //   date = date.split("-");
    //   date[0] = 1900 + "-";
    //   date[1] += "-";
    //   date = date.toString().replaceAll(",", "");
    // }
    let date2 = moment(date).utc().format()

    this.$emit("update:modelValue", date2);
  }
  // открыть календарь
  targetDatetime() {
    this.innerShowDatetime = !this.innerShowDatetime;
    this.iconFocused = this.innerShowDatetime;
    this.isDay = true;
  }
  // форматировать дату
  formatDate(date: Date) {
    var moment = require("moment");
    var now = moment(date);
    moment.locale("ru");
    let data: string = now.format("L");
    if (Number(now.format("L").split(".")[2]) < 1900) {
      let currentDate: Array<string> = now.format("L").split(".");
      currentDate[0] += ".";
      currentDate[1] += ".";
      currentDate[2] = "1900";
      data = currentDate.toString().replaceAll(",", "");
    }
    return data;
  }
  // для того чтобы при потере фокуса, нажатии на пробел и таб отправить дату
  onBlur() {
    if (moment(this.innerDateFormatted, "DD.MM.YYYY HH:mm").isValid()) {
      this.innerDate = moment(
        this.innerDateFormatted,
        this.dateFormat
      ).toDate();
    }
    if (this.innerDateFormatted.toString().length != 10) {
      this.innerDate = null;
    }
    this.oninnerDateFormatted();
  }
}
