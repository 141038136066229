import { App } from "vue";
import { Store } from "vuex";
import Vuex from 'vuex'
import { Events } from "./api/main/dataSource/api/plugins/events";
import CompanyDataViewModel from "./views/pages/user-identification/models/CompanyDataViewModel";
import BeneficiaryModel from "./views/pages/user-identification/models/BeneficiaryModel";
import BusinessProfileViewModel from "./views/pages/user-identification/models/BusinessProfileViewModel";
import DirectorItemModel from "./views/pages/user-identification/models/DirecorItemModel";
import GetNaturalPersonResponse from "./api/main/services/NaturalPersonHome/models/response/GetNaturalPersonResponse";
import { Vue } from "vue-class-component";

declare module '@vue/runtime-core' {
  export interface ComponentCustomProperties {
    $store: Store<StateModel>;
  }
}
export class StateModel {
  companyData: CompanyDataViewModel = null;
  companyDataEvent: Events<void> = new Events<void>();
  setCompanyData(value: CompanyDataViewModel) {
    this.companyData = value;
    this.companyDataEvent.trigger();
  }

  beneficiaryData: BeneficiaryModel = null;
  beneficiaryDataEvent: Events<void> = new Events<void>();
  setBeneficiaryData(value: BeneficiaryModel) {
    this.beneficiaryData = value;
    this.beneficiaryDataEvent.trigger();
  }

  businessProfileData: BusinessProfileViewModel = null;
  businessProfileDataEvent: Events<void> = new Events<void>();
  setBusinessProfileData(value: BusinessProfileViewModel) {
    this.businessProfileData = value;
    this.businessProfileDataEvent.trigger();
  }

  directorsData: Array<DirectorItemModel> = null;
  directorsDataEvent: Events<void> = new Events<void>();
  setDirectorsData(value: Array<DirectorItemModel>) {
    this.directorsData = value;
    this.directorsDataEvent.trigger();
  }

  modalDialogVisible: boolean = false;
  modalDialogMessage: string = "";
  modalDialogAlert(message: string) {
    this.modalDialogVisible = true;
    this.modalDialogMessage = message;
  }

  currentPersonProfile: GetNaturalPersonResponse = null;
  setCurrentPersonProfile(person: GetNaturalPersonResponse) {
    this.currentPersonProfile = person;
  }

  getAmountForSend(amount: string) {
    return (amount + "").replace(",", ".");
  }

  translateTypeTransaction(responseCode: number) {
    var response = "";
    switch (responseCode) {
      case 1:
        response = "TransferFromOneCardToAnother";
        break;
      case 2:
        response = "TemplateTranslation";
        break;
      case 3:
        response = "CardReplenishment";
        break;
      case 4:
        response = "TranslationBySEPA";
        break;
      case 5:
        response = "ReplenishmentOfInternalBalance";
        break;
      case 6:
        response = "TransferFromOneAccountToAnother";
        break;
      case 7:
        response = "Commission";
        break;
      case 8:
        response = "OrderCard";
        break;
      case 9:
        response = "CommissionSepaLegal";
        break;
      case 10:
        response = "IncomingSepaNatural";
        break;
      case 11:
        response = "IncomingSepaLegal";
        break;
    }
    return response;
  }
}

const install = (app: App) => {
  const store = new Vuex.Store({
    state: new StateModel(),
  })
  app.config.globalProperties.$store = store;
};

const store = {
  install,
};

export default store;
