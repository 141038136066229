import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "pagination" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.totalPageCount, (page) => {
      return (_openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["pages", { active: page == _ctx.currentPage }]),
        key: page,
        onClick: ($event: any) => (_ctx.pageClick(page))
      }, _toDisplayString(page), 11, _hoisted_2))
    }), 128))
  ]))
}