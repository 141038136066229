export const Countries = [
  { id: 2, value: "AX", text: "Åland Islands", code: "+358" },
  { id: 5, value: "AS", text: "American Samoa", code: "+1 684" },
  { id: 6, value: "AD", text: "Andorra", code: "+376" },
  { id: 7, value: "AO", text: "Angola", code: "+244" },
  { id: 8, value: "AI", text: "Anguilla", code: "+1 264" },
  { id: 9, value: "AQ", text: "Antarctica", code: "+672" },
  { id: 10, value: "AG", text: "Antigua and Barbuda", code: "+1 268" },
  { id: 11, value: "AR", text: "Argentina", code: "+54" },
  { id: 12, value: "AM", text: "Armenia", code: "+374" },
  { id: 13, value: "AW", text: "Aruba", code: "+297" },
  { id: 14, value: "AU", text: "Australia", code: "+61" },
  { id: 15, value: "AT", text: "Austria", code: "+43" },
  { id: 16, value: "AZ", text: "Azerbaijan", code: "+994" },
  { id: 17, value: "BS", text: "Bahamas", code: "+1 242" },
  { id: 18, value: "BH", text: "Bahrain", code: "+973" },
  { id: 19, value: "BD", text: "Bangladesh", code: "+880" },
  { id: 20, value: "BB", text: "Barbados", code: "+1 246" },
  { id: 21, value: "BY", text: "Belarus", code: "+375" },
  { id: 22, value: "BE", text: "Belgium", code: "+32" },
  { id: 23, value: "BZ", text: "Belize", code: "+501" },
  { id: 24, value: "BJ", text: "Benin", code: "+229" },
  { id: 25, value: "BM", text: "Bermuda", code: "+1 441" },
  { id: 26, value: "BT", text: "Bhutan", code: "+975" },
  { id: 27, value: "BO", text: "Bolivia, Plurinational State of", code: "+591" },
  { id: 28, value: "BQ", text: "Bonaire, Sint Eustatius and Saba", code: "+599" },
  { id: 29, value: "BA", text: "Bosnia and Herzegovina", code: "+387" },
  { id: 30, value: "BW", text: "Botswana", code: "+267" },
  { id: 31, value: "BV", text: "Bouvet Island", code: "+47" },
  { id: 32, value: "BR", text: "Brazil", code: "+55" },
  { id: 34, value: "BN", text: "Brunei Darussalam", code: "+673" },
  { id: 35, value: "BG", text: "Bulgaria", code: "+359" },
  { id: 36, value: "BF", text: "Burkina Faso", code: "+226" },
  { id: 37, value: "BI", text: "Burundi", code: "+257" },
  { id: 38, value: "KH", text: "Cambodia", code: "+855" },
  { id: 39, value: "CM", text: "Cameroon", code: "+237" },
  { id: 40, value: "CA", text: "Canada", code: "+1" },
  { id: 41, value: "CV", text: "Cape Verde", code: "+238" },
  { id: 42, value: "KY", text: "Cayman Islands", code: "+1 345" },
  { id: 44, value: "TD", text: "Chad", code: "+235" },
  { id: 45, value: "CL", text: "Chile", code: "+56" },
  { id: 47, value: "CX", text: "Christmas Island", code: "+61" },
  { id: 48, value: "CC", text: "Cocos (Keeling) Islands", code: "+61" },
  { id: 49, value: "CO", text: "Colombia", code: "+57" },
  { id: 50, value: "KM", text: "Comoros", code: "+269" },
  { id: 51, value: "CG", text: "Congo", code: "+242" },
  { id: 53, value: "CK", text: "Cook Islands", code: "+682" },
  { id: 54, value: "CR", text: "Costa Rica", code: "+506" },
  { id: 55, value: "CI", text: "Côte d'Ivoire", code: "+225" },
  { id: 56, value: "HR", text: "Croatia", code: "+385" },
  { id: 58, value: "CW", text: "Curaçao", code: "+599" },
  { id: 59, value: "CY", text: "Cyprus", code: "+357" },
  { id: 60, value: "CZ", text: "Czech Republic", code: "+420" },
  { id: 61, value: "DK", text: "Denmark", code: "+45" },
  { id: 62, value: "DJ", text: "Djibouti", code: "+253" },
  { id: 63, value: "DM", text: "Dominica", code: "+1 767" },
  { id: 64, value: "DO", text: "Dominican Republic", code: "+1 809" },
  { id: 66, value: "EG", text: "Egypt", code: "+20" },
  { id: 67, value: "SV", text: "El Salvador", code: "+503" },
  { id: 68, value: "GQ", text: "Equatorial Guinea", code: "+240" },
  { id: 69, value: "ER", text: "Eritrea", code: "+291" },
  { id: 70, value: "EE", text: "Estonia", code: "+372" },
  { id: 71, value: "ET", text: "Ethiopia", code: "+251" },
  { id: 72, value: "FK", text: "Falkland Islands (Malvinas)", code: "+500" },
  { id: 73, value: "FO", text: "Faroe Islands", code: "+298" },
  { id: 74, value: "FJ", text: "Fiji", code: "+679" },
  { id: 75, value: "FI", text: "Finland", code: "+358" },
  { id: 76, value: "FR", text: "France", code: "+33" },
  { id: 77, value: "GF", text: "French Guiana", code: "+594" },
  { id: 78, value: "PF", text: "French Polynesia", code: "+689" },
  { id: 79, value: "TF", text: "French Southern Territories", code: "+262" },
  { id: 80, value: "GA", text: "Gabon", code: "+241" },
  { id: 81, value: "GM", text: "Gambia", code: "+220" },
  { id: 82, value: "GE", text: "Georgia", code: "+995" },
  { id: 83, value: "DE", text: "Germany", code: "+49" },
  { id: 84, value: "GH", text: "Ghana", code: "+233" },
  { id: 85, value: "GI", text: "Gibraltar", code: "+350" },
  { id: 86, value: "GR", text: "Greece", code: "+30" },
  { id: 87, value: "GL", text: "Greenland", code: "+299" },
  { id: 88, value: "GD", text: "Grenada", code: "+1 473" },
  { id: 89, value: "GP", text: "Guadeloupe", code: "+590" },
  { id: 90, value: "GU", text: "Guam", code: "+1 671" },
  { id: 91, value: "GT", text: "Guatemala", code: "+502" },
  { id: 92, value: "GG", text: "Guernsey", code: "+44" },
  { id: 93, value: "GN", text: "Guinea", code: "+224" },
  { id: 94, value: "GW", text: "Guinea-Bissau", code: "+245" },
  { id: 96, value: "HT", text: "Haiti", code: "+509" },
  { id: 97, value: "HM", text: "Heard Island and McDonald Islands", code: "+672" },
  { id: 98, value: "VA", text: "Holy See (Vatican City State)", code: "+39" },
  { id: 99, value: "HN", text: "Honduras", code: "+504" },
  { id: 100, value: "HK", text: "Hong Kong", code: "+852" },
  { id: 101, value: "HU", text: "Hungary", code: "+36" },
  { id: 102, value: "IS", text: "Iceland", code: "+354" },
  { id: 104, value: "ID", text: "Indonesia", code: "+62" },
  { id: 105, value: "IR", text: "Iran, Islamic Republic of", code: "+98" },
  { id: 107, value: "IE", text: "Ireland", code: "+353" },
  { id: 108, value: "IM", text: "Isle of Man", code: "+44" },
  { id: 109, value: "IL", text: "Israel", code: "+972" },
  { id: 110, value: "IT", text: "Italy", code: "+39" },
  { id: 111, value: "JM", text: "Jamaica", code: "+1 876" },
  { id: 112, value: "JP", text: "Japan", code: "+81" },
  { id: 113, value: "JE", text: "Jersey", code: "+44" },
  { id: 114, value: "JO", text: "Jordan", code: "+962" },
  { id: 115, value: "KZ", text: "Kazakhstan", code: "+7" },
  { id: 116, value: "KE", text: "Kenya", code: "+254" },
  { id: 117, value: "KI", text: "Kiribati", code: "+686" },
  { id: 119, value: "KR", text: "Korea, Republic of", code: "+82" },
  { id: 120, value: "KW", text: "Kuwait", code: "+965" },
  { id: 121, value: "KG", text: "Kyrgyzstan", code: "+996" },
  { id: 122, value: "LA", text: "Lao People's Democratic Republic", code: "+856" },
  { id: 123, value: "LV", text: "Latvia", code: "+371" },
  { id: 124, value: "LB", text: "Lebanon", code: "+961" },
  { id: 125, value: "LS", text: "Lesotho", code: "+266" },
  { id: 126, value: "LR", text: "Liberia", code: "+231" },
  { id: 127, value: "LY", text: "Libya", code: "+218" },
  { id: 128, value: "LI", text: "Liechtenstein", code: "+423" },
  { id: 129, value: "LT", text: "Lithuania", code: "+370" },
  { id: 130, value: "LU", text: "Luxembourg", code: "+352" },
  { id: 131, value: "MO", text: "Macao", code: "+853" },
  { id: 132, value: "MK", text: "Macedonia, the former Yugoslav Republic of", code: "+389" },
  { id: 133, value: "MG", text: "Madagascar", code: "+261" },
  { id: 134, value: "MW", text: "Malawi", code: "+265" },
  { id: 135, value: "MY", text: "Malaysia", code: "+60" },
  { id: 136, value: "MV", text: "Maldives", code: "+960" },
  { id: 137, value: "ML", text: "Mali", code: "+223" },
  { id: 138, value: "MT", text: "Malta", code: "+356" },
  { id: 139, value: "MH", text: "Marshall Islands", code: "+692" },
  { id: 140, value: "MQ", text: "Martinique", code: "+596" },
  { id: 141, value: "MR", text: "Mauritania", code: "+222" },
  { id: 142, value: "MU", text: "Mauritius", code: "+230" },
  { id: 143, value: "YT", text: "Mayotte", code: "+262" },
  { id: 144, value: "MX", text: "Mexico", code: "+52" },
  { id: 145, value: "FM", text: "Micronesia, Federated States of", code: "+691" },
  { id: 146, value: "MD", text: "Moldova, Republic of", code: "+373" },
  { id: 147, value: "MC", text: "Monaco", code: "+377" },
  { id: 148, value: "MN", text: "Mongolia", code: "+976" },
  { id: 149, value: "ME", text: "Montenegro", code: "+382" },
  { id: 150, value: "MS", text: "Montserrat", code: "+1 664" },
  { id: 151, value: "MA", text: "Morocco", code: "+212" },
  { id: 152, value: "MZ", text: "Mozambique", code: "+258" },
  { id: 153, value: "MM", text: "Myanmar", code: "+95" },
  { id: 154, value: "NA", text: "Namibia", code: "+264" },
  { id: 155, value: "NR", text: "Nauru", code: "+674" },
  { id: 156, value: "NP", text: "Nepal", code: "+977" },
  { id: 157, value: "NL", text: "Netherlands", code: "+31" },
  { id: 158, value: "NC", text: "New Caledonia", code: "+687" },
  { id: 159, value: "NZ", text: "New Zealand", code: "+64" },
  { id: 160, value: "NI", text: "Nicaragua", code: "+505" },
  { id: 161, value: "NE", text: "Niger", code: "+227" },
  { id: 163, value: "NU", text: "Niue", code: "+683" },
  { id: 164, value: "NF", text: "Norfolk Island", code: "+672" },
  { id: 165, value: "MP", text: "Northern Mariana Islands", code: "+1 670" },
  { id: 166, value: "NO", text: "Norway", code: "+47" },
  { id: 167, value: "OM", text: "Oman", code: "+968" },
  { id: 168, value: "PK", text: "Pakistan", code: "+92" },
  { id: 169, value: "PW", text: "Palau", code: "+680" },
  { id: 170, value: "PS", text: "Palestinian Territory, Occupied", code: "+970" },
  { id: 173, value: "PY", text: "Paraguay", code: "+595" },
  { id: 174, value: "PE", text: "Peru", code: "+51" },
  { id: 175, value: "PH", text: "Philippines", code: "+63" },
  { id: 176, value: "PN", text: "Pitcairn", code: "+870" },
  { id: 177, value: "PL", text: "Poland", code: "+48" },
  { id: 178, value: "PT", text: "Portugal", code: "+351" },
  { id: 179, value: "PR", text: "Puerto Rico", code: "+1 787" },
  { id: 180, value: "QA", text: "Qatar", code: "+974" },
  { id: 181, value: "RE", text: "Réunion", code: "+262" },
  { id: 182, value: "RO", text: "Romania", code: "+40" },
  { id: 184, value: "RW", text: "Rwanda", code: "+250" },
  { id: 185, value: "BL", text: "Saint Barthélemy", code: "+590" },
  { id: 186, value: "SH", text: "Saint Helena, Ascension and Tristan da Cunha", code: "+290" },
  { id: 187, value: "KN", text: "Saint Kitts and Nevis", code: "+1 869" },
  { id: 188, value: "LC", text: "Saint Lucia", code: "+1 758" },
  { id: 189, value: "MF", text: "Saint Martin (French part)", code: "+590" },
  { id: 190, value: "PM", text: "Saint Pierre and Miquelon", code: "+508" },
  { id: 191, value: "VC", text: "Saint Vincent and the Grenadines", code: "+1 784" },
  { id: 192, value: "WS", text: "Samoa", code: "+685" },
  { id: 193, value: "SM", text: "San Marino", code: "+378" },
  { id: 194, value: "ST", text: "Sao Tome and Principe", code: "+239" },
  { id: 195, value: "SA", text: "Saudi Arabia", code: "+966" },
  { id: 196, value: "SN", text: "Senegal", code: "+221" },
  { id: 197, value: "RS", text: "Serbia", code: "+381" },
  { id: 198, value: "SC", text: "Seychelles", code: "+248" },
  { id: 199, value: "SL", text: "Sierra Leone", code: "+232" },
  { id: 200, value: "SG", text: "Singapore", code: "+65" },
  { id: 201, value: "SX", text: "Sint Maarten (Dutch part)", code: "+1 721" },
  { id: 202, value: "SK", text: "Slovakia", code: "+421" },
  { id: 203, value: "SI", text: "Slovenia", code: "+386" },
  { id: 204, value: "SB", text: "Solomon Islands", code: "+677" },
  { id: 205, value: "SO", text: "Somalia", code: "+252" },
  { id: 206, value: "ZA", text: "South Africa", code: "+27" },
  { id: 207, value: "GS", text: "South Georgia and the South Sandwich Islands", code: "+500" },
  { id: 209, value: "ES", text: "Spain", code: "+34" },
  { id: 210, value: "LK", text: "Sri Lanka", code: "+94" },
  { id: 211, value: "SD", text: "Sudan", code: "+249" },
  { id: 212, value: "SR", text: "Suriname", code: "+597" },
  { id: 213, value: "SJ", text: "Svalbard and Jan Mayen", code: "+47" },
  { id: 214, value: "SZ", text: "Swaziland", code: "+268" },
  { id: 215, value: "SE", text: "Sweden", code: "+46" },
  { id: 216, value: "CH", text: "Switzerland", code: "+41" },
  { id: 218, value: "TW", text: "Taiwan, Province of China", code: "+886" },
  { id: 219, value: "TJ", text: "Tajikistan", code: "+992" },
  { id: 220, value: "TZ", text: "Tanzania, United Republic of", code: "+255" },
  { id: 221, value: "TH", text: "Thailand", code: "+66" },
  { id: 222, value: "TL", text: "Timor-Leste", code: "+670" },
  { id: 223, value: "TG", text: "Togo", code: "+228" },
  { id: 224, value: "TK", text: "Tokelau", code: "+690" },
  { id: 225, value: "TO", text: "Tonga", code: "+676" },
  { id: 226, value: "TT", text: "Trinidad and Tobago", code: "+1 868" },
  { id: 227, value: "TN", text: "Tunisia", code: "+216" },
  { id: 228, value: "TR", text: "Turkey", code: "+90" },
  { id: 229, value: "TM", text: "Turkmenistan", code: "+993" },
  { id: 230, value: "TC", text: "Turks and Caicos Islands", code: "+1 649" },
  { id: 231, value: "TV", text: "Tuvalu", code: "+688" },
  { id: 233, value: "UA", text: "Ukraine", code: "+380" },
  { id: 234, value: "AE", text: "United Arab Emirates", code: "+971" },
  { id: 235, value: "GB", text: "United Kingdom", code: "+44" },
  { id: 236, value: "US", text: "United States", code: "+1" },
  { id: 237, value: "UM", text: "United States Minor Outlying Islands", code: "+1" },
  { id: 238, value: "UY", text: "Uruguay", code: "+598" },
  { id: 239, value: "UZ", text: "Uzbekistan", code: "+998" },
  { id: 240, value: "VU", text: "Vanuatu", code: "+678" },
  { id: 241, value: "VE", text: "Venezuela, Bolivarian Republic of", code: "+58" },
  { id: 242, value: "VN", text: "Viet Nam", code: "+84" },
  { id: 243, value: "VG", text: "Virgin Islands, British", code: "+1 284" },
  { id: 244, value: "VI", text: "Virgin Islands, U.S.", code: "+1 340" },
  { id: 245, value: "WF", text: "Wallis and Futuna", code: "+681" },
  { id: 246, value: "EH", text: "Western Sahara", code: "+212" },
  { id: 248, value: "ZM", text: "Zambia", code: "+260" }
];
