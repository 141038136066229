export default class SepaModel {
  AccountNumber: string = null;
  Amount: string = null;
  PurposePayment: string = null;
  Recipient: string = null;
  Commission: number = 0;
  BeneficiaryCountryCode: string = null;
  BeneficiaryAddress: string = null;
  BeneficiaryCity: string = null;
  BeneficiaryPostalCode: string = null;
  BeneficiaryState: string = null;

  constructor(obj?: Partial<SepaModel>) {
    if (obj) {
      Object.assign(this, obj)
    }
  }
}