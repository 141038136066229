
import { Options, Prop, Vue, Ref } from "vue-property-decorator";

@Options<UiTableInput>({
 emits: ["onFilterChanged"]
})
export default class UiTableInput extends Vue {
  search = "";
  onFilterChanged() {
    this.$emit("onFilterChanged", this.search);
  }
}
