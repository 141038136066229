
import { Options, Vue, Ref, Prop } from "vue-property-decorator";
import UiTableFilter from "@/views/components/ui-table/ui-table-filter.vue";
import IFilter from "@models/interfaces/IFilter";
import UiPagination from "@views/components/ui-table/ui-pagination.vue";

//onFilterChanged
interface IPaginationResponse {
  Items: Object[];
  Count: number;
}
@Options({
  name: "content-table",
  components: {
    UiTableFilter,
  },
})
export default class ContentTableComponent extends Vue {
  @Ref("pagination") pagination: UiPagination;

  @Prop({ type: Object, default: null, required: false }) filter: IFilter;
  @Prop({ type: Function, required: true }) getDataFunc: (
    filter?: Object
  ) => Promise<IPaginationResponse>;
  @Prop({ type: Function }) mapFunction: (fromObj: Object) => Object;
  @Prop({ type: Array, required: true }) columns: string[];
  @Prop({ type: String, required: true }) templateColumns: string;
  isLoading = false;
  items: Object[] = [];
  count: number = 0;
  currentPage: number = 1;
  pageSize = 10;
  async created() {
    await this.fetchData();
  }
  async onChangeFilter() {
    this.currentPage = 1;
    await this.fetchData();
    this.pagination.reset();
  }
  async fetchData() {
    console.log('fetchData');    
    const filter = this.filter || {};
   console.log(filter);

    // const filter = this.filter.toServerFilter();
    // после того как добавим метод приведем к toServerFilter
    this.isLoading = true;
    try {
      const pag = {
        Page: {
          Skip: this.pageSize * (this.currentPage - 1),
          Take: this.pageSize,
        },
      };
      Object.assign(filter, pag);
      this.getDataFunc(filter)
        .then((res) => {          
          console.log('result getData:', res);
          
          const items =
            this.mapFunction == null
              ? res.Items
              : (res.Items || []).map(this.mapFunction);
          this.items = items;

          this.count = (res as any).Page.Count;
        })
        .catch((res) => {
          if (!res.IsSuccess) {
            console.log(res.ErrorMessage);
            return;
          }
        });
    } catch (e) {
      throw e;
    } finally {
      this.isLoading = false;
      // this.pagination.reset();
    }
  }
  async onPaginationChanged(data: number) {
    this.currentPage = data;
    await this.fetchData();
  }
}
