
import { Vue, Prop, Watch } from "vue-property-decorator";
export default class UiInput extends Vue {
	@Prop({ default: "" }) headerText: string;
	@Prop({ default: false }) readOnly: boolean;
	@Prop({ default: "" }) placeholder: string;
	@Prop({ default: false }) isWrong: boolean;
	@Prop({ default: "" }) modelValue: string;
	@Prop({ type: String, default: "text" }) type!: string;
	@Prop({ default: null }) img!: string;
	@Prop({ default: false }) required: boolean;
	@Prop({ default: "Please fiil in this input" }) warningMsg: string;
	@Prop({ default: null }) iconName: string;
	@Prop({ default: null }) currency: string;

	styleForWrong: boolean = false;
	innerValue: string;

	@Watch("modelValue")
	onUpdateModelValue(newValue: string) {
		this.innerValue = newValue;
	}

	keyUp() {
		this.$emit("keyUp");
	}
	onInput(value: any) {
		// console.log(`value`, value.target.value);
		this.$emit("update:modelValue", value.target.value);
	}
	onUpdateText() {
		// console.log(`222`, 222);
		// this.$emit("update:modelValue", this.innerValue);
	}

	@Watch("isWrong")
	onUpdateIsWrong(newValue: boolean) {
		this.styleForWrong = newValue;
	}

	created() {
		this.innerValue = this.modelValue;
		this.onUpdateIsWrong(this.isWrong);
	}
}
