import { WebApiService } from '../../../../../../plugins/webApiService'
import { Events } from '../../../../../../plugins/events'
import { SendRequestModel } from '../../../../../../plugins/models/SendRequestModel'
import TransactionPersonRequest from '../../../../../../transaction-services/models/TransactionPersonRequest';
import GetTransactionsRequest from '../../../../../../i-public-service/aba-finance/models/transaction/GetTransactionsRequest';
import WsResponseModelT from '../../../../../../schema-builder/server/models/WsResponseModelT';
import MoneyTransactionPersonResponse from '../../../../../../transaction-services/models/MoneyTransactionPersonResponse';
import GetTransactionsResponse from '../../../../../../i-public-service/aba-finance/models/transaction/GetTransactionsResponse';

export default class LegalPaymentWsController {
	private url?: string;
	private cookieName: string;
	private appWebSettingsPath?: string;
	private apiUrlProperty?: string;

	GetHistoryTransactionNewEvent = new Events<WsResponseModelT<MoneyTransactionPersonResponse>>();
	GetInternalTransactionsEvent = new Events<WsResponseModelT<GetTransactionsResponse>>();

	subscribeEvents = async () => {
		const self = this;
		const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
		webApiService.on('LegalPaymentWsController', 'GetHistoryTransactionNew', 'Main', (res: WsResponseModelT<MoneyTransactionPersonResponse>) => {
			self.GetHistoryTransactionNewEvent.trigger(res);
		});
		webApiService.on('LegalPaymentWsController', 'GetInternalTransactions', 'Main', (res: WsResponseModelT<GetTransactionsResponse>) => {
			self.GetInternalTransactionsEvent.trigger(res);
		});
	}

	constructor(url?: string, cookieName?: string, appWebSettingsPath?: string, apiUrlProperty?: string) {
		this.url = url;
		this.cookieName = cookieName ?? "Auth-Token";
		this.appWebSettingsPath = appWebSettingsPath && [location.host, appWebSettingsPath].join("/");
		this.apiUrlProperty = apiUrlProperty;
		this.subscribeEvents();
	}

	async GetHistoryTransactionNew(model: TransactionPersonRequest) {
		const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
		const sendRequest = new SendRequestModel({
			ProjectName: 'Main',
			Controller: 'LegalPaymentWsController',
			Method: 'GetHistoryTransactionNew',
			Value: typeof(model) === 'object' ? JSON.stringify(model) : model,
		})

		webApiService.send(sendRequest);
	}

	async GetInternalTransactions(request: GetTransactionsRequest) {
		const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
		const sendRequest = new SendRequestModel({
			ProjectName: 'Main',
			Controller: 'LegalPaymentWsController',
			Method: 'GetInternalTransactions',
			Value: typeof(request) === 'object' ? JSON.stringify(request) : request,
		})

		webApiService.send(sendRequest);
	}

	 // async methods
	async getHistoryTransactionNewAsync(model: TransactionPersonRequest): Promise<MoneyTransactionPersonResponse> {
		return new Promise<MoneyTransactionPersonResponse>(async (resolve, reject) => {
			const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
			const sendRequest = new SendRequestModel({
				ProjectName: 'Main',
				Controller: 'LegalPaymentWsController',
				Method: 'GetHistoryTransactionNew',
				Value: typeof(model) === 'object' ? JSON.stringify(model) : model,
				Callback: (response: WsResponseModelT<MoneyTransactionPersonResponse>) => {
					const isGeneric = typeof response.IsSuccess == 'boolean'
					const result = response.IsSuccess ? response.Value : response

					if (!isGeneric || response.IsSuccess) resolve(result as MoneyTransactionPersonResponse)
					else reject(result)
				}
			})

			webApiService.send(sendRequest);
		});
	}

	async getInternalTransactionsAsync(request: GetTransactionsRequest): Promise<GetTransactionsResponse> {
		return new Promise<GetTransactionsResponse>(async (resolve, reject) => {
			const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
			const sendRequest = new SendRequestModel({
				ProjectName: 'Main',
				Controller: 'LegalPaymentWsController',
				Method: 'GetInternalTransactions',
				Value: typeof(request) === 'object' ? JSON.stringify(request) : request,
				Callback: (response: WsResponseModelT<GetTransactionsResponse>) => {
					const isGeneric = typeof response.IsSuccess == 'boolean'
					const result = response.IsSuccess ? response.Value : response

					if (!isGeneric || response.IsSuccess) resolve(result as GetTransactionsResponse)
					else reject(result)
				}
			})

			webApiService.send(sendRequest);
		});
	}

}