
import { Vue, Options, Prop, Provide, Watch } from "vue-property-decorator";
import TabsProvider, { TabInfo } from "./tabsProvider";
@Options({
	emits: ["select", "sendTabs"],
})
export default class UITabsComponent extends Vue {
	@Prop({ default: "" }) tabsTitle: string;
	@Prop({ default: true, type: Boolean }) gaphOn!: Boolean;
	@Prop({ default: null, type: Boolean }) right: Boolean;
	@Prop({ default: 15, type: Number }) titlePadding: Number;
	@Prop({ default: false }) fluid: boolean;
	@Prop({ default: new TabInfo() }) tabForChange: TabInfo;
	@Prop({ default: [] }) allowedTabIds: Array<number>;

	@Provide({ to: "tabsProvider" }) tabsProvider = new TabsProvider();

	innerAllowedTabIds: Array<number>;

	get tabs(): TabInfo[] {
		if (this.tabsProvider.tabs.length != 0) {
			this.$emit("sendTabs", this.tabsProvider.tabs);
		}
		return this.tabsProvider.tabs || [];
	}
	created() {
		this.innerAllowedTabIds = this.allowedTabIds;
		this.tabsProvider.allowedTabsIds = this.allowedTabIds;
		this.tabsProvider.refreshAllowedIds();
	}

	updateCheck(id: number) {
		console.log(
			"ui-tabs updateCheck",
			id,
			this.innerAllowedTabIds.find((x) => x == id) != null,
			this.innerAllowedTabIds
		);

		if (this.innerAllowedTabIds.find((x) => x == id) != null) {
			return true;
		} else {
			return false;
		}
	}

	selectTab(tab: TabInfo) {
		console.log(
			"ui-tabs selectTab",
			tab.id,
			tab.isAllowed,
			this.innerAllowedTabIds.find((x) => x == tab.id || tab.id == 0)
		);
		if (this.tabsProvider.trySelectTab(tab)) {
			this.$emit("select", tab);
		}
		// if (
		//   this.innerAllowedTabIds.find((x) => x == tab.id || tab.id == 0) != null
		// ) {
		//   if (this.tabsProvider.trySelectTab(tab)) {
		//     this.$emit("select", tab);
		//   }
		// }
	}
	@Watch("allowedTabIds", { deep: true })
	onUpdateAllowedIds(allowedTabIds: Array<number>) {
		console.log("onUpdateAllowedIds", allowedTabIds);

		this.innerAllowedTabIds = allowedTabIds;
		this.tabsProvider.allowedTabsIds = allowedTabIds;
		this.tabsProvider.refreshAllowedIds();
	}
	@Watch("tabForChange")
	onUpdateTabForChange(tab: TabInfo) {
		this.selectTab(tab);
	}
}
