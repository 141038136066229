import CryptPayoutRequest from '../../../transaction-services/models/transaction-operations/CryptPayoutRequest';

export default class ModelTransferCryptPayout  {
	Transfer!: CryptPayoutRequest;
	Pin!: string;
	constructor(obj?: Partial<ModelTransferCryptPayout>) {
		if (obj) {
			Object.assign(this, obj);
		}
	}
}
