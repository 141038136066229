
import { Vue, Options } from "vue-property-decorator";
@Options({
  name: "naturalperson-support-index",
})
export default class NaturalpersonSupportIndex extends Vue {
  Page: string = "main"; //main

  ChangePage(page: string) {
    this.Page = page;
  }
}
