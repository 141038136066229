export default class TabsProvider {
    tabs: TabInfo[] = [];
    activeTabHash = '';
    allowedTabsIds: Array<number> = []
    constructor(obj?: Partial<TabsProvider>) {
        if (obj != null) {
            Object.assign(this, obj);
        }
    }
    trySelectTab(tab: TabInfo): boolean {
        if (tab.isDisabled) {
            return false;
        }
        this.activeTabHash = tab.hash;
        return true;
    }
    addTab(tab: TabInfo) {
        if (this.tabs.length == 0 && !tab.isDisabled) {
            this.activeTabHash = tab.hash;
        }
        this.tabs.push(tab);
    }
    refreshAllowedIds(){
        for(let i=0; i < this.tabs.length; i++){
            
    console.log('ui-tabs refreshAllowedIds',i, this.allowedTabsIds.filter(x=>x==i).length>0,this.allowedTabsIds);
            this.tabs[i].isAllowed = this.allowedTabsIds.filter(x=>x==i).length>0
        }
    }
}

export class TabInfo {
    isAllowed: boolean = false;
    title: string;
    isDisabled: boolean;
    id: number;
    hash: string;
    constructor(obj?: Partial<TabInfo>) {
        if (obj != null) {
            Object.assign(this, obj);
        }
        if (!this.hash) {
            this.hash = Array(10)
                .fill(null)
                .map(() => String.fromCharCode(Math.random() * 100 % 25 + 'A'.charCodeAt(0))).join('')
        }
    }
}