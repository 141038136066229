import EnumCurrency from "@/api/main/models/Enums/EnumCurrency";
import EnumStatusTransaction from "@/api/main/models/Enums/EnumStatusTransaction";
import EnumTypeTransaction from "@/api/main/models/Enums/EnumTypeTransaction";

export default class UpdateCardTransactionModel {
  Id: number = 0;
  FromWhomId: number = 0;
  FromName: string = null;
  FromCardNumber: string = null;
  FromWhomLegalPersonId: number = 0;
  FromNameLegalPerson: string = null;
  FromCardNumberLegalPerson: string = null;
  ToId: number = 0;
  ToName: string = null;
  ToCardNumber: string = null;
  DateCreate: string = null;
  DateCreateJs: number = 0;
  DateCreateString: string = null;
  Currency: EnumCurrency = null;
  CurrencyName: string = null;
  Amount: number = 0;
  TypeTransactionId: EnumTypeTransaction = null;
  TypeTransactionName: string = null;
  IsDefaultTransaction: boolean = null;
  DateCreateEnd: string = null;
  DateCreateStart: string = null;
  Search: string = null;
  IBAN: string = null;
  PurposeOfPayment: string = null;
  Status: EnumStatusTransaction = null;

  constructor(obj?: Partial<UpdateCardTransactionModel>) {
    if (obj) {
      Object.assign(this, obj)
    }
  }
}