
import { Vue, Options, Prop } from "vue-property-decorator";
import $ = require("jquery");
import "@/plugins/daterangepicker/jquery.daterangepicker.min.js";

@Options({
	name: "datepicker-range",
})
export default class DatepickerRange extends Vue {
	@Prop({ default: false }) visible: boolean;

	StartDate: string = "";
	EndDate: string = "";

	mounted() {
		this.init();
	}

	init() {
		var self = this;
		let Options = {
			hoveringTooltip: false,
			startOfWeek: "monday",
			container: "#datepicker_container",
			language: this.$localization.currentLang,
			singleMonth: true,
			showShortcuts: false,
			showTopbar: false,
			alwaysOpen: true,
			inline: true,
		};
		if ((<any>$("#daterange")).length) {
			(<any>$("#daterange"))
				.dateRangePicker(Options)
				.bind("datepicker-change", function (event, obj) {
					self.StartDate = obj.date1;
					self.EndDate = obj.date2;
				});
		}
	}

	ChangeDate() {
		if (this.StartDate !== "" && this.EndDate !== "") {
			this.$emit("changeDate", {
				StartDate: this.StartDate,
				EndDate: this.EndDate,
			});
		}
	}

	close() {
		this.$emit("close");
	}
}
