
import { Countries } from "@models/const/countries";
import { Options, Prop, Vue, Watch } from "vue-property-decorator";

import BeneficiaryModel from "./../models/BeneficiaryModel";
import BeneficiaryInfoModel from "../models/BeneficiaryInfoModel";
import EnumApiErrors from "@models/enums/EnumApiError";
import LegalBeneficiaryGetDataResponse from "@/api/main/dataSource/api/identification-services/models/legal-beneficiary/LegalBeneficiaryGetDataResponse";

import UploadPhotoCropRequest from "@/api/main/dataSource/api/file-services/models/UploadPhotoCropRequest";
import { AdapterApiService } from "../../_adapter";

@Options({
	emits: ["check", "changeTab"],
})
export default class BeneficiaryTab extends Vue {
	@Prop({ default: null }) userId: number;
	TitleOptions: string[] = ["Mr", "Mrs", "Miss"];
	countries = Countries;
	errorText: string = "";
	isLoading: boolean = false;
	errorMessage: string = "";
	buttonItems: Array<{ value: boolean; text: string }> = Array<{
		value: boolean;
		text: string;
	}>(
		{
			value: true,
			text: "",
		},
		{
			value: false,
			text: "",
		}
	);
	adapter = AdapterApiService.getInstance();
	checkStart: boolean = false;
	key: string = "";
	currentId: number = null;
	beneficiaryData: BeneficiaryModel = new BeneficiaryModel();
	documents: Array<{ doc: string; key: string }> = new Array<{
		doc: string;
		key: string;
	}>();

	async mounted() {
		this.buttonItems[0].text = this.$localization.getTranslate("Yes");
		this.buttonItems[1].text = this.$localization.getTranslate("No");
		this.documents = [
			{ doc: this.$localization.getTranslate("Passport"), key: "Passport" },
			{ doc: "ID", key: "ID" },
		];
		
		await this.getBeneficiaryData();
	}

	onUpdatePhone(beneficiaryInfo: BeneficiaryInfoModel) {
		if (!beneficiaryInfo.Phone) return;
		let phone = beneficiaryInfo.Phone;
		if (phone == "+") {
			phone = "";
		}
		if (phone.length) {
			phone = "+" + phone.replaceAll("+", "");
		}
		if (phone != beneficiaryInfo.Phone) beneficiaryInfo.Phone = phone;
	}

	@Watch("errorText")
	onUpdateButtonText(newValue: string) {
		if (newValue && newValue.length) {
			this.errorMessage = this.$localization.getTranslate(`EnumApiError.${newValue}`);
		}
	}

	deleteDocumentFile(beneficiary: BeneficiaryInfoModel) {
		if (beneficiary.DocumentFile) {
			beneficiary.DocumentFile = null;
		}
		beneficiary.DocumentFileUrl = "";
	}

	deleteApproveOfAddressFile(beneficiary: BeneficiaryInfoModel) {
		if (beneficiary.ApproveOfAddressFile) {
			beneficiary.ApproveOfAddressFile = null;
		}
		beneficiary.ApproveOfAddressFileUrl = "";
	}

	onAddApproveOfAdressDocument(beneficiary: BeneficiaryInfoModel, file) {
		beneficiary.ApproveOfAddressFileUrl = "";
		beneficiary.ApproveOfAddressFile = new UploadPhotoCropRequest({
			FileName: file.name,
			FileBase64: file.fileBase64,
		});
	}
	onAddDocumentFile(beneficiary: BeneficiaryInfoModel, file) {
		beneficiary.DocumentFileUrl = "";
		beneficiary.DocumentFile = new UploadPhotoCropRequest({
			FileName: file.name,
			FileBase64: file.fileBase64,
		});
	}

	removeBeneficiaryInfo(info) {
		this.beneficiaryData.BeneficiaryInfo = this.beneficiaryData.BeneficiaryInfo.filter(
			(x) => x !== info
		);
	}
	async getBeneficiaryData() {
		// if (this.$store.state.beneficiaryData) {
		// 	this.beneficiaryData = this.$store.state.beneficiaryData;
		// 	this.checkStart = true;
		// } else {

		this.errorMessage = "";
		const res = await this.adapter.getBeneficiaryDataAsync();
		
		if (!res.IsSuccess) {
			this.errorMessage = res.ErrorMessage;
			return;
		}
		
		this.beneficiaryData = new BeneficiaryModel(res.Value);
		if (res.Value.BeneficiaryInfo.length>0) {
			this.onSuccessGetBeneficiaryData(res.Value);
			
		}

		// }
	}

	onSuccessGetBeneficiaryData(response: LegalBeneficiaryGetDataResponse) {
		if (response) {
			this.beneficiaryData = new BeneficiaryModel(response);
			if (this.beneficiaryData.BeneficiaryInfo.length > 0) {
				this.$emit("check", 4, true);
			}
			// this.$store.state.setBeneficiaryData(this.beneficiaryData);
		}

		// if (this.userId) {
		// this.beneficiaryData.LegalPersonId = this.userId;
		// }
	}

	catchError(err) {
		if (EnumApiErrors[err.ErrorCode]) {
			this.errorText = EnumApiErrors[err.ErrorCode];
		} else {
			this.errorText = "ErrorOccured";
		}
		this.$emit("check", 1, false);
		this.isLoading = false;
	}

	async beginContinue() {
		this.checkStart = true;
		if (this.beneficiaryData && this.beneficiaryData.isValid()) {
			this.errorText = "";
			await this.saveBeneficiaryData();
		} else {
			this.$emit("check", 1, false);
			this.errorText = "IncompleteInformation";
		}
	}

	async saveBeneficiaryData() {
		this.isLoading = true;
		let req = this.beneficiaryData.toLegalBeneficiarySaveDataRequest();
		req.LegalPersonId = AdapterApiService.getInstance().userInfo.value.Id;
		await this.adapter
			.saveBeneficiaryDataAsync(req)
			.then(this.onSuccessSaveBeneficiaryData)
			.catch(this.catchError);
	}

	async onSuccessSaveBeneficiaryData() {
		// this.$store.state.setBeneficiaryData(null);
		await this.getBeneficiaryData();
		this.$emit("changeTab", 2);
		this.$emit("check", 4, true);
		this.isLoading = false;
	}

	upCount() {
		this.beneficiaryData.BeneficiaryInfo.push(new BeneficiaryInfoModel());
		let block = document.querySelector(".main_target").getBoundingClientRect();
		let needableScroll: number = block.height + block.y;
		if (document.documentElement.offsetWidth >= 991) {
			needableScroll -= 100;
		} else {
			needableScroll -= 170;
		}
		setTimeout(() => {
			window.scrollBy({
				top: needableScroll,
				behavior: "smooth",
			});
		}, 1);
	}
}
