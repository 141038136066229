
import { Vue } from "vue-class-component";
import { DEFAULT, USERIDENTIFICATION } from "./router/routerNames";

export default class AppComponent extends Vue {
	isNext: boolean = true;

	beforeCreate() {
		console.log("beforeCreate");
	}
}
