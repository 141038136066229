
import { Options, Vue } from "vue-class-component";
import CropImagePreview from "./crop-image-preview.vue";
import ImagePreviewModel from "./ImagePreviewModel";
import UiFileInput from "../ui-file-input/ui-file-input.vue";

//import ImagePreview from "@models/imagePreview";
@Options<UiImagePreview>({
  props: {
    modelValue: {
      type: ImagePreviewModel,
    },
    noCache: Boolean,
    showError: Boolean,
    required: Boolean,
    aspectRatio: { type: Number, default: 1 },
    elHight: Number,
    oneImage: Boolean,
    showImage: { type: Boolean, default: true },
    cropBlockStyle: Object,
    imgStyle: Object,
  },
  watch: {
    modelValue(value) {
      this.innervalue = this.modelValue;
    },
  },
  components: { CropImagePreview },
})
export default class UiImagePreview extends Vue {
  modelValue: ImagePreviewModel = {
    Width: 0,
    Height: 0,
    Top: 0,
    Left: 0,
    ImageSrc: "",
  };
  readonly noCache: boolean;
  readonly showError: boolean;
  readonly required: boolean;
  readonly aspectRatio: number;
  readonly elHight: number;
  readonly oneImage: boolean;
  // @Prop({ type: Object, default: new ImagePreviewModel() })
  // value: ImagePreviewModel;
  // @Prop({ default: true }) noCache!: boolean;
  // @Prop({ default: true }) showError!: boolean;
  // @Prop({ default: true }) required!: boolean;
  // @Prop({ default: 1 }) aspectRatio: number;
  // @Prop({ default: 100 }) elHight: number;
  // @Prop({ default: false, type: Boolean }) oneImage: boolean;
  cropImageUrl = "";
  file: File;
  cropFile: File;
  errorMessage: string = "";
  hasError: boolean = false;

  innervalue: ImagePreviewModel = this.modelValue;
  cropResult: ImagePreviewModel = this.modelValue;

  // @Watch("innervalue")
  // onInnerValueChanged() {
  //   this.$emit("input", this.innervalue);
  // }
  // @Watch("value")
  // onValueChanged() {
  //   this.innervalue = this.value;
  // }
  created() {
    // //console.log(`this`, this);

    this.innervalue = this.modelValue;
    this.cropResult = this.modelValue;
  }
  get imageUrlPreview(): string {
    return (
      this.innervalue.ImageSrc +
      (this.noCache ? `?no_cache=${new Date().getTime()}` : "")
    );
  }

  setPositions(data: any) {
    this.innervalue = new ImagePreviewModel();
    this.innervalue.Width = parseInt(data.Width);
    this.innervalue.Height = parseInt(data.Height);
    this.innervalue.Top = parseInt(data.Top);
    this.innervalue.Left = parseInt(data.Left);
    this.innervalue.ImageSrc = data.ImageSrc;
  }

  onCropUpdate(data: ImagePreviewModel) {
    this.$emit("update:modelValue", data);
    this.setPositions(data);
  }

  onLoadFile(file) {
    this.cropFile = file;
    var fileToLoad = file;
    var fileReader = new FileReader();

    fileReader.onload = (fileLoadedEvent: any) => {
      var srcData = fileLoadedEvent.target.result;
      this.cropImageUrl = srcData;
    };
    this.$emit("sendFileName", fileToLoad.name);
    fileReader.readAsDataURL(fileToLoad);
  }

  saveCrop() {
    this.file = this.cropFile;
    this.cropFile = null;
    // (this.$refs.crop as any).forceUpdate();
    this.innervalue.ImageSrc = this.cropImageUrl;
    this.cropImageUrl = null;
    this.cropResult = this.innervalue;
    this.clearFile();
    this.isValid();
  }

  getSetParams() {
    return this.innervalue;
  }

  isValid(): boolean {
    this.errorMessage = "";
    if (
      this.required &&
      (!this.innervalue.ImageSrc ||
        this.innervalue.ImageSrc == "/assets/img/no_image.svg")
    ) {
      this.errorMessage = "*Выберите изображение";
      this.hasError = true;
      return false;
    }
    this.hasError = false;
    let data = {
      file: this.file,
      cropResult: this.cropResult,
    };
    this.$emit("saveImage", data);
    return true;
  }

  getFile() {
    return this.innervalue;
  }

  clearFile() {
    this.cropImageUrl = null;
    (this.$refs.fileInput as UiFileInput).setFile(this.file);
  }
}
