import CropSettings from '../../tirscript/image-resizer/models/CropSettings';

export default class UploadPhotoCropRequest  {
	FileName!: string;
	FileBase64!: string;
	CropSettings!: CropSettings;
	constructor(obj?: Partial<UploadPhotoCropRequest>) {
		if (obj) {
			Object.assign(this, obj);
		}
	}
}
