export default class FormTransferAcquiringModel {
  ToFirstName: string = "";
  ToLastName: string = "";
  ToCardNumber: string = "";
  Amount: string = "";
  Description: string = "";
  TemplateName: string = "";
  Pin: string = "";
  CommissionAmount: number = 0;

  constructor(obj?: Partial<FormTransferAcquiringModel>) {
    if (obj) {
      Object.assign(this, obj)
    }
  }
}