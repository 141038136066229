import UserType from '../../../../common/enums/UserType';
import EnumTypeIdentified from '../../../../aba/data/models/models/EnumTypeIdentified';

export default class CurrentUserResponse  {
	Email!: string;
	Phone!: string;
	Id!: number;
	UserRole!: UserType;
	FirstName!: string;
	LastName!: string;
	IdentifiedStatus!: EnumTypeIdentified;
	constructor(obj?: Partial<CurrentUserResponse>) {
		if (obj) {
			Object.assign(this, obj);
		}
	}
}
