export default class PaymentParamModel {
  Skip: number = 0;
  Take: number = 0;
  Filter: Filter = new Filter();

  constructor(obj?: Partial<PaymentParamModel>) {
    if (obj) {
      Object.assign(this, obj)
    }
  }
}

class Filter {
  DateCreateStart: string = null
  DateCreateEnd: string = null

  constructor(obj?: Partial<Filter>) {
    if (obj) {
      Object.assign(this, obj)
    }
  }
}