import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createBlock as _createBlock, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_loading = _resolveComponent("ui-loading")!
  const _component_ui_table_filter = _resolveComponent("ui-table-filter")!
  const _component_ui_table_header_item = _resolveComponent("ui-table-header-item")!
  const _component_ui_table = _resolveComponent("ui-table")!
  const _component_ui_pagination = _resolveComponent("ui-pagination")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_ui_loading, { loading: _ctx.isLoading }, null, 8, ["loading"]),
    _createVNode(_component_ui_table_filter, {
      filter: _ctx.filter,
      onChange: _ctx.onChangeFilter
    }, {
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "filter", {}, undefined, true)
      ]),
      _: 3
    }, 8, ["filter", "onChange"]),
    _createVNode(_component_ui_table, {
      items: _ctx.items,
      templateColumns: _ctx.templateColumns
    }, {
      header: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columns, (item) => {
          return (_openBlock(), _createBlock(_component_ui_table_header_item, { key: item }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(item), 1)
            ]),
            _: 2
          }, 1024))
        }), 128))
      ]),
      body: _withCtx((data) => [
        _renderSlot(_ctx.$slots, "default", _normalizeProps(_guardReactiveProps(data.item)), undefined, true)
      ]),
      _: 3
    }, 8, ["items", "templateColumns"]),
    _createVNode(_component_ui_pagination, {
      size: _ctx.pageSize,
      count: _ctx.count,
      onOnChange: _ctx.onPaginationChanged,
      ref: "pagination"
    }, null, 8, ["size", "count", "onOnChange"])
  ]))
}