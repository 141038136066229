
import EnumTypeIdentified from '@/api/main/models/Enums/EnumTypeIdentified';
import EnumTypeUser from "@/api/main/models/Enums/EnumTypeUser";
import ModelPersonBalance from "@/api/main/services/NaturalPersonProfile/models/response/GetNewUser/ModelPersonBalance";
import ModelPersonCard from "@/api/main/services/NaturalPersonProfile/models/response/GetNewUser/ModelPersonCard";

export default class PersonModel {
  Balance: ModelPersonBalance = new ModelPersonBalance();
  Cards: Array<ModelPersonCard> = [];
  CompanyName: string = "";
  Email: string = "";
  Id: number = 0;
  Identified: EnumTypeIdentified;
  IsTwoFactorAuthentication: boolean = false;
  
  Name?: string ="";
  LastName?: string ="";
  PaymentsType?: EnumUserPaymentsType = 0;
  Phone?: string ="";
  Role?: string ="";
  TypeIdentified?: EnumTypeIdentified = 0;
  TypeUser?: EnumTypeUser = EnumTypeUser.NaturalPerson;

  constructor(obj?: Partial<PersonModel>) {
    if (obj) {
      Object.assign(this, obj)
    }
  }
}
