import { WebApiService } from '../../../../plugins/webApiService'
import { Events } from '../../../../plugins/events'
import { SendRequestModel } from '../../../../plugins/models/SendRequestModel'
import EnumLang from '../../../../i-public-service/aba-finance/models/enums/EnumLang';
import MailBankModel from '../../../../i-public-service/aba-finance/models/support/MailBankModel';
import WsResponseModelT from '../../../../schema-builder/server/models/WsResponseModelT';
import FAQCategoryModel from '../../../../i-public-service/aba-finance/models/support/FAQCategoryModel';
import FAQQuestionModel from '../../../../i-public-service/aba-finance/models/support/FAQQuestionModel';

export default class SupportWsController {
	private url?: string;
	private cookieName: string;
	private appWebSettingsPath?: string;
	private apiUrlProperty?: string;

	GetCategoriesEvent = new Events<WsResponseModelT<Array<FAQCategoryModel>>>();
	SendMessageEvent = new Events<WsResponseModelT<Boolean>>();
	GetPopularQuestionsEvent = new Events<WsResponseModelT<Array<FAQQuestionModel>>>();

	subscribeEvents = async () => {
		const self = this;
		const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
		webApiService.on('SupportWsController', 'GetCategories', 'Main', (res: WsResponseModelT<Array<FAQCategoryModel>>) => {
			self.GetCategoriesEvent.trigger(res);
		});
		webApiService.on('SupportWsController', 'SendMessage', 'Main', (res: WsResponseModelT<Boolean>) => {
			self.SendMessageEvent.trigger(res);
		});
		webApiService.on('SupportWsController', 'GetPopularQuestions', 'Main', (res: WsResponseModelT<Array<FAQQuestionModel>>) => {
			self.GetPopularQuestionsEvent.trigger(res);
		});
	}

	constructor(url?: string, cookieName?: string, appWebSettingsPath?: string, apiUrlProperty?: string) {
		this.url = url;
		this.cookieName = cookieName ?? "Auth-Token";
		this.appWebSettingsPath = appWebSettingsPath && [location.host, appWebSettingsPath].join("/");
		this.apiUrlProperty = apiUrlProperty;
		this.subscribeEvents();
	}

	async GetCategories(langId: EnumLang) {
		const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
		const sendRequest = new SendRequestModel({
			ProjectName: 'Main',
			Controller: 'SupportWsController',
			Method: 'GetCategories',
			Value: typeof(langId) === 'object' ? JSON.stringify(langId) : langId,
		})

		webApiService.send(sendRequest);
	}

	async SendMessage(model: MailBankModel) {
		const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
		const sendRequest = new SendRequestModel({
			ProjectName: 'Main',
			Controller: 'SupportWsController',
			Method: 'SendMessage',
			Value: typeof(model) === 'object' ? JSON.stringify(model) : model,
		})

		webApiService.send(sendRequest);
	}

	async GetPopularQuestions(langId: EnumLang) {
		const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
		const sendRequest = new SendRequestModel({
			ProjectName: 'Main',
			Controller: 'SupportWsController',
			Method: 'GetPopularQuestions',
			Value: typeof(langId) === 'object' ? JSON.stringify(langId) : langId,
		})

		webApiService.send(sendRequest);
	}

	 // async methods
	async getCategoriesAsync(langId: EnumLang): Promise<Array<FAQCategoryModel>> {
		return new Promise<Array<FAQCategoryModel>>(async (resolve, reject) => {
			const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
			const sendRequest = new SendRequestModel({
				ProjectName: 'Main',
				Controller: 'SupportWsController',
				Method: 'GetCategories',
				Value: typeof(langId) === 'object' ? JSON.stringify(langId) : langId,
				Callback: (response: WsResponseModelT<Array<FAQCategoryModel>>) => {
					const isGeneric = typeof response.IsSuccess == 'boolean'
					const result = response.IsSuccess ? response.Value : response

					if (!isGeneric || response.IsSuccess) resolve(result as Array<FAQCategoryModel>)
					else reject(result)
				}
			})

			webApiService.send(sendRequest);
		});
	}

	async sendMessageAsync(model: MailBankModel): Promise<Boolean> {
		return new Promise<Boolean>(async (resolve, reject) => {
			const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
			const sendRequest = new SendRequestModel({
				ProjectName: 'Main',
				Controller: 'SupportWsController',
				Method: 'SendMessage',
				Value: typeof(model) === 'object' ? JSON.stringify(model) : model,
				Callback: (response: WsResponseModelT<Boolean>) => {
					const isGeneric = typeof response.IsSuccess == 'boolean'
					const result = response.IsSuccess ? response.Value : response

					if (!isGeneric || response.IsSuccess) resolve(result as Boolean)
					else reject(result)
				}
			})

			webApiService.send(sendRequest);
		});
	}

	async getPopularQuestionsAsync(langId: EnumLang): Promise<Array<FAQQuestionModel>> {
		return new Promise<Array<FAQQuestionModel>>(async (resolve, reject) => {
			const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
			const sendRequest = new SendRequestModel({
				ProjectName: 'Main',
				Controller: 'SupportWsController',
				Method: 'GetPopularQuestions',
				Value: typeof(langId) === 'object' ? JSON.stringify(langId) : langId,
				Callback: (response: WsResponseModelT<Array<FAQQuestionModel>>) => {
					const isGeneric = typeof response.IsSuccess == 'boolean'
					const result = response.IsSuccess ? response.Value : response

					if (!isGeneric || response.IsSuccess) resolve(result as Array<FAQQuestionModel>)
					else reject(result)
				}
			})

			webApiService.send(sendRequest);
		});
	}

}