
import { Options, Vue, Prop } from "vue-property-decorator";
const views = ["days", "months", "years"];
import UiDaySelector from "@views/components/ui-date-selector/ui-day-selector.vue";
import UiMonthSelector from "@views/components/ui-date-selector/ui-month-selector.vue";
import UiYearSelector from "@views/components/ui-date-selector/ui-year-selector.vue";

@Options<UiDateSelector>({
  watch: {
    maximumView(value) {
      this.validateViewRanges();
    },
    minimumView(value) {
      this.validateViewRanges();
    },
    minDate(value) {
      if (this.linkedDatePicker) {
        return this.linkedDatePicker;
      }
      return this.minimumDate;
    },
  },
  components: { UiMonthSelector, UiYearSelector, UiDaySelector },
})
export default class UiDateSelector extends Vue {
  @Prop({ default: new Date(), type: Date }) date: Date;
  // @Prop({ default: new Date(), type: Date }) dateSecond: Date;
  @Prop({ default: null, type: Date }) minimumDate: Date;
  // ограничение даты сверху
  @Prop({ default: null, type: Date }) maximumDate: Date;
  @Prop({ default: null, type: String }) linkedDatePickerName: String;
  @Prop({ default: true }) showCurrentDate: boolean;

  minimumView = "days";
  maximumView = "years";
  initialView = "days";
  innerCurrentView = this.getInitialView();
  innerDay: number = null;
  innerMonth: number = null;
  innerYear: number = null;
  innerHour: number = null;
  innerMinute: number = null;
  innerDate: Date = null;
  linkedDatePicker;
  created() {
    if (!this.date) {
      this.innerDate = new Date();
    } else {
      this.innerDate = this.date;
    }

    this.innerCurrentView = this.getInitialView();
    this.innerDay = this.innerDate.getDate();
    this.innerMonth = this.innerDate.getMonth();
    this.innerYear = this.innerDate.getFullYear();
    this.innerHour = this.innerDate.getHours();
    this.innerMinute = this.innerDate.getMinutes();
    this.validateViewRanges();
    this.innerCurrentView = this.getInitialView();
    this.linkedDatePicker = this.linkedDatePickerName;
  }
  mounted() {
    this.linkedDatePicker = this.linkedDatePickerName;
    this.validateViewRanges();
    document.querySelector<HTMLDivElement>(".date-selector").focus();
  }
  validateViewRanges() {
    var invalid =
      views.indexOf(this.minimumView) > views.indexOf(this.maximumView);
    if (invalid) {
      console.error(
        `Maximumview не может быть меньше minimumView. Maximumview=${this.maximumView}, minimumview=${this.minimumView}`
      );
    }
  }
  getDate() {
    return new Date(this.innerYear, this.innerMonth, this.innerDay);
  }

  NavigatorDatetime(typeView: any) {
    if (this.allowedToShowView(typeView)) {
      this.innerCurrentView = typeView;
    }
  }
  ChangeDatetime(date: Date, typeView: any, type: string) {
    this.innerDate = date;
    // this.$emit("change", date, type);
    if (this.allowedToShowView(typeView)) {
      this.ChangeCurrentView(typeView);
    }
  }
  ChangeDatetimes(date: Date, typeView: any, type: string) {
    this.innerDate = date;
    this.$emit("change", date, type);
    if (this.allowedToShowView(typeView)) {
      this.ChangeCurrentView(typeView);
    }
  }
  ChangeData(typeView: any, type: string) {
    if (this.allowedToShowView(typeView)) {
      this.ChangeCurrentView(typeView);
    }
  }
  /**
   * Изменить текущее представление
   */
  ChangeCurrentView(typeView: any) {
    this.innerCurrentView = typeView;
  }

  getInitialView(): String {
    if (!this.initialView) return this.maximumView;
    return this.initialView;
  }
  /**
   * Разрешено ли открытие представления
   */
  allowedToShowView(view: any) {
    let minimumViewIndex = views.indexOf(this.minimumView);
    let maximumViewIndex = views.indexOf(this.maximumView);
    let viewIndex = views.indexOf(view);
    return viewIndex >= minimumViewIndex && viewIndex <= maximumViewIndex;
  }
  getRequest() {
    return this.date;
  }
}
