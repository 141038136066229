
import LegalBusinessProfileGetDataResponse from '@/api/main/dataSource/api/identification-services/models/legal-business-profile/LegalBusinessProfileGetDataResponse';
import BusinessPartnerModel from "./BusinessPartnerModel";
import PlannedTransactionsLimits from '@/api/main/dataSource/api/identification-services/models/legal-business-profile/PlannedTransactionsLimits';
import LegalBusinessProfileSaveDataRequest from '@/api/main/dataSource/api/identification-services/models/legal-business-profile/LegalBusinessProfileSaveDataRequest';
import LegalPartnersSaveRequest from '@/api/main/dataSource/api/identification-services/models/legal-business-profile/LegalPartnersSaveRequest';

export default class BusinessProfileViewModel {
    constructor(obj?: Partial<LegalBusinessProfileGetDataResponse>) {
        if (obj) {
            Object.assign(this, obj)
            let self = this;
            if (!obj.BusinessActivityDescription) {
                self.BusinessActivityDescription = '';
            }
            if (!obj.LegalPartners) {
                self.LegalPartners = new Array<BusinessPartnerModel>();
            }
            if (!obj.PlannedTransactionsLimits) {
                self.PlannedTransactionsLimits = new PlannedTransactionsLimits();
            }
            if (obj.LegalPartners)
                self.LegalPartners = obj.LegalPartners.map(x => new BusinessPartnerModel(x))
        }
    }
    LegalUserId: number = 0;
    BusinessActivityDescription?: string = ''
    LegalPartners: Array<BusinessPartnerModel> = [];
    PlannedTransactionsLimits: PlannedTransactionsLimits = null;
    BusinessActivity: Array<number> = null;
    BusinessActivityOtherText: string = '';

    toLegalBusinessProfileSaveDataRequest(): LegalBusinessProfileSaveDataRequest {
        const result = new LegalBusinessProfileSaveDataRequest(this);
        const self = this;
        result.LegalPartners = [];
        result.LegalPartners = self.LegalPartners.map(x => new LegalPartnersSaveRequest(x))
        return result;
    }
    isValid() {
        const self = this;
        if (self.BusinessActivityDescription.length == 0) {
            return false;
        }
        if (self.LegalPartners.some(x => !x.isValid())) {
            return false;
        }
        if (self.PlannedTransactionsLimits.IncomingMonthsLimit === null
            || self.PlannedTransactionsLimits.IncomingYearsLimit === null
            || self.PlannedTransactionsLimits.OutgoingMonthsLimit === null
            || self.PlannedTransactionsLimits.OutgoingYearsLimit === null) {
            return false;
        }
        
        if (self.BusinessActivity.length == 0 && self.BusinessActivityOtherText.length == 0) {
            return false;
        }

        return true;
    }
}
