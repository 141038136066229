export default class SetProfileModel {
  UserId: number = 0;
  FirstName: string = null;
  SecondName: string = null;
  Appeal: string = null;;
  BirthPlace: string = null;
  BirthDate: string = null;

  constructor(obj?: Partial<SetProfileModel>) {
    if (obj) {
      Object.assign(this, obj)
    }
  }
}