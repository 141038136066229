
import WsResponseModel from "@/api/main/SchemaBuilderServer/Models/WsResponseModel";
import { Options, Vue } from "vue-property-decorator";

@Options({
	name: "header-user",
})
export default class HeaderAdmin extends Vue {
	userName: string = "";
	isShow: boolean = false;
	changeProfile() {
		this.isShow = !this.isShow;
	}
	async goToHome() {
		this.$router.push({ path: "/" });
	}
	sumNumbers(q: number, w: number): number {
		return q + w;
	}
}
