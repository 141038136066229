
import { Vue, Prop } from "vue-property-decorator";
import moment from "moment";
export default class UiDaySelector extends Vue {
  weekday: string[] = ["Пт", "Вт", "Ср", "Чт", "Пт", "Сб", "Вс"];

  @Prop({ default: () => new Date(), type: Date }) date: Date;

  @Prop({ default: () => new Date(), type: Date }) dateSecond: Date;
  @Prop({ type: Function }) allowedToShowView: Function;
  @Prop({ default: null, type: Date }) minimumDate: Date;
  @Prop({ default: null, type: Date }) maximumDate: Date;
  isDateRange = true;
  @Prop({ default: () => [] }) eventDates: Array<any>;
  @Prop({ default: true, type: Boolean }) showCurrentDay: boolean;
  @Prop({ default: true, type: Boolean }) letSelectAllDays: boolean;
  @Prop({ type: Number, default: null }) month: number;
  @Prop({ type: Number, default: null }) year: number;

  innerDays: any[] = [];
  innerMonth: number = null;
  innerYear: number = null;
  innerDay: number = null;
  innerHour: number = null;

  innerDate: Date = null;
  innerSecondDate: Date = null;
  weeks: any[] = [];
  click = false;
  firstData = null;
  created() {
    this.innerMonth = this.date.getMonth();
    this.innerYear = this.date.getFullYear();
    this.innerDay = this.date.getDate();
    this.innerHour = this.date.getHours();

    this.innerDate = this.date; // первая дата
    this.innerSecondDate = this.dateSecond; // вторая дата
  }
  mounted() {
    this.innerMonth = this.date.getMonth();
    this.innerYear = this.date.getFullYear();
    this.innerDay = this.date.getDate();
    this.innerHour = this.date.getHours();

    this.innerDate = this.date; // первая дата
    this.innerSecondDate = this.dateSecond; // вторая дата
    this.installWeeks();
    this.setMonth(this.date);
  }

  days() {
    return this.innerDays;
  }
  /**
   * Устанавливаем текущий месяц, собираем массив дней, дополняя его до 6 недель
   */
  moment(date: Date, format: string = "DD.MM.YYYY") {
    return moment(date).format(format);
  }
  setMonth(date: Date) {
    this.innerMonth = date.getMonth();
    this.innerYear = date.getFullYear();
    this.innerDays = [];
    var firstDay = new Date(this.innerYear, this.innerMonth, 1).getDay() || 7;
    var days = new Date(this.innerYear, this.innerMonth + 1, 0).getDate();
    var lastDay = new Date(this.innerYear, this.innerMonth, days).getDay() || 7;
    let begin = 2 - firstDay;
    var end = days + (7 - lastDay);
    end = end - begin < 41 ? end + 7 : end;
    for (let index = begin; index <= end; index++) {
      this.innerDays.push({
        type: this.getStyle(index, days),
        number: new Date(this.innerYear, this.innerMonth, index).getDate(),
        date: new Date(this.innerYear, this.innerMonth, index),
      });
    }
  }

  /**
   * Проверить активность даты
   */
  checkDateActivity(day: any) {
    if (!this.minimumDate) return true;
    var x = new Date(this.innerYear, this.innerMonth, day) > this.minimumDate;
    return x;
  }
  next() {
    this.setMonth(new Date(this.innerYear, this.innerMonth + 1, 1));
  }
  backward() {
    if (this.innerMonth == 0 && this.innerYear == 1900) {
      return;
    }
    this.setMonth(new Date(this.innerYear, this.innerMonth - 1, 1));
  }
  changeMonth() {
    this.$emit("onNavigation");
  }
  changeDay(day: any) {
    let mon = parseInt(moment(this.date).format("MM")) - 1;

    let hour = parseInt(moment(this.date).format("HH"));
    let min = parseInt(moment(this.date).format("mm"));
    let sek = parseInt(moment(this.date).format("ss"));

    if (day.type != "no-active") {
      this.clearDays();
      this.innerDay = day.number;
      day.type = "current";
      this.innerDate = new Date(
        this.innerYear,
        this.innerMonth,
        this.innerDay,
        hour,
        min,
        sek
      );
      this.$emit(
        "changeDay",
        new Date(this.innerYear, this.innerMonth, day.number, hour, min, sek)
      );
    }
  }

  clearDays() {
    for (let index = 0; index < this.innerDays.length; index++) {
      if (this.innerDays[index].type == "current") {
        this.innerDays[index].type = "";
      }
    }
  }
  clearDaysAll() {
    for (let index = 0; index < this.innerDays.length; index++) {
      this.innerDays[index].type = "";
    }
  }
  openHours() {
    this.$emit("changeCurrentView", "hours");
  }
  installWeeks() {
    var moment = require("moment");
    var startOfWeek = moment().startOf("isoWeek");
    var endOfWeek = moment().endOf("isoWeek");

    this.weeks = [];
    var day = startOfWeek;

    while (day <= endOfWeek) {
      this.weeks.push(day.toDate());
      day = day.clone().add(1, "d");
    }
  }
  getStyle(index: number, days: any) {
    var result = "";
    if (index < 1 || index > days || !this.checkDateActivity(index)) {
      result = "no-active";
    }
    if (
      moment(new Date(this.innerYear, this.innerMonth, index)).isSame(
        this.innerDate,
        "day"
      ) &&
      !this.isDateRange
    ) {
      result = "current";
    }
    if (
      moment(new Date(this.innerYear, this.innerMonth, index)).isSame(
        this.innerDate,
        "day"
      )
    )
      result = "current-area-begin-single";

    var eventDateIndex =
      this.eventDates.length > 0
        ? this.eventDates.findIndex((item) =>
            moment(item).isSame(
              new Date(this.innerYear, this.innerMonth, index)
            )
          )
        : -1;
    if (
      moment(new Date(this.innerYear, this.innerMonth, index)).isSame(
        this.innerDate,
        "day"
      ) &&
      moment(new Date(this.innerYear, this.innerMonth, index)).isSame(
        this.innerSecondDate,
        "day"
      )
    )
      result = "current-area-begin-single";
    if (eventDateIndex != -1) {
      result = result + "event-day-" + "green";
    }
    return result;
  }
}
