
import UploadPhotoCropRequest from "@/api/main/dataSource/api/file-services/models/UploadPhotoCropRequest";
import CropSettings from "@/api/main/dataSource/api/tirscript/image-resizer/models/CropSettings";
import ImagePreviewModel from "@/views/components/ui-crop-image/ImagePreviewModel";
import { Vue, Options, Prop, Watch } from "vue-property-decorator";
import CatchCamera from "./catch-camera";
@Options({
	name: "ModalCamera",
})
export default class ModalCamera extends Vue {
	@Prop({ default: false }) visibility: boolean;
	camera: CatchCamera = new CatchCamera();
	imgSrc: string = "";
	buttonOkText: string = "";
	photo: UploadPhotoCropRequest = new UploadPhotoCropRequest();
	showError: boolean = false;

	@Watch("imgSrc")
	onUpdateIMGSrc() {
		if (this.imgSrc.length != 0) {
			this.buttonOkText = this.$localization.getTranslate("Confirm");
		} else {
			this.buttonOkText = this.$localization.getTranslate("Button.TakeAPhoto");
		}
	}

	created() {
		this.onUpdateIMGSrc();
	}

	onCropUpdate(data: ImagePreviewModel) {
		this.photo.FileName = "photo.jpg";
		this.photo.FileBase64 = data.ImageSrc.substr(
			data.ImageSrc.indexOf("base64,") + "base64,".length
		);
		this.photo.CropSettings = new CropSettings();
		this.photo.CropSettings.X = data.Left;
		this.photo.CropSettings.Y = data.Top;
		this.photo.CropSettings.Width = data.Width;
		this.photo.CropSettings.Height = data.Height;
	}

	@Watch("visibility")
	async onUpdateVisibility(newValue: boolean) {
		if (newValue) {
			setTimeout(async () => {
				let isStart = await this.camera.startCamera(this.$refs.video as HTMLVideoElement);
				if (!isStart) {
					this.showError = true;
				} else {
					this.showError = false;
				}
			});
		} else {
			this.camera.stopCamera();
			this.imgSrc = "";
		}
	}

	sendPhoto() {
		if (this.imgSrc.length != 0) {
			this.photo.FileName = "photo.jpg";
			this.$emit("photo", this.photo);
			this.imgSrc = "";
			this.closeModal();
		}
		this.imgSrc = this.camera.takePhoto();
		this.camera.stopCamera();
	}

	closeModal() {
		this.camera.stopCamera();
		this.imgSrc = "";
		this.$emit("closeModal");
	}
}
