
import { Options, Vue, Prop, Watch } from "vue-property-decorator";

import * as Router from "@/router/routerNames";
import Cookies from "js-cookie";
import { isDevelopment } from "@/helper/helpers";
import NaturalPersonModel from "../models/NaturalPersonModel";
import GetSystemMessages from "@/api/main/services/NaturalPersonProfile/models/response/GetSystemMessages";
import WsResponseModel from "@/api/main/SchemaBuilderServer/Models/WsResponseModel";
import { AdapterApiService } from "../pages";
import { AdapterProfileService } from "../pages/profile";
import { AdapterAuthService } from "../pages/login/adapter";

@Options({
	components: {
		// LoginPageComponent,
	},
	emits: ["changeMenu"],
})
export default class LayoutHeaderUser extends Vue {
	@Prop({ type: Boolean, default: true }) show: boolean;
	@Prop({ type: Boolean, default: false }) isLogin: boolean;

	Cookies = Cookies;
	UserIdentification: string = Router.USERIDENTIFICATION;
	userName: string = "";
	isShow: boolean = true;

	Person: NaturalPersonModel;
	balancePerson: string = "";

	SystemMessages: Array<GetSystemMessages> = [];
	isOpenNotificationPopup: boolean = false;
	adapter = AdapterProfileService.getInstance();

	async mounted() {
		// this.getPerson(); //TODO и это тоже
		// await this.getSystemMessages(); // TODO пока отключил
		// this.calcBalance();
	}

	isShowBurger(): boolean {
		const res =
			this.$route.name != "Login" &&
			this.$route.name != "Signup" &&
			this.$route.name != "forgot-password";

		return res;
	}
	getLogoName() {
		return this.$helper.getLogoFileName();
	}
	goOut() {
		const adapter = AdapterAuthService.getInstance();
		adapter.logoutUser();
		// this.ada.log
		Cookies.remove("Auth-Token");
		if (isDevelopment()) {
			// alert("Вышли");
			location.reload();
		} else location.href = "/";
	}

	@Watch("show")
	onUpdateShow(newValue: boolean) {
		this.isShow = newValue;
	}
	@Watch("adapter.naturalPerson.value")
	// onpdateBalans(newValue: boolean) {
	// 	this.balancePerson =
	// 		this.adapter.naturalPerson.value.Balance.Balance.toString() || "5.55";
	// }
	updated() {
		this.calcBalance();
	}
	goToMainPage() {
		this.$router.push({ name: Router.BALANCE });
	}

	changeProfile() {
		this.isShow = !this.isShow;
	}
	async goToHome() {
		this.$router.push({ path: "/natural" });
	}
	logout(res: WsResponseModel<Boolean>) {
		this.$router.push({ name: "login" });
	}
	onClickMenu(value: boolean) {
		this.$emit("changeMenu", value);
	}
	async getPerson() {
		// if (!this.$store.state.currentPersonProfile) {
		// 	const res = await this.adapter.getNaturalPerson();
		// 	this.$store.state.setCurrentPersonProfile(res);
		// }
	}

	async calcBalance() {
		// const adap = AdapterProfileService.getInstance();
		// await adap.GetNaturalPerson();
		// const person = adap.naturalPerson.value;
		// if (person) {
		// 	this.Person = new NaturalPersonModel({ Name: person.Name || "" });
		// 	this.balancePerson = person.Balance.Balance.toFixed(2).toString();
		// }
	}

	async getSystemMessages() {
		const res = await this.adapter.getSystemMessages();
		if (res.IsSuccess) {
			this.SystemMessages = this.adapter.mapModelSystemMessagesToGetSystemMessages(
				res.Value
			);
			if (this.$localization.currentLang !== "ru") {
				this.SystemMessages.forEach(
					(msg) => (msg.Text = msg.SystemMessagesLangs[1].Text)
				);
			}
		}
	}
	onClose() {
		this.$router.push({ path: "/" });
	}
	async openNotificationPopup() {
		const adapter = AdapterProfileService.getInstance();
		this.isOpenNotificationPopup = true;
		const res = await adapter.readingSystemMessagesAll();
		if (res.IsSuccess) {
			this.SystemMessages.forEach((msg) => (msg.IsRead = true));
		}
		// this.$api.NaturalPersonProfileService.ReadingSystemMessagesAll((res) => {
		// 	this.SystemMessages.forEach((msg) => (msg.IsRead = true));
		// });
		this.closeNotificationPopup(); //TODO временно
	}
	closeNotificationPopup() {
		setTimeout(() => {
			this.isOpenNotificationPopup = false;
			this.SystemMessages = this.SystemMessages.filter((msg) => msg.IsRead !== true);
		}, 1000);
	}
}
