
import { Options, Vue, Prop } from "vue-property-decorator";

@Options({})
export default class PageTemplate extends Vue {
  @Prop({ type: String, default: "Название страницы" }) name: string; // title
  @Prop({ type: Boolean, default: false }) showBack: boolean;
  routerBack() {
    this.$router.back();
  }
}
