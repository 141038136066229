
import { Options, Vue, Prop, Watch } from "vue-property-decorator";
import ListTags from "./listtags";

@Options({
  name: "ui-tags-item"
})
export default class UITagsItem extends Vue {
  @Prop({ default: null, type: String })  title!: String;
  @Prop({ default: null, type: Object })   item!: ListTags;
  @Prop({ default: true, type: Boolean })   edit: Boolean;

  close(value: any) {
    if (this.edit) 
      console.log("close ui-tags-item")
      this.$emit("close", value);
    return false;
  }
}
