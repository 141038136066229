
import HistoryItemModel from "@/api/main/services/NaturalPersonPayments/models/HistoryItemModel";
import UpdateCardTransactionModel from "@/api/main/services/NaturalPersonPayments/models/UpdateCardTransactionModel";
import NaturalPersonModel from "@/views/models/NaturalPersonModel";
import { Vue, Options, Prop, Watch } from "vue-property-decorator";
@Options({
	name: "main-table-card",
})
export default class MainTableCard extends Vue {
	@Prop({ type: Object, default: null })
	itemsInternal: Array<HistoryItemModel>;
	@Prop({ type: Object, default: null })
	items: Array<UpdateCardTransactionModel>;
	@Prop({ type: Object, default: null })
	persone: NaturalPersonModel;
	@Prop({ type: Number, default: null }) type: string;

	transactions: Array<HistoryItemModel> = null;

	@Watch("type")
	onChangeType() {
		this.SetTransactions();
	}

	@Watch("itemsInternal", { deep: true })
	onChangeItemsInternal() {
		this.SetTransactions();
	}

	@Watch("items", { deep: true })
	onChangeItems() {
		this.SetTransactions();
	}

	downloadInfo(item) {
		//TODO нет апи
		// let formData = new FormData();
		// formData.append("tId", item.Id.toString());
		// this.$api.NaturalPersonBalanceService.DownloadTransactionInfo((res) => {
		//   var link = document.createElement("a");
		//   var typeFile = res.substr(res.length - 3); // => typeFile
		//   link.href = res;
		//   link.download = "transaction_No" + item.Id + "." + typeFile;
		//   link.dispatchEvent(new MouseEvent("click"));
		// }, formData);
	}

	FormatDateString(date: Date) {
		month = date.getMonth() + 1;
		var day = ("0" + date.getDate()).slice(-2);
		var month = ("0" + month).slice(-2);
		var year = date.getFullYear();
		return day + "." + month + "." + year.toString().substr(-2);
	}

	SetTransactions() {
		if (this.type == "Card") this.transactions = this.itemsInternal;
	}
	translateTypeTransaction(key: number, name: string) {
		if (key == 0) return name;
		var getTranslateKey = this.$store.state.translateTypeTransaction(key);
		if (getTranslateKey == "") return key;
		return this.$localization.getTranslate(getTranslateKey);
	}
}
