import { renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "ui-table" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _renderSlot(_ctx.$slots, "input"),
    _createElementVNode("div", {
      style: _normalizeStyle(_ctx.styleObject),
      "on:changeCol": _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.changeCol && _ctx.changeCol(...args)))
    }, [
      _renderSlot(_ctx.$slots, "header"),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
        return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
          _renderSlot(_ctx.$slots, "body", {
            item: item,
            styl: _ctx.selLine == item.id ? true : false
          }),
          _createElementVNode("div", {
            class: "inner-item",
            style: _normalizeStyle(_ctx.getInnerTableStyle(index * 2 + 1))
          }, [
            _renderSlot(_ctx.$slots, "inner", { item: item })
          ], 4)
        ], 64))
      }), 128))
    ], 36)
  ]))
}