import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-24185c2b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ui-tags-simple-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.Items, (item, index) => {
      return (_openBlock(), _createElementBlock("div", { key: index }, [
        _createTextVNode(_toDisplayString(item.Value.toString()), 1),
        (index<_ctx.count())
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [], 64))
          : _createCommentVNode("", true)
      ]))
    }), 128))
  ]))
}