import ApiService from '@/api/index';
import CurrentUserResponse from '@/api/main/dataSource/api/aba/cabinet/web/models/CurrentUserResponse';
import EnumLang from '@/api/main/dataSource/api/i-public-service/aba-finance/models/enums/EnumLang';
import ModelPersonBalance from '@/api/main/dataSource/api/i-public-service/aba-finance/models/ModelPersonBalance';
import MailBankModel from '@/api/main/dataSource/api/i-public-service/aba-finance/models/support/MailBankModel';
import ModelLegalPerson from '@/api/main/dataSource/api/i-public-service/aba-finance/models/user/ModelLegalPerson';
import ModelNaturalPerson from '@/api/main/dataSource/api/i-public-service/aba-finance/models/user/ModelNaturalPerson';
import EnumTypeUser from '@/api/main/models/Enums/EnumTypeUser';
import UserType from '@/api/main/models/Enums/UserType';
import WsResponseModel from '@/api/main/SchemaBuilderServer/Models/WsResponseModel';
import ModelPersonCard from '@/api/main/services/NaturalPersonProfile/models/response/GetNewUser/ModelPersonCard';
import FAQQuestionModel from '@/api/main/services/NaturalPersonSupport/models/FAQQuestionModel';

import { notify } from '@/app/notifications';
import { PersonModel } from '@/views/models';
import FAQCategoryModel from '@/views/models/FAQCategoryModel';


import { Ref, ref } from 'vue';


export default class AdapterSupportService {

  /**
 * Сервис
 */
  private static instance: AdapterSupportService;
  /**
 * API
 */
  private static api: ApiService;
  isLoading: boolean = false;
  isInited = ref(false);
  api: ApiService;
  Person: Ref<PersonModel> = ref(new PersonModel());

  constructor() {
    AdapterSupportService.api = new ApiService();
  }

  public static getInstance() {
    if (!AdapterSupportService.instance) {
      AdapterSupportService.instance = new AdapterSupportService();
    }
    return AdapterSupportService.instance;
  }

  /**
   * преобразование текста (en или bkb ru) в EnumLang
   * @param lang 
   * @returns 
   */
  getLang(lang: string): EnumLang {

    switch (lang) {
      case "en":
        return EnumLang.en

      case "ru":
        return EnumLang.ru

      default:
        return EnumLang.en
    }
  }
  async getCurrentUser(): Promise<WsResponseModel<CurrentUserResponse>> {
    const result = new WsResponseModel<CurrentUserResponse>();
    try {
      const resp = await AdapterSupportService.api.Main.AuthWsController.getCurrentUserAsync();
      result.Value = resp;
      result.IsSuccess = true;
    } catch (e) {
      return this.handleError(e, "ERROR getCurrentUser")
    }
    return result;
  }

  async getPerson(): Promise<PersonModel> {
    if (this.Person?.value?.Id) {
      return this.Person.value
    }
    const res = await this.getCurrentUser();
    if (!res.IsSuccess) {
      return
    }
    if (res.Value.UserRole == UserType.Natural) {
      await this.getNaturalPerson();
      return this.Person.value
    }
    await this.getLegalPerson();
    return this.Person.value
  }
  async getNaturalPerson(): Promise<WsResponseModel<ModelNaturalPerson>> {
    const result = new WsResponseModel<ModelNaturalPerson>();
    try {
      const resp = await AdapterSupportService.api.Main.ProfileWsController.getNaturalPersonAsync();
      result.Value = resp;
      this.Person.value = new PersonModel({
        Balance: new ModelPersonBalance(resp.Balance),
        Cards: resp.Cards.map(el => new ModelPersonCard(el)),
        CompanyName: resp.CompanyName, Email: resp.Email, Id: resp.Id, Identified: resp.Identified,
        Name: resp.Name,
        LastName: resp.LastName,
        Phone: resp.Phone,
        PaymentsType: resp.PaymentsType,
        Role: resp.Role,
        TypeIdentified: resp.TypeIdentified,
        TypeUser: EnumTypeUser.LegalPerson,
      })
      result.IsSuccess = true;
    } catch (e) {
      return this.handleError(e, "ERROR getNaturalPerson")
    }
    return result;
  }

  async getLegalPerson(): Promise<WsResponseModel<ModelLegalPerson>> {
    const result = new WsResponseModel<ModelLegalPerson>();
    try {
      const resp = await AdapterSupportService.api.Main.ProfileWsController.getLegalPersonAsync();
      result.Value = resp;
      this.Person.value = new PersonModel({
        Balance: new ModelPersonBalance(resp.Balance),
        Cards: resp.Cards.map(el => new ModelPersonCard(el)),
        CompanyName: resp.CompanyName, Email: resp.Email, Id: resp.Id,
        Identified: resp.IdentifiedId,
        Name: resp.Name,
        LastName: resp.LastName,
        Phone: resp.Phone,
        PaymentsType: resp.PaymentsType,
        Role: resp.Role,
        TypeIdentified: resp.TypeIdentified,
        TypeUser: EnumTypeUser.LegalPerson,
      })
      result.IsSuccess = true;
    } catch (e) {
      return this.handleError(e, "ERROR getLegalPerson")
    }
    return result;
  }


  async getPopularQuestions(lang: string): Promise<Array<FAQQuestionModel>> {
    try {
      const value = this.getLang(lang);
      const res = await AdapterSupportService.api.Main.SupportWsController.getPopularQuestionsAsync(value)
      return res
    } catch (err: any) {
      this.showError(err)
      return null
    }
  }

  async getCategories(lang: string): Promise<Array<FAQCategoryModel>> {
    try {
      const value = this.getLang(lang);
      const res = await AdapterSupportService.api.Main.SupportWsController.getCategoriesAsync(value);
      return res as any // Todo чето тут не так
    } catch (err: any) {
      this.showError(err)
      return null
    }
  }

  async sendMessage(value: MailBankModel): Promise<Boolean> {
    try {
      const res = await AdapterSupportService.api.Main.SupportWsController.sendMessageAsync(value);
      return res
    } catch (err: any) {
      this.showError(err)
      return null
    }
  }

  // async getPopularQuestions(value: EnumLang): Promise<Array<FAQQuestionModel>> {
  //   try {
  //     const res = await AdapterSupportService.api.Main.SupportWsController.getPopularQuestionsAsync(value);
  //     return res
  //   } catch (err: any) {
  //     this.showError(err)
  //     return null
  //   }
  // }

  handleError(e: any, context: string): WsResponseModel<any> {
    console.error(`ERROR ${context}`, e);
    return {
      IsSuccess: false,
      ErrorCode: e?.ErrorCode || 500,
      ErrorMessage: e?.ErrorMessage || 'Internal Server Error',
      Value: null
    };
  }

  /**
  * показать информацию об ошибке
  * @param err 
  */
  showError(err: any) {
    console.error(err?.ErrorMessage);
    notify({ text: err?.ErrorMessage as any, title: "Error", type: 'error' })
  }
}
