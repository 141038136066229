import { normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-06c14e11"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "default" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "ui-tags-item",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.close(_ctx.item)))
  }, [
    _renderSlot(_ctx.$slots, "default", _normalizeProps(_guardReactiveProps(_ctx.item.Value)), () => [
      _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.title), 1)
    ], true)
  ]))
}