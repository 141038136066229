import EnumCurrency from "../../../../../models/Enums/EnumCurrency";

export default class ModelTypeCard {
  Id: number = 0;
  Name: string = null;
  Currency: EnumCurrency = 0;
  CurrencyName: string = null;
  LimitService: number = 0;
  TermService: Date = null;
  TermServiceJs: number = 0;
  Commission: number = 0;

  constructor(obj?: Partial<ModelTypeCard>) {
    if (obj) {
      Object.assign(this, obj)
    }
  }
}