import EnumCurrency from '../../../aba/data/enums/EnumCurrency';

export default class VirtpayPayoutRequest  {
	Amount!: number;
	UserId!: number;
	Currency!: EnumCurrency;
	CardNumber!: string;
	PaymentDescription!: string;
	PaymentPurpose!: string;
	ReturnUrl!: string;
	constructor(obj?: Partial<VirtpayPayoutRequest>) {
		if (obj) {
			Object.assign(this, obj);
		}
	}
}
