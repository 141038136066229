
import { Vue, Options, Watch, Prop } from "vue-property-decorator";
import UiSelect from "../../../components/ui-select.vue";
import CountryModel from "../models/CountryModel";
import countries from "../json/Country.json";
@Options({
  name: "select-country",
  components: { UiSelect },
})
export default class SelectCountry extends Vue {
  @Prop({ default: null }) valueCode: string;

  currentCountry: string = null;
  countryItems: Array<CountryModel> = [];
  itemsSelect: Array<string> = [];

  @Watch("valueCode")
  onChangeValueCode() {
    if (!this.valueCode) {
      this.currentCountry = null;
      return;
    }
    this.currentCountry = this.countryItems.find(
      (item) => item.code === this.valueCode
    ).name;
  }

  @Watch("currentCountry")
  onChangeInnerCode() {
    if (!this.currentCountry) return;
    let country = this.countryItems.find(
      (item) => item.name === this.currentCountry
    );
    this.$emit("onChange", country.code);
  }

  created() {
    this.Init();
  }
  Init() {
    countries.forEach(country => {
      this.countryItems.push(
        new CountryModel({ code: country.code, name: country.name })
      );
      this.itemsSelect.push(country.name);
    });
    // for (let i = 0; i < 248; i++) {
    //   this.countryItems.push(
    //     new CountryModel({ code: countries[i].code, name: countries[i].name })
    //   );
    //   this.itemsSelect.push(countries[i].name);
    // }
  }
}
