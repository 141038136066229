import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, vModelText as _vModelText, withDirectives as _withDirectives, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-20f3e86f"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "multiselect d-flex"
}
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "flex-grow-1" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { key: 0 }
const _hoisted_6 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_click_outside = _resolveDirective("click-outside")!

  return _withDirectives((_openBlock(), _createElementBlock("div", {
    tabindex: "0",
    onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onGetFocus && _ctx.onGetFocus(...args))),
    ref: "root",
    class: "ui-dropdown"
  }, [
    _createElementVNode("div", {
      class: "",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.clickCurrent && _ctx.clickCurrent(...args)))
    }, [
      _renderSlot(_ctx.$slots, "current", {
        key: _ctx.innerValue,
        value: _ctx.active
      }, () => [
        (_ctx.multiselect)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              (_ctx.active.length == 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.selectText), 1))
                : _createCommentVNode("", true),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.active, (item) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "multiselect-item d-flex mr-3",
                  key: _ctx.getKey(item)
                }, [
                  _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.getValue(item)), 1),
                  _createElementVNode("div", {
                    class: "remove",
                    onClick: ($event: any) => (_ctx.deleteItem(item)),
                    tabindex: "1"
                  }, "X", 8, _hoisted_4)
                ]))
              }), 128))
            ]))
          : (_openBlock(), _createElementBlock("div", {
              key: 1,
              class: _normalizeClass(["current", { active: _ctx.open }])
            }, _toDisplayString(_ctx.activeText), 3))
      ], true)
    ]),
    (_ctx.autocomplete && _ctx.open)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _withDirectives(_createElementVNode("input", {
            ref: "searchEl",
            class: "current-input",
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.search) = $event))
          }, null, 512), [
            [_vModelText, _ctx.search]
          ])
        ]))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "container", { data: _ctx.itemsToDisplay }, () => [
      _createElementVNode("div", {
        class: _normalizeClass(["container", { active: _ctx.open }])
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.itemsToDisplay, (item, index) => {
          return (_openBlock(), _createElementBlock("div", {
            class: _normalizeClass(["item", { active: _ctx.isSelect(item), hover: index == _ctx.hoveredIdx }]),
            key: index,
            onClick: ($event: any) => (_ctx.select(item))
          }, [
            _renderSlot(_ctx.$slots, "item", {
              item: item,
              index: index,
              hover: index == _ctx.hoveredIdx,
              active: _ctx.isSelect(item)
            }, () => [
              _createTextVNode(_toDisplayString(_ctx.getValue(item)), 1)
            ], true)
          ], 10, _hoisted_6))
        }), 128))
      ], 2)
    ], true)
  ])), [
    [_directive_click_outside, _ctx.onBlur]
  ])
}