
import { Options, Prop, Vue, Watch } from "vue-property-decorator";
const rounded = "rounded";
const rectangle = "rectangle";
@Options({ name: "ui-toggle-switcher", emits: "update:modelValue" })
export default class UiToggleSwitcherComponent extends Vue {
  @Prop({
    type: [Boolean, String, Number, Number, Date, Object, Array],
    default: true,
  })
  yesValue: any;
  @Prop({
    type: [Boolean, String, Number, Number, Date, Object, Array],
    default: false,
  })
  noValue: any;
  @Prop({ type: Boolean, default: false }) disabled: boolean;
  @Prop({
    type: [Boolean, String, Number, Number, Date, Object, Array],
    default: false,
  })
  modelValue: any;
  @Prop({ type: Boolean }) state: Boolean;
  @Prop({
    default: rounded,
    validator: (x: string) => {
      return x == rounded || x == rectangle;
    },
  })
  type: string;
  hasFocus = false;
  async toggle() {
    if (this.disabled) {    
      return;
    }
    const res = this.modelValue == this.yesValue ? this.noValue : this.yesValue;
    if (res) {
      var res1 = await this.$dialog.confirm(
        "Вы действительно хотите разблокировать день недели ?"
      );
      if (res1) {
        await this.$emit("update:modelValue", res);
      }
    } else {
      var res2 = await this.$dialog.confirm(
        "Вы действительно хотите заблокировать день недели ?"
      );
      if (res2) {
        await this.$emit("update:modelValue", res);
      }
    }
    
  }
  mounted() {
    document.addEventListener("keydown", this.onKeyDown.bind(this));
  }
  beforeDestroy() {
    document.removeEventListener("keydown", this.onKeyDown.bind(this));
  }
  onGetFocus() {
    this.hasFocus = true;
  }
  onBlur() {
    this.hasFocus = false;
  }
  onKeyDown(ev: KeyboardEvent) {
    if (!this.hasFocus) {
      return;
    }
    var key = ev.key.toLowerCase();
    if (key == "enter") {
      return this.toggle();
    }
  }
}
