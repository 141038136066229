import { WebApiService } from '../../../../../plugins/webApiService'
import { Events } from '../../../../../plugins/events'
import { SendRequestModel } from '../../../../../plugins/models/SendRequestModel'
import SwiftPayoutRequest from '../../../../../transaction-services/models/transaction-operations/SwiftPayoutRequest';
import ModelTransferSwift from '../../../../../i-public-service/aba-finance/models/ModelTransferSwift';
import WsResponseModelT from '../../../../../schema-builder/server/models/WsResponseModelT';
import GetSwiftTemplatesResponse from '../../../../../transaction-services/models/transfer-template-service/GetSwiftTemplatesResponse';

export default class SwiftWsController {
	private url?: string;
	private cookieName: string;
	private appWebSettingsPath?: string;
	private apiUrlProperty?: string;

	GetTemplatesEvent = new Events<WsResponseModelT<GetSwiftTemplatesResponse>>();
	GetCommissionForSwiftPaymentEvent = new Events<WsResponseModelT<number>>();
	CheckSwiftTransferEvent = new Events<WsResponseModelT<Boolean>>();
	TransferSwiftEvent = new Events<WsResponseModelT<Boolean>>();

	subscribeEvents = async () => {
		const self = this;
		const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
		webApiService.on('SwiftWsController', 'GetTemplates', 'Main', (res: WsResponseModelT<GetSwiftTemplatesResponse>) => {
			self.GetTemplatesEvent.trigger(res);
		});
		webApiService.on('SwiftWsController', 'GetCommissionForSwiftPayment', 'Main', (res: WsResponseModelT<number>) => {
			self.GetCommissionForSwiftPaymentEvent.trigger(res);
		});
		webApiService.on('SwiftWsController', 'CheckSwiftTransfer', 'Main', (res: WsResponseModelT<Boolean>) => {
			self.CheckSwiftTransferEvent.trigger(res);
		});
		webApiService.on('SwiftWsController', 'TransferSwift', 'Main', (res: WsResponseModelT<Boolean>) => {
			self.TransferSwiftEvent.trigger(res);
		});
	}

	constructor(url?: string, cookieName?: string, appWebSettingsPath?: string, apiUrlProperty?: string) {
		this.url = url;
		this.cookieName = cookieName ?? "Auth-Token";
		this.appWebSettingsPath = appWebSettingsPath && [location.host, appWebSettingsPath].join("/");
		this.apiUrlProperty = apiUrlProperty;
		this.subscribeEvents();
	}

	async GetTemplates() {
		const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
		const sendRequest = new SendRequestModel({
			ProjectName: 'Main',
			Controller: 'SwiftWsController',
			Method: 'GetTemplates',
		})

		webApiService.send(sendRequest);
	}

	async GetCommissionForSwiftPayment(amount: number) {
		const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
		const sendRequest = new SendRequestModel({
			ProjectName: 'Main',
			Controller: 'SwiftWsController',
			Method: 'GetCommissionForSwiftPayment',
			Value: typeof(amount) === 'object' ? JSON.stringify(amount) : amount,
		})

		webApiService.send(sendRequest);
	}

	async CheckSwiftTransfer(model: SwiftPayoutRequest) {
		const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
		const sendRequest = new SendRequestModel({
			ProjectName: 'Main',
			Controller: 'SwiftWsController',
			Method: 'CheckSwiftTransfer',
			Value: typeof(model) === 'object' ? JSON.stringify(model) : model,
		})

		webApiService.send(sendRequest);
	}

	async TransferSwift(model: ModelTransferSwift) {
		const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
		const sendRequest = new SendRequestModel({
			ProjectName: 'Main',
			Controller: 'SwiftWsController',
			Method: 'TransferSwift',
			Value: typeof(model) === 'object' ? JSON.stringify(model) : model,
		})

		webApiService.send(sendRequest);
	}

	 // async methods
	async getTemplatesAsync(): Promise<GetSwiftTemplatesResponse> {
		return new Promise<GetSwiftTemplatesResponse>(async (resolve, reject) => {
			const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
			const sendRequest = new SendRequestModel({
				ProjectName: 'Main',
				Controller: 'SwiftWsController',
				Method: 'GetTemplates',
				Callback: (response: WsResponseModelT<GetSwiftTemplatesResponse>) => {
					const isGeneric = typeof response.IsSuccess == 'boolean'
					const result = response.IsSuccess ? response.Value : response

					if (!isGeneric || response.IsSuccess) resolve(result as GetSwiftTemplatesResponse)
					else reject(result)
				}
			})

			webApiService.send(sendRequest);
		});
	}

	async getCommissionForSwiftPaymentAsync(amount: number): Promise<number> {
		return new Promise<number>(async (resolve, reject) => {
			const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
			const sendRequest = new SendRequestModel({
				ProjectName: 'Main',
				Controller: 'SwiftWsController',
				Method: 'GetCommissionForSwiftPayment',
				Value: typeof(amount) === 'object' ? JSON.stringify(amount) : amount,
				Callback: (response: WsResponseModelT<number>) => {
					const isGeneric = typeof response.IsSuccess == 'boolean'
					const result = response.IsSuccess ? response.Value : response

					if (!isGeneric || response.IsSuccess) resolve(result as number)
					else reject(result)
				}
			})

			webApiService.send(sendRequest);
		});
	}

	async checkSwiftTransferAsync(model: SwiftPayoutRequest): Promise<Boolean> {
		return new Promise<Boolean>(async (resolve, reject) => {
			const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
			const sendRequest = new SendRequestModel({
				ProjectName: 'Main',
				Controller: 'SwiftWsController',
				Method: 'CheckSwiftTransfer',
				Value: typeof(model) === 'object' ? JSON.stringify(model) : model,
				Callback: (response: WsResponseModelT<Boolean>) => {
					const isGeneric = typeof response.IsSuccess == 'boolean'
					const result = response.IsSuccess ? response.Value : response

					if (!isGeneric || response.IsSuccess) resolve(result as Boolean)
					else reject(result)
				}
			})

			webApiService.send(sendRequest);
		});
	}

	async transferSwiftAsync(model: ModelTransferSwift): Promise<Boolean> {
		return new Promise<Boolean>(async (resolve, reject) => {
			const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
			const sendRequest = new SendRequestModel({
				ProjectName: 'Main',
				Controller: 'SwiftWsController',
				Method: 'TransferSwift',
				Value: typeof(model) === 'object' ? JSON.stringify(model) : model,
				Callback: (response: WsResponseModelT<Boolean>) => {
					const isGeneric = typeof response.IsSuccess == 'boolean'
					const result = response.IsSuccess ? response.Value : response

					if (!isGeneric || response.IsSuccess) resolve(result as Boolean)
					else reject(result)
				}
			})

			webApiService.send(sendRequest);
		});
	}

}