import { WebApiService } from '../../../../../plugins/webApiService'
import { Events } from '../../../../../plugins/events'
import { SendRequestModel } from '../../../../../plugins/models/SendRequestModel'
import WsResponseModelT from '../../../../../schema-builder/server/models/WsResponseModelT';
import EnumPaymentServiceType from '../../../../../aba/data/enums/EnumPaymentServiceType';

export default class PaymentSettingsWsController {
	private url?: string;
	private cookieName: string;
	private appWebSettingsPath?: string;
	private apiUrlProperty?: string;

	GetUserPaymentServicesEvent = new Events<WsResponseModelT<Array<EnumPaymentServiceType>>>();

	subscribeEvents = async () => {
		const self = this;
		const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
		webApiService.on('PaymentSettingsWsController', 'GetUserPaymentServices', 'Main', (res: WsResponseModelT<Array<EnumPaymentServiceType>>) => {
			self.GetUserPaymentServicesEvent.trigger(res);
		});
	}

	constructor(url?: string, cookieName?: string, appWebSettingsPath?: string, apiUrlProperty?: string) {
		this.url = url;
		this.cookieName = cookieName ?? "Auth-Token";
		this.appWebSettingsPath = appWebSettingsPath && [location.host, appWebSettingsPath].join("/");
		this.apiUrlProperty = apiUrlProperty;
		this.subscribeEvents();
	}

	async GetUserPaymentServices() {
		const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
		const sendRequest = new SendRequestModel({
			ProjectName: 'Main',
			Controller: 'PaymentSettingsWsController',
			Method: 'GetUserPaymentServices',
		})

		webApiService.send(sendRequest);
	}

	 // async methods
	async getUserPaymentServicesAsync(): Promise<Array<EnumPaymentServiceType>> {
		return new Promise<Array<EnumPaymentServiceType>>(async (resolve, reject) => {
			const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
			const sendRequest = new SendRequestModel({
				ProjectName: 'Main',
				Controller: 'PaymentSettingsWsController',
				Method: 'GetUserPaymentServices',
				Callback: (response: WsResponseModelT<Array<EnumPaymentServiceType>>) => {
					const isGeneric = typeof response.IsSuccess == 'boolean'
					const result = response.IsSuccess ? response.Value : response

					if (!isGeneric || response.IsSuccess) resolve(result as Array<EnumPaymentServiceType>)
					else reject(result)
				}
			})

			webApiService.send(sendRequest);
		});
	}

}