import LegalBeneficiaryInfoGetResponse from '../../../identification-services/models/legal-beneficiary/LegalBeneficiaryInfoGetResponse';

export default class LegalBeneficiaryGetDataResponse  {
	BeneficiaryDescription!: string;
	BeneficiaryInfo!: Array<LegalBeneficiaryInfoGetResponse>;
	constructor(obj?: Partial<LegalBeneficiaryGetDataResponse>) {
		if (obj) {
			Object.assign(this, obj);
		}
	}
}
