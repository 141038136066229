import { Watch } from "vue-property-decorator";

export default class CatchCamera {
    stream: MediaStream = new MediaStream();
    video: HTMLVideoElement;

    async startCamera(video: HTMLVideoElement): Promise<boolean> {
        let self = this;
        return new Promise<boolean>(async resolve => {
            if (self.stream.active) {
                self.stopCamera();
            }

            await navigator.mediaDevices
                .getUserMedia({ video: !0, audio: false })
                .then((stream) => {
                    video.srcObject = stream;
                    video.play();
                    self.video = video;
                    self.stream = stream;
                    resolve(true)
                })
                .catch(res => {
                    console.log('startCamera error', res);
                    resolve(false)
                });
        })
        
        
    }

    stopCamera() {
        this.stream.getTracks().forEach(track => {
            track.stop();
        });
    }

    takePhoto() {
        let canvas = document.createElement('canvas');
        canvas.width = this.video.offsetWidth;
        canvas.height = this.video.offsetHeight;
        let hRatio = (canvas.width / this.video.videoWidth) * this.video.videoHeight;
        canvas.getContext('2d').drawImage(this.video, 0, 0, canvas.width, hRatio);
        return canvas.toDataURL('image/png');
    }
}