
import { Options, Vue, Ref, Prop, Watch } from "vue-property-decorator";
import StatusWindow from "./status-window";
import UiButton from "@views/components/ui-button.vue";
import UiScroll from "@/views/components/ui-scroll.vue";

@Options<UiModalWindow>({
	components: {
		StatusWindow,
		UiButton,
		UiScroll,
	},
	emits: ["onCancel", "onOk", "clickOutside"],
})
export default class UiModalWindow extends Vue {
	@Prop({ type: String }) titleBtnYes: string;
	@Prop({ type: String }) titleBtnNo: string;

	@Prop({ type: String, default: "title" }) title: string;
	@Prop({ type: Boolean, default: false }) visible: boolean;
	@Prop({ type: Boolean, default: true }) showCloseBtn: boolean;
	@Prop({ type: Boolean, default: true }) showOkBtn: boolean;
	@Prop({ type: Function, default: null, required: false }) closeHandler: () =>
		| boolean
		| (() => Promise<boolean>);
	@Prop({ type: Object, default: null, required: false }) okHandler: () =>
		| boolean
		| (() => Promise<boolean>);
	@Prop({ type: Number, default: StatusWindow.Text }) type: StatusWindow;
	@Prop() imageUrl: string;
	@Prop({ type: Boolean, default: false }) noButton: boolean;
	@Prop({ type: Boolean, default: true }) showHeaderTitle: boolean;
	@Prop({ type: Boolean, default: true }) autoClose: boolean;
	@Prop({ type: String, default: "flex-end" }) buttonsJustify: string;
	@Prop({ type: String, default: "flex-end" }) titleJustify: string;

	icon: boolean = false;
	innerVisible = false;
	elementClick: HTMLElement = null;
	specialization: any = [];
	titleMarginLeft: string = "";

	@Watch("visible")
	onChangeVisible() {
		this.innerVisible = this.visible;
	}
	// @Watch("innerVisible")
	// onChangeVisible(visible: boolean) {
	//   this.innerVisible = visible;
	// }

	created() {
		if (this.titleJustify == "center") {
			this.titleMarginLeft = "0px";
		}
	}

	private hasSlotData() {
		return this.$slots.button;
	}
	private async getHandlerResult(
		handler: () => boolean | (() => Promise<boolean>)
	): Promise<boolean> {
		return new Promise<boolean>(async (resolve) => {
			if (handler != null) {
				const resultOrPromise = handler();
				const isPromise = resultOrPromise instanceof Promise;
				let result: boolean = null;
				if (isPromise) {
					result = await (resultOrPromise as boolean);
				} else {
					result = resultOrPromise as boolean;
				}
				resolve(result);
				return;
			}
			resolve(true);
		});
	}
	private async closeWindow(handler?: () => boolean | (() => Promise<boolean>)) {
		//console.log("close");

		const isClose = await this.getHandlerResult(handler);
		if (!isClose) {
			return;
		}
		this.innerVisible = false;

		return false;
	}
	private async onCloseBtnClicked() {
		await this.closeWindow(this.closeHandler);
	}
	private async onCancel() {
		this.$emit("onCancel", false);
		await this.closeWindow(this.closeHandler);
	}
	private async onOk() {
		this.$emit("onOk", true);
		if (this.autoClose) {
			await this.closeWindow(this.okHandler);
		}
		return false;
	}
	private async onKeyDown(evt: any) {
		if (this.innerVisible) {
			evt = evt || window.event;
			var isEscape = false;
			if ("key" in evt) {
				isEscape = evt.key === "Escape" || evt.key === "Esc";
			} else {
				isEscape = evt.keyCode === 27;
			}
			if (isEscape) {
				await this.onCancel();
			}
		}
	}
	private getWindowsType(type: StatusWindow) {
		var value = "";
		switch (type) {
			case 1:
				//text
				value = "";
				break;
			case 2:
				//error
				value = "icon-icon_error";
				break;
			case 3:
				//warning
				value = "icon-icon_warning";
				break;
			case 4:
				//info
				value = "icon-icon_info";
				break;
			default:
				break;
		}

		return value;
	}
	private mouseDownWrapper(ev: MouseEvent) {
		this.elementClick = ev.target as HTMLElement;
	}
	private mouseUpWrapper(ev: MouseEvent) {
		const wrapper = document.getElementById("modal-wrapper");
		if (this.elementClick == wrapper && ev.target == wrapper) {
			this.$emit("clickOutside");
		}
	}

	show() {
		this.innerVisible = true;
	}
	async hide() {
		await this.closeWindow(this.closeHandler);
	}
}
