
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'css-group',
  inheritAttrs: false,
  props: {
    name: { type: String, required: true },
  },
});
