
import ModelPersonBalance from "@/api/main/services/NaturalPersonProfile/models/response/GetNewUser/ModelPersonBalance";
import * as RoutesNames from "../../../../router/routerNames";
import { Vue, Options, Prop } from "vue-property-decorator";
import NaturalPersonModel from "@/views/models/NaturalPersonModel";
@Options({
	name: "account-section",
})
export default class AccountSection extends Vue {
	@Prop({ type: Object, default: null }) balance: ModelPersonBalance;
	@Prop({ type: Object, default: null }) profile: NaturalPersonModel;

	get locked() {
		return this.balance.DateBlocking !== null || this.balance.DateClosed !== null;
	}

	goToTransaction() {
		console.log("goToTransaction");
		this.$router.push({
			name: RoutesNames.TRANSUCTIONS,
		});
	}
	transfer() {
		this.$router.push({
			name: RoutesNames.TRANSFERS,
		});
	}

	openReplenish() {
		this.$emit(
			// this.profile.PaymentsType != 4 ? "openReplenish" : "openReplenishAcquiring"
			"openReplenishAcquiring"
		);
	}
}
