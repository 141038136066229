import { normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-64771b2b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "img_wrapper" }
const _hoisted_2 = {
  key: 1,
  class: "error_message"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_crop_image = _resolveComponent("ui-crop-image")!
  const _component_ui_modal_window = _resolveComponent("ui-modal-window")!
  const _directive_localization = _resolveDirective("localization")!

  return (_openBlock(), _createBlock(_component_ui_modal_window, {
    title: "Сфотографировать",
    visible: _ctx.visibility,
    onOnOk: _ctx.sendPhoto,
    onClickOutside: _ctx.closeModal,
    onOnCancel: _ctx.closeModal,
    titleJustify: "flex-start",
    titleBtnYes: _ctx.buttonOkText,
    titleBtnNo: _ctx.$localization.getTranslate('Cancel'),
    buttonsJustify: !_ctx.showError ? 'space-between' : 'center',
    autoClose: false,
    showOkBtn: !_ctx.showError
  }, {
    default: _withCtx(() => [
      (_ctx.imgSrc.length == 0)
        ? (_openBlock(), _createElementBlock("video", {
            key: 0,
            src: "",
            class: "modal_camera_video",
            ref: "video",
            style: _normalizeStyle({ height: _ctx.showError ? '0px' : 'unset' })
          }, null, 4))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_1, [
        (_ctx.imgSrc.length != 0)
          ? (_openBlock(), _createBlock(_component_ui_crop_image, {
              key: 0,
              class: "modal_camera_img",
              ref: "img",
              "image-src": _ctx.imgSrc,
              onUpdate: _ctx.onCropUpdate,
              "max-height": 480,
              "max-width": 640
            }, null, 8, ["image-src", "onUpdate"]))
          : _createCommentVNode("", true)
      ]),
      (_ctx.showError)
        ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, null, 512)), [
            [_directive_localization, 'NoCamera']
          ])
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["visible", "onOnOk", "onClickOutside", "onOnCancel", "titleBtnYes", "titleBtnNo", "buttonsJustify", "showOkBtn"]))
}