
import { Vue, Options, Prop, Watch } from "vue-property-decorator";
import UiDatePicker from "../../../components/ui-datetime-picker/ui-datetime-picker.vue";
import ProfileModel from "../../../models/ProfileModel";
import IdentificationModel from "../models/IdentificationModel";
import UiFileInpit from "../../../components/ui-file-input/ui-file-input.vue";
import "../../../../plugins/jasny-bootstrap.min.js";
import $ = require("jquery");
import moment = require("moment");
import ModelStreamFile from "@/api/main/dataSource/api/i-public-service/aba-finance/models/ModelStreamFile";
import DirectorItemModel from "../../user-identification/models/DirecorItemModel";

@Options({
	name: "identification",
	components: { UiDatePicker, UiFileInpit },
})
export default class Identification extends Vue {
	@Prop({ default: null, type: Object }) profile!: ProfileModel;

	PassportNumber: string = "";
	PassportIssuedBy: string = "";
	PassportIssuedDate: string = null;
	PassportCountryCode: string = "";
	ScanPassport: ModelStreamFile = null;
	ScanUtilityBill: ModelStreamFile = null;
	Street: string = "";
	IndexCode: string = "";
	ErrorMessage: string = "";
	CountryCode: string = "";
	State: string = "";
	State1: string = "";
	City: string = "";
	country: string = "";

	// syncPassport(e: any) {
	// 	e.preventDefault();
	// 	var self = this;
	// 	var files = e.target.files;
	// 	$.each(files, function (i, file: File) {
	// 		self.ScanPassport = file;
	// 	});
	// }

	base64StringScanPassport = "";
	base64StringScanUtilityBill = "";

	mounted() {
		this.City = this.profile.AddressCity;
		this.Street = this.profile.AddressStreet;
		this.IndexCode = this.profile.PostCode;
		this.PassportNumber = this.profile.DocumentNumber;
		this.State = this.profile.Country;
		this.country = this.profile.Country;
	}
	onAddScan(isSide1: boolean, file) {
		if (isSide1) {
			this.ScanPassport = new ModelStreamFile({
				FileBase64: file.fileBase64,
				FileName: file.name,
			});
			return;
		}
		this.ScanUtilityBill = new ModelStreamFile({
			FileBase64: file.fileBase64,
			FileName: file.name,
		});
	}

	deleteFile(value: ModelStreamFile) {
		value.FileBase64 = "";
		value.FileName = "";
	}
	syncPassport(event) {
		
		const file = event.target.files[0]; // Получаем файл из input
		if (!file) {
			return; // Если файл не выбран, выходим из функции
		}
		const reader = new FileReader(); // Создаем экземпляр FileReader для чтения файла
		// Обработчик, который срабатывает после завершения чтения файла
		reader.onload = (e) => {
			this.ScanPassport = new ModelStreamFile({
				ContentLength: file.size,
				FileName: file.name,
			}); // Содержимое файла в формате base64
		};

		reader.readAsDataURL(file); // Начинаем чтение файла с преобразованием в формат Data URL
	}
	syncUtilityBill(event) {
		const file = event.target.files[0]; // Получаем файл из input
		if (!file) {
			return; // Если файл не выбран, выходим из функции
		}
		const reader = new FileReader(); // Создаем экземпляр FileReader для чтения файла
		// Обработчик, который срабатывает после завершения чтения файла
		reader.onload = (e) => {
			this.ScanUtilityBill = new ModelStreamFile({
				FileBase64: e.target.result.toString(),
				ContentLength: file.size,
				FileName: file.name,
			}); // Содержимое файла в формате base64
		};

		reader.readAsDataURL(file); // Начинаем чтение файла с преобразованием в формат Data URL
	}

	selectPassportCountryCode(code: string) {
		this.PassportCountryCode = code;
	}

	selectCountryCode(code: string) {
		this.CountryCode = code;
	}

	GoNext() {
		var validation = this.Validation();
		if (validation) {
			var date = new Date(this.PassportIssuedDate);
			let identification = new IdentificationModel({
				PassportNumber: this.PassportNumber,
				PassportIssuedBy: this.PassportIssuedBy,
				PassportIssuedDate: moment(date).format(),
				PassportCountryCode: this.PassportCountryCode,
				ScanPassport: this.ScanPassport,
				ScanUtilityBill: this.ScanUtilityBill,
				Street: this.Street,
				CountryCode: this.CountryCode,
				City: this.City,
				State: this.State1,
				IndexCode: this.IndexCode,
			});
			this.$emit("SetIdentification", identification);
		} else return;
	}
	Validation() {
		var russian = /^[а-яА-Я]*$/;
		if (
			!(
				this.PassportNumber != "" &&
				this.ScanPassport !== null &&
				this.ScanUtilityBill !== null &&
				this.Street !== "" &&
				this.CountryCode !== "" &&
				this.City !== "" &&
				this.IndexCode !== ""
			)
		) {
			this.ErrorMessage = "Fill in all the fields";
			return false;
		} else if (
			russian.test(this.PassportNumber) ||
			russian.test(this.Street) ||
			//   russian.test(this.HouseNumber) ||
			russian.test(this.CountryCode) ||
			russian.test(this.City) ||
			russian.test(this.IndexCode)
		) {
			this.ErrorMessage = "The text should consist only of English letters";
			return false;
		}
		this.ErrorMessage = "";
		return true;
	}
	onSubmit() {
		return false;
	}
}
