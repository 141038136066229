import { WebApiService } from '../../../../../plugins/webApiService'
import { Events } from '../../../../../plugins/events'
import { SendRequestModel } from '../../../../../plugins/models/SendRequestModel'
import LoginRequest from '../../../../../aba/public/services/models/login/LoginRequest';
import CheckUniqueEmailRequest from '../../../../../aba/public/services/models/registration/CheckUniqueEmailRequest';
import RegistertrationStartRequest from '../../../../../aba/public/services/models/registration/RegistertrationStartRequest';
import RegistrationRequest from '../../../../../aba/public/services/models/registration/RegistrationRequest';
import RecoverPasswordRequestRequest from '../../../../../aba/public/services/models/restore-password/RecoverPasswordRequestRequest';
import RestorePasswordRequest from '../../../../../aba/public/services/models/restore-password/RestorePasswordRequest';
import ConfirmCodeRequest from '../../../../../aba/public/services/models/security/ConfirmCodeRequest';
import TwoFactorConfirmCodeRequest from '../../../../../aba/public/services/models/login/TwoFactorConfirmCodeRequest';
import WsResponseModelT from '../../../../../schema-builder/server/models/WsResponseModelT';
import CurrentUserResponse from '../../../../../aba/cabinet/web/models/CurrentUserResponse';
import LoginResponse from '../../../../../aba/public/services/models/login/LoginResponse';
import TwoFactorConfirmCodeResponse from '../../../../../aba/public/services/models/login/TwoFactorConfirmCodeResponse';

export default class AuthWsController {
	private url?: string;
	private cookieName: string;
	private appWebSettingsPath?: string;
	private apiUrlProperty?: string;

	GetCurrentUserEvent = new Events<WsResponseModelT<CurrentUserResponse>>();
	LoginEvent = new Events<WsResponseModelT<LoginResponse>>();
	LogOutEvent = new Events<WsResponseModelT<Boolean>>();
	CheckUniqueEmalEvent = new Events<WsResponseModelT<Boolean>>();
	RegisterStartEvent = new Events<WsResponseModelT<Boolean>>();
	RegisterEndEvent = new Events<WsResponseModelT<Boolean>>();
	RecoverPasswordRequestEvent = new Events<WsResponseModelT<Boolean>>();
	RecoverPasswordEvent = new Events<WsResponseModelT<Boolean>>();
	TwoFactorAuthenticationEvent = new Events<WsResponseModelT<Boolean>>();
	ConfrimCodeEvent = new Events<WsResponseModelT<TwoFactorConfirmCodeResponse>>();

	subscribeEvents = async () => {
		const self = this;
		const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
		webApiService.on('AuthWsController', 'GetCurrentUser', 'Main', (res: WsResponseModelT<CurrentUserResponse>) => {
			self.GetCurrentUserEvent.trigger(res);
		});
		webApiService.on('AuthWsController', 'Login', 'Main', (res: WsResponseModelT<LoginResponse>) => {
			self.LoginEvent.trigger(res);
		});
		webApiService.on('AuthWsController', 'LogOut', 'Main', (res: WsResponseModelT<Boolean>) => {
			self.LogOutEvent.trigger(res);
		});
		webApiService.on('AuthWsController', 'CheckUniqueEmal', 'Main', (res: WsResponseModelT<Boolean>) => {
			self.CheckUniqueEmalEvent.trigger(res);
		});
		webApiService.on('AuthWsController', 'RegisterStart', 'Main', (res: WsResponseModelT<Boolean>) => {
			self.RegisterStartEvent.trigger(res);
		});
		webApiService.on('AuthWsController', 'RegisterEnd', 'Main', (res: WsResponseModelT<Boolean>) => {
			self.RegisterEndEvent.trigger(res);
		});
		webApiService.on('AuthWsController', 'RecoverPasswordRequest', 'Main', (res: WsResponseModelT<Boolean>) => {
			self.RecoverPasswordRequestEvent.trigger(res);
		});
		webApiService.on('AuthWsController', 'RecoverPassword', 'Main', (res: WsResponseModelT<Boolean>) => {
			self.RecoverPasswordEvent.trigger(res);
		});
		webApiService.on('AuthWsController', 'TwoFactorAuthentication', 'Main', (res: WsResponseModelT<Boolean>) => {
			self.TwoFactorAuthenticationEvent.trigger(res);
		});
		webApiService.on('AuthWsController', 'ConfrimCode', 'Main', (res: WsResponseModelT<TwoFactorConfirmCodeResponse>) => {
			self.ConfrimCodeEvent.trigger(res);
		});
	}

	constructor(url?: string, cookieName?: string, appWebSettingsPath?: string, apiUrlProperty?: string) {
		this.url = url;
		this.cookieName = cookieName ?? "Auth-Token";
		this.appWebSettingsPath = appWebSettingsPath && [location.host, appWebSettingsPath].join("/");
		this.apiUrlProperty = apiUrlProperty;
		this.subscribeEvents();
	}

	async GetCurrentUser() {
		const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
		const sendRequest = new SendRequestModel({
			ProjectName: 'Main',
			Controller: 'AuthWsController',
			Method: 'GetCurrentUser',
		})

		webApiService.send(sendRequest);
	}

	async Login(request: LoginRequest) {
		const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
		const sendRequest = new SendRequestModel({
			ProjectName: 'Main',
			Controller: 'AuthWsController',
			Method: 'Login',
			Value: typeof(request) === 'object' ? JSON.stringify(request) : request,
		})

		webApiService.send(sendRequest);
	}

	async LogOut() {
		const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
		const sendRequest = new SendRequestModel({
			ProjectName: 'Main',
			Controller: 'AuthWsController',
			Method: 'LogOut',
		})

		webApiService.send(sendRequest);
	}

	async CheckUniqueEmal(request: CheckUniqueEmailRequest) {
		const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
		const sendRequest = new SendRequestModel({
			ProjectName: 'Main',
			Controller: 'AuthWsController',
			Method: 'CheckUniqueEmal',
			Value: typeof(request) === 'object' ? JSON.stringify(request) : request,
		})

		webApiService.send(sendRequest);
	}

	async RegisterStart(request: RegistertrationStartRequest) {
		const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
		const sendRequest = new SendRequestModel({
			ProjectName: 'Main',
			Controller: 'AuthWsController',
			Method: 'RegisterStart',
			Value: typeof(request) === 'object' ? JSON.stringify(request) : request,
		})

		webApiService.send(sendRequest);
	}

	async RegisterEnd(request: RegistrationRequest) {
		const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
		const sendRequest = new SendRequestModel({
			ProjectName: 'Main',
			Controller: 'AuthWsController',
			Method: 'RegisterEnd',
			Value: typeof(request) === 'object' ? JSON.stringify(request) : request,
		})

		webApiService.send(sendRequest);
	}

	async RecoverPasswordRequest(request: RecoverPasswordRequestRequest) {
		const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
		const sendRequest = new SendRequestModel({
			ProjectName: 'Main',
			Controller: 'AuthWsController',
			Method: 'RecoverPasswordRequest',
			Value: typeof(request) === 'object' ? JSON.stringify(request) : request,
		})

		webApiService.send(sendRequest);
	}

	async RecoverPassword(request: RestorePasswordRequest) {
		const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
		const sendRequest = new SendRequestModel({
			ProjectName: 'Main',
			Controller: 'AuthWsController',
			Method: 'RecoverPassword',
			Value: typeof(request) === 'object' ? JSON.stringify(request) : request,
		})

		webApiService.send(sendRequest);
	}

	async TwoFactorAuthentication(request: ConfirmCodeRequest) {
		const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
		const sendRequest = new SendRequestModel({
			ProjectName: 'Main',
			Controller: 'AuthWsController',
			Method: 'TwoFactorAuthentication',
			Value: typeof(request) === 'object' ? JSON.stringify(request) : request,
		})

		webApiService.send(sendRequest);
	}

	async ConfrimCode(request: TwoFactorConfirmCodeRequest) {
		const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
		const sendRequest = new SendRequestModel({
			ProjectName: 'Main',
			Controller: 'AuthWsController',
			Method: 'ConfrimCode',
			Value: typeof(request) === 'object' ? JSON.stringify(request) : request,
		})

		webApiService.send(sendRequest);
	}

	 // async methods
	async getCurrentUserAsync(): Promise<CurrentUserResponse> {
		return new Promise<CurrentUserResponse>(async (resolve, reject) => {
			const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
			const sendRequest = new SendRequestModel({
				ProjectName: 'Main',
				Controller: 'AuthWsController',
				Method: 'GetCurrentUser',
				Callback: (response: WsResponseModelT<CurrentUserResponse>) => {
					const isGeneric = typeof response.IsSuccess == 'boolean'
					const result = response.IsSuccess ? response.Value : response

					if (!isGeneric || response.IsSuccess) resolve(result as CurrentUserResponse)
					else reject(result)
				}
			})

			webApiService.send(sendRequest);
		});
	}

	async loginAsync(request: LoginRequest): Promise<LoginResponse> {
		return new Promise<LoginResponse>(async (resolve, reject) => {
			const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
			const sendRequest = new SendRequestModel({
				ProjectName: 'Main',
				Controller: 'AuthWsController',
				Method: 'Login',
				Value: typeof(request) === 'object' ? JSON.stringify(request) : request,
				Callback: (response: WsResponseModelT<LoginResponse>) => {
					const isGeneric = typeof response.IsSuccess == 'boolean'
					const result = response.IsSuccess ? response.Value : response

					if (!isGeneric || response.IsSuccess) resolve(result as LoginResponse)
					else reject(result)
				}
			})

			webApiService.send(sendRequest);
		});
	}

	async logOutAsync(): Promise<Boolean> {
		return new Promise<Boolean>(async (resolve, reject) => {
			const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
			const sendRequest = new SendRequestModel({
				ProjectName: 'Main',
				Controller: 'AuthWsController',
				Method: 'LogOut',
				Callback: (response: WsResponseModelT<Boolean>) => {
					const isGeneric = typeof response.IsSuccess == 'boolean'
					const result = response.IsSuccess ? response.Value : response

					if (!isGeneric || response.IsSuccess) resolve(result as Boolean)
					else reject(result)
				}
			})

			webApiService.send(sendRequest);
		});
	}

	async checkUniqueEmalAsync(request: CheckUniqueEmailRequest): Promise<Boolean> {
		return new Promise<Boolean>(async (resolve, reject) => {
			const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
			const sendRequest = new SendRequestModel({
				ProjectName: 'Main',
				Controller: 'AuthWsController',
				Method: 'CheckUniqueEmal',
				Value: typeof(request) === 'object' ? JSON.stringify(request) : request,
				Callback: (response: WsResponseModelT<Boolean>) => {
					const isGeneric = typeof response.IsSuccess == 'boolean'
					const result = response.IsSuccess ? response.Value : response

					if (!isGeneric || response.IsSuccess) resolve(result as Boolean)
					else reject(result)
				}
			})

			webApiService.send(sendRequest);
		});
	}

	async registerStartAsync(request: RegistertrationStartRequest): Promise<Boolean> {
		return new Promise<Boolean>(async (resolve, reject) => {
			const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
			const sendRequest = new SendRequestModel({
				ProjectName: 'Main',
				Controller: 'AuthWsController',
				Method: 'RegisterStart',
				Value: typeof(request) === 'object' ? JSON.stringify(request) : request,
				Callback: (response: WsResponseModelT<Boolean>) => {
					const isGeneric = typeof response.IsSuccess == 'boolean'
					const result = response.IsSuccess ? response.Value : response

					if (!isGeneric || response.IsSuccess) resolve(result as Boolean)
					else reject(result)
				}
			})

			webApiService.send(sendRequest);
		});
	}

	async registerEndAsync(request: RegistrationRequest): Promise<Boolean> {
		return new Promise<Boolean>(async (resolve, reject) => {
			const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
			const sendRequest = new SendRequestModel({
				ProjectName: 'Main',
				Controller: 'AuthWsController',
				Method: 'RegisterEnd',
				Value: typeof(request) === 'object' ? JSON.stringify(request) : request,
				Callback: (response: WsResponseModelT<Boolean>) => {
					const isGeneric = typeof response.IsSuccess == 'boolean'
					const result = response.IsSuccess ? response.Value : response

					if (!isGeneric || response.IsSuccess) resolve(result as Boolean)
					else reject(result)
				}
			})

			webApiService.send(sendRequest);
		});
	}

	async recoverPasswordRequestAsync(request: RecoverPasswordRequestRequest): Promise<Boolean> {
		return new Promise<Boolean>(async (resolve, reject) => {
			const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
			const sendRequest = new SendRequestModel({
				ProjectName: 'Main',
				Controller: 'AuthWsController',
				Method: 'RecoverPasswordRequest',
				Value: typeof(request) === 'object' ? JSON.stringify(request) : request,
				Callback: (response: WsResponseModelT<Boolean>) => {
					const isGeneric = typeof response.IsSuccess == 'boolean'
					const result = response.IsSuccess ? response.Value : response

					if (!isGeneric || response.IsSuccess) resolve(result as Boolean)
					else reject(result)
				}
			})

			webApiService.send(sendRequest);
		});
	}

	async recoverPasswordAsync(request: RestorePasswordRequest): Promise<Boolean> {
		return new Promise<Boolean>(async (resolve, reject) => {
			const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
			const sendRequest = new SendRequestModel({
				ProjectName: 'Main',
				Controller: 'AuthWsController',
				Method: 'RecoverPassword',
				Value: typeof(request) === 'object' ? JSON.stringify(request) : request,
				Callback: (response: WsResponseModelT<Boolean>) => {
					const isGeneric = typeof response.IsSuccess == 'boolean'
					const result = response.IsSuccess ? response.Value : response

					if (!isGeneric || response.IsSuccess) resolve(result as Boolean)
					else reject(result)
				}
			})

			webApiService.send(sendRequest);
		});
	}

	async twoFactorAuthenticationAsync(request: ConfirmCodeRequest): Promise<Boolean> {
		return new Promise<Boolean>(async (resolve, reject) => {
			const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
			const sendRequest = new SendRequestModel({
				ProjectName: 'Main',
				Controller: 'AuthWsController',
				Method: 'TwoFactorAuthentication',
				Value: typeof(request) === 'object' ? JSON.stringify(request) : request,
				Callback: (response: WsResponseModelT<Boolean>) => {
					const isGeneric = typeof response.IsSuccess == 'boolean'
					const result = response.IsSuccess ? response.Value : response

					if (!isGeneric || response.IsSuccess) resolve(result as Boolean)
					else reject(result)
				}
			})

			webApiService.send(sendRequest);
		});
	}

	async confrimCodeAsync(request: TwoFactorConfirmCodeRequest): Promise<TwoFactorConfirmCodeResponse> {
		return new Promise<TwoFactorConfirmCodeResponse>(async (resolve, reject) => {
			const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
			const sendRequest = new SendRequestModel({
				ProjectName: 'Main',
				Controller: 'AuthWsController',
				Method: 'ConfrimCode',
				Value: typeof(request) === 'object' ? JSON.stringify(request) : request,
				Callback: (response: WsResponseModelT<TwoFactorConfirmCodeResponse>) => {
					const isGeneric = typeof response.IsSuccess == 'boolean'
					const result = response.IsSuccess ? response.Value : response

					if (!isGeneric || response.IsSuccess) resolve(result as TwoFactorConfirmCodeResponse)
					else reject(result)
				}
			})

			webApiService.send(sendRequest);
		});
	}

}