import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-543eb81f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "main_inputs_block" }
const _hoisted_2 = { class: "inputs_block" }
const _hoisted_3 = { class: "inputs_block_header" }
const _hoisted_4 = { class: "inputs" }
const _hoisted_5 = { class: "inputs_block" }
const _hoisted_6 = { class: "inputs_block_header" }
const _hoisted_7 = { class: "inputs" }
const _hoisted_8 = { class: "inputs_block" }
const _hoisted_9 = { class: "inputs_block_header" }
const _hoisted_10 = { class: "inputs" }
const _hoisted_11 = {
  key: 0,
  class: "inputs_block"
}
const _hoisted_12 = { class: "inputs_block_header" }
const _hoisted_13 = { key: 0 }
const _hoisted_14 = { class: "continue_block" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_input = _resolveComponent("ui-input")!
  const _component_ui_datetime_picker = _resolveComponent("ui-datetime-picker")!
  const _component_ui_select_autocomplete = _resolveComponent("ui-select-autocomplete")!
  const _component_ui_company_document = _resolveComponent("ui-company-document")!
  const _component_ui_company_document_adding = _resolveComponent("ui-company-document-adding")!
  const _component_continue_button = _resolveComponent("continue-button")!
  const _component_ui_message_error = _resolveComponent("ui-message-error")!
  const _component_loading = _resolveComponent("loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$localization.getTranslate("Header.Company.CompanyInfo").toUpperCase()), 1),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_ui_input, {
          readOnly: "true",
          headerText: _ctx.$localization.getTranslate('Header.CompanyName'),
          class: "indentification-input",
          modelValue: _ctx.companyDataNew.CompanyName
        }, null, 8, ["headerText", "modelValue"]),
        _createVNode(_component_ui_input, {
          headerText: _ctx.$localization.getTranslate('Header.RegisterNumber') + '*',
          class: "indentification-input",
          isWrong: _ctx.checkStart && _ctx.companyDataNew.RegistrationNumber == '0',
          modelValue: _ctx.companyDataNew.RegistrationNumber,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.companyDataNew.RegistrationNumber) = $event))
        }, null, 8, ["headerText", "isWrong", "modelValue"]),
        _createVNode(_component_ui_datetime_picker, {
          headerText: _ctx.$localization.getTranslate('Header.RegisterDate') + '*',
          class: "indentification-input",
          modelValue: _ctx.companyDataNew.IncorporationDate,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.companyDataNew.IncorporationDate) = $event)),
          isWrong: _ctx.checkStart && _ctx.companyDataNew.IncorporationDate == null
        }, null, 8, ["headerText", "modelValue", "isWrong"])
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$localization.getTranslate("Header.Company.RegisteredAddress").toUpperCase()), 1),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_ui_select_autocomplete, {
          isWrong: _ctx.checkStart && _ctx.companyDataNew.AddressCountryCode.length == 0,
          modelValue: _ctx.companyDataNew.AddressCountryCode,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.companyDataNew.AddressCountryCode) = $event)),
          keyField: "value",
          valueField: "text",
          items: _ctx.countries,
          class: "indentification-input",
          headerText: _ctx.$localization.getTranslate('Header.RegisterCountry') + '*',
          noUndefined: true
        }, null, 8, ["isWrong", "modelValue", "items", "headerText"]),
        _createVNode(_component_ui_input, {
          headerText: _ctx.$localization.getTranslate('Header.StateRegion'),
          class: "indentification-input",
          modelValue: _ctx.companyDataNew.AddressState,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.companyDataNew.AddressState) = $event))
        }, null, 8, ["headerText", "modelValue"]),
        _createVNode(_component_ui_input, {
          headerText: _ctx.$localization.getTranslate('Header.City') + '*',
          class: "indentification-input",
          modelValue: _ctx.companyDataNew.AddressCity,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.companyDataNew.AddressCity) = $event)),
          isWrong: _ctx.checkStart && _ctx.companyDataNew.AddressCity.length == 0
        }, null, 8, ["headerText", "modelValue", "isWrong"]),
        _createVNode(_component_ui_input, {
          headerText: _ctx.$localization.getTranslate('Header.Postcode') + '*',
          class: "indentification-input",
          modelValue: _ctx.companyDataNew.AddressPostalCode,
          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.companyDataNew.AddressPostalCode) = $event)),
          isWrong: _ctx.checkStart && _ctx.companyDataNew.AddressPostalCode.length == 0
        }, null, 8, ["headerText", "modelValue", "isWrong"]),
        _createVNode(_component_ui_input, {
          headerText: _ctx.$localization.getTranslate('Header.Street') + '*',
          class: "indentification-input",
          isWrong: _ctx.checkStart && _ctx.companyDataNew.AddressStreet.length == 0,
          modelValue: _ctx.companyDataNew.AddressStreet,
          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.companyDataNew.AddressStreet) = $event))
        }, null, 8, ["headerText", "isWrong", "modelValue"]),
        _createVNode(_component_ui_input, {
          headerText: _ctx.$localization.getTranslate('Header.BuildingNumber') + '*',
          class: "indentification-input",
          isWrong: _ctx.checkStart && _ctx.companyDataNew.AddressHome.length == 0,
          modelValue: _ctx.companyDataNew.AddressHome,
          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.companyDataNew.AddressHome) = $event))
        }, null, 8, ["headerText", "isWrong", "modelValue"]),
        _createVNode(_component_ui_input, {
          headerText: _ctx.$localization.getTranslate('Header.OfficeApartment'),
          class: "indentification-input",
          modelValue: _ctx.companyDataNew.AddressApartment,
          "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.companyDataNew.AddressApartment) = $event))
        }, null, 8, ["headerText", "modelValue"])
      ])
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.$localization.getTranslate("Header.Company.ContactInfo").toUpperCase()), 1),
      _createElementVNode("div", _hoisted_10, [
        _createVNode(_component_ui_input, {
          headerText: _ctx.$localization.getTranslate('Header.Website'),
          class: "indentification-input",
          modelValue: _ctx.companyDataNew.Website,
          "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.companyDataNew.Website) = $event))
        }, null, 8, ["headerText", "modelValue"]),
        _createVNode(_component_ui_input, {
          headerText: _ctx.$localization.getTranslate('Header.Email'),
          readOnly: "true",
          class: "indentification-input",
          modelValue: _ctx.companyDataNew.Email,
          "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.companyDataNew.Email) = $event))
        }, null, 8, ["headerText", "modelValue"]),
        _createVNode(_component_ui_input, {
          readOnly: "true",
          headerText: _ctx.$localization.getTranslate('Header.Phone'),
          class: "indentification-input",
          modelValue: _ctx.companyDataNew.Phone,
          "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.companyDataNew.Phone) = $event))
        }, null, 8, ["headerText", "modelValue"])
      ])
    ]),
    (_ctx.companyDataNew)
      ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
          _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.$localization.getTranslate("Header.Documents").toUpperCase()), 1),
          (_ctx.companyDataNew.CompanyDocuments[0])
            ? (_openBlock(), _createBlock(_component_ui_company_document, {
                key: 0,
                isWrong: 
					_ctx.checkStart &&
					!(
						(_ctx.companyDataNew.CompanyDocuments.find((x) => x.Type == 0)?.File &&
							_ctx.companyDataNew.CompanyDocuments.find((x) => x.Type == 0)?.FileName) ||
						_ctx.companyDataNew.CompanyDocuments.find((x) => x.Type == 0)?.FileUrl
					)
				,
                class: "indentification_file",
                document: _ctx.companyDataNew.CompanyDocuments[0],
                headerText: 
					_ctx.$localization.getTranslate(_ctx.setHeader(_ctx.companyDataNew.CompanyDocuments[0].Type)) +
					'*'
				,
                onDeleteFile: _ctx.onDeleteDocument,
                showFile: 
					(_ctx.companyDataNew.CompanyDocuments.find((x) => x.Type == 0)?.File &&
						_ctx.companyDataNew.CompanyDocuments.find((x) => x.Type == 0)?.FileName) ||
					_ctx.companyDataNew.CompanyDocuments.find((x) => x.Type == 0)?.FileUrl
				,
                buttonText: 
					(_ctx.companyDataNew.CompanyDocuments.find((x) => x.Type == 0)?.File &&
						_ctx.companyDataNew.CompanyDocuments.find((x) => x.Type == 0)?.FileName) ||
					_ctx.companyDataNew.CompanyDocuments.find((x) => x.Type == 0)?.FileUrl
						? `${_ctx.$localization.getTranslate('Identification.EditCompanyDocumentBtn')}`
						: `${_ctx.$localization.getTranslate('Button.AddDocument')}`
				
              }, null, 8, ["isWrong", "document", "headerText", "onDeleteFile", "showFile", "buttonText"]))
            : _createCommentVNode("", true),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.companyDataNew.CompanyDocuments, (doc, index) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "extra_doc",
              key: index
            }, [
              (index > 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                    _createVNode(_component_ui_company_document, {
                      class: "indentification_file",
                      document: doc,
                      headerText: _ctx.$localization.getTranslate(_ctx.setHeader(doc.Type)),
                      onDeleteFile: _ctx.onDeleteDocument,
                      showFile: true
                    }, null, 8, ["document", "headerText", "onDeleteFile"])
                  ]))
                : _createCommentVNode("", true)
            ]))
          }), 128)),
          _createVNode(_component_ui_company_document_adding, {
            onAddedFile: _ctx.addedFile,
            documents: _ctx.companyDataNew.CompanyDocuments
          }, null, 8, ["onAddedFile", "documents"]),
          _createElementVNode("div", _hoisted_14, [
            (!_ctx.isLoading)
              ? (_openBlock(), _createBlock(_component_continue_button, {
                  key: 0,
                  class: "company_continue",
                  onClicked: _ctx.saveAndContinue
                }, null, 8, ["onClicked"]))
              : _createCommentVNode("", true),
            _createVNode(_component_ui_message_error, { class: "ml-2" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString((_ctx.errorMessage ? "*" : "") + _ctx.errorMessage), 1)
              ]),
              _: 1
            }),
            (_ctx.isLoading)
              ? (_openBlock(), _createBlock(_component_loading, {
                  key: 1,
                  class: "continue_loading"
                }))
              : _createCommentVNode("", true)
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}