
import { Vue, Options } from "vue-property-decorator";
import BeginningWork from "./components/BeginningWork.vue";
import PersonalData from "./components/PersonalData.vue";
import Identification from "./components/Identification.vue";
import IdentificationLiveness from "./components/Liveness.vue";
import IdentifiactionWaiting from "./components/IdentificationWaiting.vue";
import ProfileModel from "../../models/ProfileModel";
import IdentificationModel from "./models/IdentificationModel";
import SetProfileModel from "./models/SetProfileModel";
import * as Routes from "../../../router/routerNames";
import { AdapterNaturalIdentificationService } from "./adapter";
import PersonalIdentificationData from "@/api/main/dataSource/api/i-public-service/aba-finance/models/user/identification/PersonalIdentificationData";
import { AdapterProfileService } from "../profile/adapter";
import PassportIdentificationData from "@/api/main/dataSource/api/i-public-service/aba-finance/models/user/identification/PassportIdentificationData";
import { AdapterApiService } from "../_adapter";
import EnumTypeIdentified from "@/api/main/dataSource/api/aba/data/models/models/EnumTypeIdentified";
import { useRouter } from "vue-router";
@Options({
	name: "naturalperson-profile-identification",
	components: {
		BeginningWork,
		PersonalData,
		Identification,
		IdentificationLiveness,
		IdentifiactionWaiting,
	},
})
export default class ProfileIdentificationNatural extends Vue {
	Step: number = 1;
	Profile: ProfileModel = null;
	IsLoad: boolean = false;
	ErrorMessageInd: string = "test";
	adapter = AdapterNaturalIdentificationService.getInstance();
	errorMessage = "";
	router = useRouter();
	async mounted() {
		const isConfirmed =
			AdapterApiService.getInstance().userInfo.value.IdentifiedStatus ==
			EnumTypeIdentified.Confirmed;
		if (isConfirmed) {
			this.router.push({ name: Routes.BALANCE });
			return;
		}
		this.Profile = new ProfileModel();
		await this.GetProfile();
	}
	async GoNext() {
		if (this.Step === 5) {
			const res = await this.adapter.getUserIdentification();
			if (res.IsSuccess) {
				// this.Profile = res as any) as ProfileModel;
				this.Profile.Country = res.Value.AddressCountry;
				this.Profile.Code = "999"; //TODO нету в апи
				this.Profile.City = res.Value.AddressCity;
				this.Profile.Street = res.Value.AddressStreet;
				this.Profile.HouseNumber = "88"; //TODO нету в апи
				this.Profile.ExtraBedForAnApartment = "888"; //TODO нету в апи
				this.Profile.PostCode = res.Value.AddressZip;
				this.Step++;
			}
		} else {
			this.Step++;
		}
	}
	async SetProfile(profile: SetProfileModel) {
		this.Profile.FirstName = profile.FirstName;
		this.Profile.SecondName = profile.SecondName;
		this.Profile.Appeal = profile.Appeal;
		this.Profile.BirthPlace = profile.BirthPlace;
		this.Profile.BirthDate = new Date(profile.BirthDate);

		// var formData = new FormData();
		// formData.append("UserId", profile.UserId.toString());
		// formData.append("FirstName", profile.FirstName);
		// formData.append("SecondName", profile.SecondName);
		// formData.append("Appeal", profile.Appeal);
		// formData.append("BirthPlace", profile.BirthPlace);
		// formData.append("BirthDate", profile.BirthDate);
		const req = this.adapter.mapSetProfileModelToPersonalIdentificationData(profile);
		const res = await this.adapter.savePersonalIdentificationData(req);
		if (res.IsSuccess) this.Step++;
	}

	async SetIdentification(identification: IdentificationModel) {
		this.Profile.PassportNumber = identification.PassportNumber;
		var formData = new FormData();
		// formData.append("scanPassport", identification.ScanPassport);
		// formData.append("scanUtilityBill", identification.ScanUtilityBill);
		// formData.append("DocumentNumber", identification.PassportNumber);
		// formData.append("DocumentIssuedBy", identification.PassportIssuedBy);
		// formData.append("DocumentIssuedDate", identification.PassportIssuedDate);
		// formData.append("DocumentIssuedCountryCode", identification.PassportCountryCode);
		// formData.append("AddressCountry", identification.CountryCode);
		// formData.append("AddressState", identification.State);
		// formData.append("AddressStreet", identification.Street);
		// formData.append("AddressCity", identification.City);
		// formData.append("AddressZip", identification.IndexCode);

		const req = this.adapter.comvertIdentificationModelToPassportIdentificationData(
			identification
		);

		const res = await this.adapter.savePassportIdentificationData(req);
		if (res.IsSuccess) {
			this.Step++;
			return;
		}
		this.errorMessage = res.ErrorMessage;
	}
	OnLivenessComplete() {
		this.Step++;
	}

	async GetProfile() {
		const res = await this.adapter.getUserIdentification();
		const adapter = AdapterProfileService.getInstance();

		adapter.GetNaturalPerson();
		const pr = adapter.naturalPerson.value;
		if (res.IsSuccess) {
			// this.Profile = adapter.convertModelNaturalPersonToProfilModel(pr);
			const tt = adapter.convertModelPersonIdentificationToProfilModel(res.Value);
			this.Profile = tt;
			this.SetStep();
		}
		// this.$api.Main.NaturalPersonProfileService.GetUserIdentification((res) => {
		// 	this.Profile = (res as any) as ProfileModel;
		// 	console.log("get profile", this.Profile);
		// 	this.SetStep();
		// });
	}
	SetStep() {
		if (this.Profile.Identified == 2) {
			this.Step = 5;
			this.UpdateIdentification();
			return;
		}
		let searchParams = new URLSearchParams(window.location.search);
		if (
			searchParams.get("errorStart") ||
			searchParams.get("error") ||
			searchParams.get("success")
		) {
			this.Step = 4;
			return;
		}

		var self = this;
		if (
			self.Profile.FirstName !== "" &&
			self.Profile.SecondName &&
			self.Profile.BirthDate
		) {
			if (self.Profile.Identified == 2) {
				//идентифицирован
				console.log("идентифицирован");
				this.UpdateIdentification();
			} else if (self.Profile.Identified == 1) {
				//на рассмотрении
				console.log("на рассмотрении");
				self.Step = 5; //на рассмотрении
			} else self.Step = 3; //данные документа
		} else {
			self.Step = 1;
		}
	}
	UpdateIdentification() {
		//TODO нет Api
		// this.$api.Main.NaturalPersonProfileService.ChangeIdentifiedNewUser((res) => {
		// 	console.log("redirect");
		// 	this.$router.push({ name: Routes.NATURALPERSON_PROFILE });
		// });
	}
	generateUrl(url: string) {
		var arr = url.split("/");
		var newUrl = "";
		arr.forEach((string: string, i: number) => {
			if (i != 1 && i != 0) newUrl = newUrl + "/" + string;
		});
		return "/" + this.$localization.currentLang + newUrl;
	}
}
