import LegalCompanyDocumentSaveRequest from '../../../identification-services/models/legal-company-document/LegalCompanyDocumentSaveRequest';

export default class LegalCompanySaveDataRequest  {
	LegalUserId!: number;
	RegistrationNumber!: string;
	Website!: string;
	IncorporationDate!: Date;
	AddressCountryCode!: string;
	AddressState!: string;
	AddressCity!: string;
	AddressPostalCode!: string;
	AddressStreet!: string;
	AddressHome!: string;
	AddressApartment!: string;
	CompanyDocumentsIdsForDelete!: Array<number>;
	CompanyDocumentsIdsForCreate!: Array<LegalCompanyDocumentSaveRequest>;
	constructor(obj?: Partial<LegalCompanySaveDataRequest>) {
		if (obj) {
			Object.assign(this, obj);
		}
	}
}
