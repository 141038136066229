import ApiService from '@/api/index';
import CurrentUserResponse from '@/api/main/dataSource/api/aba/cabinet/web/models/CurrentUserResponse';

import { notify } from '@/app/notifications';
import { Ref, ref } from 'vue';
import ErrorModel from './models/errorModel';
import moment from 'moment';
import AcquiringPayoutTemplateListResponse from '@/api/main/dataSource/api/transaction-services/models/transfer-template-service/AcquiringPayoutTemplateListResponse';
import WsResponseModel from '@/api/main/SchemaBuilderServer/Models/WsResponseModel';
import GetTransactionsRequest from '@/api/main/dataSource/api/i-public-service/aba-finance/models/transaction/GetTransactionsRequest';
import GetTransactionsResponse from '@/api/main/dataSource/api/i-public-service/aba-finance/models/transaction/GetTransactionsResponse';
import ModelTransaction from '@/api/main/dataSource/api/i-public-service/aba-finance/models/ModelTransaction';
import MoneyTransactionPersonItem from '@/api/main/dataSource/api/transaction-services/models/MoneyTransactionPersonItem';
import HistoryItemModel from '@/api/main/services/NaturalPersonPayments/models/HistoryItemModel';
import { Id } from '@/app/notifications/util';
import TransactionPersonRequest from '@/api/main/dataSource/api/transaction-services/models/TransactionPersonRequest';
import MoneyTransactionPersonResponse from '@/api/main/dataSource/api/transaction-services/models/MoneyTransactionPersonResponse';
import GetNaturalPersonResponse from '@/api/main/services/NaturalPersonHome/models/response/GetNaturalPersonResponse';
import NaturalPersonModel from '@/views/models/NaturalPersonModel';
import MoneyTransactionPersonItemModel from '@/api/main/services/NaturalPersonInternalBalance/models/MoneyTransactionPersonItem/MoneyTransactionPersonItem';
import EnumTransactionType from '@/api/main/models/Enums/EnumTransactionType';
import UpdateCardTransactionModel from '@/api/main/services/NaturalPersonPayments/models/UpdateCardTransactionModel';
import axios from 'axios';
import ModelNaturalPerson from '@/api/main/dataSource/api/i-public-service/aba-finance/models/user/ModelNaturalPerson';
import UserType from '@/api/main/models/Enums/UserType';
import EnumTypeUser from '@/api/main/models/Enums/EnumTypeUser';
import DownloadTransactionsForNaturalRequest from '@/api/main/dataSource/api/i-public-service/aba-finance/models/transaction/DownloadTransactionsForNaturalRequest';
import ModelPersonCard from '@/api/main/services/NaturalPersonProfile/models/response/GetNewUser/ModelPersonCard';
import ModelPersonBalance from '@/api/main/dataSource/api/i-public-service/aba-finance/models/ModelPersonBalance';
import { PersonModel } from '@/views/models';
import ModelLegalPerson from '@/api/main/dataSource/api/i-public-service/aba-finance/models/user/ModelLegalPerson';

export default class AdapterTransactionService {

  /**
 * Сервис
 */
  private static instance: AdapterTransactionService;
  /**
 * API
 */
  private static api: ApiService;
  isLoading: boolean = false;
  isInited = ref(false);
  api: ApiService;
  Person: Ref<PersonModel> = ref(new PersonModel());

  constructor() {
    AdapterTransactionService.api = new ApiService();
  }

  apiClient = axios.create({
    baseURL: 'https://beta-cabinet.swipez.eu', // Базовый URL для вашего API
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });

  public static getInstance() {
    if (!AdapterTransactionService.instance) {
      AdapterTransactionService.instance = new AdapterTransactionService();
    }
    return AdapterTransactionService.instance;
  }


  async getCurrentUser(): Promise<WsResponseModel<CurrentUserResponse>> {
    const result = new WsResponseModel<CurrentUserResponse>();
    try {
      const resp = await AdapterTransactionService.api.Main.AuthWsController.getCurrentUserAsync();
      result.Value = resp;
      result.IsSuccess = true;
    } catch (e) {
      return this.handleError(e, "ERROR getCurrentUser")
    }
    return result;
  }

  dateToString(req: Date): string {
    return moment(req).format("DD.MM.YYYY HH:mm")
  }

  /**
 * NaturalPersonPaymentsService.GetHistory
 * @returns 
 */
  async getHistory(req: GetTransactionsRequest): Promise<WsResponseModel<GetTransactionsResponse>> {
    const result = new WsResponseModel<GetTransactionsResponse>();
    try {
      const resp = await AdapterTransactionService.api.Main.NaturalPaymentsWsController.getHistoryAsync(req);
      result.Value = resp;
      result.IsSuccess = true;
    } catch (e) {
      this.handleError(e, "ERROR NaturalPaymentsWsController.getHistoryAsync")
    }
    return result;
  }


  mapMoneyModelTransactionToTransactionPersonItem(items: ModelTransaction[]): HistoryItemModel[] {
    const res = items.map(el => new HistoryItemModel({
      Id: el.Id,
      Amount: el.Amount,
      Currency: el.Currency,
      CurrencyName: el.CurrencyName,
      DateCreate: this.dateToString(el.DateCreate),
      DateCreateEnd: this.dateToString(el.DateCreateEnd),
      DateCreateJs: el.DateCreateJs, DateCreateStart: this.dateToString(el.DateCreateStart),
      DateCreateString: el.DateCreateString, FromCardNumber: el.FromCardNumber, FromCardNumberLegalPerson: el.FromCardNumberLegalPerson,
      FromName: el.FromName, FromNameLegalPerson: el.FromCardNumberLegalPerson, FromWhomId: el.FromWhomId,
      FromWhomLegalPersonId: el.FromWhomLegalPersonId, IBAN: el.IBAN, IsDefaultTransaction: el.IsDefaultTransaction,
      PurposeOfPayment: el.PurposeOfPayment, Search: el.Search, Status: el.Status, ToCardNumber: el.ToCardNumber,
      ToId: el.ToId,
      ToName: el.ToName,
      TypeTransactionId: el.TypeTransactionId,
      TypeTransactionName: el.TypeTransactionName,
    }))

    return res
  }
  mapMoneyTransactionPersonItemToTransactionPersonItem(items: MoneyTransactionPersonItem[]): MoneyTransactionPersonItemModel[] {
    const res = items.map(el => new MoneyTransactionPersonItemModel({
      Id: el.Id,
      Amount: el.Amount,
      Currency: el.Currency,
      CurrencyName: el.CurrencyName,
      Status: el.Status,
      AcquiringPayout: el.AcquiringPayout,
      Card: el.Card,
      CheckResultType: el.CheckResultType,
      CheckWarnResolve: el.CheckWarnResolve,
      CheckWarnType: el.CheckWarnType,
      Crypt: el.Crypt,
      Date: this.dateToString(el.Date,), Description: el.Description,
      ErrorMessage: el.ErrorMessage,
      FromUserIban: el.FromUserIban,
      FromUserId: el.FromUserId, FromUserName: el.FromUserName,
      Sepa: el.Sepa,
      Swift: el.Swift, ToUserIban: el.ToUserIban,
      ToUserId: el.ToUserId,
      ToUserName: el.ToUserName,
      Type: el.Type as any,
    }))

    return res
  }

  toNaturalPerson(value: GetNaturalPersonResponse): NaturalPersonModel {
    return new NaturalPersonModel(value);
  }
  mapModelNaturalPersontoNaturalPerson(req: ModelNaturalPerson): NaturalPersonModel {
    return new NaturalPersonModel({
      AcquiringCardMask: req.AcquiringCardMask, Address: req.Address,
      AllowMassTransfers: req.AllowMassTransfers, Appeal: req.Appeal,
      Balance: req.Balance,
      BirthDate: this.dateToString(req.BirthDate),
      CardId: req.CardId,
      Cards: req.Cards, CompanyName: req.CompanyName, ConfrimHash: req.ConfrimHash,
      DateLastMessage: this.dateToString(req.DateLastMessage), DateViewMessage: this.dateToString(req.DateLastMessage),
      Email: req.Email,
      Id: req.Id,
      Identified: req.Identified,
      IdentifiedId: req.IdentifiedId,
      IsConfirmPhone: req.IsConfirmPhone,
      IsHaveCards: req.IsHaveCards, IsTwoFactorAuthentication: req.IsTwoFactorAuthentication,
      LastName: req.LastName,
      Login: req.Login, Name: req.Name, NumberCard: req.NumberCard, NumberPassport: req.NumberPassport,
      Password: req.Password, PaymentsType: req.PaymentsType, Phone: req.Phone, RegistrationAddress: req.RegistrationAddress,
      Role: req.Role, Search: req.Search, SearchFilter: req.AcquiringCardMask, Token: req.Token, TypeIdentified: req.TypeIdentified, TypeUser: req.TypeUser,
      TypeUserId: this.convertUserTypeToEnumTypeUser(req.TypeUserId)
      
      
    });
  }

  convertUserTypeToEnumTypeUser(req: UserType): EnumTypeUser{
    let result = EnumTypeUser.LegalPerson;
    if (req == UserType.Natural)
    {
      result = EnumTypeUser.NaturalPerson
    }
    return result
  }
  /**
   * getInternalTransactionsNew
   * @returns 
   */
  async getInternalTransactionsNew(req: TransactionPersonRequest): Promise<WsResponseModel<MoneyTransactionPersonResponse>> {
    const result = new WsResponseModel<MoneyTransactionPersonResponse>();
    try {
      const resp = await AdapterTransactionService.api.Main.InternalBalanceWsController.getInternalTransactionsNewAsync(req);
      result.Value = resp;
      result.IsSuccess = true;
    } catch (e) {
      this.handleError(e, "ERROR getInternalTransactionsNew")
    }
    return result;
  }
  //aturalPersonBalanceService.DownloadTransactions
  //NaturalPersonPaymentsService.UpdateCardTransaction
  /**
   * образец
   * @returns 
   */
  async updateCardTransaction(req: Date): Promise<WsResponseModel<Array<ModelTransaction>>> {
    const result = new WsResponseModel<Array<ModelTransaction>>();
    try {
      const resp = await AdapterTransactionService.api.Main.NaturalPaymentsWsController.updateCardTransactionAsync(req);
      result.Value = resp;
      result.IsSuccess = true;
    } catch (e) {
      this.handleError(e, "ERROR updateCardTransaction")
    }
    return result;
  }

  mapModelTransactionToUpdateCardTransactionModel(req: ModelTransaction[]): UpdateCardTransactionModel[] {
    const res = req.map(el => new UpdateCardTransactionModel({
      Amount: el.Amount,
      Currency: el.Currency,
      CurrencyName: el.CurrencyName,
      DateCreate: this.dateToString(el.DateCreate),
      DateCreateEnd: this.dateToString(el.DateCreateEnd),
      DateCreateJs: el.DateCreateJs, DateCreateStart: this.dateToString(el.DateCreateStart),
      DateCreateString: el.DateCreateString, FromCardNumber: el.FromCardNumber, FromCardNumberLegalPerson: el.FromCardNumberLegalPerson,
      FromName: el.FromName, FromNameLegalPerson: el.FromCardNumberLegalPerson, FromWhomId: el.FromWhomId,
      FromWhomLegalPersonId: el.FromWhomLegalPersonId, IBAN: el.IBAN, IsDefaultTransaction: el.IsDefaultTransaction,
      PurposeOfPayment: el.PurposeOfPayment, Search: el.Search, Status: el.Status, ToCardNumber: el.ToCardNumber,
      ToId: el.ToId,
      ToName: el.ToName,
      TypeTransactionId: el.TypeTransactionId,
      TypeTransactionName: el.TypeTransactionName, Id: el.Id
    }))
    return res
  }







  /**
   * rest //TODO надо проверять на момет написания не работало api
   * @returns 
   */
  async downloadTransactionsForLegal(req: { type: number, dateTo: string, dateFrom: string }): Promise<WsResponseModel<any>> {
    const result = new WsResponseModel<any>();
    try {

      const resp = await this.apiClient.post('/FileController/DownloadTransactionsForLegal', req); // GET запрос к https://api.example.com/users
      result.Value = resp.data;
      result.IsSuccess = true;
    } catch (e) {
      this.handleError(e, "ERROR downloadTransactionsForLegal")
    }
    return result;
  }

  /**
   
   * @returns 
   */
  async downloadTransactionsForNatural(req: DownloadTransactionsForNaturalRequest): Promise<WsResponseModel<String>> {
    const result = new WsResponseModel<String>();
    try {
      const res = await AdapterTransactionService.api.Main.FileWsController.downloadTransactionsForNaturalAsync(req);
      result.Value = res;
      result.IsSuccess = true;
    } catch (e) {
      return this.handleError(e, "ERROR downloadTransactionsForNatural")
    }
    return result;
  }

  async getPerson(): Promise<PersonModel> {
    if (this.Person?.value?.Id) {
      return this.Person.value
    }
    const res = await this.getCurrentUser();
    if (!res.IsSuccess) {
      return
    }
    if (res.Value.UserRole == UserType.Natural) {
      await this.getNaturalPerson();
      return this.Person.value
    }
    await this.getLegalPerson();
    return this.Person.value
  }

  async getNaturalPerson(): Promise<WsResponseModel<ModelNaturalPerson>> {
    const result = new WsResponseModel<ModelNaturalPerson>();
    try {
      const resp = await AdapterTransactionService.api.Main.ProfileWsController.getNaturalPersonAsync();
      result.Value = resp;
      this.Person.value = new PersonModel({
        Balance: new ModelPersonBalance(resp.Balance),
        Cards: resp.Cards.map(el => new ModelPersonCard(el)),
        CompanyName: resp.CompanyName, Email: resp.Email, Id: resp.Id, Identified: resp.Identified,
        Name: resp.Name,
        LastName: resp.LastName,
        Phone: resp.Phone,
        PaymentsType: resp.PaymentsType,
        Role: resp.Role,
        TypeIdentified: resp.TypeIdentified,
        TypeUser: EnumTypeUser.LegalPerson,
      })
      result.IsSuccess = true;
    } catch (e) {
      return this.handleError(e, "ERROR getNaturalPerson")
    }
    return result;
  }

  async getLegalPerson(): Promise<WsResponseModel<ModelLegalPerson>> {
    const result = new WsResponseModel<ModelLegalPerson>();
    try {
      const resp = await AdapterTransactionService.api.Main.ProfileWsController.getLegalPersonAsync();
      result.Value = resp;
      this.Person.value = new PersonModel({
        Balance: new ModelPersonBalance(resp.Balance),
        Cards: resp.Cards.map(el => new ModelPersonCard(el)),
        CompanyName: resp.CompanyName, Email: resp.Email, Id: resp.Id,
        Identified: resp.IdentifiedId,
        Name: resp.Name,
        LastName: resp.LastName,
        Phone: resp.Phone,
        PaymentsType: resp.PaymentsType,
        Role: resp.Role,
        TypeIdentified: resp.TypeIdentified,
        TypeUser: EnumTypeUser.LegalPerson,
      })
      result.IsSuccess = true;
    } catch (e) {
      return this.handleError(e, "ERROR getLegalPerson")
    }
    return result;
  }
  /**
   * образец
   * @returns 
   */
  async getAcquiringTemplates(): Promise<WsResponseModel<AcquiringPayoutTemplateListResponse>> {
    const result = new WsResponseModel<AcquiringPayoutTemplateListResponse>();
    try {
      const resp = await AdapterTransactionService.api.Main.NaturalPaymentsWsController.getAcquiringTemplatesAsync();
      result.Value = resp;
      result.IsSuccess = true;
    } catch (e) {
      this.handleError(e, "ERROR getAcquiringTemplates")
    }
    return result;
  }

  /**
   * Универсальный обработчик ошибок
   * @param e 
   * @param context 
   * @returns 
   */
  handleError(e: any, context: string): WsResponseModel<any> {
    console.error(`ERROR ${context}`, e);
    return {
      IsSuccess: false,
      ErrorCode: e?.ErrorCode || 500,
      ErrorMessage: e?.ErrorMessage || 'Internal Server Error',
      Value: null
    };
  }
}
