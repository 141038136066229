import VirtpayPayoutRequest from '../../../../virtpay-transactions-services/models/payout/VirtpayPayoutRequest';

export default class VirtpayPayoutHttpRequest  {
	Payout!: VirtpayPayoutRequest;
	Pin!: string;
	constructor(obj?: Partial<VirtpayPayoutHttpRequest>) {
		if (obj) {
			Object.assign(this, obj);
		}
	}
}
