import EnumTypeIdentified from "../../../../models/Enums/EnumTypeIdentified"
import EnumTypeUser from "../../../../models/Enums/EnumTypeUser";
import ModelPersonBalance from "../../../NaturalPersonProfile/models/response/GetNewUser/ModelPersonBalance";
import ModelPersonCard from "../../../NaturalPersonProfile/models/response/GetNewUser/ModelPersonCard";
import ModelRegisteredAddress from "../../../NaturalPersonProfile/models/response/GetNewUser/ModelRegisteredAddress";

export default class GetNaturalPersonResponse {
  Identified: EnumTypeIdentified 
  IdentifiedId: number;
  ConfrimHash: string = null
  IsConfirmPhone: boolean = false;
  CardId?: number = null
  NumberCard: string = null
  NumberPassport: string = null;
  RegistrationAddress: ModelRegisteredAddress = null
  SearchFilter: string = null
  Search: string = null
  Appeal: string = null
  Balance: ModelPersonBalance = new ModelPersonBalance();
  Cards: Array<ModelPersonCard> = null;
  BirthDate: string = null
  AcquiringCardMask: string = null;
  Id: number = 0
  Email: string = null
  Login: string = null
  Password: string = null
  Phone: string = null
  Role: string = null
  Name: string = null
  CompanyName: string = null
  Address: string = null
  LastName: string = null
  TypeUser: string = null
  DateViewMessage: string = null
  DateLastMessage: string = null
  TypeUserId: EnumTypeUser = EnumTypeUser.NaturalPerson
  TypeIdentified: EnumTypeIdentified = 0
  Token: string = null
  IsTwoFactorAuthentication: boolean = false;
  IsHaveCards: boolean = false;
  PaymentsType: EnumUserPaymentsType = 0;
  AllowMassTransfers: boolean = false;

  constructor(obj?: Partial<GetNaturalPersonResponse>) {
    if (obj) {
      Object.assign(this, obj)
    }
  }
}
