
import SwiftTemplateModel from "@/api/main/services/Swift/models/SwiftTemplateModel";
import NaturalPersonModel from "@/views/models/NaturalPersonModel";
import { Vue, Options, Prop, Watch } from "vue-property-decorator";
@Options({
	name: "swift",
})
export default class Swift extends Vue {
	@Prop({ type: Object, default: null }) profile: NaturalPersonModel;
	@Prop({ type: Number, default: null }) Type: number;
	@Prop({ type: Object, default: null }) SelectedTemplate: SwiftTemplateModel;

	Recipient: string = "";
	IBAN: string = "";
	BeneficiaryAddress: string = "";
	BeneficiaryCity: string = "";
	BeneficiaryCountryCode: string = "";
	BeneficiaryPostalCode: string = "";
	BeneficiaryState: string = "";
	BeneficiaryType: string = ""; //тип получателя 1 - персона 2-компания
	Amount: string = "";
	PurposePayment: string = "";
	SwiftCode: string = "";
	ErrorMessage: string = "";
	Name: string = "";
	Commission: number = 0;

	itemsBeneficiaryType: Array<string> = ["Person", "Company"];

	IsSave: boolean = false;
	IsLoad: boolean = false;

	@Watch("SelectedTemplate", { deep: true })
	onChangeTemplate() {
		if (!this.SelectedTemplate) return;
		this.IBAN = this.SelectedTemplate.BeneficiaryIban;
		this.Amount = this.SelectedTemplate.Amount?.toString();
		this.PurposePayment = this.SelectedTemplate.Description;
		this.Recipient = this.SelectedTemplate.BeneficiaryName;
		this.SwiftCode = this.SelectedTemplate.SwiftCode;
		this.BeneficiaryAddress = this.SelectedTemplate.BeneficiaryAddress;
		this.selectBeneficiaryCountryCode(this.SelectedTemplate.BeneficiaryCountryCode);
		this.BeneficiaryCity = this.SelectedTemplate.BeneficiaryCity;
		this.BeneficiaryPostalCode = this.SelectedTemplate.BeneficiaryPostalCode;
		this.BeneficiaryState = this.SelectedTemplate.BeneficiaryState;
		this.BeneficiaryType =
			this.SelectedTemplate.BeneficiaryType === 1
				? "Person"
				: this.SelectedTemplate.BeneficiaryType === 2
				? "Company"
				: "";
	}

	selectBeneficiaryCountryCode(code: string) {
		this.BeneficiaryCountryCode = code;
	}

	getCommission() {
		var validation = this.Validation();
		if (!validation) return;

		var formData = new FormData();
		formData.append("amount", this.$store.state.getAmountForSend(this.Amount));

		// this.$api.Main.SwiftService.GetCommissionForSwiftPayment((res) => {
		// 	if (res.IsSuccess) {
		// 		this.Commission = res.Value;
		// 		this.InitTransfer();
		// 	}
		// }, formData);//TODO Api
	}
	InitTransfer() {
		if (this.IsLoad) return false;
		this.ErrorMessage = "";
		var validation = this.Validation();
		if (!validation) return;

		var formData = new FormData();
		formData.append("ToIban", this.IBAN);
		formData.append("Amount", this.$store.state.getAmountForSend(this.Amount)),
			formData.append("Description", this.PurposePayment);
		//@ts-ignore
		formData.append("SaveTemplate", this.IsSave);
		formData.append("TemplateName", this.Name);
		formData.append("ToName", this.Recipient);
		formData.append("SwiftCode", this.SwiftCode);
		formData.append("Commission", this.Commission.toString());
		formData.append("BeneficiaryCountryCode", this.BeneficiaryCountryCode);
		formData.append("BeneficiaryAddress", this.BeneficiaryAddress);
		formData.append("BeneficiaryCity", this.BeneficiaryCity);
		formData.append("BeneficiaryPostalCode", this.BeneficiaryPostalCode);
		formData.append("BeneficiaryState", this.BeneficiaryState);
		formData.append("BeneficiaryType", this.BeneficiaryType);

		this.IsLoad = true;
		// this.$api.Main.SwiftService.CheckSwiftTransfer((res) => {
		//   this.IsLoad = false;
		//   if (res.IsSuccess) {
		//     this.ErrorMessage = "";
		//     this.$emit("GoTransferSwift", formData);
		//   } else {
		//     this.ErrorMessage = res.Message;
		//   }
		// }, formData);//TODO Api
	}
	Validation() {
		var russian = /^[а-яА-Я]*$/;
		var country = this.IBAN.substring(0, 2);
		if (
			this.IBAN == "" ||
			this.Amount.toString() == "" ||
			this.PurposePayment == "" ||
			this.Recipient == "" ||
			this.BeneficiaryAddress == "" ||
			this.SwiftCode == "" ||
			this.BeneficiaryCountryCode == "" ||
			this.BeneficiaryCity == "" ||
			this.BeneficiaryPostalCode == "" ||
			this.BeneficiaryState == "" ||
			this.BeneficiaryType == "" ||
			((this.Name == undefined || this.Name == "") && this.IsSave)
		) {
			this.ErrorMessage = "Fill in all the fields";
			return false;
		} else if (this.IBAN == this.profile.Balance.Number) {
			this.ErrorMessage = "Please enter a different account number";
			return false;
		} else if (/[0-9]/.test(country[0]) || /[0-9]/.test(country[1])) {
			this.ErrorMessage =
				"Must contain the first two characters of the designation of the country";
			return false;
		} else if (
			russian.test(this.PurposePayment) ||
			russian.test(this.Recipient) ||
			russian.test(this.SwiftCode) ||
			russian.test(this.BeneficiaryAddress) ||
			russian.test(this.BeneficiaryCountryCode) ||
			russian.test(this.BeneficiaryCity) ||
			russian.test(this.BeneficiaryPostalCode) ||
			russian.test(this.BeneficiaryState) ||
			russian.test(this.BeneficiaryType) ||
			russian.test(country)
		) {
			this.ErrorMessage = "The text should consist only of English letters";
			return false;
		}
		return true;
	}
}
