export default class AccountModel {
  Recipient: string = null;
  AccountNumber: string = null;
  Amount: string = null;
  PurposePayment: string = null;

  constructor(obj?: Partial<AccountModel>) {
    if (obj) {
      Object.assign(this, obj)
    }
  }
}