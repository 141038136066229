
import { Vue, Options, Prop, Watch } from "vue-property-decorator";
import ContinueButton from "./../components/continue-button.vue";
import DirectorItemModel from "./../models/DirecorItemModel";
import ModalCamera from "./../components/modal-camera/modal-camera.vue";
import { Countries } from "@/models/const/countries";
import EnumApiErrors from "@models/enums/EnumApiError";
import BeneficiaryInfoModel from "../models/BeneficiaryInfoModel";
import UploadPhotoCropRequest from "@/api/main/dataSource/api/file-services/models/UploadPhotoCropRequest";
import LegalDirectorsGetDataResponse from "@/api/main/dataSource/api/identification-services/models/legal-directors/LegalDirectorsGetDataResponse";
import { AdapterApiService } from "../../_adapter";
import { AdapterIdentificationService } from '../../profile-identification/adapter';

@Options({
	components: {
		"continue-button": ContinueButton,
		"modal-camera": ModalCamera,
	},
	emits: ["check", "end", "changeTab"],
})
export default class DirectorTab extends Vue {
	@Prop({ default: null }) userId: number;

	adapter = AdapterApiService.getInstance();
	beneficiaries: Array<string> = Array<string>();
	photoDirector: DirectorItemModel;
	visibility: boolean = false;
	Directors: Array<DirectorItemModel> = [];
	TitleOptions: string[] = ["Mr", "Mrs", "Miss"];
	countries = Countries;
	documents: Array<{ doc: string }> = new Array<{ doc: string }>();
	errorText: string = "";
	isLoading: boolean = false;
	errorMessage: string = "";
	checkStart: boolean = false;

	@Watch("errorText")
	onUpdateButtonText(newValue: string) {
		if (newValue.length) {
			this.errorMessage = this.$localization.getTranslate(`EnumApiError.${newValue}`);
		}
	}

	onUpdatePhone(value: string, id: number) {
		if (value.length) {
			this.Directors[id].Phone = "+" + value.replaceAll("+", "");
		}
		if (this.Directors[id].Phone == "+") {
			this.Directors[id].Phone = "";
		}
	}

	async setBeneficiarData(value: string, id: number) {
		const a = AdapterIdentificationService.getInstance();
		// 
		if (!a.BeneficiaryInfoModel) {
			await a.getBeneficiaryData();
		}
		let beneficiary = a.BeneficiaryInfoModel.BeneficiaryInfo.find(
			(b) => b.FirstName + " " + b.SecondName == value
		);
		
		// let beneficiary: BeneficiaryInfoModel = this.$store.state.beneficiaryData.BeneficiaryInfo.find(
		// 	(b) => b.FirstName + " " + b.SecondName == value
		// );
		if (beneficiary) {
			this.Directors[id] = new DirectorItemModel(beneficiary);
			this.Directors[id].Id = null;
			this.Directors[id].DocumentExpireDate = beneficiary.DocumentDateOfExpiry;
			this.Directors[id].DocumentIssuedDate = beneficiary.DocumentDateOfIssue;
			this.Directors[id].DocumentIssuedCountryCode = beneficiary.DocumentCountryCode;
			this.Directors[id].DocumentIssuedBy = beneficiary.DocumentAuthority;
			this.Directors[id].DocumentFile = new UploadPhotoCropRequest({
				FileName: "photo.jpg",
			});
			this.Directors[id].ApproveOfAddressFile = new UploadPhotoCropRequest({
				FileName: "photo.jpg",
			});
			if (beneficiary.DocumentFileUrl.length) {
				this.toBase64(beneficiary.DocumentFileUrl).then((dataUrl: string) => {
					this.Directors[id].DocumentFile.FileBase64 = dataUrl.substr(
						dataUrl.indexOf("base64,") + "base64,".length
					);
				});
			}
			if (beneficiary.ApproveOfAddressFileUrl.length) {
				this.toBase64(beneficiary.DocumentFileUrl).then((dataUrl: string) => {
					this.Directors[id].ApproveOfAddressFile.FileBase64 = dataUrl.substr(
						dataUrl.indexOf("base64,") + "base64,".length
					);
				});
			}
		}
	}

	async mounted() {
		
		this.documents = [
			{ doc: this.$localization.getTranslate("Passport") },
			{ doc: "ID" },
		];
		await this.getDirectorsData();
		// if (this.$store.state.beneficiaryData) {
		// 	this.$store.state.beneficiaryData.BeneficiaryInfo.forEach((b) => {
		// 		this.beneficiaries.push(b.FirstName + " " + b.SecondName);
		// 	});
		// }

		const a = AdapterIdentificationService.getInstance();
		
		if (!a.BeneficiaryInfoModel) {
			await a.getBeneficiaryData();
		}

		a.BeneficiaryInfoModel.BeneficiaryInfo.forEach((b) => {
				this.beneficiaries.push(b.FirstName + " " + b.SecondName);
			});

		// if (this.$store.state.directorsData) {
		// 	this.checkStart = true;
		// 	this.Directors = this.$store.state.directorsData;
		// } else {
		// 	await this.getDirectorsData();
		// }
	}

	async getDirectorsData() {
		const res = await this.adapter.getLegalDirectorsAsync();
		if (!res.IsSuccess) {
			return
		}
		this.OnGetLegalDirectorsSuccess(res.Value);
	}

	OnAddedFile(director: DirectorItemModel, file) {
		director.FileUrl = null;
		director.File = new UploadPhotoCropRequest({
			FileName: file.name,
			FileBase64: file.fileBase64,
		});
	}

	deleteDocumentFile(director: DirectorItemModel) {
		director.DocumentFileUrl = "";
		if (director.DocumentFile) {
			director.DocumentFile = null;
		}
	}

	deleteApproveOfAddressFile(director: DirectorItemModel) {
		director.ApproveOfAddressFileUrl = "";
		if (director.ApproveOfAddressFile) {
			director.ApproveOfAddressFile = null;
		}
	}

	onAddApproveOfAdressDocument(director: DirectorItemModel, file) {
		director.ApproveOfAddressFileUrl = "";
		director.ApproveOfAddressFile = new UploadPhotoCropRequest({
			FileName: file.name,
			FileBase64: file.fileBase64,
		});
	}
	onAddDocumentFile(director: DirectorItemModel, file) {
		director.DocumentFileUrl = "";
		director.DocumentFile = new UploadPhotoCropRequest({
			FileName: file.name,
			FileBase64: file.fileBase64,
		});
	}
	onClearFile(director: DirectorItemModel) {
		director.FileUrl = null;
		director.File = new UploadPhotoCropRequest();
	}
	takePhoto(photo: UploadPhotoCropRequest) {
		this.photoDirector.FileUrl = null;
		this.photoDirector.File = photo;
	}
	removeDirector(director: DirectorItemModel) {
		this.Directors = this.Directors.filter((i) => i != director);
	}
	catchError(err) {
		if (EnumApiErrors[err.ErrorCode]) {
			this.errorText = EnumApiErrors[err.ErrorCode];
		} else {
			this.errorText = "ErrorOccured";
		}
		this.$emit("check", 2, false);
		this.isLoading = false;
	}

	OnGetLegalDirectorsSuccess(response: LegalDirectorsGetDataResponse) {
		this.Directors = response.LegalDirectors.map((i) => new DirectorItemModel(i));
		if (!this.Directors.length) this.Directors.push(new DirectorItemModel());
		// this.$store.state.setDirectorsData(this.Directors);

	}
	closeModal() {
		this.visibility = false;
	}

	openModal(photoDirector: DirectorItemModel) {
		this.photoDirector = photoDirector;
		this.visibility = true;
	}

	addDirector() {
		this.Directors.push(new DirectorItemModel());
		let block = document.querySelector(".main_target").getBoundingClientRect();
		let needableScroll: number = block.height + block.y;
		if (document.documentElement.offsetWidth <= 991) {
			needableScroll -= 90;
		} else {
		}
		setTimeout(() => {
			window.scrollBy({
				top: needableScroll,
				behavior: "smooth",
			});
		});
	}
	async saveAndContinue() {
		this.checkStart = true;
		if (this.Directors.map((x) => x.isValid()).filter((x) => x == false).length == 0) {
			this.errorText = "";
			await this.saveDirectorData();
		} else {
			this.errorText = "IncompleteInformation";
			this.$emit("check", 2, false);
		}
	}

	async saveDirectorData() {
		this.isLoading = true;
		await this.adapter
			.saveLegalDirectorsDataAsync({
				LegalPersonId: AdapterApiService.getInstance().userInfo.value.Id,
				LegalDirectors: this.Directors.map((i) => i.toLegalDirectorsSaveResponse()),
			})
			.then(this.OnSaveLegalDirectorsSuccess)
			.catch(this.catchError);
	}

	async OnSaveLegalDirectorsSuccess() {
		// this.$store.state.setDirectorsData(null);
		// this.getDirectorsData();
		
		this.$emit("changeTab", 3);
		this.$emit("check", 5, true);
		this.isLoading = false;
	}

	toBase64 = (url) =>
		fetch(url)
			.then((response) => response.blob())
			.then(
				(blob) =>
					new Promise((resolve, reject) => {
						const reader = new FileReader();
						reader.onloadend = () => resolve(reader.result);
						reader.onerror = reject;
						reader.readAsDataURL(blob);
					})
			);
}
