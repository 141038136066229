import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-2ac3f17a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "transfer" }
const _hoisted_2 = { class: "title_box mb-4" }
const _hoisted_3 = { class: "mb-3" }
const _hoisted_4 = { class: "form_box bank_form main_form" }
const _hoisted_5 = { class: "field_wrapper" }
const _hoisted_6 = { class: "field_box" }
const _hoisted_7 = { class: "field form-group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_select = _resolveComponent("ui-select")!
  const _component_ui_textarea = _resolveComponent("ui-textarea")!
  const _component_aba_button_right = _resolveComponent("aba-button-right")!
  const _component_ui_message_success = _resolveComponent("ui-message-success")!
  const _component_modal_fullscreen = _resolveComponent("modal-fullscreen")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.$localization.getTranslate("Support")), 1),
        _createTextVNode(" " + _toDisplayString(_ctx.Person?.Balance.Number || "----------------"), 1)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_ui_select, {
            style: { minWidth: `400px`, marginBottom: `24px` },
            items: _ctx.items,
            modelValue: _ctx.typeMessage,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.typeMessage) = $event)),
            onSelectedIndex: _ctx.selectedIndex
          }, null, 8, ["items", "modelValue", "onSelectedIndex"]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_component_ui_textarea, {
                modelValue: _ctx.Message,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.Message) = $event)),
                placeholder: _ctx.$localization.getTranslate('YourQuestion')
              }, null, 8, ["modelValue", "placeholder"])
            ])
          ])
        ]),
        _createVNode(_component_aba_button_right, {
          onClick: _ctx.SendMessage,
          isDisabled: !_ctx.isValid()
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$localization.getTranslate("Send")), 1)
          ]),
          _: 1
        }, 8, ["onClick", "isDisabled"])
      ])
    ]),
    (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
      _createVNode(_component_modal_fullscreen, {
        class: "modal-success-send",
        visible: _ctx.isOpenSuccessModal,
        type: "2",
        hideHeader: true,
        onClose: _ctx.close
      }, {
        body: _withCtx(() => [
          _createVNode(_component_ui_message_success, {
            text: _ctx.$localization.getTranslate('MessageSuccessfullySended') + '!'
          }, null, 8, ["text"])
        ]),
        _: 1
      }, 8, ["visible", "onClose"])
    ]))
  ], 64))
}