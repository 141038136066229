const getBase64 = (file: File): Promise<string> => {
    const resultPromise = new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        let base64: string = "";
        reader.onload = () => {
            base64 = reader.result.toString();
            const find = 'base64,';
            const index = base64.indexOf(find)
            if(index>=0)
                base64 = base64.substring(index+find.length)
            resolve(base64);
        };
        reader.onerror = () => {
            reject("Не удалось загрузить изображение");
        };
        reader.readAsDataURL(file);
    });
    return resultPromise;
}

export default getBase64;