
import { Vue, Watch, Options, Prop } from "vue-property-decorator";
import { Countries } from "@models/const/countries";

import CompanyDocumentModel from "./../models/CompanyDocumentModel";
import CompanyDataViewModel from "./../models/CompanyDataViewModel";
import EnumApiErrors from "@models/enums/EnumApiError";
import LegalCompanyGetDataResponse from "@/api/main/dataSource/api/identification-services/models/legal-company/LegalCompanyGetDataResponse";
import UploadPhotoCropRequest from "@/api/main/dataSource/api/file-services/models/UploadPhotoCropRequest";
import { AdapterApiService } from "../../_adapter";
import { AdapterIdentificationService } from "../../profile-identification/adapter";
import LegalCompanySaveDataRequest from "@/api/main/dataSource/api/identification-services/models/legal-company/LegalCompanySaveDataRequest";

@Options({
	emits: ["changeTab", "check"],
	name: "company-tab",
})
export default class CompanyTab extends Vue {
	@Prop({ required: true }) userId: number;
	countries = Countries;
	checkStart: boolean = false;
	companyDataNew: CompanyDataViewModel = new CompanyDataViewModel();
	errorText: string = "";
	isLoading: boolean = false;
	errorMessage: string = "";

	adapter = AdapterIdentificationService.getInstance();
	@Watch("errorText")
	onUpdateButtonText(newValue: string) {
		if (newValue.length) {
			this.errorMessage = this.$localization.getTranslate(`EnumApiError.${newValue}`);
		}
	}

	async mounted() {
		await this.getCompanyData();
		// this.onUpdateButtonText(this.errorText);

	}

	async getCompanyData() {
		this.errorMessage = "";
		const res = await this.adapter.getCompanyData();
		if (!res.IsSuccess) {
			this.errorMessage = res.ErrorMessage;
			return;
		}
		this.companyDataNew = new CompanyDataViewModel(res.Value);
		// if (this.companyDataNew.isValid()) {
		// 	this.onSuccessSaveCompanyData();
		// }
	}
	addedFile(document: CompanyDocumentModel) {
		this.companyDataNew.CompanyDocuments.push(document);
	}
	onSuccessGetCompanyData(response: LegalCompanyGetDataResponse) {
		this.companyDataNew = new CompanyDataViewModel(response);
		if (this.userId) {
			this.companyDataNew.LegalUserId = this.userId;
		}
		this.$store.state.setCompanyData(this.companyDataNew);
	}

	async saveAndContinue() {
		
		this.checkStart = true;
		if (this.companyDataNew && this.companyDataNew.isValid()) {
			this.errorText = "";
			await this.saveCompanyData();
		} else {
			this.$emit("check", 0, false, this.companyDataNew);
			this.errorText = "IncompleteInformation";
		}
	}

	async saveCompanyData() {
		this.isLoading = true;
		this.errorMessage = "";
		const res = await this.adapter.saveCompanyData(this.companyDataNew);
		this.isLoading = false;

		if (res.IsSuccess) {
			this.onSuccessSaveCompanyData();
			return;
		}
		this.getCompanyData()
		this.errorMessage = res.ErrorMessage;

		// this.isLoading = true;
		// await this.adapter
		// 	.saveCompanyDataAsync(this.companyDataNew.toLegalCompanySaveDataRequest())
		// 	.then(() => {
		// 		this.onSuccessSaveCompanyData();
		// 	})
		// 	.catch(this.catchError);
	}

	async onSuccessSaveCompanyData() {
		// this.$store.state.setCompanyData(null);
		// await this.getCompanyData();
		this.$emit("changeTab", 1);
		this.$emit("check", 3, true, this.companyDataNew);
		this.isLoading = false;
	}

	@Watch("documents")
	onUpdateDocsForCreated() {
		this.checkRegisterDoc();
	}

	checkRegisterDoc() {
		if (
			this.checkStart &&
			!this.companyDataNew.CompanyDocuments.find((doc) => doc.Type == 0)
		) {
			return true;
		} else {
			return false;
		}
	}

	onDeleteDocument(document: CompanyDocumentModel) {

		this.companyDataNew.CompanyDocuments = this.companyDataNew.CompanyDocuments.filter(
			(x) => x !== document
		);
		if (!this.companyDataNew.OldCompanyDocumentsIds.includes(document.Id)) {
			this.companyDataNew.OldCompanyDocumentsIds.push(document.Id);
		}
	}

	catchError(err) {
		if (EnumApiErrors[err.ErrorCode]) {
			this.errorText = EnumApiErrors[err.ErrorCode];
		} else {
			this.errorText = "ErrorOccured";
		}
		this.$emit("check", 0, false, this.companyDataNew);
		this.isLoading = false;
	}

	setHeader(type: number) {
		switch (type) {
			case 0:
				return "Header.RegisterDocument";
			case 1:
				return "Header.ExtractFromTheRegister";
			case 2:
				return "Header.CompanyCharter";
			case 3:
				return "Header.PowerOfAttorney";
			case 4:
				return "Header.TrustDeclaration";
			case 5:
				return "Header.NomineeRegistrationDocument";
		}
	}
}
