

export default class ModelPersonBalance  {
	UserId?: number;
	Balance?: number;
	IsDisabledReplenish: boolean = false;
	Number!: string;
	WalletUuid!: string;
	DateBlocking?: Date;
	DateClosed?: Date;
	WalletsCount!: number;
	constructor(obj?: Partial<ModelPersonBalance>) {
		if (obj) {
			Object.assign(this, obj);
		}
	}
}
