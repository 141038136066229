
import { Vue, Options, Prop } from "vue-property-decorator";
import ProfileModel from "../../../models/ProfileModel";
@Options({
  name: "identification-waiting",
})
export default class IdentifiactionWaiting extends Vue {
   @Prop({default: null, type: Object}) profile!: ProfileModel;

  GoNext() {
    this.$emit("next");
  }
}
