import UserType from '../../../../common/enums/UserType';
import EnumTypeIdentified from '../../../../aba/data/models/models/EnumTypeIdentified';
import ModelNaturalPerson from '../../../../i-public-service/aba-finance/models/user/ModelNaturalPerson';
import EnumUserPaymentsType from '../../../../aba/data/enums/EnumUserPaymentsType';

export default class ModelUserBase  {
	Id!: number;
	Email!: string;
	Login!: string;
	Password!: string;
	Phone!: string;
	Role!: string;
	Name!: string;
	CompanyName!: string;
	Address!: string;
	LastName!: string;
	TypeUser!: string;
	DateViewMessage?: Date;
	DateLastMessage?: Date;
	TypeUserId!: UserType;
	IsIdentified: boolean = false;
	TypeIdentified!: EnumTypeIdentified;
	Token!: string;
	IsTwoFactorAuthentication: boolean = false;
	IsHaveCards: boolean = false;
	User!: ModelNaturalPerson;
	PaymentsType!: EnumUserPaymentsType;
	AllowMassTransfers: boolean = false;
	DocumentType!: string;
	DocumentNumber!: string;
	constructor(obj?: Partial<ModelUserBase>) {
		if (obj) {
			Object.assign(this, obj);
		}
	}
}
