
import { Options, Prop, Vue } from "vue-property-decorator";

@Options<UiTableFilter>({
  emits: ["change"],
})
export default class UiTableFilter extends Vue {
  @Prop({ type: Object, required: true }) filter: { search: string };
  onFilterChanged() {
    this.$emit("change", this.filter);
  }
}
