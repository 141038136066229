import { WebApiService } from '../../../../plugins/webApiService'
import { Events } from '../../../../plugins/events'
import { SendRequestModel } from '../../../../plugins/models/SendRequestModel'
import DownloadTransactionsForLegalRequest from '../../../../aba/cabinet/web/models/DownloadTransactionsForLegalRequest';
import DownloadTransactionsForNaturalRequest from '../../../../i-public-service/aba-finance/models/transaction/DownloadTransactionsForNaturalRequest';
import WsResponseModelT from '../../../../schema-builder/server/models/WsResponseModelT';

export default class FileWsController {
	private url?: string;
	private cookieName: string;
	private appWebSettingsPath?: string;
	private apiUrlProperty?: string;

	DownloadTransactionsForLegalEvent = new Events<WsResponseModelT<String>>();
	DownloadTransactionsForNaturalEvent = new Events<WsResponseModelT<String>>();
	DownloadTransactionInfoEvent = new Events<WsResponseModelT<String>>();

	subscribeEvents = async () => {
		const self = this;
		const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
		webApiService.on('FileWsController', 'DownloadTransactionsForLegal', 'Main', (res: WsResponseModelT<String>) => {
			self.DownloadTransactionsForLegalEvent.trigger(res);
		});
		webApiService.on('FileWsController', 'DownloadTransactionsForNatural', 'Main', (res: WsResponseModelT<String>) => {
			self.DownloadTransactionsForNaturalEvent.trigger(res);
		});
		webApiService.on('FileWsController', 'DownloadTransactionInfo', 'Main', (res: WsResponseModelT<String>) => {
			self.DownloadTransactionInfoEvent.trigger(res);
		});
	}

	constructor(url?: string, cookieName?: string, appWebSettingsPath?: string, apiUrlProperty?: string) {
		this.url = url;
		this.cookieName = cookieName ?? "Auth-Token";
		this.appWebSettingsPath = appWebSettingsPath && [location.host, appWebSettingsPath].join("/");
		this.apiUrlProperty = apiUrlProperty;
		this.subscribeEvents();
	}

	async DownloadTransactionsForLegal(request: DownloadTransactionsForLegalRequest) {
		const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
		const sendRequest = new SendRequestModel({
			ProjectName: 'Main',
			Controller: 'FileWsController',
			Method: 'DownloadTransactionsForLegal',
			Value: typeof(request) === 'object' ? JSON.stringify(request) : request,
		})

		webApiService.send(sendRequest);
	}

	async DownloadTransactionsForNatural(request: DownloadTransactionsForNaturalRequest) {
		const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
		const sendRequest = new SendRequestModel({
			ProjectName: 'Main',
			Controller: 'FileWsController',
			Method: 'DownloadTransactionsForNatural',
			Value: typeof(request) === 'object' ? JSON.stringify(request) : request,
		})

		webApiService.send(sendRequest);
	}

	async DownloadTransactionInfo(tId: number) {
		const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
		const sendRequest = new SendRequestModel({
			ProjectName: 'Main',
			Controller: 'FileWsController',
			Method: 'DownloadTransactionInfo',
			Value: typeof(tId) === 'object' ? JSON.stringify(tId) : tId,
		})

		webApiService.send(sendRequest);
	}

	 // async methods
	async downloadTransactionsForLegalAsync(request: DownloadTransactionsForLegalRequest): Promise<String> {
		return new Promise<String>(async (resolve, reject) => {
			const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
			const sendRequest = new SendRequestModel({
				ProjectName: 'Main',
				Controller: 'FileWsController',
				Method: 'DownloadTransactionsForLegal',
				Value: typeof(request) === 'object' ? JSON.stringify(request) : request,
				Callback: (response: WsResponseModelT<String>) => {
					const isGeneric = typeof response.IsSuccess == 'boolean'
					const result = response.IsSuccess ? response.Value : response

					if (!isGeneric || response.IsSuccess) resolve(result as String)
					else reject(result)
				}
			})

			webApiService.send(sendRequest);
		});
	}

	async downloadTransactionsForNaturalAsync(request: DownloadTransactionsForNaturalRequest): Promise<String> {
		return new Promise<String>(async (resolve, reject) => {
			const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
			const sendRequest = new SendRequestModel({
				ProjectName: 'Main',
				Controller: 'FileWsController',
				Method: 'DownloadTransactionsForNatural',
				Value: typeof(request) === 'object' ? JSON.stringify(request) : request,
				Callback: (response: WsResponseModelT<String>) => {
					const isGeneric = typeof response.IsSuccess == 'boolean'
					const result = response.IsSuccess ? response.Value : response

					if (!isGeneric || response.IsSuccess) resolve(result as String)
					else reject(result)
				}
			})

			webApiService.send(sendRequest);
		});
	}

	async downloadTransactionInfoAsync(tId: number): Promise<String> {
		return new Promise<String>(async (resolve, reject) => {
			const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
			const sendRequest = new SendRequestModel({
				ProjectName: 'Main',
				Controller: 'FileWsController',
				Method: 'DownloadTransactionInfo',
				Value: typeof(tId) === 'object' ? JSON.stringify(tId) : tId,
				Callback: (response: WsResponseModelT<String>) => {
					const isGeneric = typeof response.IsSuccess == 'boolean'
					const result = response.IsSuccess ? response.Value : response

					if (!isGeneric || response.IsSuccess) resolve(result as String)
					else reject(result)
				}
			})

			webApiService.send(sendRequest);
		});
	}

}