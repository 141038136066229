import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createBlock as _createBlock, withModifiers as _withModifiers } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_list_view_item = _resolveComponent("ui-list-view-item")!

  return (_openBlock(), _createElementBlock("div", {
    class: "ui-list-view",
    onDragover: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["prevent"])),
    onDrop: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.dargDrop && _ctx.dargDrop(...args)))
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.innerItems, (item, index) => {
      return (_openBlock(), _createBlock(_component_ui_list_view_item, {
        key: index,
        draggable: _ctx.isDrageble && !item.Disabled,
        item: item,
        title: item.Value.ToString(),
        onItemdrop: _ctx.itemDragDrop,
        onItemDragStart: _ctx.itemDragStart,
        onItemdropend: _ctx.itemDropEnd,
        onSelect: _ctx.onSelect,
        class: _normalizeClass({ underline: _ctx.underline }),
        style: _normalizeStyle(_ctx.itemStyle)
      }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "item", {}, undefined, true)
        ]),
        _: 2
      }, 1032, ["draggable", "item", "title", "onItemdrop", "onItemDragStart", "onItemdropend", "onSelect", "class", "style"]))
    }), 128))
  ], 32))
}