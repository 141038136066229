import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withModifiers as _withModifiers, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "years" }
const _hoisted_2 = { class: "navigation" }
const _hoisted_3 = { class: "title_date-picker unselectable" }
const _hoisted_4 = { class: "items" }
const _hoisted_5 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: "icon-arrow-left",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.backward && _ctx.backward(...args)))
      }),
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.innerYear) + " - " + _toDisplayString(this.innerYear + 10), 1),
      _createElementVNode("div", {
        class: "icon-arrow-right",
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.next && _ctx.next(...args)))
      })
    ]),
    _createElementVNode("div", _hoisted_4, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.years, (year, key) => {
        return (_openBlock(), _createElementBlock("div", {
          key: key,
          class: _normalizeClass(["year", year.type])
        }, [
          _createElementVNode("div", {
            onClick: _withModifiers(($event: any) => (_ctx.changeYear(year)), ["stop"])
          }, _toDisplayString(year.number), 9, _hoisted_5)
        ], 2))
      }), 128))
    ])
  ]))
}