import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-7a20b908"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  id: "show_image",
  class: "card_activation_popup zoom-anim-dialog mfp-with-anim mfp-hide"
}
const _hoisted_2 = { class: "card_activation_wrapper" }
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        src: 'data:image/png;base64,' + _ctx.image
      }, null, 8, _hoisted_3)
    ])
  ]))
}