
import ModelTransferInternal from "@/api/main/dataSource/api/i-public-service/aba-finance/models/ModelTransferInternal";
import SepaModel from "@/views/models/Transfer/SepaModel";
import { Vue, Options, Prop, Watch } from "vue-property-decorator";
import { useRoute, useRouter } from "vue-router";
import AdapterPaymentsTransferService from "../../adapter/AdapterTransferService";
import * as Routes from "@/router/routerNames";
@Options({
	name: "sepa-confrim",
})
export default class SepaConfirm extends Vue {
	@Prop({ default: false }) visible: boolean;
	@Prop({ default: null }) formData: FormData;
	@Prop({ default: null }) paymentsType: number;

	pinCode: string = "";

	ErrMessage: string = "";
	IsSuccess: boolean = false;
	IsLoad: boolean = false;

	model: SepaModel = null;
	router = useRouter();
	created() {
		this.model = new SepaModel();
	}

	get isOffline() {
		return this.paymentsType === 1;
	}

	@Watch("formData", { deep: true })
	onChangeFormData() {
		if (!this.formData) return;
		this.model = new SepaModel({
			AccountNumber: this.formData.get("AccountNumber").toString(),
			Amount: this.formData.get("Amount").toString(),
			PurposePayment: this.formData.get("PurposePayment").toString(),
			Recipient: this.formData.get("Recipient").toString(),
			Commission: parseInt(this.formData.get("Commission").toString()),
			BeneficiaryCountryCode: this.formData.get("BeneficiaryCountryCode").toString(),
			BeneficiaryAddress: this.formData.get("BeneficiaryAddress").toString(),
			BeneficiaryCity: this.formData.get("BeneficiaryCity").toString(),
			BeneficiaryPostalCode: this.formData.get("BeneficiaryPostalCode").toString(),
			BeneficiaryState: this.formData.get("BeneficiaryState").toString(),
		});
	}

	onInputPin(pin: string) {
		this.pinCode = pin;
	}

	async Transfer() {
		if (!this.formData) return;
		this.model = new SepaModel({
			AccountNumber: this.formData.get("AccountNumber").toString(),
			Amount: this.formData.get("Amount").toString(),
			PurposePayment: this.formData.get("PurposePayment").toString(),
			Recipient: this.formData.get("Recipient").toString(),
			Commission: parseInt(this.formData.get("Commission").toString()),
			BeneficiaryCountryCode: this.formData.get("BeneficiaryCountryCode").toString(),
			BeneficiaryAddress: this.formData.get("BeneficiaryAddress").toString(),
			BeneficiaryCity: this.formData.get("BeneficiaryCity").toString(),
			BeneficiaryPostalCode: this.formData.get("BeneficiaryPostalCode").toString(),
			BeneficiaryState: this.formData.get("BeneficiaryState").toString(),
		});
		if (this.IsLoad) return false;
		this.ErrMessage = "";
		if (this.pinCode.length < 6) {
			this.ErrMessage = this.translation("EnterPINСode");
			return false;
		}
		var request = this.formData as FormData;
		// request.append("Pin", this.pinCode);
		this.IsLoad = true;

		const adapter = AdapterPaymentsTransferService.getInstance();
		const req = new ModelTransferInternal({
			Amount: Number(this.model.Amount),
			AccountNumber: this.model.AccountNumber,
			BeneficiaryAddress: this.model.BeneficiaryAddress,
			BeneficiaryCity: this.model.BeneficiaryCity,
			BeneficiaryCountryCode: this.model.BeneficiaryCountryCode,
			BeneficiaryPostalCode: this.model.BeneficiaryPostalCode,
			BeneficiaryState: this.model.BeneficiaryPostalCode,
			FullName: this.model.Recipient,
			Pin: this.pinCode,
			PurposePayment: this.model.PurposePayment,
			NameTemplate: this.formData.get("NameTemplate")?.toString(),
		});
		const res = await adapter.transferSepa(req);
		this.IsLoad = false;
		if (res.IsSuccess) {
			this.IsSuccess = res.IsSuccess;
			return;
		}
		this.ErrMessage = res.ErrorMessage;
		// this.$api.Main.NaturalPersonInternalBalanceService.TransferSepa((res) => {
		//   this.IsLoad = false;

		//   if ((res as any).IsError) {
		//     this.$store.state.modalDialogAlert("Неизвестная ошибка при загрузке");
		//     return;
		//   }

		//   if (res.IsSuccess) this.IsSuccess = res.IsSuccess;
		//   else
		//     this.ErrMessage = this.$localization.translateResponseCode(
		//       res.ResponseCode
		//     );
		// }, request);//TODO API
	}

	close() {
		if (this.IsSuccess) this.router.push({ name: Routes.TRANSUCTIONS });
		this.$emit("close");
	}

	translation(key: string) {
		return this.$localization.getTranslate(key);
	}
}
