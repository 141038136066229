
import {Vue, Options, Prop} from 'vue-property-decorator'
import ListTags from './listtags';



@Options({name:"ui-tags-simple-list"})
export default class UiTagsSimpleList extends Vue{
  @Prop({ default: () => {}, type: Array })
  Items!: Array<ListTags>;  
  
  count(){
    
      return this.Items.length-1;
  }
}
