import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_identification_new_user = _resolveComponent("identification-new-user")!

  return (_openBlock(), _createElementBlock("div", null, [
    (!_ctx.started)
      ? (_openBlock(), _createBlock(_component_identification_new_user, {
          key: 0,
          onContinue: _cache[0] || (_cache[0] = ($event: any) => (_ctx.continue))
        }))
      : _createCommentVNode("", true)
  ]))
}