
import moment from "moment";
import { Options, Vue, Prop } from "vue-property-decorator";
import UiDateTime from "@views/components/ui-date-picker/ui-date-time.vue";
interface rangeData {
  firstData: Date;
  SecondData: Date;
}
@Options<UiDatetimeRangePicker>({
  watch: {
    innerShowDatetime() {
      this.iconFocused = this.innerShowDatetime;
    },
    // value(value) {
    //   this.innerDate = this.value;
    //   this.innerDateFormatted = this.formatDate(this.value);
    // },
  },
  emits: ["update:modelValue", "inputRage"],
  components: { UiDateTime },
})
export default class UiDatetimeRangePicker extends Vue {
  @Prop({ type: Date, default: new Date() }) fromDate: Date;
  @Prop({ type: Date, default: new Date() }) toDate: Date;

  @Prop({ type: Date, default: () => new Date() }) value: Date;
  @Prop({ default: "DD.MM.YYYY HH:mm", type: String }) dateFormat: string;
  // ограничение даты снизу

  //включен диапазон дат
  isDateRange = true;

  view2 = true;
  // показывать текущую дату
  // @Prop({ default: true, type: Boolean }) showCurrentDate: boolean;
  showCurrentDate = true;
  //показывать рамку только активации элемента
  @Prop({ default: false, type: Boolean }) transformer: boolean;

  @Prop({ default: null }) eventDates: Array<any>; //выделенный промежуток дат
  @Prop({ default: true, type: Boolean }) autoCloseWhenChangeDay: boolean; //закрывать после выбора дня месяца
  changeDateManual = true; //разрешить редактирование даты вручную

  innerDate: Date;
  innerDateFormatted: Date;
  innerSecondDate: Date;
  innerSecondDateFormatted: Date;
  inner2DateFormatted: { dateFrom: Date; dateTo: Date };
  innerShowDatetime = false;
  isFirstChoice = true;
  iconFocused = false;
  firstClick = true;
  wrong: boolean = false;
  isDay = false;
  changeDayHours() {
    this.isDay = !this.isDay;
  }
  test(){
    let calendar = document.getElementById("dateTimeWrapper")
    let div = document.createElement('div')
  
  }
  created() {
    this.innerDateFormatted = this.formatDate(this.fromDate);
    this.innerSecondDateFormatted = this.formatDate(this.toDate);
    this.inner2DateFormatted = {
      dateFrom: this.innerDateFormatted,
      dateTo: this.innerSecondDateFormatted,
    };

    this.innerDate = this.fromDate;
    this.innerSecondDate = this.toDate;
  }
  closeCalendar(){
    this.innerShowDatetime = false
  }
  errorDate(data: boolean) {
    this.wrong = true;
    let self = this;
    setTimeout(function () {
      self.wrong = false;
    }, 2000);
  }
  oninnerDateFormatted() {
    this.inner2DateFormatted = {
      dateFrom: this.formatDate(this.innerDate),
      dateTo: this.formatDate(this.innerSecondDate),
    };
    // пропустит создание страницы с одинаковыми датами
    if (this.inner2DateFormatted.dateFrom == this.inner2DateFormatted.dateTo) {
      return;
    }
    // сравнит 2 даты и даст ошибку или отправит обраттно
    if (this.innerDate < this.innerSecondDate) {
      let set: rangeData = {
        firstData: this.innerDate,
        SecondData: this.innerSecondDate,
      };
      this.$emit("inputRage", set);
      this.wrong = false;
    } else {
      this.wrong = true;
    }
  }
  //отпраыить изменения
  changeDate(date: any) {
    this.innerShowDatetime = false;
    this.innerDate = date.firstData;
    this.innerSecondDate = date.SecondData;
    // this.oninnerDateFormatted();
    this.inner2DateFormatted = {
      dateFrom: this.formatDate(this.innerDate),
      dateTo: this.formatDate(this.innerSecondDate),
    };
    this.$emit("inputRage", date);
  }
  // открыть календарь
  targetDatetime() {
    this.innerShowDatetime = !this.innerShowDatetime;
    this.iconFocused = this.innerShowDatetime;
    this.isDay = true;
  }
  // форматировать дату
  formatDate(date: Date) {
    var moment = require("moment");
    var now = moment(date);
    moment.locale("ru");
    return now.format(this.dateFormat);
  }
  // для того чтобы при потере фокуса, нажатии на пробел и таб отправить дату

  onBlur() {
    if (
      moment(this.inner2DateFormatted.dateFrom, "DD.MM.YYYY HH:mm").isValid() &&
      moment(this.inner2DateFormatted.dateTo, "DD.MM.YYYY HH:mm").isValid()
    ) {
      this.innerDate = moment(
        this.inner2DateFormatted.dateFrom,
        this.dateFormat
      ).toDate();
      this.innerSecondDate = moment(
        this.inner2DateFormatted.dateTo,
        this.dateFormat
      ).toDate();
      this.wrong = false;
      this.oninnerDateFormatted();
    } else {
      this.wrong = true;
    }
  }
}
