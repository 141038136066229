export default class TabModel {
  Id: number = 0;
  Name: string = null;
  Active: boolean = false;

  constructor(obj?: Partial<TabModel>) {
    if (obj) {
      Object.assign(this, obj)
    }
  }
}