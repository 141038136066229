
import { Vue, Watch, Options, Prop } from "vue-property-decorator";

@Options({
	emits: ["continue"],
	name: "identification-start",
})
export default class IdentificationStart extends Vue {
	isLoading: boolean = false;

	startContinue() {
		this.$emit("continue");
		this.isLoading = true;
	}

	unmounted() {
		this.isLoading = false;
	}
}
