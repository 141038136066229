
import { Vue, Prop, Options } from "vue-property-decorator";
@Options({
  name: "ui-company-document-file",
})
export default class UiCompanyDocumentFile extends Vue {
  @Prop({ type: String }) fileName: string;
  @Prop({ default: true }) showDelete: boolean;
  @Prop({ default: false }) isUrl: boolean;
}
