import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createSlots as _createSlots, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-62f21048"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "toggle-1" }
const _hoisted_2 = {
  key: 0,
  class: ""
}
const _hoisted_3 = {
  key: 1,
  class: ""
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_loading = _resolveComponent("ui-loading")!
  const _component_ui_toggle_switcher = _resolveComponent("ui-toggle-switcher")!
  const _component_ui_modal_window = _resolveComponent("ui-modal-window")!

  return (_openBlock(), _createBlock(_component_ui_modal_window, {
    closeHandler: _ctx.closeHandler,
    ref: "add-modal",
    title: _ctx.title,
    onOnOk: _ctx.onOk,
    onOnCancel: _ctx.onCancel,
    titleBtnYes: "Сохранить",
    titleBtnNo: "Отмена"
  }, _createSlots({
    default: _withCtx(() => [
      _createVNode(_component_ui_loading, { loading: _ctx.isLoading }, null, 8, ["loading"]),
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ]),
    _: 2
  }, [
    (_ctx.togle)
      ? {
          name: "togle",
          fn: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              (_ctx.modelValue.isActive)
                ? (_openBlock(), _createElementBlock("div", _hoisted_2, "Активный"))
                : (_openBlock(), _createElementBlock("div", _hoisted_3, "Не активный")),
              _createVNode(_component_ui_toggle_switcher, {
                modelValue: _ctx.modelValue.isActive,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.modelValue.isActive) = $event))
              }, null, 8, ["modelValue"])
            ])
          ]),
          key: "0"
        }
      : undefined
  ]), 1032, ["closeHandler", "title", "onOnOk", "onOnCancel"]))
}