
import EnumDocumentType from '@/api/main/dataSource/api/common/enums/EnumDocumentType';
import DownloadTransactionsForNaturalRequest from "@/api/main/dataSource/api/i-public-service/aba-finance/models/transaction/DownloadTransactionsForNaturalRequest";
import MoneyTransactionPersonItem from "@/api/main/services/NaturalPersonInternalBalance/models/MoneyTransactionPersonItem/MoneyTransactionPersonItem";
import moment from "moment";
import { Vue, Options, Prop } from "vue-property-decorator";
import { AdapterBalanceService } from "../../balance/adapter";
import EnumTransactionType from '@/api/main/models/Enums/EnumTransactionType';
@Options({
	name: "transactions-table",
})
export default class TransactionsTable extends Vue {
	@Prop({ type: Object, default: null })
	transactions: Array<MoneyTransactionPersonItem>;
	@Prop({ type: Boolean, default: false }) isLegal: boolean;
	@Prop({ type: Number, default: null }) paymentsType: number;

	async downloadInfo(item: MoneyTransactionPersonItem) {
		const adapter = AdapterBalanceService.getInstance();
		const req = new DownloadTransactionsForNaturalRequest({DocumentType:EnumDocumentType.Pdf});
		const res = await adapter.downloadTransactionInfo(req);
		if (res.IsSuccess) {
			// Создание нового элемента <a> на лету
			const filePath = res.Value as string;
			const link = document.createElement("a");
			link.href = window.location.origin + filePath; // Устанавливаем путь к файлу
			link.download = filePath.split("/").pop(); // Установка имени файла для сохранения

			// Эмуляция клика по ссылке
			document.body.appendChild(link);
			link.click();

			// После клика ссылку можно удалить
			document.body.removeChild(link);
			return;
		}

		// let formData = new FormData();
		// formData.append("tId", item.Id.toString());
		// this.$api.Main.NaturalPersonBalanceService.DownloadTransactionInfo((res) => {
		//   var link = document.createElement("a");
		//   var typeFile = res.substr(res.length - 3); // => typeFile
		//   link.href = res;
		//   link.download = "transaction_No" + item.Id + "." + typeFile;
		//   link.dispatchEvent(new MouseEvent("click"));
		// }, formData);
	}
	translateTypeTransaction(key: number) {
		if (key == 1) key = 6;
		return this.$localization.getTranslate(
			this.$store.state.translateTypeTransaction(key)
		);
	}

	isCardPayout(transaction: MoneyTransactionPersonItem) {
		return transaction.Type === 13 && transaction.Card;
	}
	isCryptPayout(transaction: MoneyTransactionPersonItem) {
		return transaction.Type === 12 && transaction.Crypt;
	}
	isSepa(transaction: MoneyTransactionPersonItem) {
		return [1, 2].includes(transaction.Type) && transaction.Sepa;
	}
	isSwift(transaction: MoneyTransactionPersonItem) {
		return [3, 4].includes(transaction.Type) && transaction.Swift;
	}
	isIntenal(transaction: MoneyTransactionPersonItem) {
		return transaction.Type === 0;
	}
	isIncomingAcquiring(transaction: MoneyTransactionPersonItem) {
		return transaction.Type === 10;
	}
	isOutgoingAcquiring(transaction: MoneyTransactionPersonItem) {
		return transaction.Type === 11;
	}
	getPayerName(transaction: MoneyTransactionPersonItem) {
		var name;
		if (this.isSepa(transaction)) name = transaction.Sepa.FromBeneficiaryName;
		if (this.isSwift(transaction)) name = transaction.Swift.FromBeneficiaryName;
		if (transaction.Type==EnumTransactionType.VirtpayPayin) name =transaction.ToUserName
		if (transaction.Type==EnumTransactionType.VirtpayPayout) name =transaction.FromUserName
		if (!name) name = transaction.FromUserName;
		return name;
	}
	getPayerIban(transaction: MoneyTransactionPersonItem) {
		var name;
		if (this.isSepa(transaction)) name = transaction.Sepa.FromIban;
		if (this.isSwift(transaction)) name = transaction.Swift.FromIban;
		if (transaction.Type==EnumTransactionType.VirtpayPayin) name =transaction.ToUserIban
		if (transaction.Type==EnumTransactionType.VirtpayPayout) name =transaction.FromUserIban
		if (!name) name = transaction.FromUserIban;
		return name;
	}

	getPayeeName(transaction: MoneyTransactionPersonItem) {
		var name;
		if (this.isCryptPayout(transaction)) name = transaction.Crypt.Currency;
		if (this.isCardPayout(transaction)) name = transaction.Card.CardHolderName;
		if (this.isSepa(transaction)) name = transaction.Sepa.ToBeneficiaryName;
		if (this.isSwift(transaction)) name = transaction.Swift.ToBeneficiaryName;

		if (!name) name = transaction.ToUserName;
		return name;
	}
	getPayeeIban(transaction: MoneyTransactionPersonItem) {
		var name;
		if (this.isCryptPayout(transaction)) name = transaction.Crypt.Wallet;
		if (this.isCardPayout(transaction)) name = transaction.Card.CardNumber;
		if (this.isSepa(transaction)) name = transaction.Sepa.ToIban;
		if (this.isSwift(transaction)) name = transaction.Swift.ToIban;
		if (!name) name = transaction.ToUserIban;
		return name;
	}

	convertToFormat(date: Date) {
		return moment(date).format("D.MM.YYYY / h:mm");
	}
}
