import UploadPhotoCropRequest from '../../../file-services/models/UploadPhotoCropRequest';

export default class LegalDirectorsSaveResponse  {
	Id?: number;
	FirstName!: string;
	SecondName!: string;
	Appeal!: string;
	Phone!: string;
	Email!: string;
	BirthDate!: Date;
	BirthPlace!: string;
	AddressState!: string;
	AddressCountryCode!: string;
	AddressCity!: string;
	AddressStreet!: string;
	AddressHome!: string;
	AddressApartment!: string;
	AddressZip!: string;
	DocumentType!: string;
	DocumentNumber!: string;
	DocumentIssuedCountryCode!: string;
	DocumentIssuedBy!: string;
	DocumentIssuedDate!: Date;
	DocumentExpireDate!: Date;
	File!: UploadPhotoCropRequest;
	DocumentFile!: UploadPhotoCropRequest;
	ApproveOfAddressFile!: UploadPhotoCropRequest;
	constructor(obj?: Partial<LegalDirectorsSaveResponse>) {
		if (obj) {
			Object.assign(this, obj);
		}
	}
}
