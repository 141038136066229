
import { Vue, Options, Prop, Watch } from "vue-property-decorator";
import TransferAcquiringModel from "@/views/models/Transfer/TransferAcquiringModel";
import AdapterTransferService from "../../adapter/AdapterTransferService";
import VirtpayPayoutHttpRequest from "@/api/main/dataSource/api/aba/cabinet/web/models/VirtpayPayoutHttpRequest";
import VirtpayPayoutRequest from "@/api/main/dataSource/api/virtpay-transactions-services/models/payout/VirtpayPayoutRequest";
import EnumCurrency from "@/api/main/dataSource/api/aba/data/enums/EnumCurrency";
import { useRoute, useRouter } from "vue-router";
import * as Routes from "@/router/routerNames";
@Options({
	name: "transfer-acquiring-confrim",
})
export default class TransferAcquiringConfrim extends Vue {
	@Prop({ default: false }) visible: boolean;
	@Prop({ default: null }) formData: FormData;

	pinCode: string = "";
	router = useRouter();
	ErrMessage: string = "";
	IsSuccess: boolean = false;
	IsLoad: boolean = false;

	model: TransferAcquiringModel = null;

	created() {
		this.model = new TransferAcquiringModel();
	}

	adapter = AdapterTransferService.getInstance();
	@Watch("formData", { deep: true })
	onChangeFormData() {
		if (!this.formData) return;
		this.model = new TransferAcquiringModel({
			ToFirstName: this.formData.get("ToFirstName").toString(),
			ToLastName: this.formData.get("ToLastName").toString(),
			ToCardNumber: this.formData.get("ToCardNumber").toString(),
			Amount: this.formData.get("Amount").toString(),
			CommissionAmount: this.formData.get("CommissionAmount").toString(),
			Description: this.formData.get("Description").toString(),
		});
	}

	async Transfer() {
		if (this.IsLoad) return false;
		this.ErrMessage = "";
		if (this.pinCode.length < 6) {
			this.ErrMessage = this.translation("EnterPINСode");
			return false;
		}
		var request = this.formData;
		request.append("Pin", this.pinCode);
		this.IsLoad = true;
		const req = new VirtpayPayoutHttpRequest({
			Payout: new VirtpayPayoutRequest({
				Amount: Number(this.model.Amount),
				CardNumber: this.model.ToCardNumber,
				Currency: EnumCurrency.EUR,
				PaymentDescription: this.model.Description,
			}),
			Pin: this.pinCode,
		});
		const res = await this.adapter.transfer(req);
		this.IsLoad = false;
		if (res.IsSuccess) {
			this.IsSuccess = res.IsSuccess;
			return;
		}
		this.ErrMessage = res.ErrorMessage;

		// this.$api.Main.NaturalPersonInternalBalanceService.TransferAcquiringToCard(
		//   (res) => {
		//     this.IsLoad = false;

		//     if ((res as any).IsError) {
		//       this.$store.state.modalDialogAlert("Неизвестная ошибка при загрузке");
		//       return;
		//     }

		//     if (res.IsSuccess) this.IsSuccess = res.IsSuccess;
		//     else
		//       this.ErrMessage = this.$localization.translateResponseCode(
		//         res.ResponseCode
		//       );
		//   },
		//   request
		// );//TODO api
	}

	onInputPin(pin: string) {
		this.pinCode = pin;
	}

	close() {
		this.ErrMessage = "";
		if (this.IsSuccess) {
			this.router.push({ name: Routes.TRANSUCTIONS });
		}
		this.$emit("close");
	}

	translation(key: string) {
		return this.$localization.getTranslate(key);
	}
}
