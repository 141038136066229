import EnumCompanyDocumentType from '../../../aba/data/enums/EnumCompanyDocumentType';
import UploadPhotoCropRequest from '../../../file-services/models/UploadPhotoCropRequest';

export default class LegalCompanyDocumentSaveRequest  {
	Type!: EnumCompanyDocumentType;
	File!: UploadPhotoCropRequest;
	constructor(obj?: Partial<LegalCompanyDocumentSaveRequest>) {
		if (obj) {
			Object.assign(this, obj);
		}
	}
}
