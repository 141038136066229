
import { Options, Vue, Prop, Watch, Inject } from "vue-property-decorator";
import TabsProvider, { TabInfo } from "./tabsProvider";

@Options({
  name: "ui-tab-item",
})
export default class UITabItemComponent extends Vue {
  @Prop({ default: null, type: String, required: true }) title!: string;
  @Prop({ default: false, type: Boolean }) isDisabled!: boolean;
  @Inject({ from: "tabsProvider" }) tabsProvider: TabsProvider;
  tabInfo: TabInfo = null;
  get isActive(): boolean {
    return this.tabInfo.hash == this.tabsProvider.activeTabHash;
  }
  @Watch("title")
  onPropsChanged() {
    this.tabInfo.isDisabled = this.isDisabled;
    this.tabInfo.title = this.title;
  }
  created() {
    this.tabInfo = new TabInfo({
      title: this.title,
      isDisabled: this.isDisabled,
      id: this.tabsProvider.tabs.length,
    });
  }
  beforeMount() {
    this.tabsProvider.addTab(this.tabInfo);
  }
}
