import { WebApiService } from '../../../../../plugins/webApiService'
import { Events } from '../../../../../plugins/events'
import { SendRequestModel } from '../../../../../plugins/models/SendRequestModel'
import TransactionPersonRequest from '../../../../../transaction-services/models/TransactionPersonRequest';
import ModelTransferInternal from '../../../../../i-public-service/aba-finance/models/ModelTransferInternal';
import CheckTransferPinT from '../../../../../aba/cabinet/web/models/CheckTransferPinT';
import InternalTransferRequest from '../../../../../transaction-services/models/transaction-operations/InternalTransferRequest';
import WsResponseModelT from '../../../../../schema-builder/server/models/WsResponseModelT';
import MoneyTransactionPersonResponse from '../../../../../transaction-services/models/MoneyTransactionPersonResponse';

export default class InternalBalanceWsController {
	private url?: string;
	private cookieName: string;
	private appWebSettingsPath?: string;
	private apiUrlProperty?: string;

	GetInternalTransactionsNewEvent = new Events<WsResponseModelT<MoneyTransactionPersonResponse>>();
	CheckInternalTransferEvent = new Events<WsResponseModelT<Boolean>>();
	InternalTransferEvent = new Events<WsResponseModelT<Boolean>>();
	CheckTransferSepaEvent = new Events<WsResponseModelT<Boolean>>();
	TransferSepaEvent = new Events<WsResponseModelT<Boolean>>();

	subscribeEvents = async () => {
		const self = this;
		const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
		webApiService.on('InternalBalanceWsController', 'GetInternalTransactionsNew', 'Main', (res: WsResponseModelT<MoneyTransactionPersonResponse>) => {
			self.GetInternalTransactionsNewEvent.trigger(res);
		});
		webApiService.on('InternalBalanceWsController', 'CheckInternalTransfer', 'Main', (res: WsResponseModelT<Boolean>) => {
			self.CheckInternalTransferEvent.trigger(res);
		});
		webApiService.on('InternalBalanceWsController', 'InternalTransfer', 'Main', (res: WsResponseModelT<Boolean>) => {
			self.InternalTransferEvent.trigger(res);
		});
		webApiService.on('InternalBalanceWsController', 'CheckTransferSepa', 'Main', (res: WsResponseModelT<Boolean>) => {
			self.CheckTransferSepaEvent.trigger(res);
		});
		webApiService.on('InternalBalanceWsController', 'TransferSepa', 'Main', (res: WsResponseModelT<Boolean>) => {
			self.TransferSepaEvent.trigger(res);
		});
	}

	constructor(url?: string, cookieName?: string, appWebSettingsPath?: string, apiUrlProperty?: string) {
		this.url = url;
		this.cookieName = cookieName ?? "Auth-Token";
		this.appWebSettingsPath = appWebSettingsPath && [location.host, appWebSettingsPath].join("/");
		this.apiUrlProperty = apiUrlProperty;
		this.subscribeEvents();
	}

	async GetInternalTransactionsNew(model: TransactionPersonRequest) {
		const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
		const sendRequest = new SendRequestModel({
			ProjectName: 'Main',
			Controller: 'InternalBalanceWsController',
			Method: 'GetInternalTransactionsNew',
			Value: typeof(model) === 'object' ? JSON.stringify(model) : model,
		})

		webApiService.send(sendRequest);
	}

	async CheckInternalTransfer(model: ModelTransferInternal) {
		const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
		const sendRequest = new SendRequestModel({
			ProjectName: 'Main',
			Controller: 'InternalBalanceWsController',
			Method: 'CheckInternalTransfer',
			Value: typeof(model) === 'object' ? JSON.stringify(model) : model,
		})

		webApiService.send(sendRequest);
	}

	async InternalTransfer(request: CheckTransferPinT<InternalTransferRequest>) {
		const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
		const sendRequest = new SendRequestModel({
			ProjectName: 'Main',
			Controller: 'InternalBalanceWsController',
			Method: 'InternalTransfer',
			Value: typeof(request) === 'object' ? JSON.stringify(request) : request,
		})

		webApiService.send(sendRequest);
	}

	async CheckTransferSepa(model: ModelTransferInternal) {
		const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
		const sendRequest = new SendRequestModel({
			ProjectName: 'Main',
			Controller: 'InternalBalanceWsController',
			Method: 'CheckTransferSepa',
			Value: typeof(model) === 'object' ? JSON.stringify(model) : model,
		})

		webApiService.send(sendRequest);
	}

	async TransferSepa(model: ModelTransferInternal) {
		const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
		const sendRequest = new SendRequestModel({
			ProjectName: 'Main',
			Controller: 'InternalBalanceWsController',
			Method: 'TransferSepa',
			Value: typeof(model) === 'object' ? JSON.stringify(model) : model,
		})

		webApiService.send(sendRequest);
	}

	 // async methods
	async getInternalTransactionsNewAsync(model: TransactionPersonRequest): Promise<MoneyTransactionPersonResponse> {
		return new Promise<MoneyTransactionPersonResponse>(async (resolve, reject) => {
			const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
			const sendRequest = new SendRequestModel({
				ProjectName: 'Main',
				Controller: 'InternalBalanceWsController',
				Method: 'GetInternalTransactionsNew',
				Value: typeof(model) === 'object' ? JSON.stringify(model) : model,
				Callback: (response: WsResponseModelT<MoneyTransactionPersonResponse>) => {
					const isGeneric = typeof response.IsSuccess == 'boolean'
					const result = response.IsSuccess ? response.Value : response

					if (!isGeneric || response.IsSuccess) resolve(result as MoneyTransactionPersonResponse)
					else reject(result)
				}
			})

			webApiService.send(sendRequest);
		});
	}

	async checkInternalTransferAsync(model: ModelTransferInternal): Promise<Boolean> {
		return new Promise<Boolean>(async (resolve, reject) => {
			const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
			const sendRequest = new SendRequestModel({
				ProjectName: 'Main',
				Controller: 'InternalBalanceWsController',
				Method: 'CheckInternalTransfer',
				Value: typeof(model) === 'object' ? JSON.stringify(model) : model,
				Callback: (response: WsResponseModelT<Boolean>) => {
					const isGeneric = typeof response.IsSuccess == 'boolean'
					const result = response.IsSuccess ? response.Value : response

					if (!isGeneric || response.IsSuccess) resolve(result as Boolean)
					else reject(result)
				}
			})

			webApiService.send(sendRequest);
		});
	}

	async internalTransferAsync(request: CheckTransferPinT<InternalTransferRequest>): Promise<Boolean> {
		return new Promise<Boolean>(async (resolve, reject) => {
			const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
			const sendRequest = new SendRequestModel({
				ProjectName: 'Main',
				Controller: 'InternalBalanceWsController',
				Method: 'InternalTransfer',
				Value: typeof(request) === 'object' ? JSON.stringify(request) : request,
				Callback: (response: WsResponseModelT<Boolean>) => {
					const isGeneric = typeof response.IsSuccess == 'boolean'
					const result = response.IsSuccess ? response.Value : response

					if (!isGeneric || response.IsSuccess) resolve(result as Boolean)
					else reject(result)
				}
			})

			webApiService.send(sendRequest);
		});
	}

	async checkTransferSepaAsync(model: ModelTransferInternal): Promise<Boolean> {
		return new Promise<Boolean>(async (resolve, reject) => {
			const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
			const sendRequest = new SendRequestModel({
				ProjectName: 'Main',
				Controller: 'InternalBalanceWsController',
				Method: 'CheckTransferSepa',
				Value: typeof(model) === 'object' ? JSON.stringify(model) : model,
				Callback: (response: WsResponseModelT<Boolean>) => {
					const isGeneric = typeof response.IsSuccess == 'boolean'
					const result = response.IsSuccess ? response.Value : response

					if (!isGeneric || response.IsSuccess) resolve(result as Boolean)
					else reject(result)
				}
			})

			webApiService.send(sendRequest);
		});
	}

	async transferSepaAsync(model: ModelTransferInternal): Promise<Boolean> {
		return new Promise<Boolean>(async (resolve, reject) => {
			const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
			const sendRequest = new SendRequestModel({
				ProjectName: 'Main',
				Controller: 'InternalBalanceWsController',
				Method: 'TransferSepa',
				Value: typeof(model) === 'object' ? JSON.stringify(model) : model,
				Callback: (response: WsResponseModelT<Boolean>) => {
					const isGeneric = typeof response.IsSuccess == 'boolean'
					const result = response.IsSuccess ? response.Value : response

					if (!isGeneric || response.IsSuccess) resolve(result as Boolean)
					else reject(result)
				}
			})

			webApiService.send(sendRequest);
		});
	}

}