import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-3d8d4b33"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "transfer" }
const _hoisted_3 = { class: "title_box" }
const _hoisted_4 = { class: "mb-3" }
const _hoisted_5 = {
  key: 0,
  class: "transfer_main_container"
}
const _hoisted_6 = { class: "transfer_left_box" }
const _hoisted_7 = { class: "transfer_right_box" }
const _hoisted_8 = { key: 1 }
const _hoisted_9 = {
  key: 1,
  class: "modal-wrapper"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_transfer_acquiring_to_card = _resolveComponent("transfer-acquiring-to-card")!
  const _component_sepa = _resolveComponent("sepa")!
  const _component_account_number = _resolveComponent("account-number")!
  const _component_ui_tab_profile = _resolveComponent("ui-tab-profile")!
  const _component_user_contacts = _resolveComponent("user-contacts")!
  const _component_swift_templates = _resolveComponent("swift-templates")!
  const _component_transfer_acquiring_confrim = _resolveComponent("transfer-acquiring-confrim")!
  const _component_sepa_confrim = _resolveComponent("sepa-confrim")!
  const _component_swift_confrim = _resolveComponent("swift-confrim")!
  const _component_transfer_confrim = _resolveComponent("transfer-confrim")!
  const _component_card_transfer_confrim = _resolveComponent("card-transfer-confrim")!
  const _component_crypt_transfer_confrim = _resolveComponent("crypt-transfer-confrim")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.IsLoadPerson && _ctx.isSetTabs)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("h2", _hoisted_4, _toDisplayString(_ctx.$localization.getTranslate("Send money")), 1),
              _createTextVNode(" " + _toDisplayString(_ctx.Person.Balance.Number), 1)
            ]),
            (_ctx.Person.Balance.DateBlocking === null && _ctx.Person.Balance.DateClosed === null)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  _createElementVNode("div", _hoisted_6, [
                    _createVNode(_component_ui_tab_profile, {
                      tabs: _ctx.tabs,
                      onOnChangeTab: _ctx.onChangeTab
                    }, {
                      "5": _withCtx(() => [
                        _createVNode(_component_transfer_acquiring_to_card, {
                          onGoTransferAcquiringToCard: _ctx.AcquiringToCard,
                          SelectedTemplate: _ctx.SelectedTemplateAcquiringToCard,
                          profile: _ctx.Person,
                          Type: _ctx.Type
                        }, null, 8, ["onGoTransferAcquiringToCard", "SelectedTemplate", "profile", "Type"])
                      ]),
                      "3": _withCtx(() => [
                        _createVNode(_component_sepa, {
                          onGoTransferSepa: _ctx.GoTransferSepa,
                          SelectedTemplate: _ctx.SelectedSepa,
                          profile: _ctx.Person,
                          Type: _ctx.Type
                        }, null, 8, ["onGoTransferSepa", "SelectedTemplate", "profile", "Type"])
                      ]),
                      "2": _withCtx(() => [
                        _createVNode(_component_account_number, {
                          onGoTransferAccount: _ctx.GoTransferAccount,
                          SelectedTemplate: _ctx.SelectedAccount,
                          profile: _ctx.Person,
                          Type: _ctx.Type
                        }, null, 8, ["onGoTransferAccount", "SelectedTemplate", "profile", "Type"])
                      ]),
                      _: 1
                    }, 8, ["tabs", "onOnChangeTab"])
                  ]),
                  _createElementVNode("div", _hoisted_7, [
                    (_ctx.Type < 4)
                      ? (_openBlock(), _createBlock(_component_user_contacts, {
                          key: 0,
                          type: _ctx.Type,
                          onUseTemplate: _ctx.UseTemplateAba
                        }, null, 8, ["type", "onUseTemplate"]))
                      : _createCommentVNode("", true),
                    (_ctx.Type === 4)
                      ? (_openBlock(), _createBlock(_component_swift_templates, {
                          key: 1,
                          onUseTemplate: _ctx.UseTemplateAba
                        }, null, 8, ["onUseTemplate"]))
                      : _createCommentVNode("", true)
                  ])
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(_ctx.$localization.getTranslate("SorryYourInternalAccountHasBeenSuspended")), 1))
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.Person)
      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
          _createVNode(_component_transfer_acquiring_confrim, {
            visible: _ctx.isOpenTransferAcquiringConfrim,
            formData: _ctx.modelAcquiringToCard,
            onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isOpenTransferAcquiringConfrim = false))
          }, null, 8, ["visible", "formData"]),
          _createVNode(_component_sepa_confrim, {
            visible: _ctx.isOpenSepaConfirm,
            formData: _ctx.modelSepa,
            paymentsType: _ctx.Person.PaymentsType,
            onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isOpenSepaConfirm = false))
          }, null, 8, ["visible", "formData", "paymentsType"]),
          _createVNode(_component_swift_confrim, {
            visible: _ctx.isOpenSwiftConfirm,
            formData: _ctx.modelSwift,
            onClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.isOpenSwiftConfirm = false))
          }, null, 8, ["visible", "formData"]),
          _createVNode(_component_transfer_confrim, {
            visible: _ctx.isOpenTransferConfrim,
            formData: _ctx.modelAccount,
            onClose: _cache[3] || (_cache[3] = ($event: any) => (_ctx.isOpenTransferConfrim = false))
          }, null, 8, ["visible", "formData"]),
          _createVNode(_component_card_transfer_confrim, {
            visible: _ctx.isOpenCardTransferConfrim,
            formData: _ctx.modelCardTransfer,
            onClose: _cache[4] || (_cache[4] = ($event: any) => (_ctx.isOpenCardTransferConfrim = false))
          }, null, 8, ["visible", "formData"]),
          _createVNode(_component_crypt_transfer_confrim, {
            visible: _ctx.isCryptTransferConfrim,
            formData: _ctx.modelCryptTransfer,
            onClose: _cache[5] || (_cache[5] = ($event: any) => (_ctx.isCryptTransferConfrim = false))
          }, null, 8, ["visible", "formData"])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}