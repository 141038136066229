const newGuid = (): string => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

/**
 * Базовая модель отправки запроса на сервер через вебсокет
 */
export class SendRequestModel {
  /**
   * Название контроллер (на сервере)
   */
  Controller!: string;
  /**
   * Название метода
   */
  Method!: string;
  /**
   * Название проекта
   */
  ProjectName!: string;
  /**
   * Идентификатор запроса, что бы можно было отследить ответ. Сервер его не модифицирует и возращает в ответе.
   */
  RequestId: string;
  /**
   * Объект для отправки на сервер
   */
  Value?: any;
  /**
   * Объект для отправки на сервер
   */
  Callback?: Function;
  constructor(data?: Partial<SendRequestModel>) {
    if (data) Object.assign(this, data);
    this.RequestId = newGuid();
  }
}
