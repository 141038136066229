import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-7b12cff0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "sidebarBoxMenu" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["ui-box-menu-sidedar", { active: _ctx.show }])
    }, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ], 2)
  ]))
}