import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-49d22d27"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "wrapper" }
const _hoisted_2 = { class: "beneficiary_title" }
const _hoisted_3 = { class: "beneficiary_data_block" }
const _hoisted_4 = { class: "beneficiary_blocks" }
const _hoisted_5 = { class: "beneficiary_wrapper" }
const _hoisted_6 = { class: "inputs_block_header" }
const _hoisted_7 = { class: "beneficiary_block" }
const _hoisted_8 = { class: "beneficiary_wrapper" }
const _hoisted_9 = { class: "inputs_block_header" }
const _hoisted_10 = { class: "beneficiary_block" }
const _hoisted_11 = { class: "input_block_possession" }
const _hoisted_12 = { class: "beneficiary_wrapper" }
const _hoisted_13 = { class: "inputs_block_header" }
const _hoisted_14 = { class: "beneficiary_block" }
const _hoisted_15 = { class: "director_document_adding_wrapper" }
const _hoisted_16 = { class: "director_doc_add_block" }
const _hoisted_17 = { class: "director_doc_header" }
const _hoisted_18 = { class: "director_file_adding" }
const _hoisted_19 = { class: "director_doc_add_block" }
const _hoisted_20 = { class: "director_doc_header" }
const _hoisted_21 = { class: "director_file_adding" }
const _hoisted_22 = { class: "beneficiary_questions" }
const _hoisted_23 = { class: "beneficiary_header" }
const _hoisted_24 = { class: "beneficiary_header" }
const _hoisted_25 = { class: "beneficiary_text" }
const _hoisted_26 = { class: "continue_block" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_textarea = _resolveComponent("ui-textarea")!
  const _component_ui_button_delete = _resolveComponent("ui-button-delete")!
  const _component_ui_select = _resolveComponent("ui-select")!
  const _component_ui_input = _resolveComponent("ui-input")!
  const _component_ui_datetime_picker = _resolveComponent("ui-datetime-picker")!
  const _component_ui_select_autocomplete = _resolveComponent("ui-select-autocomplete")!
  const _component_ui_company_document_file = _resolveComponent("ui-company-document-file")!
  const _component_ui_company_document_adding_btn = _resolveComponent("ui-company-document-adding-btn")!
  const _component_ui_radio_buttons = _resolveComponent("ui-radio-buttons")!
  const _component_continue_button = _resolveComponent("continue-button")!
  const _component_ui_message_error = _resolveComponent("ui-message-error")!
  const _component_loading = _resolveComponent("loading")!
  const _directive_mask = _resolveDirective("mask")!
  const _directive_localization = _resolveDirective("localization")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.beneficiaryData)
      ? (_openBlock(), _createBlock(_component_ui_textarea, {
          key: 0,
          class: "ui_textarea",
          headerText: _ctx.$localization.getTranslate('Header.BeneficiaryWithComment'),
          modelValue: _ctx.beneficiaryData.BeneficiaryDescription,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.beneficiaryData.BeneficiaryDescription) = $event))
        }, null, 8, ["headerText", "modelValue"]))
      : _createCommentVNode("", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.beneficiaryData.BeneficiaryInfo, (info, index) => {
      return (_openBlock(), _createElementBlock("div", { key: index }, [
        _createElementVNode("div", {
          class: _normalizeClass(["", [
					'beneficiary',
					index + 1 == _ctx.beneficiaryData.BeneficiaryInfo.length ? 'main_target' : '',
				]])
        }, [
          _createElementVNode("div", _hoisted_2, [
            (index > 0)
              ? (_openBlock(), _createBlock(_component_ui_button_delete, {
                  key: 0,
                  onClick: ($event: any) => (_ctx.removeBeneficiaryInfo(info))
                }, null, 8, ["onClick"]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$localization
										.getTranslate("Header.Beneficiary.BeneficiaryInfo")
										.toUpperCase()), 1),
                _createElementVNode("div", _hoisted_7, [
                  _createVNode(_component_ui_select, {
                    class: "indentification-input",
                    noUndefined: "true",
                    items: _ctx.TitleOptions,
                    headerText: _ctx.$localization.getTranslate('Header.Title') + '*',
                    modelValue: info.Appeal,
                    "onUpdate:modelValue": ($event: any) => ((info.Appeal) = $event),
                    defaultText: _ctx.$localization.getTranslate('NotChoosen'),
                    isWrong: _ctx.checkStart && info.Appeal.length == 0
                  }, null, 8, ["items", "headerText", "modelValue", "onUpdate:modelValue", "defaultText", "isWrong"]),
                  _createVNode(_component_ui_input, {
                    headerText: _ctx.$localization.getTranslate('Header.Name') + '*',
                    class: "indentification-input",
                    isWrong: _ctx.checkStart && info.FirstName.length == 0,
                    modelValue: info.FirstName,
                    "onUpdate:modelValue": ($event: any) => ((info.FirstName) = $event)
                  }, null, 8, ["headerText", "isWrong", "modelValue", "onUpdate:modelValue"]),
                  _createVNode(_component_ui_input, {
                    headerText: _ctx.$localization.getTranslate('Header.Surname') + '*',
                    class: "indentification-input",
                    isWrong: _ctx.checkStart && info.SecondName.length == 0,
                    modelValue: info.SecondName,
                    "onUpdate:modelValue": ($event: any) => ((info.SecondName) = $event)
                  }, null, 8, ["headerText", "isWrong", "modelValue", "onUpdate:modelValue"]),
                  _createVNode(_component_ui_datetime_picker, {
                    isWrong: _ctx.checkStart && info.BirthDate == null,
                    headerText: _ctx.$localization.getTranslate('Header.DateOfBirth') + '*',
                    class: "indentification-input",
                    modelValue: info.BirthDate,
                    "onUpdate:modelValue": ($event: any) => ((info.BirthDate) = $event)
                  }, null, 8, ["isWrong", "headerText", "modelValue", "onUpdate:modelValue"]),
                  _createVNode(_component_ui_input, {
                    isWrong: _ctx.checkStart && info.BirthPlace.length == 0,
                    headerText: _ctx.$localization.getTranslate('Header.PlaceOfBirth') + '*',
                    class: "indentification-input",
                    modelValue: info.BirthPlace,
                    "onUpdate:modelValue": ($event: any) => ((info.BirthPlace) = $event)
                  }, null, 8, ["isWrong", "headerText", "modelValue", "onUpdate:modelValue"]),
                  _createVNode(_component_ui_select, {
                    valueField: "doc",
                    keyField: "key",
                    isWrong: _ctx.checkStart && info.DocumentType.length == 0,
                    class: "indentification-input",
                    noUndefined: "true",
                    items: _ctx.documents,
                    headerText: _ctx.$localization.getTranslate('Header.DocumentType') + '*',
                    modelValue: info.DocumentType,
                    "onUpdate:modelValue": ($event: any) => ((info.DocumentType) = $event)
                  }, null, 8, ["isWrong", "items", "headerText", "modelValue", "onUpdate:modelValue"]),
                  _createVNode(_component_ui_input, {
                    headerText: _ctx.$localization.getTranslate('Header.DocumentNumber') + '*',
                    class: "indentification-input",
                    isWrong: _ctx.checkStart && info.DocumentNumber.length == 0,
                    modelValue: info.DocumentNumber,
                    "onUpdate:modelValue": ($event: any) => ((info.DocumentNumber) = $event)
                  }, null, 8, ["headerText", "isWrong", "modelValue", "onUpdate:modelValue"]),
                  _createVNode(_component_ui_input, {
                    headerText: _ctx.$localization.getTranslate('Header.IssuedBy') + '*',
                    class: "indentification-input",
                    isWrong: _ctx.checkStart && info.DocumentAuthority.length == 0,
                    modelValue: info.DocumentAuthority,
                    "onUpdate:modelValue": ($event: any) => ((info.DocumentAuthority) = $event)
                  }, null, 8, ["headerText", "isWrong", "modelValue", "onUpdate:modelValue"]),
                  _createVNode(_component_ui_datetime_picker, {
                    headerText: _ctx.$localization.getTranslate('Header.DateOfIssue') + '*',
                    class: "indentification-input",
                    isWrong: _ctx.checkStart && info.DocumentDateOfIssue == null,
                    modelValue: info.DocumentDateOfIssue,
                    "onUpdate:modelValue": ($event: any) => ((info.DocumentDateOfIssue) = $event)
                  }, null, 8, ["headerText", "isWrong", "modelValue", "onUpdate:modelValue"]),
                  _createVNode(_component_ui_datetime_picker, {
                    headerText: _ctx.$localization.getTranslate('Header.DateOfExpiry') + '*',
                    class: "indentification-input",
                    isWrong: _ctx.checkStart && info.DocumentDateOfExpiry == null,
                    modelValue: info.DocumentDateOfExpiry,
                    "onUpdate:modelValue": ($event: any) => ((info.DocumentDateOfExpiry) = $event)
                  }, null, 8, ["headerText", "isWrong", "modelValue", "onUpdate:modelValue"]),
                  _createVNode(_component_ui_select_autocomplete, {
                    keyField: "value",
                    valueField: "text",
                    items: _ctx.countries,
                    class: "indentification-input",
                    headerText: 
										_ctx.$localization.getTranslate('Header.CountryOfIssuance') + '*'
									,
                    noUndefined: true,
                    isWrong: _ctx.checkStart && info.DocumentCountryCode.length == 0,
                    modelValue: info.DocumentCountryCode,
                    "onUpdate:modelValue": ($event: any) => ((info.DocumentCountryCode) = $event)
                  }, null, 8, ["items", "headerText", "isWrong", "modelValue", "onUpdate:modelValue"]),
                  _createVNode(_component_ui_input, {
                    isWrong: _ctx.checkStart && info.Email.length == 0,
                    headerText: _ctx.$localization.getTranslate('Header.Email') + '*',
                    class: "indentification-input",
                    modelValue: info.Email,
                    "onUpdate:modelValue": ($event: any) => ((info.Email) = $event)
                  }, null, 8, ["isWrong", "headerText", "modelValue", "onUpdate:modelValue"]),
                  _createVNode(_component_ui_input, {
                    isWrong: _ctx.checkStart && info.Phone.length == 0,
                    headerText: _ctx.$localization.getTranslate('Header.Phone') + '*',
                    class: "indentification-input",
                    modelValue: info.Phone,
                    "onUpdate:modelValue": [($event: any) => ((info.Phone) = $event), ($event: any) => (_ctx.onUpdatePhone(info))]
                  }, null, 8, ["isWrong", "headerText", "modelValue", "onUpdate:modelValue"])
                ])
              ]),
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.$localization
										.getTranslate("Header.Company.RegisteredAddress")
										.toUpperCase()), 1),
                _createElementVNode("div", _hoisted_10, [
                  _createVNode(_component_ui_select_autocomplete, {
                    modelValue: info.AddressCountryCode,
                    "onUpdate:modelValue": ($event: any) => ((info.AddressCountryCode) = $event),
                    keyField: "value",
                    valueField: "text",
                    items: _ctx.countries,
                    class: "indentification-input",
                    headerText: _ctx.$localization.getTranslate('Header.Country') + '*',
                    noUndefined: true,
                    isWrong: _ctx.checkStart && info.AddressCountryCode.length == 0
                  }, null, 8, ["modelValue", "onUpdate:modelValue", "items", "headerText", "isWrong"]),
                  _createVNode(_component_ui_input, {
                    headerText: _ctx.$localization.getTranslate('Header.StateRegion'),
                    class: "indentification-input",
                    modelValue: info.AddressState,
                    "onUpdate:modelValue": ($event: any) => ((info.AddressState) = $event)
                  }, null, 8, ["headerText", "modelValue", "onUpdate:modelValue"]),
                  _createVNode(_component_ui_input, {
                    headerText: _ctx.$localization.getTranslate('Header.City') + '*',
                    class: "indentification-input",
                    modelValue: info.AddressCity,
                    "onUpdate:modelValue": ($event: any) => ((info.AddressCity) = $event),
                    isWrong: _ctx.checkStart && info.AddressCity.length == 0
                  }, null, 8, ["headerText", "modelValue", "onUpdate:modelValue", "isWrong"]),
                  _createVNode(_component_ui_input, {
                    headerText: _ctx.$localization.getTranslate('Header.AddressZip') + '*',
                    class: "indentification-input",
                    modelValue: info.AddressZip,
                    "onUpdate:modelValue": ($event: any) => ((info.AddressZip) = $event),
                    isWrong: _ctx.checkStart && info.AddressZip.length == 0
                  }, null, 8, ["headerText", "modelValue", "onUpdate:modelValue", "isWrong"]),
                  _createVNode(_component_ui_input, {
                    headerText: _ctx.$localization.getTranslate('Header.Street') + '*',
                    class: "indentification-input",
                    modelValue: info.AddressStreet,
                    "onUpdate:modelValue": ($event: any) => ((info.AddressStreet) = $event),
                    isWrong: _ctx.checkStart && info.AddressStreet.length == 0
                  }, null, 8, ["headerText", "modelValue", "onUpdate:modelValue", "isWrong"]),
                  _createVNode(_component_ui_input, {
                    headerText: _ctx.$localization.getTranslate('Header.BuildingNumber') + '*',
                    class: "indentification-input",
                    modelValue: info.AddressHome,
                    "onUpdate:modelValue": ($event: any) => ((info.AddressHome) = $event),
                    isWrong: _ctx.checkStart && info.AddressHome.length == 0
                  }, null, 8, ["headerText", "modelValue", "onUpdate:modelValue", "isWrong"]),
                  _createVNode(_component_ui_input, {
                    headerText: _ctx.$localization.getTranslate('Header.OfficeApartment'),
                    class: "indentification-input",
                    modelValue: info.AddressApartment,
                    "onUpdate:modelValue": ($event: any) => ((info.AddressApartment) = $event)
                  }, null, 8, ["headerText", "modelValue", "onUpdate:modelValue"])
                ])
              ]),
              _createElementVNode("div", _hoisted_11, [
                _withDirectives(_createVNode(_component_ui_input, {
                  headerText: _ctx.$localization.getTranslate('Header.Possession') + ' %*',
                  class: "indentification-input",
                  modelValue: info.OwnershipPercent,
                  "onUpdate:modelValue": ($event: any) => ((info.OwnershipPercent) = $event),
                  isWrong: 
									_ctx.checkStart &&
									(!info.OwnershipPercent || info.OwnershipPercent.toString().length == 0)
								
                }, null, 8, ["headerText", "modelValue", "onUpdate:modelValue", "isWrong"]), [
                  [_directive_mask, '###']
                ])
              ]),
              _createElementVNode("div", _hoisted_12, [
                _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.$localization.getTranslate("Header.Documents").toUpperCase()), 1),
                _createElementVNode("div", _hoisted_14, [
                  _createElementVNode("div", _hoisted_15, [
                    _createElementVNode("div", _hoisted_16, [
                      _createElementVNode("span", _hoisted_17, _toDisplayString(_ctx.$localization.getTranslate("Passport") + "/ID") + "*", 1),
                      _createElementVNode("div", _hoisted_18, [
                        (
													(info.DocumentFile && info.DocumentFile.FileBase64) ||
													info.DocumentFileUrl
												)
                          ? (_openBlock(), _createBlock(_component_ui_company_document_file, {
                              key: 0,
                              fileName: 
													info.DocumentFile
														? info.DocumentFile.FileName
														: info.DocumentFileUrl
												,
                              onDeleteFile: ($event: any) => (_ctx.deleteDocumentFile(info)),
                              isUrl: info.DocumentFileUrl
                            }, null, 8, ["fileName", "onDeleteFile", "isUrl"]))
                          : _createCommentVNode("", true),
                        _createVNode(_component_ui_company_document_adding_btn, {
                          isWrong: 
													!(
														(info.DocumentFile && info.DocumentFile.FileBase64) ||
														info.DocumentFileUrl
													) && _ctx.checkStart
												,
                          buttonColor: "#8e97a1",
                          buttonText: 
													(info.DocumentFile && info.DocumentFile.FileBase64) ||
													info.DocumentFileUrl
														? `${_ctx.$localization.getTranslate(
																'Identification.EditCompanyDocumentBtn'
														  )}`
														: `${_ctx.$localization.getTranslate('Button.AddDocument')}`
												,
                          onAddedFile: ($event: any) => (_ctx.onAddDocumentFile(info, $event))
                        }, null, 8, ["isWrong", "buttonText", "onAddedFile"])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_19, [
                      _createElementVNode("span", _hoisted_20, _toDisplayString(_ctx.$localization.getTranslate("Header.ApproveOfAddress") + "*"), 1),
                      _createElementVNode("div", _hoisted_21, [
                        (
													(info.ApproveOfAddressFile &&
														info.ApproveOfAddressFile.FileBase64) ||
													info.ApproveOfAddressFileUrl
												)
                          ? (_openBlock(), _createBlock(_component_ui_company_document_file, {
                              key: 0,
                              fileName: 
													info.ApproveOfAddressFile
														? info.ApproveOfAddressFile.FileName
														: info.ApproveOfAddressFileUrl
												,
                              onDeleteFile: ($event: any) => (_ctx.deleteApproveOfAddressFile(info)),
                              isUrl: info.ApproveOfAddressFileUrl
                            }, null, 8, ["fileName", "onDeleteFile", "isUrl"]))
                          : _createCommentVNode("", true),
                        _createVNode(_component_ui_company_document_adding_btn, {
                          isWrong: 
													!(
														(info.ApproveOfAddressFile &&
															info.ApproveOfAddressFile.FileBase64) ||
														info.ApproveOfAddressFileUrl
													) && _ctx.checkStart
												,
                          buttonColor: "#8e97a1",
                          buttonText: 
													(info.ApproveOfAddressFile &&
														info.ApproveOfAddressFile.FileBase64) ||
													info.ApproveOfAddressFileUrl
														? `${_ctx.$localization.getTranslate(
																'Identification.EditCompanyDocumentBtn'
														  )}`
														: `${_ctx.$localization.getTranslate('Button.AddDocument')}`
												,
                          onAddedFile: ($event: any) => (_ctx.onAddApproveOfAdressDocument(info, $event))
                        }, null, 8, ["isWrong", "buttonText", "onAddedFile"])
                      ])
                    ])
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_22, [
                _withDirectives(_createElementVNode("div", _hoisted_23, null, 512), [
                  [_directive_localization, 'Question.PoliticallySignificant']
                ]),
                _createVNode(_component_ui_radio_buttons, {
                  items: _ctx.buttonItems,
                  valueField: "text",
                  keyField: "value",
                  class: "beneficiary_radio_btn",
                  radioName: `${index}pep1`,
                  modelValue: info.IsPep,
                  "onUpdate:modelValue": ($event: any) => ((info.IsPep) = $event)
                }, null, 8, ["items", "radioName", "modelValue", "onUpdate:modelValue"]),
                _withDirectives(_createElementVNode("div", _hoisted_24, null, 512), [
                  [_directive_localization, 'Question.TaxResidency']
                ]),
                _withDirectives(_createElementVNode("div", _hoisted_25, null, 512), [
                  [_directive_localization, 'Question.MeetAtLeast']
                ]),
                _createVNode(_component_ui_radio_buttons, {
                  items: _ctx.buttonItems,
                  valueField: "text",
                  keyField: "value",
                  class: "beneficiary_radio_btn",
                  radioName: `${index}pep2`,
                  modelValue: info.IsUsaTaxResident,
                  "onUpdate:modelValue": ($event: any) => ((info.IsUsaTaxResident) = $event)
                }, null, 8, ["items", "radioName", "modelValue", "onUpdate:modelValue"])
              ])
            ]),
            (index + 1 == _ctx.beneficiaryData.BeneficiaryInfo.length)
              ? _withDirectives((_openBlock(), _createElementBlock("button", {
                  key: 0,
                  class: "beneficiary_add_btn",
                  onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.upCount && _ctx.upCount(...args)))
                }, null, 512)), [
                  [_directive_localization, 'Button.Add']
                ])
              : _createCommentVNode("", true)
          ])
        ], 2)
      ]))
    }), 128)),
    _createElementVNode("div", _hoisted_26, [
      (!_ctx.isLoading)
        ? (_openBlock(), _createBlock(_component_continue_button, {
            key: 0,
            class: "beneficiary_continue",
            onClicked: _ctx.beginContinue
          }, null, 8, ["onClicked"]))
        : _createCommentVNode("", true),
      _createVNode(_component_ui_message_error, { class: "ml-2" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString((_ctx.errorMessage ? "*" : "") + _ctx.errorMessage), 1)
        ]),
        _: 1
      }),
      (_ctx.isLoading)
        ? (_openBlock(), _createBlock(_component_loading, {
            key: 1,
            class: "continue_loading"
          }))
        : _createCommentVNode("", true)
    ])
  ]))
}