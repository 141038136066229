import UserType from '../../../../../common/enums/UserType';
import EnumUserPaymentsType from '../../../../../aba/data/enums/EnumUserPaymentsType';

export default class LoginResponse  {
	Id!: number;
	Email!: string;
	Role!: string;
	Login!: string;
	DateViewMessage?: Date;
	Identified: boolean = false;
	TypeUserId!: UserType;
	IsTwoFactorAuthentication: boolean = false;
	Name!: string;
	PaymentsType!: EnumUserPaymentsType;
	CompanyName!: string;
	AllowMassTransfers: boolean = false;
	SessionToken!: string;
	constructor(obj?: Partial<LoginResponse>) {
		if (obj) {
			Object.assign(this, obj);
		}
	}
}
