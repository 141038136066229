
import { Vue, Options, Prop } from "vue-property-decorator";
@Options({
	name: "modal-fullscreen",
})
export default class ModalFullscreen extends Vue {
	@Prop({ default: false }) visible: boolean;
	@Prop({ default: "2" }) type: string;
	@Prop({ default: false }) hideHeader: boolean;
	@Prop({ default: true }) hideFooter: boolean;

	isVisibleBlock(isVisible: boolean) {
		if (isVisible) {
			this.overflowHiddenBody();
		} else {
			this.overflowVisibleBody();
		}
		return {
			opacity: isVisible ? `1` : `0`,
			visibility: isVisible ? `visible` : `hidden`,
			background: this.background,
		};
	}

	get background(): string {
		switch (this.type) {
			case "1":
				return "#334256";
			case "2":
				return "rgba(0, 0, 0, 0.75)";
			default:
				return "#334256";
		}
	}

	get getScale() {
		return {
			transform: this.visible ? `scale(1)` : `scale(0.8)`,
		};
	}

	overflowHiddenBody() {
		const rootElement = document.body;
		rootElement.style.top = -document.documentElement.scrollTop + "px";
		rootElement.style.overflow = "hidden";
		rootElement.style.width = "100%";
	}
	overflowVisibleBody() {
		const rootElement = document.body;
		const topPosition = rootElement.style.top;
		rootElement.style.overflow = "";
		rootElement.style.width = "100%";
		rootElement.style.top = "unset";
		const scrollTop = -parseFloat(topPosition.replace("px", ""));
		if (!isNaN(scrollTop)) {
			document.documentElement.scrollTop = scrollTop;
		}
	}

	close() {
		this.$emit("close");
	}
}
