import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-5a13d4ee"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "button_text" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "button",
    style: _normalizeStyle(_ctx.getStyle),
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.click && _ctx.click(...args)))
  }, [
    _createElementVNode("span", _hoisted_1, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ]),
    (_ctx.isArrow)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          style: _normalizeStyle({ height: `${_ctx.fz}px` }),
          src: require(`@assets/img/Icons/${_ctx.iconPath}.svg`),
          alt: ""
        }, null, 12, _hoisted_2))
      : _createCommentVNode("", true)
  ], 4))
}