import ModelStreamFile from '@/api/main/dataSource/api/i-public-service/aba-finance/models/ModelStreamFile';

export default class IdentificationModel {
  PassportNumber: string = "";
  PassportIssuedBy: string = "";
  PassportIssuedDate: string = null;
  PassportCountryCode: string = "";
  ScanPassport: ModelStreamFile = null;
  ScanUtilityBill: ModelStreamFile = null;
  Street: string = "";
  IndexCode: string = "";
  ErrorMessage: string = "";
  CountryCode: string = "";
  State: string = "";
  City: string = "";

  constructor(obj?: Partial<IdentificationModel>) {
    if (obj) {
      Object.assign(this, obj)
    }
  }
}
