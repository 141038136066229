import LegalPartnersSaveRequest from '../../../identification-services/models/legal-business-profile/LegalPartnersSaveRequest';
import PlannedTransactionsLimits from '../../../identification-services/models/legal-business-profile/PlannedTransactionsLimits';

export default class LegalBusinessProfileSaveDataRequest  {
	LegalUserId!: number;
	BusinessActivityDescription!: string;
	LegalPartners!: Array<LegalPartnersSaveRequest>;
	PlannedTransactionsLimits!: PlannedTransactionsLimits;
	BusinessActivity!: Array<number>;
	BusinessActivityOtherText!: string;
	constructor(obj?: Partial<LegalBusinessProfileSaveDataRequest>) {
		if (obj) {
			Object.assign(this, obj);
		}
	}
}
