
import { Options, Vue } from "vue-class-component";
import UiTableHeaderItem from "@views/components/ui-table/ui-table-header-item.vue";
import UiTableBodyItem from "@views/components/ui-table/ui-table-body-item.vue";
import UiTableInput from "@views/components/ui-table/ui-table-input.vue";
@Options<UiTableComponent>({
  components: { UiTableHeaderItem, UiTableBodyItem, UiTableInput },
  props: {
    items: { type: Array, default: [] },
    columnGap: { default: 1, type: Number },
    rowGap: { default: 1, type: Number },
    templateColumns: { default: "", type: String },
    selectLine: { type: Number, default: 0 },
    key: { type: String, default: "Id" },
  },
  watch: {
    templateColumns(value) {
      this.onCangeTemplateColumns();
    },
  },
})
export default class UiTableComponent extends Vue {
  items: Object = [];
  readonly columnGap = 1;
  readonly rowGap = 1;
  readonly templateColumns = "";
  readonly selectLine = 0;
  readonly key = "Id";

  selLine = this.selectLine;
  getKey(item: Object): string {
    // const key = item[this.key];
    // if (!key) {
    //   console.warn(`key ${key} not found`);
    // }
    // return key;
    return this.newGuid();
  }
  newGuid(): string {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }
  styleObject: Object = new Object();
  created() {
    this.styleObject = {
      "grid-column-gap": this.columnGap + "px",
      "grid-row-gap": this.rowGap + "px",
      "grid-template-columns": this.templateColumns,
    };
  }

  onCangeTemplateColumns() {
    this.styleObject == new Object();
    this.styleObject = {
      "grid-column-gap": this.columnGap + "px",
      "grid-row-gap": this.rowGap + "px",
      "grid-template-columns": this.templateColumns,
    };
  }
  getInnerTableStyle(idRow: number) {
    let styleInnerObject = {
      "grid-row-start": idRow + 2,
      "grid-row-end": idRow + 2,
      "grid-column-start": 1,
      "grid-column-end": this.templateColumns.split(" ").length + 1,
    };
    return styleInnerObject;
  }

  changeCol(v1: any, v2: any) {
    this.selLine = v1;
  }
}
