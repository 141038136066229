
import { Vue, Options } from "vue-property-decorator";
import Language from "../../../models/Language";
import LocaleProvider from "@/plugins/LocalizationStore/localization";

@Options({
	name: "language-tab",
})
export default class LanguageTab extends Vue {
	Languages: Array<Language> = [];
	currentLanguage: string = "";

	created() {
		this.init();
	}

	init() {
		this.$localization.getLanguages().forEach((l) => {
			this.Languages.push(
				new Language({
					Id: l.id,
					Name: l.translateKey,
					ImageName: l.ImageName,
					HtmlId: l.HtmlId,
				})
			);
		});
		this.currentLanguage = this.$localization.currentLang;
	}
	ChangeLang(language: Language) {
		LocaleProvider.setCurrentLang(language.HtmlId);
		this.currentLanguage = this.$localization.currentLang;
		this.$emit("changeLang");
	}

	getImage(nameFile: string) {
		return {
			backgroundImage: `url('~@assets/img/Icons/${nameFile}')`,
		};
	}
}
