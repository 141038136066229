import Cookies from 'js-cookie'
import { MessageResponseBaseModel } from './models/MessageResponseBaseModel'
import { MessageResponseModel } from './models/MessageResponseModel'
import { SendRequestModel } from './models/SendRequestModel'
import { PingService } from './pingService'

/**
 * * Сервис для работы с сокетом
 */
export class WebApiService {
  /**
   * * Сокет
   */
  private websocket: WebSocket | null = null
  /**
   * * Ссылка на подключение к вебсокету
   */
  wsUrl: string = ''
  /**
   * * Включить реконнекты при потере соединения
   * @type {boolean}
   * @memberof WebApiService
   */
  isReconnect: boolean = true
  /**
   * * Время простоя перед реконнектом после потери соединения
   * @type {number}
   * @memberof WebApiService
   */
  private reconnectTimeouts: number[] = [0, 3, 5, 25, 60]
  /**
   * * Текущий индекс реконекта
   */
  private currentReconnectIndex: number = -1
  /**
   * * Флаг обозначающий наличие подключения к вебсокету
   *
   * @type {boolean}
   * @memberof WebApiService
   */
  connected: boolean = false
  /**
   * * Список кэлбэк функци, которе необходимо выполнить после того, как придет ответ от сервера
   */
  handlerMessages: MessageResponseModel[] = []
  /**
   * * Очередь неотправленных сообщений
   */
  sendOrder: SendRequestModel[] = []
  /**
   * * Активен ли пинг
   */
  pingEnabled: boolean = false
  /**
   * * Сервис для отправки пингов
   */
  pingService?: PingService
  /**
   * * Количество открытий подключения
   */
  onOpenedTimesCount: number = 0
  /**
   * * Активено ли принудительное переподключение
   */
  forcedReconnectEnabled: boolean = false
  /**
   * * Функция для закрытия предыдущего сокета
   */
  previousWebsocketClose?: any
  /**
   * * Таймер для принудительного переподключения
   */
  reconnectTimer?: any
  /**
   * * Интервал принудительного переподключения
   */
  forcedReconnectTime = 1000 * 60 * 5

  /**
   * * Создание GUID
   * @returns
   */
  private static newGuid(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == 'x' ? r : (r & 0x3) | 0x8
        return v.toString(16)
      }
    )
  }
  /**
   * * Получить токен пользователя из Cookies
   * @param cookieName Название куки
   * @returns Токен пользователя
   */
  private static getCookieToken(cookieName: string): string {
    var token = Cookies.get(cookieName)
    if (!token) token = this.newGuid().toString()

    // 30 дней
    Cookies.set(cookieName, token, {
      expires: new Date(new Date().getTime() + 30 * 24 * 60 * 60 * 1000),
    })

    return token
  }
  /**
   * * Добавить токен пользователя в URL
   * @param url URL подключения к вебсокету
   * @param cookieName Название куки
   * @returns URL подключения к вебсокету
   */
  private static addTokenToUrl(url: string, cookieName: string): string {
    var token = this.getCookieToken(cookieName)
    if (url.indexOf('?') >= 0) return url + '&token=' + token
    return url + '?token=' + token
  }
  /**
   * * Получение appWebSettings
   * @param path Путь
   */
  private static async fetchAppWebSettings(
    path: string
  ): Promise<Record<string, string> | undefined> {
    const startAuthPart = document.URL.indexOf('//') + 2
    const endAuthPart = document.URL.indexOf('@')

    const time = new Date().getTime()

    let fetchOptions = {}
    let fetchUrl = `${location.protocol}//${path}?v=${time}`

    if (endAuthPart >= 0) {
      fetchOptions = {
        headers: new Headers({
          Authorization:
            'Basic ' +
            window.btoa(document.URL.substring(startAuthPart, endAuthPart)),
          'Content-Type': 'application/json',
        }),
      }
    }

    return fetch(fetchUrl, fetchOptions).then(async (res) => {
      return await res.text().then((result) => {
        return JSON.parse(result)
      })
    })
  }
  /**
   * * Чтение файла appWebSetting и получения URL для подключения
   * @param path Путь к файлу appWebSettings
   * @param property Свойство которое необходимо прочитать
   * @returns
   */
  private static async readUrlFromAppWebSettings(
    path: string,
    property: string
  ): Promise<string | undefined> {
    // Находим URL из объекта window
    const urlFromWindow = (window as any).appWebSettingsWsInfo?.[
      `${path}:${property}`
    ] as string | undefined
    if (urlFromWindow) return urlFromWindow

    // Настройки приложения
    const appWebSettings = await this.fetchAppWebSettings(path)
    // Ссылка на подключение
    const url = appWebSettings?.[property]

    if (!(window as any).appWebSettingsWsInfo) {
      ;(window as any).appWebSettingsWsInfo = {
        [`${path}:${property}`]: url,
      }
    } else {
      ;(window as any).appWebSettingsWsInfo[`${path}:${property}`] = url
    }

    if (!url) {
      throw new Error('Конфигурация appWebSettings не настроена')
    }

    return url
  }
  /**
   * * Получить URL для подключения к вебсокеты
   * @param url URL для подключения из конфига
   * @param cookieName Название куки
   * @param appWebSettingsPath Путь к файлу appWebSettings
   * @param apiUrlProperty Название свойства для чтения URL для подключения к вебсокету
   * @returns
   */
  private static async getWsUrl(
    url?: string,
    cookieName: string = 'Auth-Token',
    appWebSettingsPath?: string,
    apiUrlProperty?: string
  ) {
    if (url) {
      return this.addTokenToUrl(url, cookieName)
    } else {
      // Проверки на обязательные параметры
      if (!appWebSettingsPath) {
        throw new Error(
          'Необходимо передать apiUrl или путь к appWebSettings файлу'
        )
      }
      if (!apiUrlProperty) {
        throw new Error(
          'Необходимо указать AppWebSettings/ApiProperty в конфигурации'
        )
      }

      // Чтение URL для подключения из файла appWebSettings
      const urlFromAppWebSettings = await this.readUrlFromAppWebSettings(
        appWebSettingsPath,
        apiUrlProperty
      )
      if (!urlFromAppWebSettings) {
        throw new Error(
          'Неудалось прочитать URL подключения из appWebSettings файла'
        )
      }

      return this.addTokenToUrl(urlFromAppWebSettings, cookieName)
    }
  }
  /**
   * * Получить экземпляр сервиса
   */
  static async getInstance(
    url?: string,
    cookieName: string = 'Auth-Token',
    appWebSettingsPath?: string,
    apiUrlProperty?: string,
    pingEnabled?: boolean
  ): Promise<WebApiService> {
    // Ссылка на подклюение к вебсокету
    const wsUrl = await this.getWsUrl(
      url,
      cookieName,
      appWebSettingsPath,
      apiUrlProperty
    )

    // Проверяем есть ли экземпляр в объекте window
    let instance = (window as any).websocketInstances?.[wsUrl]
    if (!instance) {
      // Создаем новый экземпляр и записываем в объект window
      instance = new WebApiService(wsUrl, pingEnabled)

      if (!(window as any).websocketInstances) {
        ;(window as any).websocketInstances = {
          [wsUrl]: instance,
        }
      } else {
        ;(window as any).websocketInstances[wsUrl] = instance
      }
    }

    return instance
  }
  /**
   * * Подключиться к вебсокету
   */
  private async connect() {
    if (this.forcedReconnectEnabled) {
      if (this.websocket) {
        this.unsubscribeToEvents()
      }
    }

    this.websocket = new WebSocket(this.wsUrl)
    // Если вебсокет уже был открыт раньше, отправляем отложенные сообщения
    if (this.websocket?.readyState === 1) {
      this.onOpen()
    }
    // Подписываемся на события
    this.subscribeToEvents()
  }
  /**
   * * Обработка открытия вебсокет соединения
   */
  onOpen() {
    if (this.forcedReconnectEnabled) {
      if (this.previousWebsocketClose) {
        this.websocket?.removeEventListener('close', this.onClose.bind(this))
        ;() => this.previousWebsocketClose?.()
        this.previousWebsocketClose = undefined
      }
  
      if (!this.reconnectTimer) {
        this.reconnectTimer = setTimeout(() => {
          this.previousWebsocketClose = this.websocket?.close
          this.connect()
          clearTimeout(this.reconnectTimer)
          this.reconnectTimer = undefined
        }, this.forcedReconnectTime)
      }
    }

    this.onOpenedTimesCount++

    if (this.sendOrder?.length) {
      const filteredSendOrder = this.filterSendOrderFunc(this.sendOrder)
      if (filteredSendOrder?.length) {
        filteredSendOrder.forEach((query) => this.websocket?.send(query))
        if (this.onOpenedTimesCount > 1) this.clearQueryStorage()
      }
    }
    this.sendOrder = []

    this.currentReconnectIndex = -1
    this.connected = true

    if (this.pingEnabled && this.websocket) {
      this.pingService = new PingService(this, this.websocket)
    }
  }
  /**
   * * Отправка сообщения
   * @param data Данные для отправки
   * @returns
   */
  send(data: Partial<SendRequestModel>) {
    // Если метод асинхронный
    if (data.Callback) {
      this.handlerMessages.push(
        new MessageResponseModel({
          Controller: data.Controller,
          Method: data.Method,
          ProjectName: data.ProjectName,
          RequestId: WebApiService.newGuid(),
          Callback: data.Callback,
        })
      )
    }

    if (this.websocket?.readyState != 1 || !window.navigator.onLine) {
      this.addQueryInOrder(new SendRequestModel(data))
      return
    }

    this.websocket.send(
      data && typeof data != 'string' ? JSON.stringify(data) : (data as string)
    )
  }
  /**
   * * Подписаться на события
   * @returns
   */
  private subscribeToEvents = () => {
    if (!this.websocket) return

    this.websocket.addEventListener('message', this.onMessage.bind(this))
    this.websocket.addEventListener('open', this.onOpen.bind(this))
    this.websocket.addEventListener('error', this.onError.bind(this))
    this.websocket.addEventListener('close', this.onClose.bind(this))
  }
  /**
   * * Отписаться от событий
   * @returns
   */
  private unsubscribeToEvents = () => {
    if (!this.websocket) return
    this.websocket.removeEventListener('message', this.onMessage.bind(this))
    this.websocket.removeEventListener('open', this.onOpen.bind(this))
    this.websocket.removeEventListener('error', this.onError.bind(this))
    this.websocket.removeEventListener('close', this.onClose.bind(this))
    this.connected = false
  }
  /**
   * * Отписка по контроллеру и методу
   */
  unsubscribe(project: string, controller: string, method: string) {
    this.handlerMessages = this.handlerMessages.filter((x) => {
      return (
        project != x.ProjectName &&
        controller != x.Controller &&
        method != x.Method
      )
    })
  }
  /**
   * * Обработка сообщений от вебсокета
   * @param msg Сообщение вебсокета
   * @returns
   */
  onMessage = (msg: MessageEvent) => {
    try {
      const data = JSON.parse(msg.data) as MessageResponseBaseModel

      if (!data.RequestId) return

      var callers = this.handlerMessages.filter(
        (x) =>
          x.RequestId == data.RequestId ||
          (x.Controller == data.Class &&
            x.Method == data.Method
            // &&            x.ProjectName == data.ProjectName
          )
      )

      if (callers) {
        callers.forEach((x) => x.Callback(data))

        this.handlerMessages = this.handlerMessages.filter(
          (x) => x.RequestId != data.RequestId
        )
      }
    } catch (e) {
      console.error('Error msg', e)
    }
  }
  /**
   * * Обработка ошибки вебсокета
   * @param e Ошибка
   */
  onError(e: Event) {
    console.error('Websocket unexpected ERROR', e)
  }
  /**
   * * Обработка закрытия вебсокета
   * @param e
   * @returns
   */
  async onClose(e: CloseEvent | Event) {
    console.error('onClose', e)

    // Проверяем включены ли реконнекты
    if (!this.isReconnect) return

    await this.Reconect()
  }
  /**
   * * Подписка на событие
   * @param controller Название контроллер (на сервере)
   * @param method Название метода
   * @param projectName Название проекта
   * @param callback Функци для обработка ответа от сервера
   */
  on(
    controller: string,
    method: string,
    projectName: string,
    callback: Function
  ) {
    this.handlerMessages.push(
      new MessageResponseModel({
        Controller: controller,
        Method: method,
        ProjectName: projectName,
        RequestId: WebApiService.newGuid().toString(),
        Callback: callback,
      })
    )
  }
  /**
   * * Переподключение
   */
  private async Reconect() {
    await this.delay()
    this.unsubscribeToEvents()
    this.websocket?.close()
    this.connect()
  }
  /**
   * * Блокируем поток на определенное время
   */
  private delay = () =>
    new Promise((resolve) => {
      if (this.currentReconnectIndex + 1 <= this.reconnectTimeouts.length)
        this.currentReconnectIndex++

      return setTimeout(
        resolve,
        this.reconnectTimeouts[this.currentReconnectIndex] * 1000
      )
    })
  /**
   * * Функция для фильтрации запросов отправляемых после реконнекта
   */
  filterSendOrderFunc = (sendOrder: SendRequestModel[]) => {
    const controller = 'ExerciseAnswerSaveVersion1'
    const method = 'SaveAnswer'

    let order = sendOrder.map((x) => new SendRequestModel(x))
    order.forEach((x) => {
      if (x.Value) {
        if (typeof x.Value == 'string') {
          x.Value = JSON.parse(x.Value)
        }
      } else {
        x.Value = ''
      }
    })

    const nonAnswerRequests = order.filter(
      (x) => !x.Controller.includes(controller) && x.Method != method
    )
    order = order.filter(
      (x) => x.Controller.includes(controller) && x.Method == method
    )

    const exerciseIds = Array.from(new Set(order.map((x) => x.Value.Id)))

    const requiredRequests = exerciseIds.map((id) => {
      const requests = order.filter((x) => x.Value.Id == id)
      return requests[requests.length - 1]
    })

    const result = nonAnswerRequests.concat(requiredRequests)
    result.forEach((r) => (r.Value = JSON.stringify(r.Value)))
    return result.map((r) => JSON.stringify(r))
  }
  /**
   * * Добавление запроса в очередь
   */
  private addQueryInOrder(sendData: SendRequestModel) {
    this.sendOrder.push(sendData)
    this.saveQueryInStorage(sendData)
  }
  /**
   * * Сохранение запроса в локальном хранилище
   */
  private saveQueryInStorage(query: SendRequestModel) {
    try {
      const controller = 'ExerciseAnswerSaveVersion1'
      const method = 'SaveAnswer'

      if (!query.Controller.includes(controller) && query.Method != method) {
        return
      }

      let queryStorageString = localStorage.getItem('queryStorage')
      let queryStorage: any[] = []
      if (queryStorageString) {
        queryStorage = JSON.parse(queryStorageString)
      }
      query.Value = JSON.parse(query.Value)
      query.Value.DateCreateAnswer = new Date().toISOString()

      queryStorage.push(query)

      localStorage.setItem('queryStorage', JSON.stringify(queryStorage))
    } catch (ex) {
      console.error('saveQueryInStorage error:', ex)
    }
  }
  /**
   * * Очистка локального хранилища запросов
   */
  private clearQueryStorage() {
    localStorage.setItem('queryStorage', JSON.stringify([]))
  }

  constructor(wsUrl: string, pingEnabled?: boolean) {
    this.wsUrl = wsUrl
    this.pingEnabled = !!pingEnabled
    this.connect()
  }
}
