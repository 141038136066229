
import NaturalPersonModel from "@/views/models/NaturalPersonModel";
import TransferAcquiringToCardTemplateModel from "@/views/models/TemplatesTrasnfer/TransferAcquiringToCardTemplateModel";
import FormTransferAcquiringModel from "@/views/models/FormTransferAcquiringModel";
import { Vue, Options, Prop, Watch } from "vue-property-decorator";
import AdapterTransferService from "../../adapter/AdapterTransferService";
import InfoForAcquiringIntPayin from "@/api/main/dataSource/api/transaction-services/models/transfer-info-service/InfoForAcquiringIntPayin";
import AcquiringInitPayoutRequest from "@/api/main/dataSource/api/transaction-services/models/acquirings/AcquiringInitPayoutRequest";
import ErrorModel from "@/views/pages/login/adapter/models/errorModel";
import VirtpayPayoutRequest from "@/api/main/dataSource/api/virtpay-transactions-services/models/payout/VirtpayPayoutRequest";
import EnumCurrency from "@/api/main/dataSource/api/aba/data/enums/EnumCurrency";
@Options({
	name: "transfer-acquiring-to-card",
})
export default class TransferAcquiringToCard extends Vue {
	@Prop({ type: Object, default: null }) profile: NaturalPersonModel;
	@Prop({ type: Number, default: null }) Type: number;
	@Prop({ type: Object, default: null })
	SelectedTemplate: TransferAcquiringToCardTemplateModel;

	Form: FormTransferAcquiringModel = null;
	IsConfirmCondition: boolean = false;
	ErrorMessage: string = null;

	IsSave: boolean = false;
	IsLoad: boolean = false;
	adapter = AdapterTransferService.getInstance();

	created() {
		this.Form = new FormTransferAcquiringModel();
	}

	@Watch("SelectedTemplate", { deep: true })
	onChangeTemplate() {
		if (!this.SelectedTemplate) return;
		this.Form.ToFirstName = this.SelectedTemplate.ToFirstName;
		this.Form.ToLastName = this.SelectedTemplate.ToLastName;
		this.Form.Amount = this.SelectedTemplate.Amount;
		this.Form.Description = this.SelectedTemplate.Description;
		this.Form.ToCardNumber = this.SelectedTemplate.ToCardNumber;
	}
	transferReq: VirtpayPayoutRequest = new VirtpayPayoutRequest();

	async InitTransfer() {
		if (this.IsLoad) return false;
		var validation = this.Validation();
		if (!validation) return;
		this.IsLoad = true;
		this.transferReq = new VirtpayPayoutRequest({
			// ToFirstName: this.Form.ToFirstName,
			// ToLastName: this.Form.ToLastName,
			// ToCardNumber: this.Form.ToCardNumber,
			Amount: Number(this.Form.Amount),
			// Description: this.Form.Description,
			// TemplateName: this.Form.TemplateName,
			CardNumber: this.Form.ToCardNumber,
			Currency: EnumCurrency.EUR,
			PaymentDescription: this.Form.Description,
			ReturnUrl: window.location.origin + "/natural/transaction",
		});
		let formData = new FormData();
		formData.append("ToFirstName", this.Form.ToFirstName);
		formData.append("ToLastName", this.Form.ToLastName);
		formData.append("ToCardNumber", this.Form.ToCardNumber);
		formData.append("Amount", this.$store.state.getAmountForSend(this.Form.Amount));
		formData.append("Description", this.Form.Description);
		formData.append("TemplateName", this.Form.TemplateName);
		const res = await this.adapter.checkVirtpayTransfer(this.transferReq);
		if (res.IsSuccess) {
			this.IsLoad = false;
			this.ErrorMessage = "";
			formData.append("CommissionAmount", res.Value.CommissionAmount.toString());
			this.$emit("GoTransferAcquiringToCard", formData);
		} else {
			this.ErrorMessage = res.ErrorMessage;
		}
		this.IsLoad = false;
	}

	Validation() {
		if (!this.IsConfirmCondition) {
			this.ErrorMessage =
				"You need agree with the Terms and Conditions, Privacy Policy and User Items";
			return false;
		}
		var russian = /^[а-яА-Я]*$/;
		if (
			!this.Form.ToCardNumber ||
			!this.Form.Amount ||
			!this.Form.Description ||
			(!this.Form.TemplateName && this.IsSave)
		) {
			this.ErrorMessage = "Fill in all the fields";
			return false;
		} else if (
			russian.test(this.Form.ToFirstName + this.Form.ToLastName + this.Form.Description)
		) {
			this.ErrorMessage = "The text should consist only of English letters";
			return false;
		}
		return true;
	}
}
