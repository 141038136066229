
import { Vue, Options, Prop, Watch, Ref } from "vue-property-decorator";
@Options({
  name: "ui-select",
  emits: ["update:modelValue", "currentClick"],
})
export default class UiSelectComponent extends Vue {
  @Prop({ default: "" }) headerText: string;
  // todo: сделать проверку на повторяющиеся значения ключей и кидать ошибку/предупреждение
  @Prop({ default: () => new Array<any>(), type: Array, required: true })
  items!: any[];
  @Prop({ default: false, type: Boolean }) autocomplete: boolean;
  @Prop({ default: false }) multiselect: boolean;
  @Prop({ default: null }) selectText: string;
  @Prop({ default: true }) closeOnSelect: boolean;
  @Prop({ default: false }) noUndefined: boolean;
  @Prop({ default: false }) checkActiveByRef: boolean;
  @Prop({ type: String, default: "Not Chosen" }) defaultText: string;
  //@Prop({ type: Boolean, default: false }) required: boolean;
  @Prop({ default: false }) noPlace: boolean;
  @Prop({ default: false }) isWrong: boolean;
  @Prop({ default: "" }) defaultChoosen: string;
  @Prop() modelValue: any | any[] | String | Number | string | number | Date;

  styleForWrong: boolean = false;
  tempItems: any[] = [];
  innerValue: any | any[] | String | Number | string | number | Date = null;
  innerItems: any[] = this.items;
  containerPosition: Object = new Object();
  tempValue: { key: any; value: any } = { key: null, value: null };
  search: string = "";

  @Watch("defaultChoosen")
  onUpdateDefaultChoosen() {
    if (this.defaultChoosen.length != 0) {
      this.innerValue = this.defaultChoosen;
    }
  }

  currentClick() {
    this.$emit("currentClick");
  }

  @Watch("noPlace")
  onUpdateNoPlace(newValue: boolean) {
    if (newValue) {
      this.containerPosition = {
        top: "unset",
        bottom: "100%",
        boxShadow: "0 -12px 24px 0 #ccd0d5",
      };
    } else {
      this.containerPosition = {
        top: "100%",
        bottom: "unset",
        boxShadow: "0 12px 24px 0 #ccd0d5",
      };
    }
  }

  @Watch("isWrong")
  onUpdateIsWrong(newValue: boolean) {
    this.styleForWrong = newValue;
  }

  @Watch("modelValue")
  onValueChanged(value) {
    this.innerValue = value;
  }
  // внутреннее значение
  @Watch("innerValue")
  onInnerValueChanged() {
    this.$emit("update:modelValue", this.innerValue);
  }
  private open = false;
  preventCurrentClick = false;
  setOpen(value: boolean) {
    if (!value) {
    }
    this.open = value;
    var active = this.active;
    if (value && this.autocomplete) {
      this.$nextTick(() => {
        (this.$refs["searchEl"] as HTMLInputElement).focus();
      });
    }
    if (!active) {
      this.setHoveredIdx(0);
    }
    Array.isArray(active)
      ? this.setHoveredIdx(
          this.itemsToDisplay.indexOf(active[active.length - 1])
        )
      : this.setHoveredIdx(this.itemsToDisplay.indexOf(active));
  }
  clickCurrent() {
    if (this.preventCurrentClick || this.multiselect) {
      return;
    }
    this.setOpen(!this.open);
  }
  hoveredIdx: number = null;
  hasFocus = false;
  mounted() {
    this.onUpdateNoPlace(this.noPlace);
    document.addEventListener("keydown", this.onKeyDown.bind(this));
  }
  beforeDestroy() {
    document.removeEventListener("keydown", this.onKeyDown.bind(this));
  }
  onGetFocus() {
    this.setOpen(true);
    this.preventCurrentClick = true;
    setTimeout(() => (this.preventCurrentClick = false), 300);
    this.hasFocus = true;
  }
  onBlur() {
    this.setOpen(false);
    this.hasFocus = false;

    if (this.$refs.currentSearch) {
      (this.$refs.currentSearch as HTMLInputElement).blur();
    }
  }

  onKeyDown(ev: KeyboardEvent) {
    if (!this.hasFocus) {
      return;
    }
    var key = ev.key.toLowerCase();
    if (key == "escape") {
      return (this.open = false);
    }
    if (key == "enter") {
      return this.open ? this.onEnter() : this.setOpen(true);
    }
    if (key == "arrowdown" || key == "arrowup") {
      ev.preventDefault();
      return this.open
        ? key === "arrowdown"
          ? this.setHoveredIdx(++this.hoveredIdx)
          : this.setHoveredIdx(--this.hoveredIdx)
        : this.setOpen(true);
    }
  }
  isSelect(item: any) {
    if (item === undefined) return this.innerValue === undefined;
    var key = item;

    return this.multiselect
      ? (this.innerValue || []).some((el: any) => this.isEqual(el, key))
      : this.isEqual(key, this.innerValue);
  }
  isEqual(fst: any, scd: any): boolean {
    return this.checkActiveByRef
      ? fst === scd
      : JSON.stringify(fst) === JSON.stringify(scd);
  }
  setHoveredIdx(idx: number) {
    this.hoveredIdx =
      (idx + this.itemsToDisplay.length) % this.itemsToDisplay.length;
  }
  onEnter() {
    // this.select(this.itemsToDisplay[this.hoveredIdx]);
  }
  @Watch("items", { deep: true })
  onItemsChanged(value, oldValue) {
    this.tempItems = value;
    this.trySetDefault();
  }
  hasValue(): boolean {
    return (
      this.innerValue != null ||
      (this.multiselect && this.innerValue.length > 0)
    );
  }
  valueInItems(): boolean {
    const allKeys = (this.innerItems || []).map((x) => x);
    const selectedKeys = this.multiselect ? this.innerValue : [this.innerValue];
    return selectedKeys.every((x) => allKeys.indexOf(x) > -1);
    //текущее значения все есть в наборе items
  }
  trySetDefault() {
    if (this.hasValue() && this.valueInItems()) {
      return;
    } else {
      if (!this.noUndefined) this.select(undefined);
      //выбирает не найдено при обновлении страницы
    }
  }
  get itemsToDisplay(): any[] {
    if (this.noUndefined) {
      return this.innerItems;
    }
    if (!this.autocomplete || !this.search) {
      let res = this.innerItems;
      res = [undefined].concat(res);
      return res;
    }

    let res = this.innerItems.filter((x) =>
      (x || "").toLowerCase().includes(this.search.toLowerCase())
    );
    res = [undefined].concat(res);
    return res;
  }

  get active(): any {
    return this.multiselect
      ? this.innerItems.filter((el: any) =>
          this.innerValue.some((iv: any) => this.isEqual(iv, el))
        )
      : (this.innerItems.filter((el: any) =>
          this.isEqual(el, this.innerValue)
        ) || [])[0];
  }
  get activeText(): string {
    var active = this.active;
    if (Array.isArray(active)) {
      var text =
        active.length > 0
          ? active.map((el: any) => el).join(",")
          : this.selectText || "";
    } else {
      text = active ? active : this.selectText || this.defaultText;
    }
    return text;
  }
  select(item: any) {
    if (item === undefined) {
      this.innerValue = undefined;
      if (this.closeOnSelect) {
        this.setOpen(false);
      }
      return;
    }
    this.innerValue = item;
    if (this.closeOnSelect) {
      this.setOpen(false);
    }
    this.$emit("update:modelValue", this.innerValue);
    this.setHoveredIdx(this.innerItems.indexOf(item));
  }

  created() {
    if (this.items) {
      this.tempItems = this.items;
    }
    this.innerValue = this.modelValue;
    this.trySetDefault();
    this.onUpdateDefaultChoosen();
  }
}
