import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "dialog__title" }
const _hoisted_2 = { class: "dialog__text" }
const _hoisted_3 = {
  key: 0,
  class: "dialod__btn"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_button = _resolveComponent("ui-button")!
  const _component_ui_modal_window = _resolveComponent("ui-modal-window")!

  return (_openBlock(), _createBlock(_component_ui_modal_window, {
    visible: _ctx.visible,
    width: '600px',
    title: 'Удаление',
    onClose: _ctx.onClose
  }, {
    header: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.dialogModal.Title), 1)
    ]),
    button: _withCtx(() => [
      (_ctx.dialogModal.Buttons)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dialogModal.Buttons, (item, index) => {
              return (_openBlock(), _createBlock(_component_ui_button, {
                key: index,
                width: 120,
                style: _normalizeStyle(_ctx.getButtonStyle(item)),
                onOnClick: ($event: any) => (_ctx.clickButton(item.Value))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(item.Title), 1)
                ]),
                _: 2
              }, 1032, ["style", "onOnClick"]))
            }), 128))
          ]))
        : _createCommentVNode("", true)
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.dialogModal.Text), 1)
    ]),
    _: 1
  }, 8, ["visible", "onClose"]))
}