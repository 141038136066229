import ApiService from '@/api/index';
import CurrentUserResponse from '@/api/main/dataSource/api/aba/cabinet/web/models/CurrentUserResponse';
import ModelNaturalPerson from '@/api/main/dataSource/api/i-public-service/aba-finance/models/user/ModelNaturalPerson';
import GetNaturalPersonResponse from '@/api/main/services/NaturalPersonHome/models/response/GetNaturalPersonResponse';

import { notify } from '@/app/notifications';
import NaturalPersonModel from '@/views/models/NaturalPersonModel';


import { Ref, ref } from 'vue';
import ErrorModel from './models/errorModel';
import ModelPersonBalance from '@/api/main/services/NaturalPersonProfile/models/response/GetNewUser/ModelPersonBalance';
import EnumTypeUser from '@/api/main/models/Enums/EnumTypeUser';
import UserType from '@/api/main/models/Enums/UserType';
import moment from 'moment';
import ModelPersonTemplate from '@/api/main/dataSource/api/i-public-service/aba-finance/models/ModelPersonTemplate';
import AcquiringInitPayoutRequest from '@/api/main/dataSource/api/transaction-services/models/acquirings/AcquiringInitPayoutRequest';
import InfoForAcquiringIntPayin from '@/api/main/dataSource/api/transaction-services/models/transfer-info-service/InfoForAcquiringIntPayin';
import SwiftPayoutRequest from '@/api/main/dataSource/api/transaction-services/models/transaction-operations/SwiftPayoutRequest';
import ModelTransferInternal from '@/api/main/dataSource/api/i-public-service/aba-finance/models/ModelTransferInternal';
import GetCommissionRequest from '@/api/main/dataSource/api/aba/cabinet/web/models/GetCommissionRequest';
import ModelTransferSwift from '@/api/main/dataSource/api/i-public-service/aba-finance/models/ModelTransferSwift';
import AcquiringPayoutTemplateListResponse from '@/api/main/dataSource/api/transaction-services/models/transfer-template-service/AcquiringPayoutTemplateListResponse';
import DictionaryItem from '@/api/main/dataSource/api/dictionary-services/models/DictionaryItem';
import CryptPayoutRequest from '@/api/main/dataSource/api/transaction-services/models/transaction-operations/CryptPayoutRequest';
import InfoForCryptPayout from '@/api/main/dataSource/api/transaction-services/models/transfer-info-service/InfoForCryptPayout';
import WsResponseModel from '@/api/main/SchemaBuilderServer/Models/WsResponseModel';
import InfoForVirtpay from '@/api/main/dataSource/api/transaction-services/models/transfer-info-service/InfoForVirtpay';
import VirtpayPayoutRequest from '@/api/main/dataSource/api/virtpay-transactions-services/models/payout/VirtpayPayoutRequest';
import VirtpayPayoutHttpRequest from '@/api/main/dataSource/api/aba/cabinet/web/models/VirtpayPayoutHttpRequest';
import VirtpayPayoutResponse from '@/api/main/dataSource/api/virtpay-transactions-services/models/payout/VirtpayPayoutResponse';
import CheckTransferPinT from '@/api/main/dataSource/api/aba/cabinet/web/models/CheckTransferPinT';
import InternalTransferRequest from '@/api/main/dataSource/api/transaction-services/models/transaction-operations/InternalTransferRequest';
import ModelTransferCryptPayout from '@/api/main/dataSource/api/i-public-service/aba-finance/models/ModelTransferCryptPayout';
import ModelLegalPerson from '@/api/main/dataSource/api/i-public-service/aba-finance/models/user/ModelLegalPerson';
import { PersonModel } from '@/views/models';
import { AdapterApiService } from '../../_adapter';
import ModelPersonCard from '@/api/main/services/NaturalPersonProfile/models/response/GetNewUser/ModelPersonCard';


export default class AdapterTransferService {

  /**
 * Сервис
 */
  private static instance: AdapterTransferService;
  /**
 * API
 */
  private static api: ApiService;
  isLoading: boolean = false;
  isInited = ref(false);
  api: ApiService;
  Person: Ref<PersonModel> = ref(new PersonModel());


  constructor() {
    AdapterTransferService.api = new ApiService();
  }

  public static getInstance() {
    if (!AdapterTransferService.instance) {
      AdapterTransferService.instance = new AdapterTransferService();
    }
    return AdapterTransferService.instance;
  }


  async getCurrentUser(): Promise<WsResponseModel<CurrentUserResponse>> {
    const result = new WsResponseModel<CurrentUserResponse>();
    try {
      const resp = await AdapterTransferService.api.Main.AuthWsController.getCurrentUserAsync();
      result.Value = resp;
      result.IsSuccess = true;
    } catch (e) {
      return this.handleError(e, "ERROR getCurrentUser")
    }
    return result;
  }


  dateToString(req: Date): string {
    return moment(req).format("dd.mm.YYYY HH:MM")
  }

  convertModelNaturalPersonToGetNaturalPersonResponse(value: ModelNaturalPerson): GetNaturalPersonResponse {
    return new GetNaturalPersonResponse({
      Identified: value.Identified,
      IdentifiedId: value.IdentifiedId,
      ConfrimHash: value.ConfrimHash,
      IsConfirmPhone: value.IsConfirmPhone,
      CardId: value.CardId,
      NumberCard: value.NumberCard,
      NumberPassport: value.NumberPassport,
      RegistrationAddress: value.RegistrationAddress,
      SearchFilter: value.SearchFilter,
      Search: value.Search,
      Appeal: value.Appeal,
      Balance: value.Balance,
      Cards: value.Cards,
      BirthDate: value.BirthDate ? this.dateToString(value.BirthDate) : null, // Преобразование даты в строку, если требуется
      AcquiringCardMask: value.AcquiringCardMask,
    });

  }

  convertModelNaturalPersonToNaturalPersonModel(req: ModelNaturalPerson): NaturalPersonModel {
    const result = new NaturalPersonModel({
      AcquiringCardMask: req.AcquiringCardMask,
      Address: req.Address,
      AllowMassTransfers: req.AllowMassTransfers,
      Appeal: req.Appeal,
      Balance: new ModelPersonBalance(req.Balance),
      BirthDate: this.dateToString(req.BirthDate),
      CardId: req.CardId,
      Cards: req.Cards,
      CompanyName: req.CompanyName,
      ConfrimHash: req.ConfrimHash,
      DateLastMessage: this.dateToString(req.DateLastMessage),
      DateViewMessage: this.dateToString(req.DateViewMessage),
      Email: req.Email,
      Id: req.Id,
      Identified: req.Identified, IdentifiedId: req.IdentifiedId,
      IsConfirmPhone: req.IsConfirmPhone, IsHaveCards: req.IsHaveCards, IsTwoFactorAuthentication: req.IsTwoFactorAuthentication,
      LastName: req.LastName,
      Login: req.Login,
      Name: req.Name, NumberCard: req.NumberCard, NumberPassport: req.NumberPassport, Password: req.Password, PaymentsType: req.PaymentsType,
      Phone: req.Phone, RegistrationAddress: req.RegistrationAddress,
      Role: req.Role,
      Search: req.Search,
      SearchFilter: req.SearchFilter,
      Token: req.Token,
      TypeIdentified: req.TypeIdentified, TypeUser: req.TypeUser,
      TypeUserId: this.convertUserTypeEnumTypeUser(req.TypeUserId),
    })
    return result

  }

  convertUserTypeEnumTypeUser(req: UserType | undefined): EnumTypeUser {
    if (req == undefined || req == null) return EnumTypeUser.LegalPerson;
    switch (req) {
      case UserType.Legal:
        return EnumTypeUser.LegalPerson
      case UserType.Natural:
        return EnumTypeUser.NaturalPerson
      default:
        break;
    }
  }

  async getPerson(): Promise<PersonModel> {
    if (this.Person?.value?.Id) {
      return this.Person.value
    }
    const res = await this.getCurrentUser();
    if (!res.IsSuccess) {
      return
    }
    if (res.Value.UserRole == UserType.Natural) {
      await this.getNaturalPerson();
      return this.Person.value
    }
    await this.getLegalPerson();
    return this.Person.value
  }

  
  async getNaturalPerson(): Promise<WsResponseModel<ModelNaturalPerson>> {
    const result = new WsResponseModel<ModelNaturalPerson>();
    try {
      const resp = await AdapterTransferService.api.Main.ProfileWsController.getNaturalPersonAsync();
      result.Value = resp;
      this.Person.value = new PersonModel({
        Balance: new ModelPersonBalance(resp.Balance),
        Cards: resp.Cards.map(el => new ModelPersonCard(el)),
        CompanyName: resp.CompanyName, Email: resp.Email, Id: resp.Id, Identified: resp.Identified,
        Name: resp.Name,
        LastName: resp.LastName,
        Phone: resp.Phone,
        PaymentsType: resp.PaymentsType,
        Role: resp.Role,
        TypeIdentified: resp.TypeIdentified,
        TypeUser: EnumTypeUser.LegalPerson,
      })
      result.IsSuccess = true;
    } catch (e) {
      return this.handleError(e, "ERROR getNaturalPerson")
    }
    return result;
  }

  async getLegalPerson(): Promise<WsResponseModel<ModelLegalPerson>> {
    const result = new WsResponseModel<ModelLegalPerson>();
    try {
      const resp = await AdapterTransferService.api.Main.ProfileWsController.getLegalPersonAsync();
      result.Value = resp;
      this.Person.value = new PersonModel({
        Balance: new ModelPersonBalance(resp.Balance),
        Cards: resp.Cards.map(el => new ModelPersonCard(el)),
        CompanyName: resp.CompanyName, Email: resp.Email, Id: resp.Id,
        Identified: resp.IdentifiedId,
        Name: resp.Name,
        LastName: resp.LastName,
        Phone: resp.Phone,
        PaymentsType: resp.PaymentsType,
        Role: resp.Role,
        TypeIdentified: resp.TypeIdentified,
        TypeUser: EnumTypeUser.LegalPerson,
      })
      result.IsSuccess = true;
    } catch (e) {
      return this.handleError(e, "ERROR getLegalPerson")
    }
    return result;
  }


  async getTemplates(): Promise<WsResponseModel<ModelPersonTemplate[]>> {
    const result = new WsResponseModel<ModelPersonTemplate[]>();
    try {
      const resp = await AdapterTransferService.api.Main.PaymentsWsController.getTemplatesAsync();
      result.Value = resp;
      result.IsSuccess = true;
    } catch (e) {
      return this.handleError(e, "ERROR getTemplates")
    }
    return result;
  }


  // SwiftService.CheckSwiftTransfer
  async checkSwiftTransfer(req: SwiftPayoutRequest): Promise<Boolean | ErrorModel> {
    try {
      const res = await AdapterTransferService.api.Main.SwiftWsController.checkSwiftTransferAsync(req)
      return res
    } catch (err: any) {
      this.showError(err)
      return new ErrorModel({ ErrorCode: err.ErrorCode, ErrorMessage: err.ErrorMessage })
    }
  }

  // SwiftService.GetCommissionForSwiftPayment
  async getCommissionForSwiftPayment(req: number): Promise<number | ErrorModel> {
    try {
      const res = await AdapterTransferService.api.Main.SwiftWsController.getCommissionForSwiftPaymentAsync(req)
      return res
    } catch (err: any) {
      this.showError(err)
      return new ErrorModel({ ErrorCode: err.ErrorCode, ErrorMessage: err.ErrorMessage })
    }
  }


  async checkTransferSepa(req: ModelTransferInternal): Promise<WsResponseModel<Boolean>> {
    const result = new WsResponseModel<Boolean>();
    try {
      const resp = await AdapterTransferService.api.Main.InternalBalanceWsController.checkTransferSepaAsync(req);
      result.Value = resp;
      result.IsSuccess = true;
    } catch (e) {
      return this.handleError(e, "ERROR checkVirtpayTransfer")
    }
    return result;
  }
  async getCommissionForSepaPayment(req: number): Promise<WsResponseModel<number>> {
    const result = new WsResponseModel<number>();
    try {
      const resp = await AdapterTransferService.api.Main.CommissionsWsController.getCommissionForSepaPaymentAsync(req);
      result.Value = resp;
      result.IsSuccess = true;
    } catch (e) {
      return this.handleError(e, "ERROR getCommissionForSepaPayment")
    }
    return result;
  }

  async checkTransferCrypto(req: CryptPayoutRequest): Promise<WsResponseModel<InfoForCryptPayout>> {
    const result = new WsResponseModel<InfoForCryptPayout>();
    try {
      const resp = await AdapterTransferService.api.Main.CryptPayoutWsController.checkTransferAsync(req);
      result.Value = resp;
      result.IsSuccess = true;
    } catch (e) {
      return this.handleError(e, "ERROR checkTransferCrypto")
    }
    return result;
  }
  async transferCrypto(req: ModelTransferCryptPayout): Promise<WsResponseModel<Boolean>> {
    const result = new WsResponseModel<Boolean>();
    try {
      const resp = await AdapterTransferService.api.Main.CryptPayoutWsController.transferAsync(req);
      result.Value = resp;
      result.IsSuccess = true;
    } catch (e) {
      return this.handleError(e, "ERROR transferCrypto")
    }
    return result;
  }


  // CommissionsService.GetCommission
  // async getCommission(req: GetCommissionRequest): Promise<number | ErrorModel> {
  //   try {
  //     const res = await AdapterTransferService.api.Main.CommissionsWsController.getCommissionAsync(req)
  //     return res
  //   } catch (err: any) {
  //     this.showError(err)
  //     return new ErrorModel({ ErrorCode: err.ErrorCode, ErrorMessage: err.ErrorMessage })
  //   }
  // }

  async getCommission(req: GetCommissionRequest): Promise<WsResponseModel<number>> {
    const result = new WsResponseModel<number>();
    try {
      const resp = await AdapterTransferService.api.Main.CommissionsWsController.getCommissionAsync(req);
      result.Value = resp;
      result.IsSuccess = true;
    } catch (e) {
      return this.handleError(e, "ERROR getCommission")
    }
    return result;
  }


  // CardPayoutService.CheckTransfe
  async checkTransferPayout(req: GetCommissionRequest): Promise<number | ErrorModel> {
    try {
      const res = await AdapterTransferService.api.Main.CommissionsWsController.getCommissionAsync(req)
      return res
    } catch (err: any) {
      this.showError(err)
      return new ErrorModel({ ErrorCode: err.ErrorCode, ErrorMessage: err.ErrorMessage })
    }
  }

  // NaturalPersonInternalBalanceService.Transfer //TODO
  // NaturalPersonInternalBalanceService.TransferAcquiringToCard
  async transferAcquiringToCard(req: AcquiringInitPayoutRequest): Promise<Boolean | ErrorModel> {
    try {
      const res = await AdapterTransferService.api.Main.NaturalInternalBalanceWsController.transferAcquiringToCardAsync(req)
      return res
    } catch (err: any) {
      this.showError(err)
      return new ErrorModel({ ErrorCode: err.ErrorCode, ErrorMessage: err.ErrorMessage })
    }
  }




  // SwiftService.TransferSwift
  async transferSwift(req: ModelTransferSwift): Promise<Boolean | ErrorModel> {
    try {
      const res = await AdapterTransferService.api.Main.SwiftWsController.transferSwiftAsync(req)
      return res
    } catch (err: any) {
      this.showError(err)
      return new ErrorModel({ ErrorCode: err.ErrorCode, ErrorMessage: err.ErrorMessage })
    }
  }


  // NaturalPersonInternalBalanceService.TransferSepa
  // async transferSepa(req: ModelTransferInternal): Promise<Boolean | ErrorModel> {
  //   try {
  //     const res = await AdapterTransferService.api.Main.InternalBalanceWsController.transferSepaAsync(req)
  //     return res
  //   } catch (err: any) {
  //     this.showError(err)
  //     return new ErrorModel({ ErrorCode: err.ErrorCode, ErrorMessage: err.ErrorMessage })
  //   }
  // }

  async transferSepa(req: ModelTransferInternal): Promise<WsResponseModel<Boolean>> {
    const result = new WsResponseModel<Boolean>();
    try {
      const resp = await AdapterTransferService.api.Main.InternalBalanceWsController.transferSepaAsync(req);
      result.Value = resp;
      result.IsSuccess = true;
    } catch (e) {
      return this.handleError(e, "ERROR transferSepa")
    }
    return result;


  }



  // CryptPayoutService.Transfer //TODO
  async TransferCripto(req: ModelTransferInternal): Promise<Boolean | ErrorModel> {
    try {
      const res = await AdapterTransferService.api.Main.InternalBalanceWsController.transferSepaAsync(req)
      return res
    } catch (err: any) {
      this.showError(err)
      return new ErrorModel({ ErrorCode: err.ErrorCode, ErrorMessage: err.ErrorMessage })
    }
  }
  // NaturalPersonInternalBalanceService.CheckTransfer //TODO
  // async checkInternalTransfer(req: ModelTransferInternal): Promise<Boolean | ErrorModel> {
  //   try {
  //     const res = await AdapterTransferService.api.Main.InternalBalanceWsController.checkInternalTransferAsync(req)
  //     return res
  //   } catch (err: any) {
  //     this.showError(err)
  //     return new ErrorModel({ ErrorCode: err.ErrorCode, ErrorMessage: err.ErrorMessage })
  //   }
  // }

  async checkInternalTransfer(req: ModelTransferInternal): Promise<WsResponseModel<Boolean>> {
    const result = new WsResponseModel<Boolean>();
    try {
      const resp = await AdapterTransferService.api.Main.InternalBalanceWsController.checkInternalTransferAsync(req);
      result.Value = resp;
      result.IsSuccess = true;
    } catch (e) {
      return this.handleError(e, "ERROR checkInternalTransfer")
    }
    return result;
  }
  async internalTransfer(req: CheckTransferPinT<InternalTransferRequest>): Promise<WsResponseModel<Boolean>> {
    const result = new WsResponseModel<Boolean>();
    try {
      const resp = await AdapterTransferService.api.Main.InternalBalanceWsController.internalTransferAsync(req);
      result.Value = resp;
      result.IsSuccess = true;
    } catch (e) {
      return this.handleError(e, "ERROR internalTransfer")
    }
    return result;
  }

  // NaturalPersonPaymentsService.GetAcquiringTemplates //TODO
  // async getAcquiringTemplates(): Promise<AcquiringPayoutTemplateListResponse | ErrorModel> {
  //   try {
  //     const res = await AdapterTransferService.api.Main.NaturalPaymentsWsController.getAcquiringTemplatesAsync()
  //     return res
  //   } catch (err: any) {
  //     this.showError(err)
  //     return new ErrorModel({ ErrorCode: err.ErrorCode, ErrorMessage: err.ErrorMessage })
  //   }
  // }
  // NaturalPersonInternalBalanceService.CheckTransferAcquiring

  async checkVirtpayTransfer(req: VirtpayPayoutRequest): Promise<WsResponseModel<InfoForVirtpay>> {
    const result = new WsResponseModel<InfoForVirtpay>();
    try {
      const resp = await AdapterTransferService.api.Main.VirtpayWsController.checkVirtpayTransferAsync(req);
      result.Value = resp;
      result.IsSuccess = true;
    } catch (e) {
      return this.handleError(e, "ERROR checkVirtpayTransfer")
    }
    return result;
  }

  async getAcquiringTemplates(): Promise<WsResponseModel<AcquiringPayoutTemplateListResponse>> {
    const result = new WsResponseModel<AcquiringPayoutTemplateListResponse>();
    try {
      const resp = await AdapterTransferService.api.Main.NaturalPaymentsWsController.getAcquiringTemplatesAsync();
      result.Value = resp;
      result.IsSuccess = true;
    } catch (e) {
      return this.handleError(e, "ERROR getAcquiringTemplates")
    }
    return result;
  }


  handleError(e: any, context: string): WsResponseModel<any> {
    console.error(`ERROR ${context}`, e);
    return {
      IsSuccess: false,
      ErrorCode: e?.ErrorCode || 500,
      ErrorMessage: e?.ErrorMessage || 'Internal Server Error',
      Value: null
    };
  }

  // DictionaryService.GetCryptCurrencyList //TODO
  async getCryptCurrencyList(): Promise<Array<DictionaryItem> | ErrorModel> {
    try {
      const res = await AdapterTransferService.api.Main.DictionaryWsController.getCryptCurrencyListAsync()
      return res
    } catch (err: any) {
      this.showError(err)
      return new ErrorModel({ ErrorCode: err.ErrorCode, ErrorMessage: err.ErrorMessage })
    }
  }
  //DictionaryService.GetCryptNetworkList
  async getCryptNetworkList(): Promise<Array<DictionaryItem> | ErrorModel> {
    try {
      const res = await AdapterTransferService.api.Main.DictionaryWsController.getCryptNetworkListAsync()
      return res
    } catch (err: any) {
      this.showError(err)
      return new ErrorModel({ ErrorCode: err.ErrorCode, ErrorMessage: err.ErrorMessage })
    }
  }
  //CryptPayoutService.CheckTransfer
  // async checkTransfer(req: CryptPayoutRequest): Promise<InfoForCryptPayout | ErrorModel> {
  //   try {
  //     const res = await AdapterTransferService.api.Main.CryptPayoutWsController.checkTransferAsync(req)
  //     return res
  //   } catch (err: any) {
  //     this.showError(err)
  //     return new ErrorModel({ ErrorCode: err.ErrorCode, ErrorMessage: err.ErrorMessage })
  //   }
  // }

  async checkTransfer(req: CryptPayoutRequest): Promise<WsResponseModel<InfoForCryptPayout>> {
    const result = new WsResponseModel<InfoForCryptPayout>();
    try {
      const resp = await AdapterTransferService.api.Main.CryptPayoutWsController.checkTransferAsync(req);
      result.Value = resp;
      result.IsSuccess = true;
    } catch (e) {
      return this.handleError(e, "ERROR checkTransfer")
    }
    return result;
  }


  /**
   * TransferTo card
   * @param req 
   * @returns 
   */
  async transfer(req: VirtpayPayoutHttpRequest): Promise<WsResponseModel<VirtpayPayoutResponse>> {
    const result = new WsResponseModel<VirtpayPayoutResponse>();
    try {
      const resp = await AdapterTransferService.api.Main.VirtpayWsController.payoutAsync(req);

      result.Value = resp;
      result.IsSuccess = true;
    } catch (e) {
      return this.handleError(e, "ERROR checkVirtpayTransfer")
    }
    return result;
  }

  //CommissionsService.GetCommission
  // async getCryptoCommission(req: GetCommissionRequest): Promise<InfoForCryptPayout | ErrorModel> {
  //   try {
  //     const res = await AdapterTransferService.api.Main.CommissionsWsController.GetCommission(req)
  //     return res
  //   } catch (err: any) {
  //     this.showError(err)
  //     return new ErrorModel({ ErrorCode: err.ErrorCode, ErrorMessage: err.ErrorMessage })
  //   }
  // }




  /*
   * показать информацию об ошибке
   * @param err 
   */
  showError(err: any) {
    console.error(err?.ErrorMessage);
    notify({ text: err?.ErrorMessage as any, title: "Error", type: 'error' })
  }
}
