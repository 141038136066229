
import { Vue, Options } from "vue-property-decorator";
import CompanyTab from "./tabs/company-tab.vue";
import * as RoutesNames from "@/router/routerNames";
import BeneficiaryTab from "./tabs/beneficiary-tab.vue";
import BusinessProfileTab from "./tabs/business-profile-tab.vue";
import DirectorTab from "./tabs/director-tab.vue";
import LivenessTab from "./tabs/liveness-tab.vue";
import { TabInfo } from "@/views/components/ui-tabs/tabsProvider";

import EnumTypeIdentified from "@api/main/dataSource/api/aba/data/models/models/EnumTypeIdentified";
import { isDevelopment } from "../../../helper/helpers";

import CurrentUserResponse from "@api/main/dataSource/api/aba/cabinet/web/models/CurrentUserResponse";
import { AdapterApiService } from "../_adapter";
import { UserModel } from "./models";
import { ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import AdapterUserIdentificationService from "./adapter/AdapterUserIdentificationService";
import PersonalIdentificationData from "@/api/main/dataSource/api/i-public-service/aba-finance/models/user/identification/PersonalIdentificationData";

@Options({
	components: {
		"company-tab": CompanyTab,
		"beneficiary-tab": BeneficiaryTab,
		"business-profile-tab": BusinessProfileTab,
		"director-tab": DirectorTab,
		"liveness-tab": LivenessTab,
	},
})
export default class UserIndetification extends Vue {
	nextTab: TabInfo = new TabInfo();
	tabs: Array<TabInfo> = new Array<TabInfo>();
	user: CurrentUserResponse = new CurrentUserResponse();
	allowedIds: Array<number> = [];
	started: boolean = true;
	finished: boolean = false;
	router = useRouter();
	adapter = AdapterUserIdentificationService.getInstance();
	adapterUser = AdapterApiService.getInstance();

	updateCheck(tabId: number, value: boolean) {
		var tab = this.tabs.find((x) => x.id == tabId);
		if (tab) tab.isAllowed = value;
		// var arr = this.allowedIds.filter((i) => i != tabId);
		// if (value) {
		//   //arr.push(tabId);
		//   for(var i=0;i<=tabId; i++)
		//     if(!arr.filter(x=>x==i)[0])
		//       arr.push(i);
		// }
		// this.allowedIds = arr;
	}

	userInfo = ref();

	continue(value: UserModel) {
		const req = new PersonalIdentificationData({
			UserId: this.adapterUser.userInfo.value.Id,
			AddressZip: value.PostCode,
			City: value.City,
			Country: value.City,
			SecondName: value.LastName,
			FirstName: value.FirstName,
			AddressStreet: value.Address1,
		});

		const response = this.adapter.saveUserInfo(req);
		if (response) {
			this.started = true;
			this.finished = true;
		}
	}

	async created() {
		await this.getCurrentUser();

		if (this.$route.name === RoutesNames.USERIDENTIFICATION_Liveness) {
			this.started = true;
			setTimeout(() => {
				//this.allowedIds.push(4);
				this.changeTab(4);
			}, 500);
		}
	}

	async getCurrentUser() {
		const response = await this.adapter.getCurrentUser();
		if (response instanceof CurrentUserResponse) {
			this.user = response;
			if (!this.user) {
				if (!isDevelopment()) location.href = "/";
				else alert("Не удалось получить текущего пользователя");
			}
			if (this.user.IdentifiedStatus === EnumTypeIdentified.Confirmed) {
				if (!isDevelopment())
					location.href = `${location.protocol}//${location.host}/${
						this.$route.params.lang ?? "en"
					}/LegalPerson`;
				else alert("Пользователь уже идентифицирован");
			}
		} else {
			console.log(`getCurrentUser this.router.push({ name: "Login" });`);
			this.router.push({ name: "Login" });
		}
	}
	goTofinish() {
		this.$router.push({ name: RoutesNames.USERIDENTIFICATION_Finish });
	}

	takeCurrentTab(tab: TabInfo) {
		this.changeTab(tab.id);
	}

	changeTab(id: number) {
		//this.updateCheck(id,true);
		//this.allowedIds.push(id);
		for (var i = 0; i < this.tabs.length; i++)
			if (this.tabs[i].id <= id) {
				this.tabs[i].isDisabled = false;
				if (this.tabs[i].id < id) this.tabs[i].isAllowed = true;
			}

		console.log("this.tabs", id, this.tabs);
		var tab = this.tabs.find((tab) => tab.id == id);
		//tab.isAllowed = true;
		tab.isDisabled = false;
		this.nextTab = tab;
		if (id == 4 && this.$route.name != RoutesNames.USERIDENTIFICATION_Liveness)
			this.$router.push({
				name: RoutesNames.USERIDENTIFICATION_Liveness,
				params: { lang: this.$localization.currentLang },
			});
	}

	takeTabs(tabs: Array<TabInfo>) {
		this.tabs = tabs;
	}
}
