import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "data_input_wrapper" }
const _hoisted_2 = { class: "field_wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_company_document_file = _resolveComponent("ui-company-document-file")!
  const _component_ui_company_document_adding_btn = _resolveComponent("ui-company-document-adding-btn")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.headerText.length != 0)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass([_ctx.styleForWrong ? 'wrong_text' : '', 'ui_input_header'])
        }, _toDisplayString(_ctx.headerText), 3))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_ui_company_document_file, {
        showDelete: _ctx.showDeleteButton,
        fileName: _ctx.document.FileUrl || _ctx.document.FileName,
        onDeleteFile: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('deleteFile', _ctx.document))),
        isUrl: _ctx.document.FileUrl
      }, null, 8, ["showDelete", "fileName", "isUrl"]),
      _createVNode(_component_ui_company_document_adding_btn, {
        buttonText: 
					_ctx.buttonText.length == 0
						? _ctx.buttonHide
							? '+ ' + _ctx.$localization.getTranslate('Button.AttachFile')
							: _ctx.$localization.getTranslate('Identification.EditCompanyDocumentBtn')
						: _ctx.buttonText
				,
        isWrong: _ctx.isWrong,
        onAddedFile: _cache[1] || (_cache[1] = ($event: any) => (_ctx.OnAddedFile($event)))
      }, null, 8, ["buttonText", "isWrong"])
    ])
  ]))
}