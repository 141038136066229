export const DEFAULT = "default";

export const USERIDENTIFICATION = "user-identification";
export const USERIDENTIFICATIONSIMPLE = "user-identification-simple";
export const USERIDENTIFICATION_Liveness = "liveness";
export const USERIDENTIFICATION_Finish = "identification-finish";

// export const NATURALPERSON_PROFILE_IDENTIFICATION = "naturalperson-profile-identification"

// export const NATURALPERSON_PROFILE = "naturalperson-profile"


// export const NATURALPERSON_PAYMENTS_historyOperation = "naturalperson-payments-historyOperation"
// export const NATURALPERSON_PAYMENTS_transferToCardAccountNumber = "naturalperson-payments-transferToCardAccountNumber"


export const FORGOTPASSWORD = "forgot-password"
export const LOGIN = "Login"
export const SIGNUP = "Signup"
export const MESSAGE = "message"
export const SUPPORT = "support"
export const PROFILEIDENTIFICATIONLEGAL = "profile-identification-legal"
export const IDENTIFICATION = "identification"
export const BALANCE = "balance"                        
export const TRANSFERS = "transfers"
export const TRANSUCTIONS = "transuctions"
export const PROFILE_NATURAL = "profile-natural"
export const PROFILE_IDENTIFICATION = "profile-identification"

