import UploadPhotoCropRequest from '@/api/main/dataSource/api/file-services/models/UploadPhotoCropRequest';
import LegalDirectorsSaveResponse from '@/api/main/dataSource/api/identification-services/models/legal-directors/LegalDirectorsSaveResponse';


export default class DirectorItemModel {

	constructor(obj?: Partial<DirectorItemModel>) {
		if (obj) {
			Object.assign(this, obj);
		}
	}

	Id?: number = null;
	FirstName?: string = '';
	SecondName?: string = '';
	Appeal?: string = '';
	Phone?: string = '';
	Email?: string = '';
	BirthDate: Date = null;
	BirthPlace?: string = '';
	AddressState?: string = '';
	AddressCountryCode?: string = '';
	AddressCity?: string = '';
	AddressStreet?: string = '';
	AddressHome?: string = '';
	AddressApartment?: string = '';
	AddressZip?: string = '';
	DocumentType?: string = '';
	DocumentNumber?: string = '';
	DocumentIssuedCountryCode?: string = '';
	DocumentIssuedBy?: string = '';
	DocumentIssuedDate: Date = null;
	DocumentExpireDate: Date = null;
	FileUrl?: string = '';
	File: UploadPhotoCropRequest = null;
	DocumentFile: UploadPhotoCropRequest = null;
	DocumentFileUrl?: string = '';
	ApproveOfAddressFile: UploadPhotoCropRequest = null;
	ApproveOfAddressFileUrl?: string = '';

	toLegalDirectorsSaveResponse(): LegalDirectorsSaveResponse {
		return new LegalDirectorsSaveResponse(this);
	}

	isValid(): boolean {
		const self = this;
		if (self.Appeal.length == 0 ||
			self.SecondName.length == 0 ||
			self.FirstName.length == 0 ||
			self.Phone.length <= 1 ||
			self.Email.length == 0 ||
			self.BirthDate == null ||
			self.BirthPlace.length == 0 ||
			self.DocumentType.length == 0 ||
			self.DocumentNumber.length == 0 ||
			self.DocumentIssuedBy.length == 0 ||
			(!self.AddressCountryCode ||
				self.AddressCountryCode.length == 0)
			||
			(!self.DocumentIssuedCountryCode ||
				self.DocumentIssuedCountryCode.length == 0) ||
			self.AddressCity.length == 0 ||
			self.AddressStreet.length == 0 ||
			self.AddressHome.length == 0 ||
			self.AddressZip.length == 0 ||
			self.DocumentIssuedDate == null ||
			self.DocumentType.length == 0 ||
			self.DocumentExpireDate == null ||
			(!self.DocumentFile ? self.DocumentFileUrl.length == 0 : self.DocumentFile.FileBase64.length == 0) ||
			(!self.ApproveOfAddressFile ? self.ApproveOfAddressFileUrl.length == 0 : self.ApproveOfAddressFile.FileBase64.length == 0)
		) {
			return false;
		}
		if (!(self.File && self.File.FileBase64.length != 0) && !self.FileUrl) {
			return false;
		}

		return true;
	}
}
