enum EnumCommission {
	PercentForBankPayment = 1,
	CardReplenishment = 2,
	Pin = 3,
	NaturalPersonOutgoingSepa = 4,
	LegalPersonOutgoingSepa = 5,
	NaturalPersonIncomingSepa = 6,
	LegalPersonIncomingSepa = 7,
	NaturalPersonIncomingSepaCzechResident = 8,
	LegalPersonIncomingSepaCzechResident = 9,
	NaturalPersonIncomingSepaNotEuResident = 10,
	LegalPersonIncomingSepaNotEuResident = 11,
	NaturalPersonOutgoingSepaCzechResident = 14,
	LegalPersonOutgoingSepaCzechResident = 15,
	NaturalPersonOutgoingSepaNotEuResident = 16,
	LegalPersonOutgoingSepaNotEuResident = 17,
	NaturalPersonFeeNotEuResident = 18,
	LegalPersonFeeNotEuResident = 19,
	NaturalPersonFeeEuResident = 20,
	LegalPersonFeeEuResident = 21,
	NaturalPersonFeeCzechResident = 22,
	LegalPersonFeeCzechResident = 23,
	CommissionSwiftPayout = 24,
	ComissionSepaPayout = 25,
	CommissionSepaPayin = 26,
	CommissionSwiftPayin = 27,
	CommissionAcquiringPayin = 28,
	CommissionAcquiringPayout = 29,
	CommissionCardPayout = 30,
	CommissionCryptPayout = 31,
	CommissionVirtpayPayout = 32,
	CommissionVirtpayPayin = 33,
	CommissionCryptoPayout = 34,
	BTCEUR = 35,
	ETHEUR = 36,
	USDTTRC20EUR = 37,
	USDTERC20EUR = 38,
	EURBTC = 39,
	EURETH = 40,
	EURUSDTTRC20 = 41,
	EURUSDTERC20 = 42,
	CommissionBTCPayout = 43,
	CommissionETHPayout = 44,
	CommissionUSDTTRC20Payout = 45,
	CommissionUSDTERC20Payout = 46
}
export default EnumCommission
