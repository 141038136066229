import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = ["id", "disabled"]
const _hoisted_3 = {
  class: "ui-button-show",
  type: "submit"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "ui-file-input",
    id: "app",
    onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args)))
  }, [
    (!!_ctx.label)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          for: _ctx.id
        }, _toDisplayString(_ctx.label) + ":", 9, _hoisted_1))
      : _createCommentVNode("", true),
    _createElementVNode("input", {
      ref: "file",
      class: "inputDl",
      type: "file",
      id: _ctx.id,
      disabled: _ctx.readonly,
      accept: "image/png, image/jpeg",
      onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onChangeInput && _ctx.onChangeInput(...args))),
      multiple: ""
    }, null, 40, _hoisted_2),
    _createElementVNode("div", _hoisted_3, [
      _renderSlot(_ctx.$slots, "default")
    ]),
    (!_ctx.showError == true)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass(["error-msg", { hidden: !_ctx.showError || !_ctx.errorMessage }])
        }, _toDisplayString(_ctx.errorMessage), 3))
      : _createCommentVNode("", true)
  ]))
}