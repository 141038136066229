import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-57032f8d"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  id: "security",
  class: "security"
}
const _hoisted_2 = { class: "security_container" }
const _hoisted_3 = { class: "change_password_box" }
const _hoisted_4 = { class: "form_box" }
const _hoisted_5 = { class: "form_box__title" }
const _hoisted_6 = { class: "inputs_box" }
const _hoisted_7 = { class: "field form-group" }
const _hoisted_8 = { class: "field form-group" }
const _hoisted_9 = { class: "field form-group" }
const _hoisted_10 = { class: "twofactor_authentication" }
const _hoisted_11 = { class: "twofactor_authentication__wrap" }
const _hoisted_12 = { class: "twofactor_authentication__wrap__title_box" }
const _hoisted_13 = { class: "d-flex" }
const _hoisted_14 = { class: "ml-3" }
const _hoisted_15 = { key: 0 }
const _hoisted_16 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_input = _resolveComponent("ui-input")!
  const _component_ui_message_error = _resolveComponent("ui-message-error")!
  const _component_aba_button_right = _resolveComponent("aba-button-right")!
  const _component_ui_toggler = _resolveComponent("ui-toggler")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$localization.getTranslate("ChangePassword")), 1),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_component_ui_input, {
                modelValue: _ctx.Password,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.Password) = $event)),
                headerText: _ctx.$localization.getTranslate('OldPassword'),
                type: "password"
              }, null, 8, ["modelValue", "headerText"])
            ]),
            _createElementVNode("div", _hoisted_8, [
              _createVNode(_component_ui_input, {
                modelValue: _ctx.NewPassword,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.NewPassword) = $event)),
                headerText: _ctx.$localization.getTranslate('NewPassword'),
                type: "password"
              }, null, 8, ["modelValue", "headerText"])
            ]),
            _createElementVNode("div", _hoisted_9, [
              _createVNode(_component_ui_input, {
                modelValue: _ctx.NewPasswordReapit,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.NewPasswordReapit) = $event)),
                headerText: _ctx.$localization.getTranslate('RepeatNewPassword'),
                type: "password",
                img: "@assets/img/Icons/button-toggle.svg"
              }, null, 8, ["modelValue", "headerText"])
            ])
          ]),
          _createVNode(_component_ui_message_error, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.ErrorMessage), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_aba_button_right, {
            isHideImg: "",
            isDisabled: !_ctx.isValid,
            onClick: _ctx.ChangePassword
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$localization.getTranslate("ChangePassword")), 1)
            ]),
            _: 1
          }, 8, ["isDisabled", "onClick"])
        ])
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.$localization.getTranslate("TwoFactorAuth")), 1),
          _createElementVNode("div", _hoisted_13, [
            _createVNode(_component_ui_toggler, {
              modelValue: _ctx.TwoAuth,
              "onUpdate:modelValue": [
                _cache[3] || (_cache[3] = ($event: any) => ((_ctx.TwoAuth) = $event)),
                _ctx.changeTwoAuth
              ]
            }, null, 8, ["modelValue", "onUpdate:modelValue"]),
            _createElementVNode("div", _hoisted_14, [
              (_ctx.TwoAuth)
                ? (_openBlock(), _createElementBlock("span", _hoisted_15, _toDisplayString(_ctx.$localization.getTranslate("Enable")), 1))
                : (_openBlock(), _createElementBlock("span", _hoisted_16, _toDisplayString(_ctx.$localization.getTranslate("SwitchOff")), 1))
            ])
          ])
        ])
      ])
    ])
  ]))
}