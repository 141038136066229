import { App } from "vue";
import Helper from "./helper"

declare module '@vue/runtime-core' {
    export interface ComponentCustomProperties {
        $helper: Helper;
    }
}

const install = (app: App) => {
    const helper = new Helper();
    app.config.globalProperties.$helper = helper;
};

const helper = {
    install
};

export default helper;