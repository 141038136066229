import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-7ba16bf8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ui-tags" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_tags_item = _resolveComponent("ui-tags-item")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
      return (_openBlock(), _createBlock(_component_ui_tags_item, {
        key: index,
        item: item,
        title: item.Value.ToString(),
        edit: _ctx.edit,
        onClose: ($event: any) => (_ctx.close(item))
      }, null, 8, ["item", "title", "edit", "onClose"]))
    }), 128))
  ]))
}