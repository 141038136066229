import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "ui-double-control-time" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_control_time = _resolveComponent("control-time")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_control_time, {
      value: _ctx.firstData,
      onInput: _ctx.inputFirstTime,
      class: _normalizeClass(["mr-2", {
        wrongTimeControl: _ctx.wrong,
      }])
    }, null, 8, ["value", "onInput", "class"]),
    _createVNode(_component_control_time, {
      value: _ctx.secondData,
      onInput: _ctx.inputSecondTime,
      class: _normalizeClass({
        wrongTimeControl: _ctx.wrong,
      })
    }, null, 8, ["value", "onInput", "class"])
  ]))
}