<template>
  <div v-if="!isCookieAccepted" class="cookie-banner">
    <p>We use technologies, such as cookies, to store and access device information to provide the best experiences.<br>
    By consenting to these technologies, we can process data like browsing behavior or unique IDs on this site.<br>
     If you choose not to consent or withdraw your consent, some features and functions may be negatively impacted.</p>
  <div class="d-flex align-center justify-content-center">
    <aba-button class="m-2" btnSize="btnLarge"  @click="acceptCookies" btnType="active">Accept</aba-button>
    <aba-button class="m-2" btnSize="btnLarge"  @click="declineCookies">Deny</aba-button>
  </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';

export default {
  setup() {
    const isCookieAccepted = ref(false);

    // Проверяем, не согласился ли пользователь ранее
    onMounted(() => {
      const cookieConsent = localStorage.getItem('cookieConsent');
      if (cookieConsent === 'accepted') {
        isCookieAccepted.value = true;
      }
    });

    // Пользователь принимает использование файлов cookie
    const acceptCookies = () => {
      localStorage.setItem('cookieConsent', 'accepted');
      isCookieAccepted.value = true;
      // Здесь можно добавить логику для работы с куки
    };

    // Пользователь отказывается от использования файлов cookie
    const declineCookies = () => {
      localStorage.setItem('cookieConsent', 'declined');
      isCookieAccepted.value = true;
      // Удаление всех cookie, если пользователь отказался
      clearCookies();
    };

    // Функция для очистки всех cookies
    const clearCookies = () => {
      const cookies = document.cookie.split(";");

      cookies.forEach(cookie => {
        const name = cookie.split("=")[0].trim();
        document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
      });
    };

    return {
      isCookieAccepted,
      acceptCookies,
      declineCookies
    };
  }
};
</script>

<style scoped>
.cookie-banner {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #f1f1f1;
  padding: 20px;
  text-align: center;
  z-index: 99999;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.2);
}

.cookie-banner button {
  margin: 0 10px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.cookie-banner button:hover {
  background-color: #0056b3;
}

.cookie-banner p {
  margin-bottom: 10px;
  font-size: 16px;
}
</style>
