
import { Vue, Prop, Watch } from "vue-property-decorator";
export default class UiInput extends Vue {
	@Prop({ default: "" }) headerText: string;
	@Prop({ default: false }) readOnly: boolean;
	@Prop({ default: "" }) placeholder: string;
	@Prop({ default: false }) isWrong: boolean;
	@Prop({ default: "" }) modelValue: number;

	styleForWrong: boolean = false;
	innerValue: number;

	@Watch("modelValue")
	onUpdateModelValue(newValue: number) {
		this.innerValue = newValue;
	}

	keyUp() {
		this.$emit("keyUp");
	}

	onUpdateText() {
		if (!this.innerValue.toString().length) {
			this.$emit("update:modelValue", null);
		} else {
			this.$emit("update:modelValue", this.innerValue);
		}
	}

	@Watch("isWrong")
	onUpdateIsWrong(newValue: boolean) {
		this.styleForWrong = newValue;
	}

	created() {
		this.innerValue = this.modelValue;
		this.onUpdateIsWrong(this.isWrong);
	}
}
