import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "business_profile_wrapper"
}
const _hoisted_2 = { class: "import_pays_block" }
const _hoisted_3 = { class: "pays_header" }
const _hoisted_4 = { class: "files_block" }
const _hoisted_5 = { class: "export_pays_block" }
const _hoisted_6 = { class: "pays_header" }
const _hoisted_7 = { class: "files_block" }
const _hoisted_8 = {
  key: 0,
  class: "planned_vol_block"
}
const _hoisted_9 = { class: "pays_header" }
const _hoisted_10 = { class: "activity_areas_wrapper" }
const _hoisted_11 = { class: "activities_wrapper" }
const _hoisted_12 = { class: "activities_block" }
const _hoisted_13 = { class: "activities_block" }
const _hoisted_14 = { class: "continue_block" }
const _hoisted_15 = {
  key: 1,
  class: "incomplete_text"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_textarea = _resolveComponent("ui-textarea")!
  const _component_ui_input = _resolveComponent("ui-input")!
  const _component_ui_select_autocomplete = _resolveComponent("ui-select-autocomplete")!
  const _component_ui_company_document_file = _resolveComponent("ui-company-document-file")!
  const _component_ui_company_document_adding_btn = _resolveComponent("ui-company-document-adding-btn")!
  const _component_ui_button_delete = _resolveComponent("ui-button-delete")!
  const _component_ui_radio_buttons = _resolveComponent("ui-radio-buttons")!
  const _component_ui_checkbox = _resolveComponent("ui-checkbox")!
  const _component_continue_button = _resolveComponent("continue-button")!
  const _component_loading = _resolveComponent("loading")!
  const _directive_localization = _resolveDirective("localization")!

  return (_ctx.businessProfileData)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_ui_textarea, {
          headerText: _ctx.$localization.getTranslate('Header.ActivityDescription') + '*',
          class: "profile_textarea",
          modelValue: _ctx.businessProfileData.BusinessActivityDescription,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.businessProfileData.BusinessActivityDescription) = $event)),
          isWrong: !_ctx.businessProfileData.BusinessActivityDescription.length && _ctx.checkStart
        }, null, 8, ["headerText", "modelValue", "isWrong"]),
        _createElementVNode("div", _hoisted_2, [
          _withDirectives(_createElementVNode("div", _hoisted_3, null, 512), [
            [_directive_localization, 'Header.ListIncomingPayment']
          ]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.takePartners(0), (partner, index) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "pay_inputs",
              key: index
            }, [
              _createVNode(_component_ui_input, {
                headerText: _ctx.$localization.getTranslate('Header.CompanyName') + '*',
                class: "profile_data_input company-name",
                modelValue: partner.Name,
                "onUpdate:modelValue": ($event: any) => ((partner.Name) = $event),
                isWrong: !partner.Name.length && _ctx.checkStart
              }, null, 8, ["headerText", "modelValue", "onUpdate:modelValue", "isWrong"]),
              _createVNode(_component_ui_select_autocomplete, {
                modelValue: partner.RegistrationCountryCode,
                "onUpdate:modelValue": ($event: any) => ((partner.RegistrationCountryCode) = $event),
                keyField: "value",
                valueField: "text",
                items: _ctx.countries,
                class: "profile_data_input",
                headerText: _ctx.$localization.getTranslate('Header.RegisterCountry') + '*',
                noUndefined: true,
                isWrong: 
						(!partner.RegistrationCountryCode ||
							!partner.RegistrationCountryCode.length) &&
						_ctx.checkStart
					
              }, null, 8, ["modelValue", "onUpdate:modelValue", "items", "headerText", "isWrong"]),
              _createElementVNode("div", _hoisted_4, [
                (partner.FileUrl || (partner.File && partner.File.FileName))
                  ? (_openBlock(), _createBlock(_component_ui_company_document_file, {
                      key: 0,
                      fileName: partner.FileUrl || (partner.File && partner.File.FileName),
                      onDeleteFile: ($event: any) => (_ctx.onClearFile(partner))
                    }, null, 8, ["fileName", "onDeleteFile"]))
                  : _createCommentVNode("", true),
                _createVNode(_component_ui_company_document_adding_btn, {
                  isWrong: 
							!(partner.FileUrl || (partner.File && partner.File.FileName)) && _ctx.checkStart
						,
                  buttonColor: "#76808E",
                  onAddedFile: ($event: any) => (_ctx.onAddedFile(partner, $event)),
                  buttonText: 
							partner.FileUrl || (partner.File && partner.File.FileName)
								? `${_ctx.$localization.getTranslate('Identification.EditCompanyDocumentBtn')}`
								: `${_ctx.$localization.getTranslate('Button.AddDocument')}`
						,
                  fileName: partner.FileUrl || (partner.File && partner.File.FileName)
                }, null, 8, ["isWrong", "onAddedFile", "buttonText", "fileName"])
              ]),
              _createVNode(_component_ui_button_delete, {
                onClick: ($event: any) => (_ctx.removePartner(partner))
              }, null, 8, ["onClick"])
            ]))
          }), 128)),
          _withDirectives(_createElementVNode("button", {
            class: "profile_data_add",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.addPartner('in')))
          }, null, 512), [
            [_directive_localization, 'Button.Add']
          ])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _withDirectives(_createElementVNode("div", _hoisted_6, null, 512), [
            [_directive_localization, 'Header.ListOutgoingPayment']
          ]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.takePartners(1), (partner, index) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "pay_inputs",
              key: index
            }, [
              _createVNode(_component_ui_input, {
                headerText: _ctx.$localization.getTranslate('Header.CompanyName') + '*',
                class: "profile_data_input company-name",
                modelValue: partner.Name,
                "onUpdate:modelValue": ($event: any) => ((partner.Name) = $event),
                isWrong: !partner.Name.length && _ctx.checkStart
              }, null, 8, ["headerText", "modelValue", "onUpdate:modelValue", "isWrong"]),
              _createVNode(_component_ui_select_autocomplete, {
                modelValue: partner.RegistrationCountryCode,
                "onUpdate:modelValue": ($event: any) => ((partner.RegistrationCountryCode) = $event),
                keyField: "value",
                valueField: "text",
                items: _ctx.countries,
                class: "profile_data_input",
                headerText: _ctx.$localization.getTranslate('Header.RegisterCountry') + '*',
                noUndefined: true,
                isWrong: 
						(!partner.RegistrationCountryCode ||
							!partner.RegistrationCountryCode.length) &&
						_ctx.checkStart
					
              }, null, 8, ["modelValue", "onUpdate:modelValue", "items", "headerText", "isWrong"]),
              _createElementVNode("div", _hoisted_7, [
                (partner.FileUrl || (partner.File && partner.File.FileName))
                  ? (_openBlock(), _createBlock(_component_ui_company_document_file, {
                      key: 0,
                      fileName: partner.FileUrl || (partner.File && partner.File.FileName),
                      onDeleteFile: ($event: any) => (_ctx.onClearFile(partner))
                    }, null, 8, ["fileName", "onDeleteFile"]))
                  : _createCommentVNode("", true),
                _createVNode(_component_ui_company_document_adding_btn, {
                  buttonColor: "#76808E",
                  onAddedFile: ($event: any) => (_ctx.onAddedFile(partner, $event)),
                  buttonText: 
							partner.FileUrl || (partner.File && partner.File.FileName)
								? `${_ctx.$localization.getTranslate('Identification.EditCompanyDocumentBtn')}`
								: `${_ctx.$localization.getTranslate('Button.AddDocument')}`
						,
                  isWrong: 
							!(partner.FileUrl || (partner.File && partner.File.FileName)) && _ctx.checkStart
						
                }, null, 8, ["onAddedFile", "buttonText", "isWrong"])
              ]),
              _createVNode(_component_ui_button_delete, {
                onClick: ($event: any) => (_ctx.removePartner(partner))
              }, null, 8, ["onClick"])
            ]))
          }), 128)),
          _withDirectives(_createElementVNode("button", {
            class: "profile_data_add",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.addPartner('out')))
          }, null, 512), [
            [_directive_localization, 'Button.Add']
          ])
        ]),
        (_ctx.businessProfileData)
          ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
              _withDirectives(_createElementVNode("div", _hoisted_9, null, 512), [
                [_directive_localization, 'Header.PlannedVolume']
              ]),
              _withDirectives(_createElementVNode("div", {
                class: _normalizeClass(["vol_header", [
					_ctx.businessProfileData.PlannedTransactionsLimits.IncomingYearsLimit == null &&
					_ctx.checkStart
						? 'wrong_text'
						: '',
				]])
              }, null, 2), [
                [_directive_localization, 'Header.PlannedVolumeYearIncoming']
              ]),
              _createVNode(_component_ui_radio_buttons, {
                items: _ctx.itemsPer1MLN,
                valueField: "text",
                keyField: "value",
                class: "business_radio",
                radioName: `${0}impYear`,
                radioStyle: 1,
                modelValue: _ctx.businessProfileData.PlannedTransactionsLimits.IncomingYearsLimit,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.businessProfileData.PlannedTransactionsLimits.IncomingYearsLimit) = $event))
              }, null, 8, ["items", "modelValue"]),
              _withDirectives(_createElementVNode("div", {
                class: _normalizeClass(["vol_header", [
					_ctx.businessProfileData.PlannedTransactionsLimits.OutgoingYearsLimit == null &&
					_ctx.checkStart
						? 'wrong_text'
						: '',
				]])
              }, null, 2), [
                [_directive_localization, 'Header.PlannedVolumeYearOutgoing']
              ]),
              _createVNode(_component_ui_radio_buttons, {
                items: _ctx.itemsPer1MLN,
                valueField: "text",
                keyField: "value",
                class: "business_radio",
                radioName: `${1}expYear`,
                radioStyle: 1,
                modelValue: _ctx.businessProfileData.PlannedTransactionsLimits.OutgoingYearsLimit,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.businessProfileData.PlannedTransactionsLimits.OutgoingYearsLimit) = $event))
              }, null, 8, ["items", "modelValue"]),
              _withDirectives(_createElementVNode("div", {
                class: _normalizeClass(["vol_header", [
					_ctx.businessProfileData.PlannedTransactionsLimits.IncomingMonthsLimit == null &&
					_ctx.checkStart
						? 'wrong_text'
						: '',
				]])
              }, null, 2), [
                [_directive_localization, 'Header.PlannedVolumeMonthIncoming']
              ]),
              _createVNode(_component_ui_radio_buttons, {
                items: _ctx.itemsPer250K,
                valueField: "text",
                keyField: "value",
                radioName: `${2}impMonth`,
                radioStyle: 1,
                class: "business_radio",
                modelValue: _ctx.businessProfileData.PlannedTransactionsLimits.IncomingMonthsLimit,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.businessProfileData.PlannedTransactionsLimits.IncomingMonthsLimit) = $event))
              }, null, 8, ["items", "modelValue"]),
              _withDirectives(_createElementVNode("div", {
                class: _normalizeClass(["vol_header", [
					_ctx.businessProfileData.PlannedTransactionsLimits.OutgoingMonthsLimit == null &&
					_ctx.checkStart
						? 'wrong_text'
						: '',
				]])
              }, null, 2), [
                [_directive_localization, 'Header.PlannedVolumeMonthOutgoing']
              ]),
              _createVNode(_component_ui_radio_buttons, {
                items: _ctx.itemsPer250K,
                valueField: "text",
                keyField: "value",
                radioName: `${3}expMonth`,
                class: "business_radio",
                radioStyle: 1,
                modelValue: _ctx.businessProfileData.PlannedTransactionsLimits.OutgoingMonthsLimit,
                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.businessProfileData.PlannedTransactionsLimits.OutgoingMonthsLimit) = $event))
              }, null, 8, ["items", "modelValue"])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("div", {
            class: _normalizeClass(["activity_areas_header", [
					!_ctx.businessProfileData.BusinessActivity.length &&
					!_ctx.businessProfileData.BusinessActivityOtherText.length &&
					_ctx.checkStart
						? 'wrong_text'
						: '',
				]])
          }, _toDisplayString(_ctx.$localization.getTranslate("Identification.BusinessProfile.AreasOfActivity")), 3),
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("div", _hoisted_12, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.bisnessActivityDictionary.slice(
							0,
							Math.ceil(_ctx.bisnessActivityDictionary.length / 2)
						), (group) => {
                return (_openBlock(), _createBlock(_component_ui_checkbox, {
                  key: group.Id,
                  class: "activity_checkbox",
                  modelValue: _ctx.checkAvailability(group.Id),
                  onClicked: ($event: any) => (_ctx.onCheck(group.Id))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(group[_ctx.currentLang]), 1)
                  ]),
                  _: 2
                }, 1032, ["modelValue", "onClicked"]))
              }), 128))
            ]),
            _createElementVNode("div", _hoisted_13, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.bisnessActivityDictionary.slice(
							Math.ceil(_ctx.bisnessActivityDictionary.length / 2)
						), (group) => {
                return (_openBlock(), _createBlock(_component_ui_checkbox, {
                  key: group.Id,
                  class: "activity_checkbox",
                  modelValue: _ctx.checkAvailability(group.Id),
                  onClicked: ($event: any) => (_ctx.onCheck(group.Id))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(group[_ctx.currentLang]), 1)
                  ]),
                  _: 2
                }, 1032, ["modelValue", "onClicked"]))
              }), 128))
            ])
          ]),
          _createVNode(_component_ui_checkbox, {
            class: "activity_checkbox",
            modelValue: _ctx.isAnother,
            "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.isAnother) = $event))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$localization.getTranslate("Identification.BusinessProfile.Another")), 1)
            ]),
            _: 1
          }, 8, ["modelValue"]),
          (_ctx.isAnother)
            ? (_openBlock(), _createBlock(_component_ui_input, {
                key: 0,
                class: "profile_data_input another_input",
                placeholder: 
					_ctx.$localization.getTranslate('Identification.BusinessProfile.Another')
				,
                modelValue: _ctx.businessProfileData.BusinessActivityOtherText,
                "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.businessProfileData.BusinessActivityOtherText) = $event))
              }, null, 8, ["placeholder", "modelValue"]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_14, [
          (!_ctx.isLoading)
            ? (_openBlock(), _createBlock(_component_continue_button, {
                key: 0,
                class: "business_continue_btn",
                onClicked: _ctx.saveAndContinue
              }, null, 8, ["onClicked"]))
            : _createCommentVNode("", true),
          (_ctx.errorText)
            ? (_openBlock(), _createElementBlock("div", _hoisted_15, "*" + _toDisplayString(_ctx.errorMessage), 1))
            : _createCommentVNode("", true),
          (_ctx.isLoading)
            ? (_openBlock(), _createBlock(_component_loading, {
                key: 2,
                class: "continue_loading"
              }))
            : _createCommentVNode("", true)
        ])
      ]))
    : _createCommentVNode("", true)
}