
import { Vue, Options, Prop } from "vue-property-decorator";
@Options({
	name: "ui-button-main",
})
export default class UiButtonMain extends Vue {
	@Prop({ type: Boolean, default: false }) isArrow: boolean;
	@Prop({ type: Boolean, default: false }) disabled: boolean;
	@Prop({ type: String, default: "18px 28px" }) padding: string;
	@Prop({ type: Number, default: "14" }) fz: string;

	click() {
		if (!this.disabled) this.$emit("onClick");
	}

	iconPath: string = "button-toggle";

	get getStyle() {
		return {
			padding: `${this.padding}`,
			"font-size": `${this.fz}px`,
			"line-height": `${this.fz}px`,
			opacity: this.disabled ? `0.5` : `1`,
			cursor: this.disabled ? "not-allowed" : "pointer",
			display: "inline-block",
		};
	}
}
