
import TemplateModel from "@/views/models/TemplatesTrasnfer/TemplateModel";
import { Vue, Options } from "vue-property-decorator";
@Options({
	name: "swift-templates",
})
export default class SwiftTemplates extends Vue {
	Templates: Array<TemplateModel> = null;

	created() {
		this.GetTemplates();
	}
	GetTemplates() {
		// this.$api.Main.SwiftService.GetTemplates((res) => {
		//   this.Templates = res.Value.Items as Array<TemplateModel>
		// });//TODO
	}
	UseTemplate(template: TemplateModel) {
		this.$emit("UseTemplate", template);
	}
}
