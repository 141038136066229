import { WebApiService } from '../../../../../plugins/webApiService'
import { Events } from '../../../../../plugins/events'
import { SendRequestModel } from '../../../../../plugins/models/SendRequestModel'
import GetCommissionRequest from '../../../../../aba/cabinet/web/models/GetCommissionRequest';
import WsResponseModelT from '../../../../../schema-builder/server/models/WsResponseModelT';

export default class CommissionsWsController {
	private url?: string;
	private cookieName: string;
	private appWebSettingsPath?: string;
	private apiUrlProperty?: string;

	GetCommissionEvent = new Events<WsResponseModelT<number>>();
	GetCommissionForSepaPaymentEvent = new Events<WsResponseModelT<number>>();

	subscribeEvents = async () => {
		const self = this;
		const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
		webApiService.on('CommissionsWsController', 'GetCommission', 'Main', (res: WsResponseModelT<number>) => {
			self.GetCommissionEvent.trigger(res);
		});
		webApiService.on('CommissionsWsController', 'GetCommissionForSepaPayment', 'Main', (res: WsResponseModelT<number>) => {
			self.GetCommissionForSepaPaymentEvent.trigger(res);
		});
	}

	constructor(url?: string, cookieName?: string, appWebSettingsPath?: string, apiUrlProperty?: string) {
		this.url = url;
		this.cookieName = cookieName ?? "Auth-Token";
		this.appWebSettingsPath = appWebSettingsPath && [location.host, appWebSettingsPath].join("/");
		this.apiUrlProperty = apiUrlProperty;
		this.subscribeEvents();
	}

	async GetCommission(request: GetCommissionRequest) {
		const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
		const sendRequest = new SendRequestModel({
			ProjectName: 'Main',
			Controller: 'CommissionsWsController',
			Method: 'GetCommission',
			Value: typeof(request) === 'object' ? JSON.stringify(request) : request,
		})

		webApiService.send(sendRequest);
	}

	async GetCommissionForSepaPayment(amount: number) {
		const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
		const sendRequest = new SendRequestModel({
			ProjectName: 'Main',
			Controller: 'CommissionsWsController',
			Method: 'GetCommissionForSepaPayment',
			Value: typeof(amount) === 'object' ? JSON.stringify(amount) : amount,
		})

		webApiService.send(sendRequest);
	}

	 // async methods
	async getCommissionAsync(request: GetCommissionRequest): Promise<number> {
		return new Promise<number>(async (resolve, reject) => {
			const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
			const sendRequest = new SendRequestModel({
				ProjectName: 'Main',
				Controller: 'CommissionsWsController',
				Method: 'GetCommission',
				Value: typeof(request) === 'object' ? JSON.stringify(request) : request,
				Callback: (response: WsResponseModelT<number>) => {
					const isGeneric = typeof response.IsSuccess == 'boolean'
					const result = response.IsSuccess ? response.Value : response

					if (!isGeneric || response.IsSuccess) resolve(result as number)
					else reject(result)
				}
			})

			webApiService.send(sendRequest);
		});
	}

	async getCommissionForSepaPaymentAsync(amount: number): Promise<number> {
		return new Promise<number>(async (resolve, reject) => {
			const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
			const sendRequest = new SendRequestModel({
				ProjectName: 'Main',
				Controller: 'CommissionsWsController',
				Method: 'GetCommissionForSepaPayment',
				Value: typeof(amount) === 'object' ? JSON.stringify(amount) : amount,
				Callback: (response: WsResponseModelT<number>) => {
					const isGeneric = typeof response.IsSuccess == 'boolean'
					const result = response.IsSuccess ? response.Value : response

					if (!isGeneric || response.IsSuccess) resolve(result as number)
					else reject(result)
				}
			})

			webApiService.send(sendRequest);
		});
	}

}