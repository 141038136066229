
import LegalBeneficiaryGetDataResponse from '@/api/main/dataSource/api/identification-services/models/legal-beneficiary/LegalBeneficiaryGetDataResponse';
import BeneficiaryInfoModel from "./BeneficiaryInfoModel";
import LegalBeneficiarySaveDataRequest from '@/api/main/dataSource/api/identification-services/models/legal-beneficiary/LegalBeneficiarySaveDataRequest';

export default class BeneficiaryModel {
    BeneficiaryDescription?: string = '';
    BeneficiaryInfo?: Array<BeneficiaryInfoModel> = null;
    LegalPersonId?: number = 0;

    constructor(obj?: Partial<LegalBeneficiaryGetDataResponse>) {

        if (obj) {
            Object.assign(this, obj)
            let self = this;
            if (obj.BeneficiaryInfo && obj.BeneficiaryInfo.length) {
                self.BeneficiaryInfo = obj.BeneficiaryInfo.map(x => new BeneficiaryInfoModel(x))
            } else {
                self.BeneficiaryInfo = [new BeneficiaryInfoModel()];
            }
        }
    }
    toLegalBeneficiarySaveDataRequest(): LegalBeneficiarySaveDataRequest {
        const result = new LegalBeneficiarySaveDataRequest(this);
        
        return result;
    }

    isValid(): boolean {
        const self = this;
        const notValid = self.BeneficiaryInfo.some(info => {
            return !info.OwnershipPercent|| info.OwnershipPercent.toString().length == 0 ||
                info.FirstName.length == 0 ||
                info.SecondName.length == 0 ||
                info.DocumentDateOfExpiry == null ||
                info.SecondName.length == 0 ||
                info.BirthDate == null ||
                info.DocumentType.length == 0 ||
                info.DocumentNumber.length == 0 ||
                info.DocumentAuthority.length == 0 ||
                !info.DocumentCountryCode ||
                info.DocumentCountryCode.length == 0 ||
                info.AddressCountryCode.length == 0 ||
                info.Phone.length <= 1 ||
                info.Email.length == 0 ||
                info.BirthDate == null ||
                info.BirthPlace.length == 0 ||
                info.AddressCity.length == 0 ||
                info.AddressStreet.length == 0 ||
                info.AddressHome.length == 0 ||
                info.AddressZip.length == 0 ||
                info.DocumentDateOfIssue == null ||
                info.Appeal.length == 0 ||
                (!info.DocumentFile ? info.DocumentFileUrl.length == 0 : info.DocumentFile.FileBase64.length == 0) ||
                (!info.ApproveOfAddressFile ? info.ApproveOfAddressFileUrl.length == 0 : info.ApproveOfAddressFile.FileBase64.length == 0)
        }
        );
        if (notValid) {

            return false;
        }
        return true;
    }
}
