import BeneficiaryType from '../../../aba/data/models/models/transactions/BeneficiaryType';
import EnumTypeTemplate from '../../../i-public-service/aba-finance/models/enums/EnumTypeTemplate';

export default class ModelTransferInternal  {
	Amount!: number;
	AccountNumber!: string;
	FullName!: string;
	Pin!: string;
	PurposePayment!: string;
	IsSave: boolean = false;
	NameTemplate!: string;
	BeneficiaryAddress!: string;
	BeneficiaryCity!: string;
	BeneficiaryCountryCode!: string;
	BeneficiaryPostalCode!: string;
	BeneficiaryState!: string;
	BeneficiaryType!: BeneficiaryType;
	Type!: EnumTypeTemplate;
	constructor(obj?: Partial<ModelTransferInternal>) {
		if (obj) {
			Object.assign(this, obj);
		}
	}
}
