import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-35c7e9d4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ui-tab-profile" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("ul", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (tab) => {
        return (_openBlock(), _createElementBlock("li", {
          key: tab.Id,
          onClick: ($event: any) => (_ctx.onChangeTab(tab.Id)),
          class: _normalizeClass([{ 'ui-tab-profile_item-active': tab.Active }, "ui-tab-profile_item"])
        }, [
          _createTextVNode(_toDisplayString(_ctx.$localization.getTranslate(tab.Name)) + " ", 1),
          _createElementVNode("div", {
            class: "bottom-line",
            style: _normalizeStyle({
					visibility: tab.Active ? 'visible' : 'hidden',
					opacity: tab.Active ? '1' : '0',
				})
          }, null, 4)
        ], 10, _hoisted_2))
      }), 128))
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (tab) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "tab-content",
        key: tab.Id
      }, [
        _createElementVNode("div", {
          class: "tab-content_item",
          style: _normalizeStyle(_ctx.isVisibleBlock(_ctx.currentTabId == tab.Id))
        }, [
          _renderSlot(_ctx.$slots, tab.Id, {}, undefined, true)
        ], 4)
      ]))
    }), 128))
  ], 64))
}