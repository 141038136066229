
import { Vue, Prop } from "vue-property-decorator";
export default class ErrorModalWindow extends Vue {
  @Prop({ default: false }) modalVision: boolean;
  @Prop() Text: string;

  close() {
    this.$emit("close");
  }
}
