export default class CardTransferModel {
  ToName: string = null;
  ToIban: string = null;
  Amount: string = null;
  Commission: string = null;
  Description: string = null;

  constructor(obj?: Partial<CardTransferModel>) {
    if (obj) {
      Object.assign(this, obj)
    }
  }
}