
import { Vue, Options } from "vue-property-decorator";
import FAQCategoryModel from "../../../models/FAQCategoryModel";
import FAQQuestionModel from "../../../models/FAQQuestionModel";
import { AdapterSupportService } from "../adapter";

@Options({
	name: "main-page",
})
export default class MainPage extends Vue {
	categories: Array<FAQCategoryModel> = [];
	isActiveCategory: boolean = false;
	SelectedCategory: FAQCategoryModel | null = null;
	isActiveQuestion: boolean = false;
	SelectedQuestion: FAQQuestionModel | null = null;
	PopularQuestions: Array<FAQQuestionModel> = [];

	isOpenSelectCategory: boolean = false;
	adapter = new AdapterSupportService();
	get domain(): string {
		return location.host && !location.host.startsWith("localhost")
			? location.host
			: "abafin.eu";
	}

	created() {
		this.GetCategories();
		this.GetPopularQuestions();
	}

	changePage(page: string) {
		this.$emit("ChangePage", page);
	}

	SelectCategory(category: FAQCategoryModel) {
		this.isActiveCategory = false;
		this.isActiveQuestion = true;
		this.SelectedCategory = this.categories.filter(function (item) {
			return item.Id === category.ParentId;
		})[0];
		console.log(this.SelectCategory);
		this.SelectQuestion(category.Questions[0] || {});
	}
	SelectQuestion(question: FAQQuestionModel) {
		this.isActiveCategory = false;
		this.isActiveQuestion = true;
		this.SelectedQuestion = question;
	}
	async GetCategories() {
		var formData = new FormData();
		formData.append("langId", this.$localization.currentLang);
		const res = await this.adapter.getCategories(this.$localization.currentLang);
		this.categories = res;
	}

	async GetPopularQuestions() {
		var formData = new FormData();
		formData.append("langId", this.$localization.currentLang);
		const res = await this.adapter.getPopularQuestions(this.$localization.currentLang);
		this.PopularQuestions = res;
	}

	isVisibleBlock(isVisible: boolean) {
		return {
			opacity: isVisible ? `1` : `0`,
			visibility: isVisible ? `visible` : `hidden`,
		};
	}
}
