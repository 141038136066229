import UploadPhotoCropRequest from '@/api/main/dataSource/api/file-services/models/UploadPhotoCropRequest';


export default class BeneficiaryInfoModel {
	Id?: number = null;
	Appeal: string = '';
	FirstName: string = '';
	SecondName: string = '';
	BirthDate: Date = null;
	OwnershipPercent: number = null;
	DocumentType: string = '';
	DocumentNumber: string = '';
	DocumentAuthority: string = '';
	DocumentCountryCode: string = '';
	DocumentDateOfIssue: Date = null;
	DocumentDateOfExpiry: Date = null;
	IsPep: boolean = false;
	IsUsaTaxResident: boolean = false;
	DocumentFile: UploadPhotoCropRequest = null;
	DocumentFileUrl: string = '';
	ApproveOfAddressFile: UploadPhotoCropRequest = null;
	ApproveOfAddressFileUrl: string = '';
	BirthPlace: string = '';
	AddressState: string = '';
	AddressCountryCode: string = '';
	AddressCity: string = '';
	AddressStreet: string = '';
	AddressHome: string = '';
	AddressApartment: string = '';
	AddressZip: string = '';
	Phone: string = '';
	Email: string = '';

	constructor(obj?: Partial<BeneficiaryInfoModel>) {
		if (obj) {
			Object.assign(this, obj)
		}
	}
}
