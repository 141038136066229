import LegalBeneficiaryInfoSaveRequest from '../../../identification-services/models/legal-beneficiary/LegalBeneficiaryInfoSaveRequest';

export default class LegalBeneficiarySaveDataRequest  {
	BeneficiaryDescription!: string;
	BeneficiaryInfo!: Array<LegalBeneficiaryInfoSaveRequest>;
	LegalPersonId!: number;
	constructor(obj?: Partial<LegalBeneficiarySaveDataRequest>) {
		if (obj) {
			Object.assign(this, obj);
		}
	}
}
