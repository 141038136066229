import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, withModifiers as _withModifiers, withKeys as _withKeys, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-a343f2c4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 1 }
const _hoisted_2 = {
  key: 0,
  class: "multiselect d-flex"
}
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "flex-grow-1" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { key: 2 }
const _hoisted_7 = {
  ref: "searchEl",
  style: {"border":"1px solid red"},
  class: "current"
}
const _hoisted_8 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    tabindex: "0",
    onFocusin: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.onGetFocus && _ctx.onGetFocus(...args))),
    onFocusout: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.onBlur && _ctx.onBlur(...args))),
    ref: "root",
    class: "ui-select"
  }, [
    (_ctx.headerText.length != 0)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["ui_input_header", [_ctx.styleForWrong ? 'wrong_text' : '']])
        }, _toDisplayString(_ctx.headerText), 3))
      : _createCommentVNode("", true),
    (!_ctx.open || !_ctx.autocomplete)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _renderSlot(_ctx.$slots, "current", {
            key: _ctx.innerValue,
            value: _ctx.active
          }, () => [
            (_ctx.multiselect)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  (_ctx.active.length == 0)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.selectText), 1))
                    : _createCommentVNode("", true),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.active, (item) => {
                    return (_openBlock(), _createElementBlock("div", {
                      class: "multiselect-item d-flex mr-3",
                      key: _ctx.getKey(item)
                    }, [
                      _createElementVNode("div", _hoisted_4, _toDisplayString(item), 1),
                      _createElementVNode("div", {
                        class: "remove",
                        onClick: ($event: any) => (_ctx.select(item))
                      }, "X", 8, _hoisted_5)
                    ]))
                  }), 128))
                ]))
              : (_openBlock(), _createElementBlock("div", {
                  key: 1,
                  class: _normalizeClass(["current", [_ctx.styleForWrong ? 'wrong' : '', { active: _ctx.open }]]),
                  onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.currentClick && _ctx.currentClick(...args)), ["stop"])),
                  onKeypress: _cache[1] || (_cache[1] = _withKeys(
//@ts-ignore
(...args) => (_ctx.onEnter && _ctx.onEnter(...args)), ["enter"])),
                  onBlur: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onBlur && _ctx.onBlur(...args))),
                  ref: "currentSearch",
                  tabindex: "111"
                }, _toDisplayString(_ctx.$localization.getTranslate("Button.SameAsBeneficiary")), 35))
          ], true)
        ]))
      : _createCommentVNode("", true),
    (_ctx.autocomplete && _ctx.open)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createElementVNode("input", _hoisted_7, null, 512)
        ]))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "container", { data: _ctx.tempItems }, () => [
      _createElementVNode("div", {
        class: _normalizeClass(["container", { active: _ctx.open }]),
        style: _normalizeStyle(_ctx.containerPosition)
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tempItems, (item, index) => {
          return (_openBlock(), _createElementBlock("div", {
            class: _normalizeClass(["item", { active: _ctx.isSelect(item) }]),
            key: index,
            onClick: ($event: any) => (_ctx.select(item))
          }, [
            (item === undefined && !_ctx.noUndefined)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createTextVNode(_toDisplayString(_ctx.defaultText), 1)
                ], 64))
              : _renderSlot(_ctx.$slots, "item", {
                  key: 1,
                  item: item,
                  index: index,
                  active: _ctx.isSelect(item)
                }, () => [
                  _createTextVNode(_toDisplayString(item), 1)
                ], true)
          ], 10, _hoisted_8))
        }), 128))
      ], 6)
    ], true)
  ], 544))
}