import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-e6a14ae8"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["crop-image-preview", { round: _ctx.round }]),
    style: _normalizeStyle(_ctx.blockStyle),
    ref: "preview"
  }, [
    _createElementVNode("img", {
      src: _ctx.imageSrc,
      style: _normalizeStyle(_ctx.imgStyle),
      class: "cr-image"
    }, null, 12, _hoisted_1)
  ], 6))
}