

export default class ModelStreamFile  {
	FileName!: string;
	FileBase64!: string;
	ContentLength!: number;
	constructor(obj?: Partial<ModelStreamFile>) {
		if (obj) {
			Object.assign(this, obj);
		}
	}
}
