
import { StyleValue } from "vue";
import { Vue, Options, Prop } from "vue-property-decorator";
import TabModel from "../models/TabModel";
@Options({
	name: "ui-tab-profile",
})
export default class UiTabProfile extends Vue {
	@Prop({ type: Array, default: null }) tabs: Array<TabModel>;

	currentTab: TabModel = null;
	currentTabId: number = 1;

	mounted() {
		this.onChangeTab(this.tabs?.find((item) => item.Active === true)?.Id);
	}

	onChangeTab(id: number) {
		this.tabs.forEach((t) => {
			if (t.Id === id) {
				t.Active = true;
				this.currentTab = t;
				this.currentTabId = t.Id;
			} else t.Active = false;
		});

		this.$emit("onChangeTab", id);
	}

	isVisibleBlock(isVisible: boolean): StyleValue {
		return {
			opacity: isVisible ? `1` : `0`,
			visibility: isVisible ? `visible` : `hidden`,
		};
	}
}
