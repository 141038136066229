
import moment from "moment";
import { Options, Vue, Watch, Prop } from "vue-property-decorator";
import UiDateTime from "@views/components/ui-date-picker/ui-date-time.vue";
import { setTransitionHooks } from "@vue/runtime-core";

@Options<UiDatePicker>({
  watch: {
    innerShowDatetime() {
      this.iconFocused = this.innerShowDatetime;
    },

    value(value) {
      this.innerDate = this.value;
      this.innerDateFormatted = this.formatDate(this.value);
    },
    innerDateFormatted(value) {
      this.oninnerDateFormatted();
    },
    innerSecondDateFormatted(value) {
      this.oninnerDateFormatted();
    },
  },
  emits: ["update:modelValue", "inputRage", "errorData"],
  components: { UiDateTime },
})
export default class UiDatePicker extends Vue {
  @Prop({ type: Date, default: new Date() }) fromDate: Date;
  @Prop({ type: Date, default: new Date() }) toDate: Date;

  @Prop({ type: Date, default: () => new Date() }) value: Date;
  // @Prop({ type: Date, default: () => new Date() }) dateSecond: Date;
  @Prop({ type: Boolean, default: true }) asUTC: boolean;
  @Prop({ default: "DD.MM.YYYY", type: String }) dateFormat: string;
  // ограничение даты снизу
  @Prop({ default: null, type: Date }) minimumDate: Date;
  @Prop({ default: null, type: Date }) maximumDate: Date;

  @Prop({ type: String }) initialView: String;
  @Prop({ default: "months", type: String }) minimumView: string;
  @Prop({ default: "years", type: String }) maximumView: string;

  //включен диапазон дат
  @Prop({ default: false, type: Boolean }) isDateRange: boolean;
  @Prop({ default: false, type: Boolean }) view2: boolean;

  // показывать текущую дату
  @Prop({ default: true, type: Boolean }) showCurrentDate: boolean;

  //показывать рамку только активации элемента
  @Prop({ default: false, type: Boolean }) transformer: boolean;

  @Prop({ default: null }) eventDates: Array<any>;
  @Prop({ default: true, type: Boolean }) autoCloseWhenChangeDay: boolean; //закрывать после выбора дня месяца
  @Prop({ default: false, type: Boolean }) changeDateManual: boolean; //разрешить редактирование даты вручную
  @Prop({ default: false, type: Boolean }) letSelectAllDays: boolean;

  innerDate: Date;
  innerDateFormatted: Date;
  innerSecondDate: Date;
  innerSecondDateFormatted: Date;
  inner2DateFormatted: { dateFrom: Date; dateTo: Date };
  innerShowDatetime = false;
  isFirstChoice = true;
  iconFocused = false;
  firstClick = true;
  wrong: boolean = false;
  created() {
    this.innerDateFormatted = this.formatDate(this.fromDate);
    this.innerSecondDateFormatted = this.formatDate(this.toDate);
    this.inner2DateFormatted = {
      dateFrom: this.innerDateFormatted,
      dateTo: this.innerSecondDateFormatted,
    };
    this.innerDate = this.fromDate;
    this.innerSecondDate = this.toDate;
    document.body.addEventListener("click", this.clickOutside.bind(this));
  }
  destroyed() {
    document.body.removeEventListener("click", this.clickOutside.bind(this));
  }
  oninnerDateFormatted() {
    if (this.isDateRange) {
      if (this.view2) {
        this.inner2DateFormatted = {
          dateFrom: this.formatDate(this.innerDate),
          dateTo: this.formatDate(this.innerSecondDate),
        };
      }
      // apdete
      if (
        this.inner2DateFormatted.dateFrom == this.inner2DateFormatted.dateTo
      ) {
        return;
      }
      if (this.innerDate < this.innerSecondDate) {
        this.wrong = false
        this.changeFirstDate(this.innerDate);
        this.changeSecondDate(this.innerSecondDate);
      } else {
        this.wrong = true;
        let self = this;
        setTimeout(function(){self.wrong = false}, 2000);
        this.inner2DateFormatted.dateFrom = this.innerDateFormatted;
        this.changeFirstDate(this.toDate);
        this.inner2DateFormatted.dateTo = this.innerSecondDateFormatted;
        this.changeFirstDate(this.fromDate);
      }
      // this.$emit(
      //   "inputrange",
      //   this.getFormatDate(this.innerDate),
      //   this.getFormatDate(this.innerSecondDate)
      // );
    } else {
      this.$emit("input", this.getFormatDate(this.innerDate));
      this.$emit("update:modelValue", this.getFormatDate(this.innerDate));

      if (this.changeDateManual) {
        return;
      }

      var x = this.formatDateRev(this.innerDateFormatted.toString());
      if (x != "Invalid Date") {
        this.innerDate = x;
      } else this.innerDateFormatted = this.formatDate(this.innerDate);

      this.$emit("input", this.getFormatDate(this.innerDate));
      this.$emit("update:modelValue", this.getFormatDate(this.innerDate));
    }
  }
  getFormatDate(date: Date): Date {
    if (!this.asUTC) {
      return date;
    }
    const temp = new Date(date);

    var now_utc = Date.UTC(
      temp.getFullYear(),
      temp.getMonth(),
      temp.getDate(),
      temp.getHours(),
      temp.getMinutes(),
      temp.getSeconds()
    );
    return new Date(now_utc);
  }

  //--------------------------------------------
  changeDate(date: Date, type: string) {
    console.log('changeDate' , date);
    
    if (this.isDateRange) {
      if (this.isFirstChoice) {
        this.changeFirstDate(date);
        this.inner2DateFormatted.dateFrom = this.formatDate(date);
      } else {
        if (this.innerDate > date) {
          //console.log("заходит сюда если сначала выбрана дата больше");
          let swap = this.innerDate;
          this.innerDate = date;
          this.innerDateFormatted = this.formatDate(this.innerDate);
          this.inner2DateFormatted.dateFrom = this.formatDate(this.innerDate);
          date = swap;
          this.innerSecondDate = date;
          this.innerSecondDateFormatted = this.formatDate(this.innerSecondDate);
          this.changeFirstDate(this.innerDate);
          this.inner2DateFormatted.dateTo = this.formatDate(date);
          this.isFirstChoice = !this.isFirstChoice;
          if (this.autoCloseWhenChangeDay && type == "days") {
            this.innerShowDatetime = false;
          }
          return;
        }
        this.changeSecondDate(date);
        this.inner2DateFormatted.dateTo = this.formatDate(date);
      }
      this.isFirstChoice = !this.isFirstChoice;
    } else {
      this.changeFirstDate(date);
    }
    if (this.autoCloseWhenChangeDay && this.isFirstChoice && type == "days") {
      this.innerShowDatetime = false;
    }
  }

  changeFirstDate(date: Date) {
    this.innerDate = date;
    this.innerDateFormatted = this.formatDate(this.innerDate);
    this.$emit("inputRage", this.getFormatDate(this.innerDate));
  }

  changeSecondDate(date: Date) {
    this.innerSecondDate = date;
    this.innerSecondDateFormatted = this.formatDate(this.innerSecondDate);
    this.$emit("inputRage", this.getFormatDate(this.innerSecondDate));
  }

  targetDatetime() {
    this.innerShowDatetime = !this.innerShowDatetime;
    this.iconFocused = this.innerShowDatetime;
  }
  formatDate(date: Date) {
    var moment = require("moment");
    var now = moment(date);
    moment.locale("ru");
    return now.format(this.dateFormat);
  }
  formatDateRev(value: String) {
    var moment = require("moment");
    var now = moment(value, this.dateFormat);
    moment.locale("ru");
    return now.toDate();
  }

  isClickedToDatePicker(event: MouseEvent): boolean {
    // const box = this.$refs.dateTimeWrapper as HTMLElement;
    const boxId = document.getElementById("dateTimeWrapper");
    if (!boxId) {
      return false;
    }
    const rect = boxId.getBoundingClientRect();
    if (!rect) {
      return false;
    }
    const dx = event.clientX - rect.left;
    const dy = event.clientY - rect.top;
    return dx > 0 && dx < rect.width && dy > 0 && dy < rect.height;
  }

  clickOutside(event: any) {
    if (this.isClickedToDatePicker(event)) {
      if (this.isDateRange) {
        // this.isFirstChoice = true;
        // if (this.isFirstChoice && this.innerDate > this.innerSecondDate) {
        //   var k = this.innerDate;
        //   this.changeFirstDate(this.innerSecondDate);
        //   this.changeSecondDate(k);
        // }
      }
    } else {
      if (!(this.$el == event.target || this.$el.contains(event.target))) {
        if (this.innerShowDatetime) {
          this.innerShowDatetime = false;
        }
      }
    }
  }
  onPressEnterLeft() {
    if (moment(this.inner2DateFormatted.dateFrom, "DD.MM.YYYY").isValid) {
      this.innerDate = moment(
        this.inner2DateFormatted.dateFrom,
        this.dateFormat
      ).toDate();
      this.oninnerDateFormatted();
    } else {
      this.inner2DateFormatted.dateFrom = this.formatDate(this.innerDate);
    }
  }
  onPressEnterRight() {
    if (moment(this.inner2DateFormatted.dateTo, "DD.MM.YYYY").isValid) {
      this.innerSecondDate = moment(
        this.inner2DateFormatted.dateTo,
        this.dateFormat
      ).toDate();
      this.oninnerDateFormatted();
    } else {
      this.inner2DateFormatted.dateTo = this.formatDate(this.innerSecondDate);
    }
  }
  onFocus(el: any) {
    this.innerShowDatetime = true;
  }
  onEnter(el: any) {
    if (this.changeDateManual) {
      if (moment(this.innerDateFormatted, this.dateFormat).isValid()) {
        var now = moment(this.innerDateFormatted, this.dateFormat);
        this.innerDate = now.toDate();
      }
    }
    el.target.blur();
    this.innerShowDatetime = false;
    this.iconFocused = false;
  }
  onTab(el: any) {
    if (this.changeDateManual) {
      if (moment(this.innerDateFormatted, this.dateFormat).isValid()) {
        var now = moment(this.innerDateFormatted, this.dateFormat);
        this.innerDate = now.toDate();
        this.$emit("input", this.getFormatDate(this.innerDate));
        this.$emit("update:modelValue", this.getFormatDate(this.innerDate));
      } else {
        this.innerDateFormatted = this.formatDate(this.innerDate);
      }
    }
    el.target.blur();
    this.innerShowDatetime = false;
    this.iconFocused = false;
  }

  onBlur(el: any) {
    if (this.changeDateManual) {
      if (moment(this.innerDateFormatted, this.dateFormat).isValid()) {
        var now = moment(this.innerDateFormatted, this.dateFormat);
        this.innerDate = now.toDate();
        this.$emit("input", this.getFormatDate(this.innerDate));
        this.$emit("update:modelValue", this.getFormatDate(this.innerDate));
      } else {
        this.innerDateFormatted = this.formatDate(this.innerDate);
      }
    }
  }
}
