/**
 * Модель получения ответа от сервера
 */
export class MessageResponseModel {
  /**
   * Название контроллер (на сервере)
   */
  Controller?: string;
  /**
   * Название метода
   */
  Method?: string;
  /**
   * Название проекта
   */
  ProjectName!: string;
  /**
   * Идентификатор запроса, что бы можно было отследить ответ. Сервер его не модифицирует и возращает в ответе.
   */
  RequestId!: string;
  /**
   * Функци для обработка ответа от сервера
   */
  Callback!: Function;
  constructor(data?: Partial<MessageResponseModel>) {
    if (data) Object.assign(this, data);
  }
}
