import ApiService from '@/api/index';
import ChangePasswordRequest from '@/api/main/dataSource/api/aba/cabinet/web/models/ChangePasswordRequest';
import CurrentUserResponse from '@/api/main/dataSource/api/aba/cabinet/web/models/CurrentUserResponse';
import GetPhotosResponse from '@/api/main/dataSource/api/aba/cabinet/web/models/GetPhotosResponse';
import CheckUniqueEmailRequest from '@/api/main/dataSource/api/aba/public/services/models/registration/CheckUniqueEmailRequest';
import RegistertrationStartRequest from '@/api/main/dataSource/api/aba/public/services/models/registration/RegistertrationStartRequest';
import RegistrationRequest from '@/api/main/dataSource/api/aba/public/services/models/registration/RegistrationRequest';
import RestorePasswordRequest from '@/api/main/dataSource/api/aba/public/services/models/restore-password/RestorePasswordRequest';
import EnumLang from '@/api/main/dataSource/api/i-public-service/aba-finance/models/enums/EnumLang';
import ModelSystemMessages from '@/api/main/dataSource/api/i-public-service/aba-finance/models/ModelSystemMessages';
import MailBankModel from '@/api/main/dataSource/api/i-public-service/aba-finance/models/support/MailBankModel';
import ModelNaturalPerson from '@/api/main/dataSource/api/i-public-service/aba-finance/models/user/ModelNaturalPerson';
import AcquiringInitPayinRequest from '@/api/main/dataSource/api/transaction-services/models/acquirings/AcquiringInitPayinRequest';
import AcquiringInitPayinResponse from '@/api/main/dataSource/api/transaction-services/models/acquirings/AcquiringInitPayinResponse';
import MoneyTransactionPersonResponse from '@/api/main/dataSource/api/transaction-services/models/MoneyTransactionPersonResponse';
import TransactionPersonRequest from '@/api/main/dataSource/api/transaction-services/models/TransactionPersonRequest';
import GetNaturalPersonResponse from '@/api/main/services/NaturalPersonHome/models/response/GetNaturalPersonResponse';
import GetSystemMessages from '@/api/main/services/NaturalPersonProfile/models/response/GetSystemMessages';
import FAQQuestionModel from '@/api/main/services/NaturalPersonSupport/models/FAQQuestionModel';

import { notify } from '@/app/notifications';
import FAQCategoryModel from '@/views/models/FAQCategoryModel';
import NaturalPersonModel from '@/views/models/NaturalPersonModel';
import moment from 'moment';


import { ref } from 'vue';


import LoginRequest from '@/api/main/dataSource/api/aba/public/services/models/login/LoginRequest';
import LoginResponse from '@/api/main/dataSource/api/aba/public/services/models/login/LoginResponse';
import ModelPersonIdentification from '@/api/main/dataSource/api/i-public-service/aba-finance/models/user/ModelPersonIdentification';
import PersonalIdentificationData from '@/api/main/dataSource/api/i-public-service/aba-finance/models/user/identification/PersonalIdentificationData';
import WsResponseModel from '@/api/main/SchemaBuilderServer/Models/WsResponseModel';
import AcquiringPayoutTemplateListResponse from '@/api/main/dataSource/api/transaction-services/models/transfer-template-service/AcquiringPayoutTemplateListResponse';
import SetProfileModel from '../models/SetProfileModel';
import PassportIdentificationData from '@/api/main/dataSource/api/i-public-service/aba-finance/models/user/identification/PassportIdentificationData';
import IdentificationModel from '../models/IdentificationModel';


export default class AdapterNaturalIdentificationService {

  /**
 * Сервис
 */
  private static instance: AdapterNaturalIdentificationService;
  /**
 * API
 */
  private static api: ApiService;
  isLoading: boolean = false;
  isInited = ref(false);
  api: ApiService;

  constructor() {
    AdapterNaturalIdentificationService.api = new ApiService();
  }

  public static getInstance() {
    if (!AdapterNaturalIdentificationService.instance) {
      AdapterNaturalIdentificationService.instance = new AdapterNaturalIdentificationService();
    }
    return AdapterNaturalIdentificationService.instance;
  }

  async livenessSaveResult(): Promise<WsResponseModel<Boolean>> {
    const result = new WsResponseModel<Boolean>();
    try {
      const resp = await AdapterNaturalIdentificationService.api.Main.FincheckerWsController.livenessSaveResultAsync();
      result.Value = resp;
      result.IsSuccess = true;
    } catch (e) {
      this.handleError(e, "ERROR livenessSaveResult")
    }
    return result;
  }

  /** строку в дату */
  convertStringToDate(s:string): Date{
    const futureStartAtDate = new Date(moment(s).locale("en").add(1, 'd').format("MMM DD, YYYY HH:MM"))
    return futureStartAtDate
  }
  comvertIdentificationModelToPassportIdentificationData(v: IdentificationModel) {
    const res = new PassportIdentificationData({
      AddressCity: v.City,
      AddressCountry: v.CountryCode,
      AddressState: v.State, AddressStreet: v.State,
      AddressZip: v.IndexCode,
      DocumentIssuedBy: v.PassportIssuedBy,
      DocumentIssuedCountryCode: v.PassportCountryCode,
      DocumentIssuedDate: this.convertStringToDate(v.PassportIssuedDate),
      DocumentNumber: v.PassportNumber,
      PassportScan: v.ScanPassport ,//TODO могут быть ошибки
      UtilityBillScan:v.ScanUtilityBill
      // ScanPassport:,
      // ScanUtilityBill: v.ScanUtilityBill as any,//TODO могут быть ошибки c типами с сервреа непорядок
      // UserId:v.
      // UtilityBillScan:v.ScanUtilityBill
      

      
    })
    return res; 
  }
  
  async savePassportIdentificationData(req: PassportIdentificationData): Promise<WsResponseModel<Boolean>> {
    const result = new WsResponseModel<Boolean>();
    try {
      const resp = await AdapterNaturalIdentificationService.api.Main.ProfileWsController.savePassportIdentificationDataAsync(req)
      result.Value = resp;
      result.IsSuccess = true;
    } catch (e) {
      return this.handleError(e, "ERROR savePassportIdentificationData")
    }
    return result;
  }

  mapSetProfileModelToPersonalIdentificationData(req: SetProfileModel): PersonalIdentificationData{
    const res = new PersonalIdentificationData({
      UserId: req.UserId,
      FirstName: req.FirstName,
      SecondName: req.SecondName,
      Appeal: req.Appeal,
      BirthPlace: req.BirthPlace,
      BirthDate:new Date(req.BirthDate)
    })
    return res
  }

  async savePersonalIdentificationData(req: PersonalIdentificationData): Promise<WsResponseModel<Boolean>> {
    const result = new WsResponseModel<Boolean>();
    try {
      const resp = await AdapterNaturalIdentificationService.api.Main.ProfileWsController.savePersonalIdentificationDataAsync(req);
      result.Value = resp;
      result.IsSuccess = true;
    } catch (e) {
      this.handleError(e, "ERROR savePersonalIdentificationData")
    }
    return result;
  }

  async getUserIdentification(): Promise<WsResponseModel<ModelPersonIdentification>> {
    const result = new WsResponseModel<ModelPersonIdentification>();
    try {
      const resp = await AdapterNaturalIdentificationService.api.Main.ProfileWsController.getUserIdentificationAsync();
      result.Value = resp;
      result.IsSuccess = true;
    } catch (e) {
      this.handleError(e, "ERROR getUserIdentification")
    }
    return result;
  }
  
  /**************************************************************************************************************************************** */
  /**
   * livenessStart
   * @returns 
   */
  async livenessStart(req: string): Promise<WsResponseModel<String>> {
    const result = new WsResponseModel<String>();
    try {
      const resp = await AdapterNaturalIdentificationService.api.Main.FincheckerWsController.livenessStartAsync(req);
      result.Value = resp;
      result.IsSuccess = true;
    } catch (e) {
      return  this.handleError(e, "ERROR livenessStart")
    }
    return result;
  }
  /**************************************************************************************************************************************** */
  /**
   * образец
   * @returns 
   */
  async getAcquiringTemplates(): Promise<WsResponseModel<AcquiringPayoutTemplateListResponse>> {
    const result = new WsResponseModel<AcquiringPayoutTemplateListResponse>();
    try {
      const resp = await AdapterNaturalIdentificationService.api.Main.NaturalPaymentsWsController.getAcquiringTemplatesAsync();
      result.Value = resp;
      result.IsSuccess = true;
    } catch (e) {
      this.handleError(e, "ERROR getAcquiringTemplates")
    }
    return result;
  }

  /**
   * Универсальный обработчик ошибок
   * @param e 
   * @param context 
   * @returns 
   */
  handleError(e: any, context: string): WsResponseModel<any> {
    console.error(`ERROR ${context}`, e);
    return {
      IsSuccess: false,
      ErrorCode: e?.ErrorCode || 500,
      ErrorMessage: e?.ErrorMessage || 'Internal Server Error',
      Value: null
    };
  }
  /**
   * показать информацию об ошибке
   * @param err 
   */
  showError(err: any) {
    console.error(err?.ErrorMessage);
    notify({ text: err?.ErrorMessage as any, title: "Error", type: 'error' })
  }
}
