
import { Options, Vue, Prop } from "vue-property-decorator";
import moment from "moment";
@Options<UiDays>({
  props: {
    weekday: [],
    date: Date,
    dateSecond: Date,
    allowedToShowView: Function,
    minimumDate: Date,
    maximumDate: Date,
    isDateRange: Boolean,
    eventDates: [],
    showCurrentDay: Boolean,
    letSelectAllDays: Boolean,
  },
  // watch: {
  //   date(value) {
  //     if (this.isDateRange) {
  //       if (!this.click) {
  //         this.innerSecondDate = this.innerDate;
  //         this.click = !this.click;
  //       } else {
  //         if (this.innerDate > this.innerSecondDate) {
  //           var k = this.innerDate;
  //           this.innerDate = this.innerSecondDate;
  //           this.innerSecondDate = k;
  //         }
  //         this.click = !this.click;
  //       }
  //       this.clearDaysAll();
  //       this.setMonth(this.date);
  //     } else {
  //       this.setMonth(this.date);
  //     }
  //   },
  // },
})
export default class UiDays extends Vue {
  weekday: string[] = ["Пт", "Вт", "Ср", "Чт", "Пт", "Сб", "Вс"];

  @Prop({ default: () => new Date(), type: Date }) date: Date;
  @Prop({ default: () => new Date(), type: Date }) dateSecond: Date;
  @Prop({ type: Function }) allowedToShowView: Function;
  @Prop({ default: null, type: Date }) minimumDate: Date;
  @Prop({ default: null, type: Date }) maximumDate: Date;
  @Prop({ default: false, type: Boolean }) isDateRange: boolean;
  @Prop({ default: () => [] }) eventDates: Array<any>;
  @Prop({ default: true, type: Boolean }) showCurrentDay: boolean;
  @Prop({ default: true, type: Boolean }) letSelectAllDays: boolean;

  innerDays: any[] = [];
  innerMonth: number = null;
  innerYear: number = null;
  innerDay: number = null;
  innerHour: number = null;

  innerMonthSecond: number = null;
  innerYearSecond: number = null;
  innerDaySecond: number = null;
  innerHourSecond: number = null;

  innerDate: Date = null;
  innerSecondDate: Date = null;
  weeks: any[] = [];
  click = false;
  created() {
    this.innerMonth = this.date.getMonth();
    this.innerYear = this.date.getFullYear();
    this.innerDay = this.date.getDate();
    this.innerHour = this.date.getHours();

    this.innerMonthSecond = this.dateSecond.getMonth();
    this.innerYearSecond = this.dateSecond.getFullYear();
    this.innerDaySecond = this.dateSecond.getDate();
    this.innerHourSecond = this.dateSecond.getHours();

    this.innerDate = this.date; // первая дата
    this.innerSecondDate = this.dateSecond; // вторая дата
  }
  mounted() {
    this.innerMonth = this.date.getMonth();
    this.innerYear = this.date.getFullYear();
    this.innerDay = this.date.getDate();
    this.innerHour = this.date.getHours();

    this.innerMonthSecond = this.dateSecond.getMonth();
    this.innerYearSecond = this.dateSecond.getFullYear();
    this.innerDaySecond = this.dateSecond.getDate();
    this.innerHourSecond = this.dateSecond.getHours();

    this.innerDate = this.date; // первая дата
    this.innerSecondDate = this.dateSecond; // вторая дата
    this.installWeeks();
    this.setMonth(this.date);
  }

  days() {
    return this.innerDays;
  }
  /**
   * Устанавливаем текущий месяц, собираем массив дней, дополняя его до 6 недель
   */
  moment(date: Date, format: string = "DD.MM.YYYY") {
    return moment(date).format(format);
  }
  setMonth(date: Date) {
    this.innerMonth = date.getMonth();
    this.innerYear = date.getFullYear();
    this.innerDays = [];
    var firstDay = new Date(this.innerYear, this.innerMonth, 1).getDay() || 7;
    var days = new Date(this.innerYear, this.innerMonth + 1, 0).getDate();
    var lastDay = new Date(this.innerYear, this.innerMonth, days).getDay() || 7;
    let begin = 2 - firstDay;
    var end = days + (7 - lastDay);
    end = end - begin < 41 ? end + 7 : end;
    for (let index = begin; index <= end; index++) {
      this.innerDays.push({
        type: this.getStyle(index, days),
        number: new Date(this.innerYear, this.innerMonth, index).getDate(),
        date: new Date(this.innerYear, this.innerMonth, index),
      });
    }
  }
  getStyle(index: number, days: any) {
    var result = "";
    if (index < 1 || index > days || !this.checkDateActivity(index)) {
      result = "no-active";
    }
    if (
      moment(new Date(this.innerYear, this.innerMonth, index)).isSame(
        this.innerDate,
        "day"
      ) &&
      !this.isDateRange
    ) {
      result = "current";
    }
    if (this.isDateRange) {
      if (
        moment(new Date(this.innerYear, this.innerMonth, index)).isBetween(
          this.innerDate,
          this.innerSecondDate,
          null,
          "()"
        )
      )
        result = "current-area";

      if (
        moment(new Date(this.innerYear, this.innerMonth, index)).isSame(
          this.innerSecondDate,
          "day"
        )
      )
        result = "current-area-end";

      if (
        moment(new Date(this.innerYear, this.innerMonth, index)).isSame(
          this.innerDate,
          "day"
        )
      )
        result = "current-area-begin";
    }
    var eventDateIndex =
      this.eventDates.length > 0
        ? this.eventDates.findIndex((item) =>
            moment(item).isSame(
              new Date(this.innerYear, this.innerMonth, index)
            )
          )
        : -1;
    if (
      moment(new Date(this.innerYear, this.innerMonth, index)).isSame(
        this.innerDate,
        "day"
      ) &&
      moment(new Date(this.innerYear, this.innerMonth, index)).isSame(
        this.innerSecondDate,
        "day"
      )
    )
      result = "current-area-begin-single";
    if (eventDateIndex != -1) {
      result = result + "event-day-" + "green";
    }
    return result;
  }

  /**
   * Проверить активность даты
   */
  checkDateActivity(day: any) {
    if (!this.minimumDate) return true;
    var x = new Date(this.innerYear, this.innerMonth, day) > this.minimumDate;
    return x;
  }
  next() {
    this.setMonth(new Date(this.innerYear, this.innerMonth + 1, 1));
  }
  backward() {
    this.setMonth(new Date(this.innerYear, this.innerMonth - 1, 1));
  }
  changeMonth() {
    this.$emit("onNavigation");
  }
  changeDay(day: any) {
    let mon = parseInt(moment(this.date).format("MM")) - 1;

    //let hour = parseInt(moment(this.date).format("HH"));
    let min = parseInt(moment(this.date).format("mm"));
    let sek = parseInt(moment(this.date).format("ss"));
    let minSecond = parseInt(moment(this.dateSecond).format("mm"));
    let sekSecond = parseInt(moment(this.dateSecond).format("ss"));

    // берет с прошлой даты время
    if (day.type != "no-active") {
      this.clearDays();
      this.innerDay = day.number;
      day.type = "current";
      // this.innerDate = new Date(
      //   this.innerYear,
      //   this.innerMonth,
      //   this.innerDay,
      //   this.innerHour,
      //   min,
      //   sek
      // );
      // this.innerSecondDate = new Date(
      //   this.innerYearSecond,
      //   this.innerMonthSecond,
      //   this.innerDaySecond,
      //   this.innerHourSecond,
      //   minSecond,
      //   sekSecond
      // );

      // не заходит сюда при выборе часов

      if (!this.click) {
        this.$emit(
          "changeDay",
          new Date(
            this.innerYear,
            this.innerMonth,
            day.number,
            this.innerHour,
            min,
            sek
          )
        );
        this.click = !this.click;
      } else {
        this.$emit(
          "changeDay",
          new Date(
            this.innerYear,
            this.innerMonth,
            day.number,
            this.innerHourSecond,
            min,
            sek
          )
        );
        this.click = !this.click;
      }
    }
  }

  clearDays() {
    for (let index = 0; index < this.innerDays.length; index++) {
      if (this.innerDays[index].type == "current") {
        this.innerDays[index].type = "";
      }
    }
  }
  clearDaysAll() {
    for (let index = 0; index < this.innerDays.length; index++) {
      this.innerDays[index].type = "";
    }
  }
  openHours() {
    this.$emit("changeCurrentView", "hours");
  }
  installWeeks() {
    var moment = require("moment");
    var startOfWeek = moment().startOf("isoWeek");
    var endOfWeek = moment().endOf("isoWeek");

    this.weeks = [];
    var day = startOfWeek;

    while (day <= endOfWeek) {
      this.weeks.push(day.toDate());
      day = day.clone().add(1, "d");
    }
  }
  monthToString(month: number) {
    switch (month) {
      case 0:
        return "Январь";
      case 1:
        return "Февраль";
      case 2:
        return "Март";
      case 3:
        return "Апрель";
      case 4:
        return "Май";
      case 5:
        return "Июнь";
      case 6:
        return "Июль";
      case 7:
        return "Август";
      case 8:
        return "Сентябрь";
      case 9:
        return "Октябрь";
      case 10:
        return "Ноябрь";
      case 11:
        return "Декабрь";
    }
  }
}
