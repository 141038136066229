import EnumCompanyDocumentType from '@/api/main/dataSource/api/aba/data/enums/EnumCompanyDocumentType'
import UploadPhotoCropRequest from '@/api/main/dataSource/api/file-services/models/UploadPhotoCropRequest'


export default class CompanyDocumentModel{
    Id?: number
    Type: EnumCompanyDocumentType
    FileUrl?: string    
    File?: string
    FileName?:string
}
