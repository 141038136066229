import $ = require("jquery");
import './tablesort/jquery.tablesorter.min.js'


const tablesort = {
    updated: function (el, binding) {
        (<any>$(el)).tablesorter({
            usNumberFormat: false,
            sortReset: true,
            sortRestart: true
        });
    }
}

export default tablesort;

