import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '../../../../assets/img/loading.svg'


const _withScopeId = (n: any) => (_pushScopeId("data-v-7b1eb9c8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "loading_wrapper" }
const _hoisted_2 = ["alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      src: _imports_0,
      alt: _ctx.alternativeText,
      class: "loading_img"
    }, null, 8, _hoisted_2)
  ]))
}