import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "second_button_wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      class: _normalizeClass(["ui_second_button", { 'un-select': _ctx.$props.isUnselect }]),
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('clicked')))
    }, [
      _renderSlot(_ctx.$slots, "default")
    ], 2)
  ]))
}