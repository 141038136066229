enum EnumTypeIdentified {
  /*
  Не идентифицирован
  */
  NotIdentified = 0,
  /*
  На рассмотрение
  */
  Consideration = 1,
  /*
  Потвержден
  */
  Confirmed = 2
}
export default EnumTypeIdentified;