import { WebApiService } from '../../../../../plugins/webApiService'
import { Events } from '../../../../../plugins/events'
import { SendRequestModel } from '../../../../../plugins/models/SendRequestModel'
import ChangePasswordRequest from '../../../../../aba/cabinet/web/models/ChangePasswordRequest';
import WsResponseModelT from '../../../../../schema-builder/server/models/WsResponseModelT';

export default class SecurityWsController {
	private url?: string;
	private cookieName: string;
	private appWebSettingsPath?: string;
	private apiUrlProperty?: string;

	ChangePasswordEvent = new Events<WsResponseModelT<Boolean>>();
	ChangeDefenseEvent = new Events<WsResponseModelT<Boolean>>();
	CheckDefenseEvent = new Events<WsResponseModelT<Boolean>>();
	GetQrImageEvent = new Events<WsResponseModelT<String>>();

	subscribeEvents = async () => {
		const self = this;
		const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
		webApiService.on('SecurityWsController', 'ChangePassword', 'Main', (res: WsResponseModelT<Boolean>) => {
			self.ChangePasswordEvent.trigger(res);
		});
		webApiService.on('SecurityWsController', 'ChangeDefense', 'Main', (res: WsResponseModelT<Boolean>) => {
			self.ChangeDefenseEvent.trigger(res);
		});
		webApiService.on('SecurityWsController', 'CheckDefense', 'Main', (res: WsResponseModelT<Boolean>) => {
			self.CheckDefenseEvent.trigger(res);
		});
		webApiService.on('SecurityWsController', 'GetQrImage', 'Main', (res: WsResponseModelT<String>) => {
			self.GetQrImageEvent.trigger(res);
		});
	}

	constructor(url?: string, cookieName?: string, appWebSettingsPath?: string, apiUrlProperty?: string) {
		this.url = url;
		this.cookieName = cookieName ?? "Auth-Token";
		this.appWebSettingsPath = appWebSettingsPath && [location.host, appWebSettingsPath].join("/");
		this.apiUrlProperty = apiUrlProperty;
		this.subscribeEvents();
	}

	async ChangePassword(request: ChangePasswordRequest) {
		const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
		const sendRequest = new SendRequestModel({
			ProjectName: 'Main',
			Controller: 'SecurityWsController',
			Method: 'ChangePassword',
			Value: typeof(request) === 'object' ? JSON.stringify(request) : request,
		})

		webApiService.send(sendRequest);
	}

	async ChangeDefense(twoAuth: boolean = false) {
		const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
		const sendRequest = new SendRequestModel({
			ProjectName: 'Main',
			Controller: 'SecurityWsController',
			Method: 'ChangeDefense',
			Value: typeof(twoAuth) === 'object' ? JSON.stringify(twoAuth) : twoAuth,
		})

		webApiService.send(sendRequest);
	}

	async CheckDefense() {
		const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
		const sendRequest = new SendRequestModel({
			ProjectName: 'Main',
			Controller: 'SecurityWsController',
			Method: 'CheckDefense',
		})

		webApiService.send(sendRequest);
	}

	async GetQrImage() {
		const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
		const sendRequest = new SendRequestModel({
			ProjectName: 'Main',
			Controller: 'SecurityWsController',
			Method: 'GetQrImage',
		})

		webApiService.send(sendRequest);
	}

	 // async methods
	async changePasswordAsync(request: ChangePasswordRequest): Promise<Boolean> {
		return new Promise<Boolean>(async (resolve, reject) => {
			const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
			const sendRequest = new SendRequestModel({
				ProjectName: 'Main',
				Controller: 'SecurityWsController',
				Method: 'ChangePassword',
				Value: typeof(request) === 'object' ? JSON.stringify(request) : request,
				Callback: (response: WsResponseModelT<Boolean>) => {
					const isGeneric = typeof response.IsSuccess == 'boolean'
					const result = response.IsSuccess ? response.Value : response

					if (!isGeneric || response.IsSuccess) resolve(result as Boolean)
					else reject(result)
				}
			})

			webApiService.send(sendRequest);
		});
	}

	async changeDefenseAsync(twoAuth: boolean = false): Promise<Boolean> {
		return new Promise<Boolean>(async (resolve, reject) => {
			const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
			const sendRequest = new SendRequestModel({
				ProjectName: 'Main',
				Controller: 'SecurityWsController',
				Method: 'ChangeDefense',
				Value: typeof(twoAuth) === 'object' ? JSON.stringify(twoAuth) : twoAuth,
				Callback: (response: WsResponseModelT<Boolean>) => {
					const isGeneric = typeof response.IsSuccess == 'boolean'
					const result = response.IsSuccess ? response.Value : response

					if (!isGeneric || response.IsSuccess) resolve(result as Boolean)
					else reject(result)
				}
			})

			webApiService.send(sendRequest);
		});
	}

	async checkDefenseAsync(): Promise<Boolean> {
		return new Promise<Boolean>(async (resolve, reject) => {
			const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
			const sendRequest = new SendRequestModel({
				ProjectName: 'Main',
				Controller: 'SecurityWsController',
				Method: 'CheckDefense',
				Callback: (response: WsResponseModelT<Boolean>) => {
					const isGeneric = typeof response.IsSuccess == 'boolean'
					const result = response.IsSuccess ? response.Value : response

					if (!isGeneric || response.IsSuccess) resolve(result as Boolean)
					else reject(result)
				}
			})

			webApiService.send(sendRequest);
		});
	}

	async getQrImageAsync(): Promise<String> {
		return new Promise<String>(async (resolve, reject) => {
			const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
			const sendRequest = new SendRequestModel({
				ProjectName: 'Main',
				Controller: 'SecurityWsController',
				Method: 'GetQrImage',
				Callback: (response: WsResponseModelT<String>) => {
					const isGeneric = typeof response.IsSuccess == 'boolean'
					const result = response.IsSuccess ? response.Value : response

					if (!isGeneric || response.IsSuccess) resolve(result as String)
					else reject(result)
				}
			})

			webApiService.send(sendRequest);
		});
	}

}