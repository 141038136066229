import * as enRes from './translations/localization.en.json';
import * as ruRes from './translations/localization.ru.json';
import codeRes from "./translations/localizationResponseCode.json"
import cookieService from '@/api/main/services/Cookie/cookie.service';
// todo dynamic import resources

export class LocaleProvider {
  private static _instance: LocaleProvider;
  private resouces = {
    en: enRes,
    ru: ruRes,
  };
  private lang = [
    { id: 1, langCode: 'ru', name: 'Русский', HtmlId: "ru", DisplayShortName: "RU", LangUrl: "ru", ShortName: "ru-RU", ImageName: "ru.svg", translateKey: 'Russian' },
    { id: 0, langCode: 'en', name: 'English', HtmlId: "en", DisplayShortName: "EN", LangUrl: "en", ShortName: "en-US", ImageName: "gb.svg", translateKey: 'English' },
  ];
  public static get Instance() {
    return this._instance || (this._instance = new this());
  }
  get currentLang() {
    return cookieService.getCookie('current-lang') || 'en';
    // if (!localStorage) return 'en';
    // return localStorage.getItem('locale') || 'en';
  }
  getLanguages() {
    return this.lang;
  }
  setCurrentLang(value: string) {
    cookieService.setCookie('current-lang', value)
    // if (localStorage) {
    //   this.setLocation(value);
      localStorage.setItem('locale', value);
    // }
  }
  setLocation(curLoc: string) {
    try {
      var oldUrl = location.pathname + location.search;
      var arrUrl = oldUrl.split("/");
      if (arrUrl[1] && arrUrl[1] !== "") {
        var newUrl = "";
        arrUrl[1] = curLoc;
        arrUrl.forEach(function (item, i) {
          if (i !== 0 && i != arrUrl.length - 1) newUrl = newUrl + item + "/";
          if (i === arrUrl.length - 1) {
            newUrl = newUrl + item;
          }
        });
        history.pushState(null, null, "/" + newUrl);
      } else {
        history.pushState(null, null, curLoc);
      }
      return;
    } catch (e) { }
    location.hash = "#" + curLoc;
  }

  get localeResource(): Object {
    return (this.resouces as any)[this.currentLang];
  }
  getTranslate(key: string) {
    const res = this.localeResource;
    if (!res) {
      console.error(`Ресурсы ключа: ${key} для языка ${this.currentLang} не найдены!`);
      return key;
    }
    if (!res.hasOwnProperty(key)) {
      console.error(`Ресурсы ключа: ${key} для языка ${this.currentLang} не найдены!`);
      return key;
    }
    return (res as any)[key];
  }
  getLangCodeById(id: number): string {
    return this.lang.find((item) => item.id == id).langCode;
  }
  getLangNameById(id: number): string {
    return this.lang.find((item) => item.id == id).name;
  }
  getLangObjectById(id: number): { id: number; langCode: string; name: string } {
    return this.lang.find((item) => item.id == id);
  }

  translateResponseCode(id: number) {
    let code = codeRes.find((item) => item.id === id.toString());
    return this.getTranslate(code.key);
  }
}

const myClassInstance = LocaleProvider.Instance;
export default myClassInstance;
