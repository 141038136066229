
import { Vue, Options, Prop, Watch } from "vue-property-decorator";
import UiDatePicker from "../../../components/ui-datetime-picker/ui-datetime-picker.vue";
import ProfileModel from "../../../models/ProfileModel";
import SetProfileModel from "../models/SetProfileModel";
import UiSelect from "../../../components/ui-select.vue";
import moment = require("moment");

@Options({
	name: "personal-data",
	components: { UiDatePicker, UiSelect },
})
export default class PersonalData extends Vue {
	@Prop({ default: null, type: Object }) profile!: ProfileModel;

	FirstName: string = this.profile.FirstName;
	SecondName: string = this.profile.SecondName;
	BirthPlace: string = this.profile.BirthPlace;
	BirthDate: string = this.profile.BirthPlace;
	ErrMessage: string = "";
	Appeals: Array<string> = ["Mr", "Mrs", "Miss"];
	appealModel: string = undefined;

	GoNext() {
		var validation = this.Validation();
		if (validation) {
			this.ErrMessage = "";
			var date = new Date(this.BirthDate);
			let SetProfile = new SetProfileModel({
				UserId: this.profile.Id,
				FirstName: this.FirstName,
				SecondName: this.SecondName,
				Appeal: this.appealModel,
				BirthPlace: this.BirthPlace,
				BirthDate: moment(date).format(),
			});
			this.$emit("SetProfile", SetProfile);
		}
		return false;
	}
	onChangeAppeals() {
		var validation = this.Validation();
		if (validation) {
			this.ErrMessage = "";
		}
	}
	Validation() {
		var russian = /[а-яА-ЯёЁ]/;
		if (this.FirstName === "" || this.SecondName === "" || this.appealModel === "") {
			this.ErrMessage = "Fill in all the fields";
			return false;
		} else if (russian.test(this.FirstName) || russian.test(this.SecondName)) {
			this.ErrMessage = "The text should consist only of English letters";
			return false;
		} else if (!this.appealModel) {
			this.ErrMessage = "Appeal is not selected";
			return false;
		}
		return true;
	}
	onSubmit() {
		return false;
	}
	dateChange(newDate: string) {
		this.BirthDate = moment(newDate).format();
	}
}
