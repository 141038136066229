
import { Vue, Options } from "vue-property-decorator";
import DatePeriodModel from "@/views/models/DatePeriodModel";

@Options({
  name: "choose-period",
})
export default class ChoosePeriod extends Vue {
  Today: DatePeriodModel = null;
  Yesterday: DatePeriodModel = null;
  Week: DatePeriodModel = null;
  Month: DatePeriodModel = null;
  LastWeek: DatePeriodModel = null;
  LastMonth: DatePeriodModel = null;

  created() {
    this.init();
  }

  init() {
    var date = new Date();
    var week = this.getMonday(date);
    var month = new Date(date.getFullYear(), date.getMonth(), 1);
    var current;
    if (date.getMonth() == 0) {
      current = new Date(date.getFullYear() - 1, 11, 1);
    } else {
      current = new Date(date.getFullYear(), date.getMonth() - 1, 1);
    }

    this.Today = new DatePeriodModel({
      StartDate: date,
      EndDate: date,
    });
    this.Yesterday = new DatePeriodModel({
      StartDate: new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate() - 1
      ),
      EndDate: date,
    });
    this.Week = new DatePeriodModel({
      StartDate: week,
      EndDate: date,
    });
    this.Month = new DatePeriodModel({
      StartDate: month,
      EndDate: date,
    });
    this.LastWeek = new DatePeriodModel({
      StartDate: this.getMonday(
        new Date(week.getFullYear(), week.getMonth(), week.getDate() - 1)
      ),
      EndDate: week,
    });
    this.LastMonth = new DatePeriodModel({
      StartDate: current,
      EndDate: month,
    });
  }

  SetPeriod(date: Date, event: any) {
    var ulEl = document.getElementById("needed_period");
    for (var i = 0; i < ulEl.childNodes.length; i++) {
      //@ts-ignore
      ulEl.childNodes[i].classList.remove("active");
    }
    event.target.parentElement.className += " active";
    this.$emit("setPeriod", date);
  }
  getMonday(d: Date) {
    d = new Date(d);
    var day = d.getDay(),
      diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
    return new Date(d.setDate(diff));
  }
}
