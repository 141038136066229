
import { Vue, Options, Watch } from "vue-property-decorator";
import { AdapterApiService } from "../../_adapter";
import { AdapterSupportService } from "../adapter";
import { PersonModel } from "@/views/models";
import { Teleport } from 'vue';
@Options({
	name: "contact-bank",
})
export default class ContactBank extends Vue {
	Type: string = "0";
	Message: string = "";
	Name: string = "";
	Email: string = "";
	items: Array<string> = [];
	itemsValue: Array<string> = [];
	typeMessage: string = "";
	Person: PersonModel = new PersonModel();

	isOpenSuccessModal: boolean = false;

	adapter = AdapterSupportService.getInstance();

	@Watch("typeMessage")
	onChangeTypeMessage() {}

	selectedIndex(index: number) {
		this.Type = this.itemsValue[index];
	}

	async mounted() {
		await this.getPerson();
		this.init();
	}

	async getPerson() {
		const resp = await this.adapter.getPerson();
		if (resp) {
			this.Person = resp;
		}
	}

	init() {
		this.typeMessage = this.$localization.getTranslate("SelectTopic");
		this.items = [
			this.$localization.getTranslate("SelectTopic"),
			this.$localization.getTranslate("AComplaint"),
			this.$localization.getTranslate("Thanks"),
			this.$localization.getTranslate("Sentence"),
			this.$localization.getTranslate("Question"),
		];

		this.itemsValue = ["0", "Жалоба", "Благодарность", "Предложение", "Вопрос"];
	}

	isValid() {
		
		const res = !!(this.Type && this.Message);
		return res;
	}
	async SendMessage() {
		if (!this.isValid()) {
			return;
		}
		let formData = new FormData();
		formData.append("Subject", this.Type);
		formData.append("Message", this.Message);

		const res = await this.adapter.sendMessage({
			Message: this.Message,
			Subject: this.Type,
		});
		if (res) {
			this.isOpenSuccessModal = true;
		} else {
			this.$store.state.modalDialogAlert(this.Message);
		}
	}

	close() {
		this.isOpenSuccessModal = false;
	}

	@Watch("isOpenSuccessModal")
	clear() {
		if (!this.isOpenSuccessModal) {
			this.typeMessage = this.$localization.getTranslate("SelectTopic");
			this.Message = "";
		}
	}
}
