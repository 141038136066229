
import { Vue, Prop, Watch } from "vue-property-decorator";
export default class UiRadioButtons extends Vue {
  @(Prop()!) items: any;
  @Prop({ default: "" }) valueField: string;
  @Prop({ default: "" }) keyField: string;
  @Prop({ default: "question" }) radioName: string;
  @Prop({ default: 0 }) radioStyle: number;
  @Prop({ default: null }) modelValue: any;

  currentStyle: boolean = true;
  innerValue: any = null;

  @Watch("modelValue")
  onUpdateModelValue(newValue: any) {
    this.innerValue = newValue;
  }

  @Watch("innerValue")
  onUpdateInnerValue(newValue: any) {
    this.$emit("update:modelValue", newValue);
  }

  radioChoosed(id: any): void {
    this.innerValue = this.items[id][this.keyField];
  }

  created() {
    this.onUpdateModelValue(this.modelValue);

    this.radioStyle == 0
      ? (this.currentStyle = true)
      : (this.currentStyle = false);
  }

  takeValue(item) {
    if (this.valueField != "") {
      return item[this.valueField];
    } else {
      return item;
    }
  }

  takeKey(item) {
    if (this.keyField != "") {
      return item[this.keyField];
    } else {
      return item;
    }
  }
}
