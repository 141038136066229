
import NaturalPersonModel from "@/views/models/NaturalPersonModel";
import { Vue, Options, Prop, Watch } from "vue-property-decorator";
@Options({
	name: "info-person",
})
export default class InfoPerson extends Vue {
	@Prop({ type: Object, default: null }) Person!: NaturalPersonModel;

	Name: string = "";
	LastName: string = "";
	Phone: string = "";
	Email: string = "";
	IsEdit: boolean = false;
	ErrorMessage: string = "";

	mounted() {
		this.onChangePerson();
	}

	@Watch("Person")
	onChangePerson() {
		this.Name = this.Person.Name;
		this.LastName = this.Person.LastName;
		this.Phone = this.Person.Phone;
		this.Email = this.Person.Email;
	}
}
