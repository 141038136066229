
import { Options, Vue, Prop } from "vue-property-decorator";
import moment from "moment";
@Options<UiMinuteSelector>({})
export default class UiMinuteSelector extends Vue {
  @Prop({ default: new Date(), type: Date }) date: Date;
  innerMinutes: any = [];
  innerMonth: number = null;
  innerYear: number = null;
  innerDay: number = null;
  innerHour: number = null;
  innerMinute: number = null;

  created() {
    console.log(this.date);
    // console.log(this/f);
    
    
    this.innerMonth = this.date.getMonth();
    this.innerYear = this.date.getFullYear();
    this.innerDay = this.date.getDate();
    this.innerHour = this.date.getHours();
    this.innerMinute = this.date.getMinutes();
  }
  mounted() {
    this.setHour(this.date);
    this.setMinutes();
  }
  get m() {
    return moment(
      new Date(this.innerYear, this.innerMonth, this.innerDay)
    ).format("D MMM YYYY");
  }
  setMinutes() {
    this.innerMinutes = [];
    for (let index = 0; index < 60; index += 5) {
      this.innerMinutes.push({
        name:
          this.innerHour +
          ":" +
          index.toLocaleString("en-US", {
            minimumIntegerDigits: 2,
            useGrouping: false,
          }),
        number: index,
        type: Number(this.innerMinute) == index ? "current" : "",
      });
    }
  }
  getHourName() {
    let hourNames = [" час", " часа", " часов"];
    let n = Math.abs(this.innerHour) % 100;
    let n1 = n % 10;
    if (n > 10 && n < 20) {
      return this.innerHour + hourNames[2];
    }
    if (n1 > 1 && n1 < 5) {
      return this.innerHour + hourNames[1];
    }
    if (n1 == 1) {
      return this.innerHour + hourNames[0];
    }
    return this.innerHour + hourNames[2];
  }

  setHour(date: Date) {
    this.innerHour = date.getHours();
  }
  next() {
    this.setHour(
      new Date(
        this.innerYear,
        this.innerMonth,
        this.innerDay,
        this.innerHour + 1
      )
    );
    this.setMinutes();
  }
  backward() {
    this.setHour(
      new Date(
        this.innerYear,
        this.innerMonth,
        this.innerDay,
        this.innerHour - 1
      )
    );
    this.setMinutes();
  }
  click = false;
  firstData: Date;
  changeMinute(minute: any) {
    this.clearMinutes();
    minute.type = "current";
    if (!this.click) {
      this.firstData = new Date(
        this.innerYear,
        this.innerMonth,
        this.innerDay,
        this.innerHour,
        minute.number
      );
    } else {
      let secondData = new Date(
        this.innerYear,
        this.innerMonth,
        this.innerDay,
        this.innerHour,
        minute.number
      );
      this.$emit("changeMinute");
    }

    // this.$emit("changeCurrentView", "hours");
  }
  changeHours() {
    this.$emit("onNavigation");
  }
  clearMinutes() {
    for (let index = 0; index < this.innerMinutes.length; index++) {
      if (this.innerMinutes[index].type == "current") {
        this.innerMinutes[index].type = "";
      }
    }
  }
  // openDays() {
  //   this.$emit("changeCurrentView", "days");
  // }
}
