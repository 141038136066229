import EnumCurrency from '../../../aba/data/enums/EnumCurrency';

export default class InternalTransferRequest  {
	FromUserId!: number;
	Amount!: number;
	Currency!: EnumCurrency;
	ToIban!: string;
	ToName!: string;
	Description!: string;
	SaveTemplate: boolean = false;
	TemplateName!: string;
	CurrentUrl!: string;
	constructor(obj?: Partial<InternalTransferRequest>) {
		if (obj) {
			Object.assign(this, obj);
		}
	}
}
