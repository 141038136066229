import EnumPlannedTransactionsLimitYears from '../../../aba/data/enums/EnumPlannedTransactionsLimitYears';
import EnumPlannedTransactionsLimitMonths from '../../../aba/data/enums/EnumPlannedTransactionsLimitMonths';

export default class PlannedTransactionsLimits  {
	IncomingYearsLimit!: EnumPlannedTransactionsLimitYears;
	OutgoingYearsLimit!: EnumPlannedTransactionsLimitYears;
	IncomingMonthsLimit!: EnumPlannedTransactionsLimitMonths;
	OutgoingMonthsLimit!: EnumPlannedTransactionsLimitMonths;
	constructor(obj?: Partial<PlannedTransactionsLimits>) {
		if (obj) {
			Object.assign(this, obj);
		}
	}
}
