import { createApp } from "vue";
import App from "./App.vue";
import router from "@/router/router";
import ErrorHandler from "./plugins/errorHandler";
import moment from "moment";

import Dialog from "./plugins/dialog/plugin.dialog"
import notify from "./app/notifications"
import ClickOutside from "@/directive/click-outside"
import FillInput from "@/directive/fillInput"
import PinCode from "@/directive/pinCode"
import TableSort from "@/directive/sortTable"

require("@assets/styles/main.css")
// require("@assets/styles/personCabinet.css")

moment.locale("ru")

const app = createApp(App);

// Регистрируем компоненты по уникальному названию файла
import registerComponents from "@/register-components"
registerComponents(app);


import Filters from "./filters/filters"
app.use(Filters);
import VueTheMask from "vue-the-mask"
app.use(VueTheMask)
// регистрируем Middleware для роутов
// import MiddlewareProcessor from "./middlewares/middlewareProcessor";
// app.use(MiddlewareProcessor, router);
import Tabs from 'vue3-tabs';

app.use(ErrorHandler, {
  handler: (err: Error) => {
    console.error('Err: ', err);
  },
});

// import Cookies from 'js-cookie';
// import { isDevelopment } from "./helper/helpers";
// // if (!Cookies.get('Auth-Token')) {
// //   if (isDevelopment())
// //     //161740b5-78f1-49ad-834f-427ccfb0d12c
// //     //e72dfeb0-7f7a-47ba-8c91-30dd068c0552
// //     //60082316-0dda-48a8-a627-61a2d729feda
// //     //5a7312fc-f145-4f72-be16-d05828eb1988
// //     //09b88a5e-57e4-47ce-ba84-c380bb8e9c27
// //     //c0a5e230-e83c-4bfc-8659-69769f37959f
// //     //c0a5e230-e83c-4bfc-8659-69769f37959f

// //     //c50d5bc4-3033-4ad7-b8c1-b7fccd72c623 old

// //     Cookies.set('Auth-Token', '9e43fe2d-aa56-460a-9cbe-42c9884af7e9')//'eee45352-cf00-41bb-aa1b-0732a544aaef' eb34f9df-37a8-4972-9bfe-402106836c7c 451D9053-5CEA-4E81-B4E2-C128B5730CAB   B77A59B3-BD79-4F65-A795-40AC226D8911
// //   else {
// //     location.href = "/";
// //   }

// }


// import Api from './api';
//app.use(Api, { url: 'https://localhost:44310/web-api' });
//app.use(Api, { url: 'https://localhost:5001/web-api' });
// let url;
// if (location.hostname === 'localhost')
//   url = 'https://localhost:5001/web-api';
// else
//   url = '/cabinet-api/web-api';
// url = 'http://abapublic.tirscript.com/cabinet-api/web-api'; 

// app.use(Api, { url: url });

//#region MAIN API
// import apiMain from "./apiMain"
// const urlMain = 'http://localhost:8088'
// "http://abapublic.tirscript.com";
// app.use(apiMain, { url: urlMain });
//#endregion

import Store from "@/store"
app.use(Store);
app.use(Dialog);
app.use(notify);


import localizationStore from './plugins/LocalizationStore/plugin.localization'
app.use(localizationStore);

import helperStore from "./plugins/helper/plugin.helper"
app.use(helperStore);

app.use(router);


app.use(Tabs);
app.directive("click-outside", ClickOutside);
app.directive("fillInput", FillInput)
app.directive("pinCode", PinCode)
app.directive("tablesort", TableSort)
app.mount("#app");
