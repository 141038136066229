export default class ModelPersonBalance {
  UserId?: number = 0;
  Balance?: number = 0;
  IsDisabledReplenish: boolean = false;
  Number: string = null;
  WalletUuid: string = null;
  DateBlocking?: Date = null;
  DateClosed?: Date = null;
  WalletsCount!: number;
  constructor(obj?: Partial<ModelPersonBalance>) {
    if (obj) {
      Object.assign(this, obj)
    }
  }
}
