import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-1d60386f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "naturalperson-profile-identification" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_beginning_work = _resolveComponent("beginning-work")!
  const _component_personal_data = _resolveComponent("personal-data")!
  const _component_identification = _resolveComponent("identification")!
  const _component_identification_liveness = _resolveComponent("identification-liveness")!
  const _component_identification_waiting = _resolveComponent("identification-waiting")!
  const _component_ui_message_error = _resolveComponent("ui-message-error")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.Step === 1)
      ? (_openBlock(), _createBlock(_component_beginning_work, {
          key: 0,
          onNext: _ctx.GoNext
        }, null, 8, ["onNext"]))
      : _createCommentVNode("", true),
    (_ctx.Step === 2)
      ? (_openBlock(), _createBlock(_component_personal_data, {
          key: 1,
          profile: _ctx.Profile,
          onSetProfile: _ctx.SetProfile
        }, null, 8, ["profile", "onSetProfile"]))
      : _createCommentVNode("", true),
    (_ctx.Step === 3)
      ? (_openBlock(), _createBlock(_component_identification, {
          key: 2,
          ErrorMessageInd: _ctx.ErrorMessageInd,
          profile: _ctx.Profile,
          onSetIdentification: _ctx.SetIdentification
        }, null, 8, ["ErrorMessageInd", "profile", "onSetIdentification"]))
      : _createCommentVNode("", true),
    (_ctx.Step === 4)
      ? (_openBlock(), _createBlock(_component_identification_liveness, {
          key: 3,
          profile: _ctx.Profile,
          onComplete: _ctx.OnLivenessComplete
        }, null, 8, ["profile", "onComplete"]))
      : _createCommentVNode("", true),
    (_ctx.Step === 5)
      ? (_openBlock(), _createBlock(_component_identification_waiting, {
          key: 4,
          profile: _ctx.Profile,
          onNext: _ctx.GoNext
        }, null, 8, ["profile", "onNext"]))
      : _createCommentVNode("", true),
    _createVNode(_component_ui_message_error, { errorMessage: _ctx.errorMessage }, null, 8, ["errorMessage"])
  ]))
}