import { App, DirectiveBinding } from "vue";
import { LocaleProvider } from "./localization";

declare module '@vue/runtime-core' {
  export interface ComponentCustomProperties {
    $localization: LocaleProvider;
  }
}

const install = (app: App) => {
  const localization = new LocaleProvider();
  app.config.globalProperties.$localization = localization;

  app.directive('localization', {
    mounted(el: HTMLElement, binding: DirectiveBinding) {
      el.innerText = app.config.globalProperties.$localization.getTranslate(binding.value);
    },
    updated(el: HTMLElement, binding: DirectiveBinding) {
      if (binding.oldValue == binding.value) {
        return;
      }
      el.innerText = app.config.globalProperties.$localization.getTranslate(binding.value);
    }
  })
};

const locale = {
  install
};

export default locale;