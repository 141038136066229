enum ApiError {

    NotFoundLegalPersonId = 200,
    NotFoundUserId = 201,
    NotFoundLegalPerson = 202,
    NotFoundBeneficiaryInfo = 203,
    EmptyBeneficiaryDescription = 204,
    EmptyBeneficiaryFirstName = 205,
    EmptyBeneficiarySecondName = 244,
    EmptyBeneficiaryDocumentType = 206,
    EmptyBeneficiaryDocumentAuthority = 207,
    EmptyBeneficiaryDocumentCountryCode = 208,
    EmptyBusinessProfileBusinessActivityDescription = 209,
    EmptyLegalPartnersName = 210,
    EmptyRegistrationCountryCode = 211,
    EmptyLegalPartnersFile = 212,
    EmptyFileName = 213,
    EmptyFileBase64 = 214,
    EmptyPlannedTransactionsLimits = 215,
    EmptyRegistrationDocument = 216,
    EmptyRegistrationNumber = 217,
    EmptyWebsite = 218,
    EmptyAddressCountryCode = 219,
    EmptyAddressCity = 220,
    EmptyAddressPostalCode = 221,
    EmptyAddressStreet = 222,
    EmptyAddressHome = 223,
    EmptyLegalDirectors = 224,
    EmptyDirectorsAppeal = 225,
    EmptyDirectorsFirstName = 226,
    EmptyDirectorsSecondName = 227,
    EmptyDirectorsPhone = 228,
    EmptyDirectorsEmail = 229,
    EmptyDirectorsBirthPlace = 230,
    EmptyDirectorsAddressCountryCode = 231,
    EmptyDirectorsAddressCity = 232,
    EmptyDirectorsAddressStreet = 233,
    EmptyDirectorsAddressHome = 234,
    EmptyDirectorsAddressZip = 235,
    EmptyDirectorsDocumentType = 236,
    EmptyDirectorsDocumentNumber = 237,
    EmptyDirectorsDocumentIssuedBy = 238,
    EmptyDirectorsFile = 239,
    InvalidBeneficiaryId = 240,
    InvalidLegalPartnersId = 241,
    InvalidLegalDirectorsId = 242,
    InvalidDateTime = 243,
    EmptyBeneficiaryAddressCountryCode = 266,
    EmptyBeneficiaryDocumentNumber = 267,
    EmptyDirectorsDocumentCountryCode = 268,
    ErrorLivenessInit = 277,        
    ErrorLivenessResult = 278
}
export default ApiError;