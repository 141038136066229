import $ = require("jquery");

const pinCode = {
    beforeMount: function (el, binding) {
        $(el).on('keyup change', function (event) {
            var thisCVV = $(this) as any;
            var KeyID = event.keyCode;

            if (KeyID === 8) thisCVV.prev().focus();
            else if (thisCVV.val().length > 0) thisCVV.next().focus()
            // else if (KeyID === 39) thisCVV.next().focus()
            // else if (KeyID === 37) thisCVV.prev().focus();

            $(el).each(function () {
                if (thisCVV.val().length == 6) {
                    thisCVV.next().focus();
                }
            })
        });

        $(el).on('keyup keypress blur change', function (e) {
            if (e.which != 8 && e.which != 0 && (e.which < 48 || e.which > 57)) {
                return false;
            } else {
                if (($(this).val() as any).length >= parseInt($(this).attr('maxlength')) && (e.which != 8 && e.which != 0)) {
                    return false;
                }
            }
        });
    }
}

export default pinCode;

