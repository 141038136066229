import ApiService from '@/api/index';
import CurrentUserResponse from '@/api/main/dataSource/api/aba/cabinet/web/models/CurrentUserResponse';
import ProfileWsController from '@/api/main/dataSource/api/aba/cabinet/web/ws-controllers/accounts/ProfileWsController';
import LoginRequest from '@/api/main/dataSource/api/aba/public/services/models/login/LoginRequest';
import LoginResponse from '@/api/main/dataSource/api/aba/public/services/models/login/LoginResponse';
import ModelNaturalPerson from '@/api/main/dataSource/api/i-public-service/aba-finance/models/user/ModelNaturalPerson';
import ModelPersonIdentification from '@/api/main/dataSource/api/i-public-service/aba-finance/models/user/ModelPersonIdentification';
import GetBusinessActivityResponse from '@/api/main/dataSource/api/identification-services/models/business-activity/GetBusinessActivityResponse';
import FincheckerLivenessStatusResponse from '@/api/main/dataSource/api/identification-services/models/finchecker/FincheckerLivenessStatusResponse';
import LegalBeneficiaryGetDataResponse from '@/api/main/dataSource/api/identification-services/models/legal-beneficiary/LegalBeneficiaryGetDataResponse';
import LegalBeneficiarySaveDataRequest from '@/api/main/dataSource/api/identification-services/models/legal-beneficiary/LegalBeneficiarySaveDataRequest';
import LegalBusinessProfileGetDataResponse from '@/api/main/dataSource/api/identification-services/models/legal-business-profile/LegalBusinessProfileGetDataResponse';
import LegalBusinessProfileSaveDataRequest from '@/api/main/dataSource/api/identification-services/models/legal-business-profile/LegalBusinessProfileSaveDataRequest';
import LegalCompanyGetDataResponse from '@/api/main/dataSource/api/identification-services/models/legal-company/LegalCompanyGetDataResponse';
import LegalCompanySaveDataRequest from '@/api/main/dataSource/api/identification-services/models/legal-company/LegalCompanySaveDataRequest';
import LegalDirectorsGetDataResponse from '@/api/main/dataSource/api/identification-services/models/legal-directors/LegalDirectorsGetDataResponse';
import LegalDirectorsSaveDataRequest from '@/api/main/dataSource/api/identification-services/models/legal-directors/LegalDirectorsSaveDataRequest';
import UserType from '@/api/main/models/Enums/UserType';
import WsResponseModel from '@/api/main/SchemaBuilderServer/Models/WsResponseModel';
import { notify } from '@/app/notifications';
import { ref } from 'vue';


export default class AdapterApiService {

  /**
 * Сервис
 */
  private static instance: AdapterApiService;
  /**
 * API
 */
  private static api: ApiService;
  isLoading: boolean = false;
  isInited = ref(false);
  // api: ApiService;
  /** True если физическое лицо */
  isNatural = ref(false)
  userInfo = ref(new CurrentUserResponse())
  constructor() {
    AdapterApiService.api = new ApiService();
  }

  public static getInstance() {
    if (!AdapterApiService.instance) {
      AdapterApiService.instance = new AdapterApiService();
    }
    return AdapterApiService.instance;
  }


  async getCurrentUser(): Promise<WsResponseModel<CurrentUserResponse>> {
    const result = new WsResponseModel<CurrentUserResponse>();
    try {
      const res = await AdapterApiService.api.Main.AuthWsController.getCurrentUserAsync();
      this.userInfo.value = new CurrentUserResponse(res);
      result.Value = res;
      this.isNatural.value = result.Value.UserRole == UserType.Natural;
      result.IsSuccess = true;
    } catch (e) {
      return this.handleError(e, "ERROR getCurrentUser")
    }
    return result;
  }



  async loginUser(value: LoginRequest): Promise<LoginResponse> {
    try {

      const res = await AdapterApiService.api.Main.AuthWsController.loginAsync(value);

      return res
    } catch (err: any) {
      this.showError(err)
      return null
    }
  }



  async isLivenessInit(): Promise<WsResponseModel<FincheckerLivenessStatusResponse>> {
    const result = new WsResponseModel<FincheckerLivenessStatusResponse>();
    try {
      const resp = await AdapterApiService.api.Main.FincheckerWsController.isLivenessInitAsync();
      result.Value = resp;
      result.IsSuccess = true;
    } catch (e) {
      return this.handleError(e, "ERROR isLivenessInit")
    }
    return result;
  }

  async livenessSaveResultAsync(): Promise<WsResponseModel<Boolean>> {
    const result = new WsResponseModel<Boolean>();
    try {
      const resp = await AdapterApiService.api.Main.FincheckerWsController.livenessSaveResultAsync();
      result.Value = resp;
      result.IsSuccess = true;
    } catch (e) {
      return this.handleError(e, "ERROR livenessSaveResultAsync")
    }
    return result;
  }


  async livenessStartAsync(returnUrl: string): Promise<WsResponseModel<String>> {
    const result = new WsResponseModel<String>();
    try {
      const resp = await AdapterApiService.api.Main.FincheckerWsController.livenessStartAsync(returnUrl);
      result.Value = resp;
      result.IsSuccess = true;
    } catch (e) {
      return this.handleError(e, "ERROR livenessStartAsync")
    }
    return result;
  }


  async getLegalDirectorsAsync(): Promise<WsResponseModel<LegalDirectorsGetDataResponse>> {
    const result = new WsResponseModel<LegalDirectorsGetDataResponse>();
    try {
      const resp = await AdapterApiService.api.Main.LegalDirectorsWsController.getLegalDirectorsAsync();
      result.Value = resp;
      result.IsSuccess = true;
    } catch (e) {
      return this.handleError(e, "ERROR getLegalDirectorsAsync")
    }
    return result;
  }

  async saveLegalDirectorsDataAsync(value: LegalDirectorsSaveDataRequest): Promise<boolean> {
    try {
      const res = await AdapterApiService.api.Main.LegalDirectorsWsController.saveLegalDirectorsDataAsync(value);
      return res as boolean
    } catch (err: any) {
      this.showError(err);
      return null
    }
  }

  async saveCompanyDataAsync(value: LegalCompanySaveDataRequest): Promise<boolean> {
    try {
      const res = await AdapterApiService.api.Main.LegalCompanyDataWsController.saveCompanyDataAsync(value);
      return res as boolean
    } catch (err: any) {
      this.showError(err);
      return null
    }
  }

  async getBusinessProfileAsync(): Promise<LegalBusinessProfileGetDataResponse> {
    try {
      const res = await AdapterApiService.api.Main.LegalBusinessProfileWsController.getBusinessProfileAsync();
      return res
    } catch (err: any) {
      this.showError(err);
      return null
    }
  }
  async saveBusinessProfileDataAsync(value: LegalBusinessProfileSaveDataRequest): Promise<boolean> {
    try {
      const res = await AdapterApiService.api.Main.LegalBusinessProfileWsController.saveBusinessProfileDataAsync(value);
      return res as boolean
    } catch (err: any) {
      this.showError(err);
      return null
    }
  }

  async getBusinessActivitiesListAsync(): Promise<Array<GetBusinessActivityResponse>> {
    try {
      const res = await AdapterApiService.api.Main.DictionaryWsController.getBusinessActivitiesListAsync();
      return res
    } catch (err: any) {
      this.showError(err);
      return null
    }
  }
  async getCompanyDataAsync(): Promise<LegalCompanyGetDataResponse> {
    try {
      const res = await AdapterApiService.api.Main.LegalCompanyDataWsController.getCompanyDataAsync();
      return res
    } catch (err: any) {
      this.showError(err);
      return null
    }
  }

  async saveBeneficiaryDataAsync(value: LegalBeneficiarySaveDataRequest): Promise<boolean> {
    try {
      const res = await AdapterApiService.api.Main.LegalBeneficiaryWsController.saveBeneficiaryDataAsync(value);
      return res as boolean
    } catch (err: any) {
      this.showError(err);
      return null
    }
  }

  // async getBeneficiaryDataAsync(): Promise<LegalBeneficiaryGetDataResponse> {
  //   try {
  //     const res = await AdapterApiService.api.Main.LegalBeneficiaryWsController.getBeneficiaryDataAsync();
  //     return res
  //   } catch (err: any) {
  //     this.showError(err);
  //     return null
  //   }
  // }

  async getBeneficiaryDataAsync(): Promise<WsResponseModel<LegalBeneficiaryGetDataResponse>> {
    const result = new WsResponseModel<LegalBeneficiaryGetDataResponse>();
    try {
      const resp = await AdapterApiService.api.Main.LegalBeneficiaryWsController.getBeneficiaryDataAsync();
      result.Value = resp;
      result.IsSuccess = true;
    } catch (e) {
      return this.handleError(e, "ERROR getBeneficiaryDataAsync")
    }
    return result;
  }

  /**
   * 
   * @returns 
   */
  async getUserIdentification(): Promise<WsResponseModel<ModelPersonIdentification>> {
    const result = new WsResponseModel<ModelPersonIdentification>();
    try {
      const resp = await AdapterApiService.api.Main.ProfileWsController.getUserIdentificationAsync();
      result.Value = resp;
      result.IsSuccess = true;
    } catch (e) {
      return this.handleError(e, "ERROR getAcquiringTemplates")
    }
    return result;
  }

  /**
   * Универсальный обработчик ошибок
   * @param e 
   * @param context 
   * @returns 
   */
  handleError(e: any, context: string): WsResponseModel<any> {
    console.error(`ERROR ${context}`, e);
    return {
      IsSuccess: false,
      ErrorCode: e?.ErrorCode || 500,
      ErrorMessage: e?.ErrorMessage || 'Internal Server Error',
      Value: null
    };
  }
  /**
   * показать информацию об ошибке
   * @param err 
   */
  showError(err: any) {

    console.error("ERROR", err);
    notify({ text: err?.ErrorMessage as any, title: "Error", type: 'error' })
  }
}
