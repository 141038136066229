
import { Vue, Options } from "vue-property-decorator";
import BeginningWork from "./components/BeginningWork.vue";
import PersonalData from "./components/PersonalData.vue";
import Identification from "./components/Identification.vue";
import IdentificationLiveness from "./components/Liveness.vue";
import IdentifiactionWaiting from "./components/IdentificationWaiting.vue";
import ProfileModel from "../../models/ProfileModel";
import IdentificationModel from "./models/IdentificationModel";
import SetProfileModel from "./models/SetProfileModel";
import * as Routes from "../../../router/routerNames";
import {
	AdapterIdentificationService,
	AdapterNaturalIdentificationService,
} from "./adapter";
import PersonalIdentificationData from "@/api/main/dataSource/api/i-public-service/aba-finance/models/user/identification/PersonalIdentificationData";
import { AdapterProfileService } from "../profile/adapter";
import PassportIdentificationData from "@/api/main/dataSource/api/i-public-service/aba-finance/models/user/identification/PassportIdentificationData";
import { AdapterApiService } from "../_adapter";
import EnumTypeIdentified from "@/api/main/dataSource/api/aba/data/models/models/EnumTypeIdentified";
import { useRouter } from "vue-router";
import { ref } from "vue";
import CompanyDataViewModel from "../user-identification/models/CompanyDataViewModel";
import LegalCompanySaveDataRequest from "@/api/main/dataSource/api/identification-services/models/legal-company/LegalCompanySaveDataRequest";
import ModelStreamFile from "@/api/main/dataSource/api/i-public-service/aba-finance/models/ModelStreamFile";
import EnumLegalIdentificationStageType from '@/api/main/dataSource/api/common/enums/EnumLegalIdentificationStageType';
@Options({
	name: "ProfileIdentificationLegal",
	components: {
		BeginningWork,
		PersonalData,
		Identification,
		IdentificationLiveness,
		IdentifiactionWaiting,
	},
})
export default class NaturalPersonProfileIdentification extends Vue {
	checked = [];
	wait_aprove = [];

	Step: number = 2;
	Profile: ProfileModel = null;
	IsLoad: boolean = false;
	ErrorMessageInd: string = "test";
	adapter = AdapterNaturalIdentificationService.getInstance();
	errorMessage = "";
	adapterI = AdapterIdentificationService.getInstance();
	router = useRouter();
	isAuto = true;

	maxStep = 6;

	async mounted() {
		const a = AdapterIdentificationService.getInstance();
		const res = await a.getMaxStage();
		if (!res.IsSuccess) {
			return;
		}
		this.maxStep = 2 + (res.Value as any) as number;
		if (AdapterApiService.getInstance().userInfo.value.IdentifiedStatus == 1) {
			this.wait_aprove.push(6)
		}
		this.Step = this.maxStep;
		this.setActiveStep();
		const isConfirmed =
			AdapterApiService.getInstance().userInfo.value.IdentifiedStatus ==
			EnumTypeIdentified.Confirmed;
		if (isConfirmed) {
			this.router.push({ name: Routes.BALANCE });
			return;
		}
		this.Profile = new ProfileModel();
		// await this.GetProfile();
	}

	setActiveStep() {
		this.checked = [];
		for (let index = 1; index < this.maxStep; index++) {
			this.checked.push(index);
		}
	}
	getStyles(val: {
		step: number;
	}): { active: boolean; checked: boolean; wait_aprove: boolean } {
		const step = val.step;
		return {
			active: this.Step == step,
			checked: this.checked.includes(step),
			wait_aprove: this.wait_aprove.includes(step),
		};
	}
	async updateCheckNew(value: number, noError: boolean, company: CompanyDataViewModel) {
		
		if (!noError) {
			return;
		}
		if (this.isAuto) {
			this.Step = value;
		}
		if (
			!this.checked.includes(value - 1)
			// &&  this.checked[this.checked.length - 1] + 1 != value - 1
		) {
			this.checked.push(value-1);
		}
		this.isAuto = true;
	}

	setStep(value: number) {
		this.isAuto = false;
		if (
			this.checked.includes(value) ||
			this.checked[this.checked.length - 1] + 1 == value
		) {
			this.Step = value;
		}

		// this.checked.push(value);
	}
	goTofinish(value) {


	}
	user: { Id: number } = { Id: 1 };

	async GoNext() {
		this.Step = 2;
		// if (this.Step === 5) {
		// 	const res = await this.adapter.getUserIdentification();
		// 	if (res.IsSuccess) {
		// 		// this.Profile = res as any) as ProfileModel;
		// 		this.Profile.Country = res.Value.AddressCountry;
		// 		this.Profile.Code = "999"; //TODO нету в апи
		// 		this.Profile.City = res.Value.AddressCity;
		// 		this.Profile.Street = res.Value.AddressStreet;
		// 		this.Profile.HouseNumber = "88"; //TODO нету в апи
		// 		this.Profile.ExtraBedForAnApartment = "888"; //TODO нету в апи
		// 		this.Profile.PostCode = res.Value.AddressZip;
		// 		this.Step++;
		// 	}
		// } else {
		// 	this.Step++;
		// }
	}
	async SetProfile(profile: SetProfileModel) {
		// this.Profile.FirstName = profile.FirstName;
		// this.Profile.SecondName = profile.SecondName;
		// this.Profile.Appeal = profile.Appeal;
		// this.Profile.BirthPlace = profile.BirthPlace;
		// this.Profile.BirthDate = new Date(profile.BirthDate);
		// // var formData = new FormData();
		// // formData.append("UserId", profile.UserId.toString());
		// // formData.append("FirstName", profile.FirstName);
		// // formData.append("SecondName", profile.SecondName);
		// // formData.append("Appeal", profile.Appeal);
		// // formData.append("BirthPlace", profile.BirthPlace);
		// // formData.append("BirthDate", profile.BirthDate);
		// const req = this.adapter.mapSetProfileModelToPersonalIdentificationData(profile);
		// const res = await this.adapter.savePersonalIdentificationData(req);
		// if (res.IsSuccess) this.Step++;
	}

	async SetIdentification(identification: IdentificationModel) {
		// this.Profile.PassportNumber = identification.PassportNumber;
		// var formData = new FormData();
		// // formData.append("scanPassport", identification.ScanPassport);
		// // formData.append("scanUtilityBill", identification.ScanUtilityBill);
		// // formData.append("DocumentNumber", identification.PassportNumber);
		// // formData.append("DocumentIssuedBy", identification.PassportIssuedBy);
		// // formData.append("DocumentIssuedDate", identification.PassportIssuedDate);
		// // formData.append("DocumentIssuedCountryCode", identification.PassportCountryCode);
		// // formData.append("AddressCountry", identification.CountryCode);
		// // formData.append("AddressState", identification.State);
		// // formData.append("AddressStreet", identification.Street);
		// // formData.append("AddressCity", identification.City);
		// // formData.append("AddressZip", identification.IndexCode);
		// const req = this.adapter.comvertIdentificationModelToPassportIdentificationData(
		// 	identification
		// );
		// const res = await this.adapter.savePassportIdentificationData(req);
		// if (res.IsSuccess) {
		// 	this.Step++;
		// 	return;
		// }
		// this.errorMessage = res.ErrorMessage;
	}
	// OnLivenessComplete() {
	// 	this.Step++;
	// }

	// async GetProfile() {
	// 	const res = await this.adapter.getUserIdentification();
	// 	const adapter = AdapterProfileService.getInstance();
	// 	adapter.GetNaturalPerson();
	// 	const pr = adapter.naturalPerson.value;
	// 	if (res.IsSuccess) {
	// 		// this.Profile = adapter.convertModelNaturalPersonToProfilModel(pr);
	// 		const tt = adapter.convertModelPersonIdentificationToProfilModel(res.Value);
	// 		this.Profile = tt;
	// 		this.SetStep();
	// 	}
	// 	// this.$api.Main.NaturalPersonProfileService.GetUserIdentification((res) => {
	// 	// 	this.Profile = (res as any) as ProfileModel;
	// 	// 	console.log("get profile", this.Profile);
	// 	// 	this.SetStep();
	// 	// });
	// }
	SetStep() {
		// if (this.Profile.Identified == 2) {
		// 	this.Step = 5;
		// 	this.UpdateIdentification();
		// 	return;
		// }
		// let searchParams = new URLSearchParams(window.location.search);
		// if (
		// 	searchParams.get("errorStart") ||
		// 	searchParams.get("error") ||
		// 	searchParams.get("success")
		// ) {
		// 	this.Step = 4;
		// 	return;
		// }
		// var self = this;
		// if (
		// 	self.Profile.FirstName !== "" &&
		// 	self.Profile.SecondName &&
		// 	self.Profile.BirthDate
		// ) {
		// 	if (self.Profile.Identified == 2) {
		// 		//идентифицирован
		// 		console.log("идентифицирован");
		// 		this.UpdateIdentification();
		// 	} else if (self.Profile.Identified == 1) {
		// 		//на рассмотрении
		// 		console.log("на рассмотрении");
		// 		self.Step = 5; //на рассмотрении
		// 	} else self.Step = 3; //данные документа
		// } else {
		// 	self.Step = 1;
		// }
	}
	UpdateIdentification() {
		//TODO нет Api
		// this.$api.Main.NaturalPersonProfileService.ChangeIdentifiedNewUser((res) => {
		// 	console.log("redirect");
		// 	this.$router.push({ name: Routes.NATURALPERSON_PROFILE });
		// });
	}
	generateUrl(url: string) {
		var arr = url.split("/");
		var newUrl = "";
		arr.forEach((string: string, i: number) => {
			if (i != 1 && i != 0) newUrl = newUrl + "/" + string;
		});
		return "/" + this.$localization.currentLang + newUrl;
	}

	changeTab(id: number) {
		this.Step = id;
		// this.currentStep.value = id;
	}
}
