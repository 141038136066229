
import { Options, Vue, Prop } from "vue-property-decorator";
import moment from "moment";
@Options<UiYears>({
  props: {
    date: Date,
    minimumDate: Date,
  },
})
export default class UiYears extends Vue {
  @Prop({ default: new Date(), type: Date }) date: Date;
  @Prop({ default: null, type: Date }) minimumDate: Date;
  innerYear: number = null;
  years: any[] = [];
  created() {
    this.innerYear = this.date.getFullYear();
  }
  mounted() {
    this.setYears(this.date);
  }

  setYears(date: Date) {
    this.innerYear = Math.trunc(date.getFullYear() / 10) * 10;
    this.years = [];
    for (
      let index = this.innerYear - 1;
      index <= this.innerYear + 10;
      index++
    ) {
      this.years.push({
        number: index,
        type:
          index < this.innerYear ||
          index >= this.innerYear + 10 ||
          !this.checkDateActivity(index)
            ? "no-active"
            : date.getFullYear() == index
            ? "current"
            : "",
      });
    }
  }
  next() {
    this.setYears(new Date(this.innerYear + 10, 1, 1));
  }
  backward() {
    this.setYears(new Date(this.innerYear - 10, 1, 1));
  }
  changeYear(year: any) {
    let mon = parseInt(moment(this.date).format("MM")) - 1;
    let day = parseInt(moment(this.date).format("DD"));
    let hour = parseInt(moment(this.date).format("HH"));
    let min = parseInt(moment(this.date).format("mm"));
    let sek = parseInt(moment(this.date).format("ss"));

    this.$emit("changeYear", new Date(year.number, mon, day, hour, min, sek));
    this.innerYear = year.number;
    this.setYears(new Date(year.number, mon, day, hour, min, sek));
  }
  /**
   * Проверить активность даты
   */
  checkDateActivity(year: any) {
    if (!this.minimumDate) return true;
    return year >= this.minimumDate.getFullYear();
  }
}
