import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-9716a930"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_modal_window = _resolveComponent("ui-modal-window")!

  return (_openBlock(), _createBlock(_component_ui_modal_window, {
    visible: _ctx.modalVision,
    onOnCancel: _ctx.close,
    onClickOutside: _ctx.close,
    onOnOk: _ctx.close,
    autoClose: false,
    titleBtnYes: "Ок",
    title: "Ошибка",
    titleJustify: 'center',
    buttonsJustify: "center",
    showCloseBtn: false,
    width: "400px"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.Text), 1)
    ]),
    _: 1
  }, 8, ["visible", "onOnCancel", "onClickOutside", "onOnOk"]))
}