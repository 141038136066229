
import { Options, Prop, Vue, Watch } from "vue-property-decorator";
interface Items {
  FromHour: number;
  FromMinutes: number;
  ToHour: number;
  ToMinutes: number;
}
@Options<UiDoubleControlTime>({
  emits: ["update:modelValue"],
})
export default class UiDoubleControlTime extends Vue {
  @Prop({ type: [String, Number, Date, Object, Array] })
  modelValue: Items;
  firstData: Date = new Date();
  secondData: Date = new Date();
  wrong = false;
  created() {
    this.firstData.setHours(this.modelValue.FromHour);
    this.firstData.setMinutes(this.modelValue.FromMinutes);
    this.secondData.setHours(this.modelValue.ToHour);
    this.secondData.setMinutes(this.modelValue.ToMinutes);
  }
  inputSecondTime(data: Date) {
    this.secondData = data;
    if (this.firstData >= this.secondData) {
      this.wrong = true;
      return;
    } else {
      this.wrong = false;
      this.modelValue.ToHour = data.getHours();
      this.modelValue.ToMinutes = data.getMinutes();
    }
  }
  inputFirstTime(data: Date) {
    this.firstData = data;
    if (this.firstData >= this.secondData) {
      this.wrong = true;
      return;
    } else {
      this.wrong = false;
      this.modelValue.FromHour = data.getHours();
      this.modelValue.FromMinutes = data.getMinutes();
    }
  }
}
