import { WebApiService } from '../../../../../plugins/webApiService'
import { Events } from '../../../../../plugins/events'
import { SendRequestModel } from '../../../../../plugins/models/SendRequestModel'
import CryptPayoutRequest from '../../../../../transaction-services/models/transaction-operations/CryptPayoutRequest';
import ModelTransferCryptPayout from '../../../../../i-public-service/aba-finance/models/ModelTransferCryptPayout';
import WsResponseModelT from '../../../../../schema-builder/server/models/WsResponseModelT';
import InfoForCryptPayout from '../../../../../transaction-services/models/transfer-info-service/InfoForCryptPayout';

export default class CryptPayoutWsController {
	private url?: string;
	private cookieName: string;
	private appWebSettingsPath?: string;
	private apiUrlProperty?: string;

	CheckTransferEvent = new Events<WsResponseModelT<InfoForCryptPayout>>();
	TransferEvent = new Events<WsResponseModelT<Boolean>>();

	subscribeEvents = async () => {
		const self = this;
		const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
		webApiService.on('CryptPayoutWsController', 'CheckTransfer', 'Main', (res: WsResponseModelT<InfoForCryptPayout>) => {
			self.CheckTransferEvent.trigger(res);
		});
		webApiService.on('CryptPayoutWsController', 'Transfer', 'Main', (res: WsResponseModelT<Boolean>) => {
			self.TransferEvent.trigger(res);
		});
	}

	constructor(url?: string, cookieName?: string, appWebSettingsPath?: string, apiUrlProperty?: string) {
		this.url = url;
		this.cookieName = cookieName ?? "Auth-Token";
		this.appWebSettingsPath = appWebSettingsPath && [location.host, appWebSettingsPath].join("/");
		this.apiUrlProperty = apiUrlProperty;
		this.subscribeEvents();
	}

	async CheckTransfer(model: CryptPayoutRequest) {
		const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
		const sendRequest = new SendRequestModel({
			ProjectName: 'Main',
			Controller: 'CryptPayoutWsController',
			Method: 'CheckTransfer',
			Value: typeof(model) === 'object' ? JSON.stringify(model) : model,
		})

		webApiService.send(sendRequest);
	}

	async Transfer(model: ModelTransferCryptPayout) {
		const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
		const sendRequest = new SendRequestModel({
			ProjectName: 'Main',
			Controller: 'CryptPayoutWsController',
			Method: 'Transfer',
			Value: typeof(model) === 'object' ? JSON.stringify(model) : model,
		})

		webApiService.send(sendRequest);
	}

	 // async methods
	async checkTransferAsync(model: CryptPayoutRequest): Promise<InfoForCryptPayout> {
		return new Promise<InfoForCryptPayout>(async (resolve, reject) => {
			const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
			const sendRequest = new SendRequestModel({
				ProjectName: 'Main',
				Controller: 'CryptPayoutWsController',
				Method: 'CheckTransfer',
				Value: typeof(model) === 'object' ? JSON.stringify(model) : model,
				Callback: (response: WsResponseModelT<InfoForCryptPayout>) => {
					const isGeneric = typeof response.IsSuccess == 'boolean'
					const result = response.IsSuccess ? response.Value : response

					if (!isGeneric || response.IsSuccess) resolve(result as InfoForCryptPayout)
					else reject(result)
				}
			})

			webApiService.send(sendRequest);
		});
	}

	async transferAsync(model: ModelTransferCryptPayout): Promise<Boolean> {
		return new Promise<Boolean>(async (resolve, reject) => {
			const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
			const sendRequest = new SendRequestModel({
				ProjectName: 'Main',
				Controller: 'CryptPayoutWsController',
				Method: 'Transfer',
				Value: typeof(model) === 'object' ? JSON.stringify(model) : model,
				Callback: (response: WsResponseModelT<Boolean>) => {
					const isGeneric = typeof response.IsSuccess == 'boolean'
					const result = response.IsSuccess ? response.Value : response

					if (!isGeneric || response.IsSuccess) resolve(result as Boolean)
					else reject(result)
				}
			})

			webApiService.send(sendRequest);
		});
	}

}