import { renderSlot as _renderSlot, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "ui-pagination" }
const _hoisted_2 = ["onClick", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["pagination", { disabled: _ctx.disablePrev }]),
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.goPrev && _ctx.goPrev(...args)))
    }, [
      _renderSlot(_ctx.$slots, "prev", { disabled: _ctx.disablePrev }, () => [
        _createTextVNode(" ❮ ")
      ])
    ], 2),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pages, (page, idx) => {
      return (_openBlock(), _createElementBlock("div", {
        key: idx,
        class: _normalizeClass(["pagination", {
        disabled: page.value == null,
        active: page.value == _ctx.currentPage,
      }]),
        onClick: ($event: any) => (_ctx.goToPage(page.value)),
        disabled: page.value == null
      }, [
        _renderSlot(_ctx.$slots, "default", { page: page }, () => [
          _createTextVNode(_toDisplayString(page.text), 1)
        ])
      ], 10, _hoisted_2))
    }), 128)),
    _createElementVNode("div", {
      class: _normalizeClass(["pagination", { disabled: _ctx.disableNext }]),
      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.goNext && _ctx.goNext(...args)))
    }, [
      _renderSlot(_ctx.$slots, "next", { disabled: _ctx.disableNext }, () => [
        _createTextVNode(" ❯ ")
      ])
    ], 2)
  ]))
}