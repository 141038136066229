import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-72e625ce"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ui-pincode_body" }
const _hoisted_2 = { class: "ui-pincode_body_label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_pinCode = _resolveDirective("pinCode")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", _hoisted_2, " SMS " + _toDisplayString(_ctx.$localization.getTranslate("Сode")), 1),
    _withDirectives(_createElementVNode("input", {
      type: "number",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.Pin1) = $event)),
      class: "ui-pincode_body_input",
      maxlength: "1",
      name: "sms_input",
      onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onInputPin && _ctx.onInputPin(...args)))
    }, null, 544), [
      [_directive_pinCode],
      [_vModelText, _ctx.Pin1]
    ]),
    _withDirectives(_createElementVNode("input", {
      type: "number",
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.Pin2) = $event)),
      class: "ui-pincode_body_input",
      maxlength: "1",
      name: "sms_input",
      onInput: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.onInputPin && _ctx.onInputPin(...args)))
    }, null, 544), [
      [_directive_pinCode],
      [_vModelText, _ctx.Pin2]
    ]),
    _withDirectives(_createElementVNode("input", {
      type: "number",
      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.Pin3) = $event)),
      class: "ui-pincode_body_input",
      maxlength: "1",
      name: "sms_input",
      onInput: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.onInputPin && _ctx.onInputPin(...args)))
    }, null, 544), [
      [_directive_pinCode],
      [_vModelText, _ctx.Pin3]
    ]),
    _withDirectives(_createElementVNode("input", {
      type: "number",
      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.Pin4) = $event)),
      class: "ui-pincode_body_input",
      maxlength: "1",
      name: "sms_input",
      onInput: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.onInputPin && _ctx.onInputPin(...args)))
    }, null, 544), [
      [_directive_pinCode],
      [_vModelText, _ctx.Pin4]
    ]),
    _withDirectives(_createElementVNode("input", {
      type: "number",
      "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.Pin5) = $event)),
      class: "ui-pincode_body_input",
      maxlength: "1",
      name: "sms_input",
      onInput: _cache[9] || (_cache[9] = 
//@ts-ignore
(...args) => (_ctx.onInputPin && _ctx.onInputPin(...args)))
    }, null, 544), [
      [_directive_pinCode],
      [_vModelText, _ctx.Pin5]
    ]),
    _withDirectives(_createElementVNode("input", {
      type: "number",
      "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.Pin6) = $event)),
      class: "ui-pincode_body_input",
      maxlength: "1",
      name: "sms_input",
      onInput: _cache[11] || (_cache[11] = 
//@ts-ignore
(...args) => (_ctx.onInputPin && _ctx.onInputPin(...args)))
    }, null, 544), [
      [_directive_pinCode],
      [_vModelText, _ctx.Pin6]
    ])
  ]))
}