import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-0e25950c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "choose_needed_period" }
const _hoisted_2 = {
  class: "needed_period_wrapper",
  id: "needed_period"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_aba_button_right = _resolveComponent("aba-button-right")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("ul", _hoisted_2, [
      _createElementVNode("li", null, [
        _createElementVNode("a", {
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.SetPeriod(_ctx.Today, $event)))
        }, _toDisplayString(_ctx.$localization.getTranslate("Today")), 1)
      ]),
      _createElementVNode("li", null, [
        _createElementVNode("a", {
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.SetPeriod(_ctx.Yesterday, $event)))
        }, _toDisplayString(_ctx.$localization.getTranslate("Yesterday")), 1)
      ]),
      _createElementVNode("li", null, [
        _createElementVNode("a", {
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.SetPeriod(_ctx.Week, $event)))
        }, _toDisplayString(_ctx.$localization.getTranslate("ThisWeek")), 1)
      ]),
      _createElementVNode("li", null, [
        _createElementVNode("a", {
          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.SetPeriod(_ctx.Month, $event)))
        }, _toDisplayString(_ctx.$localization.getTranslate("CurrentMonth")), 1)
      ]),
      _createElementVNode("li", null, [
        _createElementVNode("a", {
          onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.SetPeriod(_ctx.LastWeek, $event)))
        }, _toDisplayString(_ctx.$localization.getTranslate("LastWeek")), 1)
      ]),
      _createElementVNode("li", null, [
        _createElementVNode("a", {
          onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.SetPeriod(_ctx.LastMonth, $event)))
        }, _toDisplayString(_ctx.$localization.getTranslate("LastMonth")), 1)
      ])
    ]),
    _createVNode(_component_aba_button_right, { class: "active" }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$localization.getTranslate("Show")), 1)
      ]),
      _: 1
    })
  ]))
}