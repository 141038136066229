import { WebApiService } from '../../../../../plugins/webApiService'
import { Events } from '../../../../../plugins/events'
import { SendRequestModel } from '../../../../../plugins/models/SendRequestModel'
import GetWalletsListRequest from '../../../../../user-wallets/models/wallet/GetWalletsListRequest';
import EnumWalletCurrency from '../../../../../aba/data/enums/wallets/EnumWalletCurrency';
import GetHistoryRequest from '../../../../../user-wallets/models/wallet-transaction-history/GetHistoryRequest';
import WalletBalanceRequest from '../../../../../user-wallets/models/wallet-balance/WalletBalanceRequest';
import WalletPayoutRequest from '../../../../../user-wallets/models/wallet-transaction-maker/WalletPayoutRequest';
import ConvertCriptoToFiatAmountRequest from '../../../../../user-wallets/models/crypto-conversion/ConvertCriptoToFiatAmountRequest';
import ConvertAmountToCriptoRequest from '../../../../../user-wallets/models/crypto-conversion/ConvertAmountToCriptoRequest';
import WalletConvertFiatToCryptRequest from '../../../../../user-wallets/models/wallet-transaction-maker/WalletConvertFiatToCryptRequest';
import WsResponseModelT from '../../../../../schema-builder/server/models/WsResponseModelT';
import GetWalletsListResponse from '../../../../../user-wallets/models/wallet/GetWalletsListResponse';
import WalletReplenishSettings from '../../../../../user-wallets/models/wallet-info/WalletReplenishSettings';
import GetWalletTransactionHistoryResponse from '../../../../../user-wallets/models/wallet-transaction-history/GetWalletTransactionHistoryResponse';
import CurrencyResponse from '../../../../../user-wallets/models/wallet-info/CurrencyResponse';
import WalletBalanceResponse from '../../../../../user-wallets/models/wallet-balance/WalletBalanceResponse';
import ConvertCriptoToFiatAmountResponse from '../../../../../user-wallets/models/crypto-conversion/ConvertCriptoToFiatAmountResponse';
import ConvertAmountToCriptoResponse from '../../../../../user-wallets/models/crypto-conversion/ConvertAmountToCriptoResponse';

export default class WalletWsController {
	private url?: string;
	private cookieName: string;
	private appWebSettingsPath?: string;
	private apiUrlProperty?: string;

	GetWalletsListEvent = new Events<WsResponseModelT<GetWalletsListResponse>>();
	GetWalletReplenishEvent = new Events<WsResponseModelT<WalletReplenishSettings>>();
	GetHistoryEvent = new Events<WsResponseModelT<GetWalletTransactionHistoryResponse>>();
	GetCurrencyListEvent = new Events<WsResponseModelT<Array<CurrencyResponse>>>();
	GetWalletBalanceEvent = new Events<WsResponseModelT<WalletBalanceResponse>>();
	WalletPayoutInitEvent = new Events<WsResponseModelT<Boolean>>();
	ConvertCriptoToFiatAmountEvent = new Events<WsResponseModelT<ConvertCriptoToFiatAmountResponse>>();
	ConvertAmountToCriptoEvent = new Events<WsResponseModelT<ConvertAmountToCriptoResponse>>();
	WalletConvertFiatToCryptInitEvent = new Events<WsResponseModelT<Boolean>>();

	subscribeEvents = async () => {
		const self = this;
		const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
		webApiService.on('WalletWsController', 'GetWalletsList', 'Main', (res: WsResponseModelT<GetWalletsListResponse>) => {
			self.GetWalletsListEvent.trigger(res);
		});
		webApiService.on('WalletWsController', 'GetWalletReplenish', 'Main', (res: WsResponseModelT<WalletReplenishSettings>) => {
			self.GetWalletReplenishEvent.trigger(res);
		});
		webApiService.on('WalletWsController', 'GetHistory', 'Main', (res: WsResponseModelT<GetWalletTransactionHistoryResponse>) => {
			self.GetHistoryEvent.trigger(res);
		});
		webApiService.on('WalletWsController', 'GetCurrencyList', 'Main', (res: WsResponseModelT<Array<CurrencyResponse>>) => {
			self.GetCurrencyListEvent.trigger(res);
		});
		webApiService.on('WalletWsController', 'GetWalletBalance', 'Main', (res: WsResponseModelT<WalletBalanceResponse>) => {
			self.GetWalletBalanceEvent.trigger(res);
		});
		webApiService.on('WalletWsController', 'WalletPayoutInit', 'Main', (res: WsResponseModelT<Boolean>) => {
			self.WalletPayoutInitEvent.trigger(res);
		});
		webApiService.on('WalletWsController', 'ConvertCriptoToFiatAmount', 'Main', (res: WsResponseModelT<ConvertCriptoToFiatAmountResponse>) => {
			self.ConvertCriptoToFiatAmountEvent.trigger(res);
		});
		webApiService.on('WalletWsController', 'ConvertAmountToCripto', 'Main', (res: WsResponseModelT<ConvertAmountToCriptoResponse>) => {
			self.ConvertAmountToCriptoEvent.trigger(res);
		});
		webApiService.on('WalletWsController', 'WalletConvertFiatToCryptInit', 'Main', (res: WsResponseModelT<Boolean>) => {
			self.WalletConvertFiatToCryptInitEvent.trigger(res);
		});
	}

	constructor(url?: string, cookieName?: string, appWebSettingsPath?: string, apiUrlProperty?: string) {
		this.url = url;
		this.cookieName = cookieName ?? "Auth-Token";
		this.appWebSettingsPath = appWebSettingsPath && [location.host, appWebSettingsPath].join("/");
		this.apiUrlProperty = apiUrlProperty;
		this.subscribeEvents();
	}

	async GetWalletsList(request: GetWalletsListRequest) {
		const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
		const sendRequest = new SendRequestModel({
			ProjectName: 'Main',
			Controller: 'WalletWsController',
			Method: 'GetWalletsList',
			Value: typeof(request) === 'object' ? JSON.stringify(request) : request,
		})

		webApiService.send(sendRequest);
	}

	async GetWalletReplenish(currency: EnumWalletCurrency) {
		const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
		const sendRequest = new SendRequestModel({
			ProjectName: 'Main',
			Controller: 'WalletWsController',
			Method: 'GetWalletReplenish',
			Value: typeof(currency) === 'object' ? JSON.stringify(currency) : currency,
		})

		webApiService.send(sendRequest);
	}

	async GetHistory(request: GetHistoryRequest) {
		const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
		const sendRequest = new SendRequestModel({
			ProjectName: 'Main',
			Controller: 'WalletWsController',
			Method: 'GetHistory',
			Value: typeof(request) === 'object' ? JSON.stringify(request) : request,
		})

		webApiService.send(sendRequest);
	}

	async GetCurrencyList() {
		const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
		const sendRequest = new SendRequestModel({
			ProjectName: 'Main',
			Controller: 'WalletWsController',
			Method: 'GetCurrencyList',
		})

		webApiService.send(sendRequest);
	}

	async GetWalletBalance(request: WalletBalanceRequest) {
		const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
		const sendRequest = new SendRequestModel({
			ProjectName: 'Main',
			Controller: 'WalletWsController',
			Method: 'GetWalletBalance',
			Value: typeof(request) === 'object' ? JSON.stringify(request) : request,
		})

		webApiService.send(sendRequest);
	}

	async WalletPayoutInit(request: WalletPayoutRequest) {
		const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
		const sendRequest = new SendRequestModel({
			ProjectName: 'Main',
			Controller: 'WalletWsController',
			Method: 'WalletPayoutInit',
			Value: typeof(request) === 'object' ? JSON.stringify(request) : request,
		})

		webApiService.send(sendRequest);
	}

	async ConvertCriptoToFiatAmount(request: ConvertCriptoToFiatAmountRequest) {
		const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
		const sendRequest = new SendRequestModel({
			ProjectName: 'Main',
			Controller: 'WalletWsController',
			Method: 'ConvertCriptoToFiatAmount',
			Value: typeof(request) === 'object' ? JSON.stringify(request) : request,
		})

		webApiService.send(sendRequest);
	}

	async ConvertAmountToCripto(request: ConvertAmountToCriptoRequest) {
		const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
		const sendRequest = new SendRequestModel({
			ProjectName: 'Main',
			Controller: 'WalletWsController',
			Method: 'ConvertAmountToCripto',
			Value: typeof(request) === 'object' ? JSON.stringify(request) : request,
		})

		webApiService.send(sendRequest);
	}

	async WalletConvertFiatToCryptInit(request: WalletConvertFiatToCryptRequest) {
		const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
		const sendRequest = new SendRequestModel({
			ProjectName: 'Main',
			Controller: 'WalletWsController',
			Method: 'WalletConvertFiatToCryptInit',
			Value: typeof(request) === 'object' ? JSON.stringify(request) : request,
		})

		webApiService.send(sendRequest);
	}

	 // async methods
	async getWalletsListAsync(request: GetWalletsListRequest): Promise<GetWalletsListResponse> {
		return new Promise<GetWalletsListResponse>(async (resolve, reject) => {
			const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
			const sendRequest = new SendRequestModel({
				ProjectName: 'Main',
				Controller: 'WalletWsController',
				Method: 'GetWalletsList',
				Value: typeof(request) === 'object' ? JSON.stringify(request) : request,
				Callback: (response: WsResponseModelT<GetWalletsListResponse>) => {
					const isGeneric = typeof response.IsSuccess == 'boolean'
					const result = response.IsSuccess ? response.Value : response

					if (!isGeneric || response.IsSuccess) resolve(result as GetWalletsListResponse)
					else reject(result)
				}
			})

			webApiService.send(sendRequest);
		});
	}

	async getWalletReplenishAsync(currency: EnumWalletCurrency): Promise<WalletReplenishSettings> {
		return new Promise<WalletReplenishSettings>(async (resolve, reject) => {
			const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
			const sendRequest = new SendRequestModel({
				ProjectName: 'Main',
				Controller: 'WalletWsController',
				Method: 'GetWalletReplenish',
				Value: typeof(currency) === 'object' ? JSON.stringify(currency) : currency,
				Callback: (response: WsResponseModelT<WalletReplenishSettings>) => {
					const isGeneric = typeof response.IsSuccess == 'boolean'
					const result = response.IsSuccess ? response.Value : response

					if (!isGeneric || response.IsSuccess) resolve(result as WalletReplenishSettings)
					else reject(result)
				}
			})

			webApiService.send(sendRequest);
		});
	}

	async getHistoryAsync(request: GetHistoryRequest): Promise<GetWalletTransactionHistoryResponse> {
		return new Promise<GetWalletTransactionHistoryResponse>(async (resolve, reject) => {
			const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
			const sendRequest = new SendRequestModel({
				ProjectName: 'Main',
				Controller: 'WalletWsController',
				Method: 'GetHistory',
				Value: typeof(request) === 'object' ? JSON.stringify(request) : request,
				Callback: (response: WsResponseModelT<GetWalletTransactionHistoryResponse>) => {
					const isGeneric = typeof response.IsSuccess == 'boolean'
					const result = response.IsSuccess ? response.Value : response

					if (!isGeneric || response.IsSuccess) resolve(result as GetWalletTransactionHistoryResponse)
					else reject(result)
				}
			})

			webApiService.send(sendRequest);
		});
	}

	async getCurrencyListAsync(): Promise<Array<CurrencyResponse>> {
		return new Promise<Array<CurrencyResponse>>(async (resolve, reject) => {
			const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
			const sendRequest = new SendRequestModel({
				ProjectName: 'Main',
				Controller: 'WalletWsController',
				Method: 'GetCurrencyList',
				Callback: (response: WsResponseModelT<Array<CurrencyResponse>>) => {
					const isGeneric = typeof response.IsSuccess == 'boolean'
					const result = response.IsSuccess ? response.Value : response

					if (!isGeneric || response.IsSuccess) resolve(result as Array<CurrencyResponse>)
					else reject(result)
				}
			})

			webApiService.send(sendRequest);
		});
	}

	async getWalletBalanceAsync(request: WalletBalanceRequest): Promise<WalletBalanceResponse> {
		return new Promise<WalletBalanceResponse>(async (resolve, reject) => {
			const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
			const sendRequest = new SendRequestModel({
				ProjectName: 'Main',
				Controller: 'WalletWsController',
				Method: 'GetWalletBalance',
				Value: typeof(request) === 'object' ? JSON.stringify(request) : request,
				Callback: (response: WsResponseModelT<WalletBalanceResponse>) => {
					const isGeneric = typeof response.IsSuccess == 'boolean'
					const result = response.IsSuccess ? response.Value : response

					if (!isGeneric || response.IsSuccess) resolve(result as WalletBalanceResponse)
					else reject(result)
				}
			})

			webApiService.send(sendRequest);
		});
	}

	async walletPayoutInitAsync(request: WalletPayoutRequest): Promise<Boolean> {
		return new Promise<Boolean>(async (resolve, reject) => {
			const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
			const sendRequest = new SendRequestModel({
				ProjectName: 'Main',
				Controller: 'WalletWsController',
				Method: 'WalletPayoutInit',
				Value: typeof(request) === 'object' ? JSON.stringify(request) : request,
				Callback: (response: WsResponseModelT<Boolean>) => {
					const isGeneric = typeof response.IsSuccess == 'boolean'
					const result = response.IsSuccess ? response.Value : response

					if (!isGeneric || response.IsSuccess) resolve(result as Boolean)
					else reject(result)
				}
			})

			webApiService.send(sendRequest);
		});
	}

	async convertCriptoToFiatAmountAsync(request: ConvertCriptoToFiatAmountRequest): Promise<ConvertCriptoToFiatAmountResponse> {
		return new Promise<ConvertCriptoToFiatAmountResponse>(async (resolve, reject) => {
			const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
			const sendRequest = new SendRequestModel({
				ProjectName: 'Main',
				Controller: 'WalletWsController',
				Method: 'ConvertCriptoToFiatAmount',
				Value: typeof(request) === 'object' ? JSON.stringify(request) : request,
				Callback: (response: WsResponseModelT<ConvertCriptoToFiatAmountResponse>) => {
					const isGeneric = typeof response.IsSuccess == 'boolean'
					const result = response.IsSuccess ? response.Value : response

					if (!isGeneric || response.IsSuccess) resolve(result as ConvertCriptoToFiatAmountResponse)
					else reject(result)
				}
			})

			webApiService.send(sendRequest);
		});
	}

	async convertAmountToCriptoAsync(request: ConvertAmountToCriptoRequest): Promise<ConvertAmountToCriptoResponse> {
		return new Promise<ConvertAmountToCriptoResponse>(async (resolve, reject) => {
			const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
			const sendRequest = new SendRequestModel({
				ProjectName: 'Main',
				Controller: 'WalletWsController',
				Method: 'ConvertAmountToCripto',
				Value: typeof(request) === 'object' ? JSON.stringify(request) : request,
				Callback: (response: WsResponseModelT<ConvertAmountToCriptoResponse>) => {
					const isGeneric = typeof response.IsSuccess == 'boolean'
					const result = response.IsSuccess ? response.Value : response

					if (!isGeneric || response.IsSuccess) resolve(result as ConvertAmountToCriptoResponse)
					else reject(result)
				}
			})

			webApiService.send(sendRequest);
		});
	}

	async walletConvertFiatToCryptInitAsync(request: WalletConvertFiatToCryptRequest): Promise<Boolean> {
		return new Promise<Boolean>(async (resolve, reject) => {
			const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
			const sendRequest = new SendRequestModel({
				ProjectName: 'Main',
				Controller: 'WalletWsController',
				Method: 'WalletConvertFiatToCryptInit',
				Value: typeof(request) === 'object' ? JSON.stringify(request) : request,
				Callback: (response: WsResponseModelT<Boolean>) => {
					const isGeneric = typeof response.IsSuccess == 'boolean'
					const result = response.IsSuccess ? response.Value : response

					if (!isGeneric || response.IsSuccess) resolve(result as Boolean)
					else reject(result)
				}
			})

			webApiService.send(sendRequest);
		});
	}

}