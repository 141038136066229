
import { reactive, ref, provide } from "vue";
import { Options, Vue } from "vue-class-component";
import Pagination from "@views/components/Pagination.vue";

import * as faker from "faker";
import moment from "moment";
import UiPaginationComponent from "./ui-table/ui-pagination.vue";

interface IUser {
  login: string;
  name: string;
type: string;
boolean: boolean;
}
@Options<Page>({
  props: {
    searchPlaceholder: { default: "Поиск", type: String },
    noApi: { default: false, type: Boolean },
    noFilter: { default: false, type: Boolean },
    defaultSearchField: String,
    noPagination: { default: false, type: Boolean },
    name: {
      type: String,
      default: "Название страницы",
    },
    getDataFuncAsync: { type: Function, required: true },
  },
  watch: {
    filter(value) {
      this.innerItems = this.filter;
    },
  },
  emits: ["onAdd"],
  components: {
    Pagination,
  },
})
export default class Page extends Vue {
  created() {
    this.initData();
  }
  allUsers: IUser[] = [];
  filteredUsers: IUser[] = [];
  currentPage = 1;
  pageSize = 10;
  initData() {
    for (var i = 0; i < 30; i++) {
      let dist = {
        login: faker.name.lastName(),
        name: faker.name.firstName(),
        type: faker.vehicle.type(),
        boolean: faker.random.boolean(),
      };
      this.allUsers.push(dist);
    }
    this.filteredUsers = this.allUsers.slice();
  }

  get usersToDisplay(): IUser[] {
    let from = (this.currentPage - 1) * this.pageSize;
    let to = from + this.pageSize;
    return this.filteredUsers.slice(from, to);
  }
  // {
  //   Coutn: 100110,
  //   Items: [1,2,3,32,4,124,]
  // }
  get totalCount(): number {
    return this.filteredUsers.length;
  }
  get totalPageCount(): number {
    return Math.ceil(this.filteredUsers.length / this.pageSize);
  }
  // получает номер страницы на которую нажали
  pageClick(page: Number) {
    this.currentPage = Number(page);
  }
  formatDate(date: Date) {
    return moment(date).format("DD.MM.YYYY");
  }
  // ловля события
  onFilterChanged(data: string) {
    const s = data.toLowerCase();
    this.filteredUsers = this.allUsers.filter(
      (x) =>
        x.name.toLowerCase().includes(s) ||
        x.login.toLowerCase().includes(s) ||
         x.type.toLowerCase().includes(s) ||
          x.boolean.toString().includes(s)
        // this.formatDate(x.type).includes(s)
    );
    if (this.currentPage > this.totalPageCount) {
      this.currentPage = this.totalPageCount;
    }
  }























  // если закоментировать то будет ошибка
  readonly searchPlaceholder: string = "Поиск";
  readonly name: string = "Название страницы";
  readonly noApi: boolean = false;
  readonly noFilter: boolean = false;
  readonly noPagination: boolean = false;
  readonly defaultSearchField = "Name";

  isLoading = false;
  totalItems = 0;
  // currentPage = 1;
  innerItems: any[] = [];

  hasFilter = false;

  countPerPage = 10;
  pagination = true;
  search = "";

  onClick() {
    this.$emit("onAdd");
  }

  // async created() {
  //   if (this.noApi) {
  //     return;
  //   }
  //   await this.refresh();
  // }

  mounted() {
    this.hasFilter = this.$slots.filter != null;
  }

  addSearch(item: any) {}

  sort(sortName: string, sortType: string) {
    // if (sortName != "" && this.innerFilter.Sort == null) {
    //   this.innerFilter.Sort = { FieldName: "", Desc: false };
    // }
    // this.innerFilter.Sort.FieldName = sortName;
    // this.innerFilter.Sort.Desc = sortType == "1";
    // (this.$refs.pagination as UiPaginationComponent).currentPage = 1;

    this.refresh();
  }

  async refresh() {}

  // get pageSize(): number {
  //   return 10;
  //   // const x = this.innerFilter.Pagination.Take;
  //   // return x;
  // }

  resetPagination() {
    // this.innerFilter.Pagination.Skip = 0;
    this.currentPage = 1;
    this.totalItems = 0;
  }
  onSort(sortName: string, sortType: string) {
    // //console.log("sortType", sortType);
    this.resetPagination();
    this.refresh();
  }

  onPaginationChanged(currentPage: number) {
    this.currentPage = currentPage;
    // this.innerFilter.Pagination.Skip =
    //   (currentPage - 1) * this.innerFilter.Pagination.Take;
    this.refresh();
  }

  // onSearch() {
  //   // this.innerFilter.Pagination.Skip = 0;
  //   this.currentPage = 1;
  //   this.refresh();
  // }
}
