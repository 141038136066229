
import { Vue, Prop, Watch, Options } from "vue-property-decorator";
@Options({
	name: "ui-company-document-adding-btn",
})
export default class UiCompanyDocumentAdding extends Vue {
	@Prop({ default: "#000000" }) buttonColor: string;
	@Prop({ default: "" }) buttonText: string;
	@Prop({ default: false }) isDisabled: boolean;
	@Prop({ default: false }) isWrong: boolean;

	buttonStyle: Object = new Object();

	clearValue() {
		if (this.$refs.file as HTMLInputElement) {
			(this.$refs.file as HTMLInputElement).value = null;
		}
	}

	created() {
		this.mouseLeaveButton();
	}

	setDisabledStyle() {
		this.buttonStyle = {
			backgroundColor: "var(--btnFirstColor) !important",
			color: "#000 !important",
			border: "1px solid #8e97a1 !important",
			transition: "0.3s",
		};
	}

	@Watch("isDisabled")
	onUpdateIsDisabled(newValue: boolean) {
		if (newValue) {
			this.setDisabledStyle();
		} else {
			this.mouseLeaveButton();
		}
	}

	mouseLeaveButton() {
		if (this.isDisabled) {
			this.setDisabledStyle();
		} else {
			this.buttonStyle = {
				backgroundColor: this.buttonColor,
				border: `1px solid ${this.buttonColor}`,
				color: "#fff",
				transition: "0.3s",
			};
		}
	}

	mouseOverButton() {
		if (this.isDisabled) {
			this.setDisabledStyle();
		} else {
			this.buttonStyle = {
				backgroundColor: "#fff",
				border: `1px solid ${this.buttonColor}`,
				color: this.buttonColor,
				transition: "0.3s",
			};
		}
	}

	async addFile(event) {
		const file = event.target.files[0];
		if (!file) return;
		await this.$helper.toBase64(file).then((fileBase64) => {
			this.$emit("addedFile", { fileBase64: fileBase64, name: file.name });
		});
		this.clearValue();
	}
}
