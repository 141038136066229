import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelRadio as _vModelRadio, createElementVNode as _createElementVNode, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["id", "value", "name", "onClick", "on:update:modelValue"]
const _hoisted_2 = ["for"]
const _hoisted_3 = { class: "ui_radio_box" }
const _hoisted_4 = {
  key: 0,
  class: "lil_block"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['ui_radio_wrapper', _ctx.currentStyle ? 'style_check' : 'style_cube'])
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "toggle_item",
        key: index
      }, [
        _withDirectives(_createElementVNode("input", {
          type: "radio",
          id: _ctx.radioName + index,
          value: _ctx.takeKey(item),
          name: _ctx.radioName,
          class: "ui_radio",
          onClick: ($event: any) => (_ctx.radioChoosed(index)),
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.innerValue) = $event)),
          "on:update:modelValue": ($event: any) => (_ctx.radioChoosed(index))
        }, null, 40, _hoisted_1), [
          [_vModelRadio, _ctx.innerValue]
        ]),
        _createElementVNode("label", {
          for: _ctx.radioName + index,
          class: "ui_radio_label"
        }, [
          _createElementVNode("div", _hoisted_3, [
            (_ctx.radioStyle == 1)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4))
              : _createCommentVNode("", true)
          ]),
          _createTextVNode(" " + _toDisplayString(_ctx.takeValue(item)), 1)
        ], 8, _hoisted_2)
      ]))
    }), 128))
  ], 2))
}