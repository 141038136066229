import getBase64 from "./toBase64";

export default class Helper {
    toBase64(file: File): Promise<string> {
        return getBase64(file);
    }

    getLogoFileName():string {
        
        if(location.hostname=='cabinet.crederes.com')
            return require('@/assets/img/Icons/cabinet.crederes.com.svg');
        return require('@/assets/img/Icons/aba-logo.svg');
    }
}