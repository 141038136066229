
import { Vue, Options, Prop, Watch } from "vue-property-decorator";
@Options({
  name: "ui-select",
  emits: ["update:modelValue"],
})
export default class UiDropdownComponent extends Vue {
  @Prop() keyField?: string;
  @Prop() valueField?: string;
  @Prop({ default: () => new Array<any>(), type: Array, required: true })
  items!: any[];
  @Prop({ default: null }) selectText: string;
  @Prop({ default: true }) closeOnSelect: boolean;
  @Prop({ default: false }) checkActiveByRef: boolean;
  @Prop({ type: [String, Number, Number, Date, Object, Array] })
  modelValue: any | any[] | String | Number | string | number | Date;
  innerValue: any | any[] | String | Number | string | number | Date = null;
  // обычное значение
  @Watch("modelValue")
  onValueChanged() {    
    this.innerValue = this.modelValue;
  }
  // внутреннее значение
  //на сложный тип (array,number,class) нужно  {deep: true}
  @Watch("innerValue", {deep: true})
  onInnerValueChanged() {
    this.$emit("update:modelValue", this.innerValue);
  }
  hoveredIdx: number = null;
  hasFocus = false;
  private open = false;
  preventCurrentClick = false;

  get active(): any {
    return this.items.filter((el: any) =>
          this.innerValue.some((iv: any) => this.isEqual(iv, this.getKey(el)))
        )
  }
  get activeText(): string {
    var active = this.active;
    if (Array.isArray(active)) {
      var text =
        active.length > 0
          ? active.map((el: any) => this.getValue(el)).join(",")
          : this.selectText || "";
    } else {
      text = active ? this.getValue(active) : this.selectText || "";
    }
    return text;
  }
  created() {
    this.innerValue = this.modelValue;
    this.trySetDefault();
  }
  mounted() {
    document.addEventListener("keydown", this.onKeyDown.bind(this));
  }
  setOpen(value: boolean) {
    this.open = value;
    var active = this.active;
    if (!active) {
      this.setHoveredIdx(0);
    }
    Array.isArray(active)
      ? this.setHoveredIdx(this.items.indexOf(active[active.length - 1]))
      : this.setHoveredIdx(this.items.indexOf(active));
  }
  clickCurrent() {
    if (this.preventCurrentClick ) {
      return;
    }
    this.setOpen(!this.open);
  }

  beforeDestroy() {
    document.removeEventListener("keydown", this.onKeyDown.bind(this));
  }
  onGetFocus() {
    this.setOpen(true);
    this.preventCurrentClick = true;
    setTimeout(() => (this.preventCurrentClick = false), 300);
    this.hasFocus = true;
  }
  onBlur() {
    this.setOpen(false);
    this.hasFocus = false;
  }
  onKeyDown(ev: KeyboardEvent) {
    if (!this.hasFocus) {
      return;
    }
    var key = ev.key.toLowerCase();
    if (key == "escape") {
      return (this.open = false);
    }
    if (key == "enter") {
      return this.open ? this.onEnter() : this.setOpen(true);
    }
    if (key == "arrowdown" || key == "arrowup") {
      ev.preventDefault();
      return this.open
        ? key === "arrowdown"
          ? this.setHoveredIdx(++this.hoveredIdx)
          : this.setHoveredIdx(--this.hoveredIdx)
        : this.setOpen(true);
    }
  }
 
  isEqual(fst: any, scd: any): boolean {
    return this.checkActiveByRef
      ? fst === scd
      : JSON.stringify(fst) === JSON.stringify(scd);
  }
  setHoveredIdx(idx: number) {
    this.hoveredIdx =
      (idx + this.items.length) % this.items.length;
  }
  onEnter() {
    this.select(this.items[this.hoveredIdx]);
  }

  hasValue(): boolean {
    return (
      this.innerValue != null || this.innerValue.length > 0
    );
  }
  valueInItems(): boolean {
    const allKeys = (this.items || []).map((x) => this.getKey(x));
    const selectedKeys =  this.innerValue
    return selectedKeys.every((x) => allKeys.indexOf(x) > -1);
  }
  trySetDefault() {
    if (this.hasValue() && this.valueInItems()) {
      return;
    } else {
      this.select(this.items[0]);
    }
  }
   isSelect(item: any) {
    var key = this.getKey(item);
    return  (this.innerValue || []).some((el: any) => this.isEqual(el, key))
  }
  select(item: any) {
    this.innerValue = this.innerValue || [];
    if( this.isSelect(item)){
      this.innerValue = this.innerValue.filter(
          (el: any) => !this.isEqual(el, this.getKey(item)))
    }  else {
      this.innerValue.push(this.getKey(item));
    }
    // this.isSelect(item)
    //   ? (this.innerValue = this.innerValue.filter(
    //       (el: any) => !this.isEqual(el, this.getKey(item))
    //     ))
    //   :this.innerValue.push(this.getKey(item));
    this.setHoveredIdx(this.items.indexOf(item));
  }
  deleteItem(item: any) {
    this.setOpen(false);
    this.isSelect(item)
      ? (this.innerValue = this.innerValue.filter(
          (el: any) => !this.isEqual(el, this.getKey(item))
        ))
      : this.innerValue.push(this.getKey(item));
  }

  getKey(item: any) {
    if (this.keyField) {
      return item[this.keyField];
    }
    return item;
  }
  getValue(item: any) {
    if (this.valueField) {
      const resText = item[this.valueField];
      if (resText == undefined) {
        console.error("Не найден valueField");
      }
      return resText;
    }
    return item;
  }
}
