
import { prop } from "vue-class-component";
import { Vue, Options, Prop, Watch, Ref } from "vue-property-decorator";
@Options({
	name: "ui-select",
	emits: ["update:modelValue", "currentClick"],
})
export default class UiSelectComponent extends Vue {
	@Prop({ default: "" }) headerText: string;
	@Prop() keyField?: string;
	@Prop() valueField?: string;
	// todo: сделать проверку на повторяющиеся значения ключей и кидать ошибку/предупреждение
	@Prop({ default: () => new Array<any>(), type: Array, required: true })
	items!: any[];
	@Prop({ default: false, type: Boolean }) autocomplete: boolean;
	@Prop({ default: false }) multiselect: boolean;
	@Prop({ default: false }) isSearch: boolean;
	@Prop({ default: null }) selectText: string;
	@Prop({ default: true }) closeOnSelect: boolean;
	@Prop({ default: false }) noUndefined: boolean;
	@Prop({ default: false }) checkActiveByRef: boolean;
	@Prop({ type: String, default: "Not Chosen" }) defaultText: string;
	//@Prop({ type: Boolean, default: false }) required: boolean;
	@Prop({ default: false }) noPlace: boolean;
	@Prop({ default: false }) isWrong: boolean;
	@Prop({ default: "" }) defaultChoosen: string;
	@Prop() modelValue: any | any[] | String | Number | string | number | Date;
	@Prop({ default: "", type: String }) placeholder!: string;

	search = "";
	tempItems: any[] = [];
	innerValue: any | any[] | String | Number | string | number | Date = null;
	innerItems: any[] = this.items;
	containerPosition: Object = new Object();
	tempValue: { key: any; value: any } = { key: null, value: null };

	@Watch("search")
	onUpdateSearch(newValue: string) {
		if (this.isSearch) {
			this.tempItems = this.items?.filter(
				(x) => x[this.valueField].toLowerCase().search(newValue.toLowerCase()) != -1
			);
			if (
				this.items.some((x) => x[this.valueField].toLowerCase() == newValue.toLowerCase())
			) {
				this.tempItems = this.items;
			}
			if (
				this.items?.find(
					(x) => x[this.valueField].toLowerCase() == newValue.toLowerCase()
				)
			) {
				this.innerValue = this.items?.find(
					(x) => x[this.valueField].toLowerCase() == newValue.toLowerCase()
				)[this.keyField];
			}
		}
	}

	@Watch("defaultChoosen")
	onUpdateDefaultChoosen() {
		if (this.defaultChoosen.length != 0) {
			this.innerValue = this.defaultChoosen;
		}
	}
	@Watch("items")
	onUpdateItems() {
		this.innerItems = this.items;
	}

	currentClick() {
		this.$emit("currentClick");
	}

	@Watch("noPlace")
	onUpdateNoPlace(newValue: boolean) {
		if (newValue) {
			this.containerPosition = {
				top: "unset",
				bottom: "100%",
				boxShadow: "0 -12px 24px 0 #ccd0d5",
			};
		} else {
			this.containerPosition = {
				top: "100%",
				bottom: "unset",
				boxShadow: "0 12px 24px 0 #ccd0d5",
			};
		}
	}

	@Watch("modelValue")
	onValueChanged(value) {
		this.innerValue = value;
		if (this.isSearch && value.length != 0) {
			let item = this.items?.find((x) => x[this.keyField] == value);
			if (item) {
				this.search = item[this.valueField];
			}
		}
	}
	// внутреннее значение
	@Watch("innerValue")
	onInnerValueChanged() {
		this.$emit("update:modelValue", this.innerValue);
	}
	private open = false;
	preventCurrentClick = false;
	setOpen(value: boolean) {
		if (!value) {
		}
		this.open = value;
		var active = this.active;
		if (value && this.autocomplete) {
			this.$nextTick(() => {
				(this.$refs["searchEl"] as HTMLInputElement).focus();
			});
		}
		if (!active) {
			this.setHoveredIdx(0);
		}
		Array.isArray(active)
			? this.setHoveredIdx(this.itemsToDisplay.indexOf(active[active.length - 1]))
			: this.setHoveredIdx(this.itemsToDisplay.indexOf(active));
	}
	clickCurrent() {
		if (this.preventCurrentClick || this.multiselect) {
			return;
		}
		this.setOpen(!this.open);
	}
	hoveredIdx: number = null;
	hasFocus = false;
	mounted() {
		this.onUpdateNoPlace(this.noPlace);
		document.addEventListener("keydown", this.onKeyDown.bind(this));
	}
	beforeDestroy() {
		document.removeEventListener("keydown", this.onKeyDown.bind(this));
	}
	onGetFocus() {
		this.setOpen(true);
		this.preventCurrentClick = true;
		setTimeout(() => (this.preventCurrentClick = false), 300);
		this.hasFocus = true;
	}
	onBlur() {
		this.setOpen(false);
		this.hasFocus = false;
		if (
			this.isSearch &&
			!this.items.some(
				(x) => x[this.valueField].toLowerCase() == this.search.toLowerCase()
			)
		) {
			// let items = this.items.filter(
			//   (x) =>
			//     x[this.valueField].toLowerCase().search(this.search.toLowerCase()) !=
			//     -1
			// );
			// if (items.length) {
			//   this.search = items[0][this.valueField];
			//   this.select(items[0]);
			// }
			this.innerValue = this.tempValue.key;
			this.search = this.tempValue.value;
		}
		if (this.$refs.currentSearch) {
			(this.$refs.currentSearch as HTMLInputElement).blur();
		}
	}

	onKeyDown(ev: KeyboardEvent) {
		if (!this.hasFocus) {
			return;
		}
		var key = ev.key.toLowerCase();
		if (key == "escape") {
			return (this.open = false);
		}
		if (key == "enter") {
			return this.open ? this.onEnter() : this.setOpen(true);
		}
		if (key == "arrowdown" || key == "arrowup") {
			ev.preventDefault();
			return this.open
				? key === "arrowdown"
					? this.setHoveredIdx(++this.hoveredIdx)
					: this.setHoveredIdx(--this.hoveredIdx)
				: this.setOpen(true);
		}
	}
	isSelect(item: any) {
		if (item === undefined) return this.innerValue === undefined;
		var key = this.getKey(item);

		return this.multiselect
			? (this.innerValue || []).some((el: any) => this.isEqual(el, key))
			: this.isEqual(key, this.innerValue);
	}
	isEqual(fst: any, scd: any): boolean {
		return this.checkActiveByRef
			? fst === scd
			: JSON.stringify(fst) === JSON.stringify(scd);
	}
	setHoveredIdx(idx: number) {
		this.hoveredIdx = (idx + this.itemsToDisplay.length) % this.itemsToDisplay.length;
	}
	onEnter() {
		if (this.isSearch) {
			let items = this.items?.filter(
				(x) => x[this.valueField].toLowerCase().search(this.search.toLowerCase()) != -1
			);
			if (items.length) {
				this.search = items[0][this.valueField];
				this.select(items[0]);
			}
			if (this.$refs.currentSearch) {
				(this.$refs.currentSearch as HTMLInputElement).blur();
			}
		}
		// this.select(this.itemsToDisplay[this.hoveredIdx]);
	}
	@Watch("items", { deep: true })
	onItemsChanged(value, oldValue) {
		this.tempItems = value;
		this.trySetDefault();
	}
	hasValue(): boolean {
		return this.innerValue != null || (this.multiselect && this.innerValue.length > 0);
	}
	valueInItems(): boolean {
		const allKeys = (this.innerItems || []).map((x) => this.getKey(x));
		const selectedKeys = this.multiselect ? this.innerValue : [this.innerValue];
		return selectedKeys.every((x) => allKeys.indexOf(x) > -1);
		//текущее значения все есть в наборе items
	}
	trySetDefault() {
		if (this.hasValue() && this.valueInItems()) {
			return;
		} else {
			if (!this.noUndefined) this.select(undefined);
			//выбирает не найдено при обновлении страницы
		}
	}
	get itemsToDisplay(): any[] {
		if (this.noUndefined) {
			return this.innerItems;
		}
		if (!this.autocomplete || !this.search) {
			let res = this.innerItems;
			res = [undefined].concat(res);
			return res;
		}

		let res = this.innerItems?.filter((x) =>
			(this.getValue(x) || "").toLowerCase().includes(this.search.toLowerCase())
		);
		res = [undefined].concat(res);
		return res;
	}

	get active(): any {
		return this.multiselect
			? this.innerItems?.filter((el: any) =>
					this.innerValue.some((iv: any) => this.isEqual(iv, this.getKey(el)))
			  )
			: (this.innerItems?.filter((el: any) =>
					this.isEqual(this.getKey(el), this.innerValue)
			  ) || [])[0];
	}
	get activeText(): string {
		var active = this.active;
		if (Array.isArray(active)) {
			var text =
				active.length > 0
					? active.map((el: any) => this.getValue(el)).join(",")
					: this.selectText || "";
		} else {
			text = active ? this.getValue(active) : this.selectText || this.defaultText;
		}
		return text;
	}
	loseFocus() {
		if (this.isSearch) {
			this.setOpen(false);
			if (this.$refs.currentSearch) {
				(this.$refs.currentSearch as HTMLInputElement).blur();
			}
			this.innerValue = this.tempValue.key;
			this.search = this.tempValue.value;
		}
	}
	select(item: any) {
		if (this.isSearch) {
			this.search = item[this.valueField];
			this.loseFocus();
		}
		if (item === undefined) {
			this.innerValue = undefined;
			if (this.closeOnSelect) {
				this.setOpen(false);
			}
			return;
		}
		if (this.multiselect) {
			this.innerValue = this.innerValue || [];
			this.isSelect(item)
				? (this.innerValue = this.innerValue?.filter(
						(el: any) => !this.isEqual(el, this.getKey(item))
				  ))
				: this.innerValue.push(this.getKey(item));
		} else {
			this.innerValue = this.getKey(item);
			if (this.closeOnSelect) {
				this.setOpen(false);
			}
		}
		if (this.items?.find((x) => x[this.keyField] == item[this.keyField])) {
			this.tempValue.key = this.innerValue;
			this.tempValue.value = this.items?.find(
				(x) => x[this.keyField] == item[this.keyField]
			)[this.valueField];
		}
		this.$emit("update:modelValue", this.innerValue);
		let idx = this.innerItems.indexOf(item);
		this.$emit("selectedIndex", idx);
		this.setHoveredIdx(idx);
	}

	created() {
		if (this.items?.find((x) => x[this.keyField] == this.modelValue)) {
			if (this.isSearch) {
				this.search = this.items?.find((x) => x[this.keyField] == this.modelValue)[
					this.valueField
				];
			}
		} else {
			this.onBlur();
		}

		if (this.items) {
			this.tempItems = this.items;
		}
		this.innerValue = this.modelValue;
		this.trySetDefault();
		this.onUpdateDefaultChoosen();

		if (this.items?.find((x) => x[this.keyField] == this.innerValue)) {
			this.tempValue.key = this.innerValue;
			this.tempValue.value = this.items?.find((x) => x[this.keyField] == this.innerValue)[
				this.valueField
			];
		}
	}

	getKey(item: any) {
		if (this.keyField) {
			return item[this.keyField];
		}
		return item;
	}
	getValue(item: any) {
		if (this.valueField) {
			const resText = item[this.valueField];
			if (resText == undefined) {
				console.error("Не найден valueField");
			}
			return resText.toUpperCase();
		}
		// return item.toUpperCase();
		return item;
	}
}
