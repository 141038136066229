import ApiService from '@/api/index';
import ChangePasswordRequest from '@/api/main/dataSource/api/aba/cabinet/web/models/ChangePasswordRequest';
import CurrentUserResponse from '@/api/main/dataSource/api/aba/cabinet/web/models/CurrentUserResponse';
import GetPhotosResponse from '@/api/main/dataSource/api/aba/cabinet/web/models/GetPhotosResponse';
import CheckUniqueEmailRequest from '@/api/main/dataSource/api/aba/public/services/models/registration/CheckUniqueEmailRequest';
import RegistertrationStartRequest from '@/api/main/dataSource/api/aba/public/services/models/registration/RegistertrationStartRequest';
import RegistrationRequest from '@/api/main/dataSource/api/aba/public/services/models/registration/RegistrationRequest';
import RestorePasswordRequest from '@/api/main/dataSource/api/aba/public/services/models/restore-password/RestorePasswordRequest';
import EnumLang from '@/api/main/dataSource/api/i-public-service/aba-finance/models/enums/EnumLang';
import ModelSystemMessages from '@/api/main/dataSource/api/i-public-service/aba-finance/models/ModelSystemMessages';
import MailBankModel from '@/api/main/dataSource/api/i-public-service/aba-finance/models/support/MailBankModel';
import ModelNaturalPerson from '@/api/main/dataSource/api/i-public-service/aba-finance/models/user/ModelNaturalPerson';
import AcquiringInitPayinRequest from '@/api/main/dataSource/api/transaction-services/models/acquirings/AcquiringInitPayinRequest';
import AcquiringInitPayinResponse from '@/api/main/dataSource/api/transaction-services/models/acquirings/AcquiringInitPayinResponse';
import MoneyTransactionPersonResponse from '@/api/main/dataSource/api/transaction-services/models/MoneyTransactionPersonResponse';
import TransactionPersonRequest from '@/api/main/dataSource/api/transaction-services/models/TransactionPersonRequest';
import GetNaturalPersonResponse from '@/api/main/services/NaturalPersonHome/models/response/GetNaturalPersonResponse';
import GetSystemMessages from '@/api/main/services/NaturalPersonProfile/models/response/GetSystemMessages';
import FAQQuestionModel from '@/api/main/services/NaturalPersonSupport/models/FAQQuestionModel';

import { notify } from '@/app/notifications';
import FAQCategoryModel from '@/views/models/FAQCategoryModel';
import NaturalPersonModel from '@/views/models/NaturalPersonModel';
import moment from 'moment';


import { ref } from 'vue';

import LoginRequest from '@/api/main/dataSource/api/aba/public/services/models/login/LoginRequest';
import LoginResponse from '@/api/main/dataSource/api/aba/public/services/models/login/LoginResponse';
import PersonalIdentificationData from '@/api/main/dataSource/api/i-public-service/aba-finance/models/user/identification/PersonalIdentificationData';
import ErrorModel from '../../login/adapter/models/errorModel';


export default class AdapterUserIdentificationService {

  /**
 * Сервис
 */
  private static instance: AdapterUserIdentificationService;
  /**
 * API
 */
  private static api: ApiService;
  isLoading: boolean = false;
  isInited = ref(false);
  api: ApiService;

  constructor() {
    AdapterUserIdentificationService.api = new ApiService();
  }

  public static getInstance() {
    if (!AdapterUserIdentificationService.instance) {
      AdapterUserIdentificationService.instance = new AdapterUserIdentificationService();
    }
    return AdapterUserIdentificationService.instance;
  }


  async loginUser(value: LoginRequest): Promise<LoginResponse | ErrorModel> {
    try {
      const res = await AdapterUserIdentificationService.api.Main.AuthWsController.loginAsync(value);
      return new LoginResponse(res)
    } catch (err: any) {
      this.showError(err)
      return new ErrorModel({ ErrorCode: err.ErrorCode, ErrorMessage: err.ErrorMessage })
    }
  }
  
  async getCurrentUser(): Promise<CurrentUserResponse | ErrorModel> {
    try {
      const res = await AdapterUserIdentificationService.api.Main.AuthWsController.getCurrentUserAsync()
      return res
    } catch (err: any) {
      this.showError(err)
      return new ErrorModel({ ErrorCode: err.ErrorCode, ErrorMessage: err.ErrorMessage })
    }
  }
  
  async saveUserInfo(req: PersonalIdentificationData): Promise<boolean | ErrorModel> {
    try {
      const res = await AdapterUserIdentificationService.api.Main.ProfileWsController.savePersonalIdentificationDataAsync(req)
      return res as boolean
    } catch (err: any) {
      this.showError(err)
      return new ErrorModel({ ErrorCode: err.ErrorCode, ErrorMessage: err.ErrorMessage })
    }
  }
  
  /**
   * показать информацию об ошибке
   * @param err 
   */
  showError(err: any) {
    console.error(err?.ErrorMessage);
    notify({ text: err?.ErrorMessage as any, title: "Error", type: 'error' })
  }
}
