import { WebApiService } from '../../../../../plugins/webApiService'
import { Events } from '../../../../../plugins/events'
import { SendRequestModel } from '../../../../../plugins/models/SendRequestModel'
import ModelUserApi from '../../../../../i-public-service/aba-finance/models/user/ModelUserApi';
import PersonalIdentificationData from '../../../../../i-public-service/aba-finance/models/user/identification/PersonalIdentificationData';
import PassportIdentificationData from '../../../../../i-public-service/aba-finance/models/user/identification/PassportIdentificationData';
import WsResponseModelT from '../../../../../schema-builder/server/models/WsResponseModelT';
import ModelLegalPerson from '../../../../../i-public-service/aba-finance/models/user/ModelLegalPerson';
import ModelNaturalPerson from '../../../../../i-public-service/aba-finance/models/user/ModelNaturalPerson';
import ModelSystemMessages from '../../../../../i-public-service/aba-finance/models/ModelSystemMessages';
import ModelMessage from '../../../../../i-public-service/aba-finance/models/ModelMessage';
import GetRefillRequisitesResponse from '../../../../../aba/cabinet/web/models/GetRefillRequisitesResponse';
import GetPhotosResponse from '../../../../../aba/cabinet/web/models/GetPhotosResponse';
import ModelPersonIdentification from '../../../../../i-public-service/aba-finance/models/user/ModelPersonIdentification';
import EnumLegalIdentificationStageType from '../../../../../common/enums/EnumLegalIdentificationStageType';

export default class ProfileWsController {
	private url?: string;
	private cookieName: string;
	private appWebSettingsPath?: string;
	private apiUrlProperty?: string;

	GetLegalPersonEvent = new Events<WsResponseModelT<ModelLegalPerson>>();
	GetNaturalPersonEvent = new Events<WsResponseModelT<ModelNaturalPerson>>();
	GetSystemMessagesEvent = new Events<WsResponseModelT<Array<ModelSystemMessages>>>();
	AddMessageEvent = new Events<WsResponseModelT<Boolean>>();
	GetMessagesEvent = new Events<WsResponseModelT<Array<ModelMessage>>>();
	ReadingSystemMessagesAllEvent = new Events<WsResponseModelT<Boolean>>();
	GetRefillRequisitesEvent = new Events<WsResponseModelT<GetRefillRequisitesResponse>>();
	GetPhotosEvent = new Events<WsResponseModelT<GetPhotosResponse>>();
	CheckIdentifiedEvent = new Events<WsResponseModelT<Boolean>>();
	OrderCardEvent = new Events<WsResponseModelT<Boolean>>();
	SavePersonalIdentificationDataEvent = new Events<WsResponseModelT<Boolean>>();
	SavePassportIdentificationDataEvent = new Events<WsResponseModelT<Boolean>>();
	GetUserIdentificationEvent = new Events<WsResponseModelT<ModelPersonIdentification>>();
	GetLastCompleteStageEvent = new Events<WsResponseModelT<EnumLegalIdentificationStageType>>();

	subscribeEvents = async () => {
		const self = this;
		const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
		webApiService.on('ProfileWsController', 'GetLegalPerson', 'Main', (res: WsResponseModelT<ModelLegalPerson>) => {
			self.GetLegalPersonEvent.trigger(res);
		});
		webApiService.on('ProfileWsController', 'GetNaturalPerson', 'Main', (res: WsResponseModelT<ModelNaturalPerson>) => {
			self.GetNaturalPersonEvent.trigger(res);
		});
		webApiService.on('ProfileWsController', 'GetSystemMessages', 'Main', (res: WsResponseModelT<Array<ModelSystemMessages>>) => {
			self.GetSystemMessagesEvent.trigger(res);
		});
		webApiService.on('ProfileWsController', 'AddMessage', 'Main', (res: WsResponseModelT<Boolean>) => {
			self.AddMessageEvent.trigger(res);
		});
		webApiService.on('ProfileWsController', 'GetMessages', 'Main', (res: WsResponseModelT<Array<ModelMessage>>) => {
			self.GetMessagesEvent.trigger(res);
		});
		webApiService.on('ProfileWsController', 'ReadingSystemMessagesAll', 'Main', (res: WsResponseModelT<Boolean>) => {
			self.ReadingSystemMessagesAllEvent.trigger(res);
		});
		webApiService.on('ProfileWsController', 'GetRefillRequisites', 'Main', (res: WsResponseModelT<GetRefillRequisitesResponse>) => {
			self.GetRefillRequisitesEvent.trigger(res);
		});
		webApiService.on('ProfileWsController', 'GetPhotos', 'Main', (res: WsResponseModelT<GetPhotosResponse>) => {
			self.GetPhotosEvent.trigger(res);
		});
		webApiService.on('ProfileWsController', 'CheckIdentified', 'Main', (res: WsResponseModelT<Boolean>) => {
			self.CheckIdentifiedEvent.trigger(res);
		});
		webApiService.on('ProfileWsController', 'OrderCard', 'Main', (res: WsResponseModelT<Boolean>) => {
			self.OrderCardEvent.trigger(res);
		});
		webApiService.on('ProfileWsController', 'SavePersonalIdentificationData', 'Main', (res: WsResponseModelT<Boolean>) => {
			self.SavePersonalIdentificationDataEvent.trigger(res);
		});
		webApiService.on('ProfileWsController', 'SavePassportIdentificationData', 'Main', (res: WsResponseModelT<Boolean>) => {
			self.SavePassportIdentificationDataEvent.trigger(res);
		});
		webApiService.on('ProfileWsController', 'GetUserIdentification', 'Main', (res: WsResponseModelT<ModelPersonIdentification>) => {
			self.GetUserIdentificationEvent.trigger(res);
		});
		webApiService.on('ProfileWsController', 'GetLastCompleteStage', 'Main', (res: WsResponseModelT<EnumLegalIdentificationStageType>) => {
			self.GetLastCompleteStageEvent.trigger(res);
		});
	}

	constructor(url?: string, cookieName?: string, appWebSettingsPath?: string, apiUrlProperty?: string) {
		this.url = url;
		this.cookieName = cookieName ?? "Auth-Token";
		this.appWebSettingsPath = appWebSettingsPath && [location.host, appWebSettingsPath].join("/");
		this.apiUrlProperty = apiUrlProperty;
		this.subscribeEvents();
	}

	async GetLegalPerson() {
		const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
		const sendRequest = new SendRequestModel({
			ProjectName: 'Main',
			Controller: 'ProfileWsController',
			Method: 'GetLegalPerson',
		})

		webApiService.send(sendRequest);
	}

	async GetNaturalPerson() {
		const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
		const sendRequest = new SendRequestModel({
			ProjectName: 'Main',
			Controller: 'ProfileWsController',
			Method: 'GetNaturalPerson',
		})

		webApiService.send(sendRequest);
	}

	async GetSystemMessages() {
		const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
		const sendRequest = new SendRequestModel({
			ProjectName: 'Main',
			Controller: 'ProfileWsController',
			Method: 'GetSystemMessages',
		})

		webApiService.send(sendRequest);
	}

	async AddMessage(text: string) {
		const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
		const sendRequest = new SendRequestModel({
			ProjectName: 'Main',
			Controller: 'ProfileWsController',
			Method: 'AddMessage',
			Value: typeof(text) === 'object' ? JSON.stringify(text) : text,
		})

		webApiService.send(sendRequest);
	}

	async GetMessages() {
		const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
		const sendRequest = new SendRequestModel({
			ProjectName: 'Main',
			Controller: 'ProfileWsController',
			Method: 'GetMessages',
		})

		webApiService.send(sendRequest);
	}

	async ReadingSystemMessagesAll() {
		const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
		const sendRequest = new SendRequestModel({
			ProjectName: 'Main',
			Controller: 'ProfileWsController',
			Method: 'ReadingSystemMessagesAll',
		})

		webApiService.send(sendRequest);
	}

	async GetRefillRequisites() {
		const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
		const sendRequest = new SendRequestModel({
			ProjectName: 'Main',
			Controller: 'ProfileWsController',
			Method: 'GetRefillRequisites',
		})

		webApiService.send(sendRequest);
	}

	async GetPhotos() {
		const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
		const sendRequest = new SendRequestModel({
			ProjectName: 'Main',
			Controller: 'ProfileWsController',
			Method: 'GetPhotos',
		})

		webApiService.send(sendRequest);
	}

	async CheckIdentified() {
		const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
		const sendRequest = new SendRequestModel({
			ProjectName: 'Main',
			Controller: 'ProfileWsController',
			Method: 'CheckIdentified',
		})

		webApiService.send(sendRequest);
	}

	async OrderCard(model: ModelUserApi) {
		const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
		const sendRequest = new SendRequestModel({
			ProjectName: 'Main',
			Controller: 'ProfileWsController',
			Method: 'OrderCard',
			Value: typeof(model) === 'object' ? JSON.stringify(model) : model,
		})

		webApiService.send(sendRequest);
	}

	async SavePersonalIdentificationData(model: PersonalIdentificationData) {
		const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
		const sendRequest = new SendRequestModel({
			ProjectName: 'Main',
			Controller: 'ProfileWsController',
			Method: 'SavePersonalIdentificationData',
			Value: typeof(model) === 'object' ? JSON.stringify(model) : model,
		})

		webApiService.send(sendRequest);
	}

	async SavePassportIdentificationData(model: PassportIdentificationData) {
		const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
		const sendRequest = new SendRequestModel({
			ProjectName: 'Main',
			Controller: 'ProfileWsController',
			Method: 'SavePassportIdentificationData',
			Value: typeof(model) === 'object' ? JSON.stringify(model) : model,
		})

		webApiService.send(sendRequest);
	}

	async GetUserIdentification() {
		const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
		const sendRequest = new SendRequestModel({
			ProjectName: 'Main',
			Controller: 'ProfileWsController',
			Method: 'GetUserIdentification',
		})

		webApiService.send(sendRequest);
	}

	async GetLastCompleteStage() {
		const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
		const sendRequest = new SendRequestModel({
			ProjectName: 'Main',
			Controller: 'ProfileWsController',
			Method: 'GetLastCompleteStage',
		})

		webApiService.send(sendRequest);
	}

	 // async methods
	async getLegalPersonAsync(): Promise<ModelLegalPerson> {
		return new Promise<ModelLegalPerson>(async (resolve, reject) => {
			const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
			const sendRequest = new SendRequestModel({
				ProjectName: 'Main',
				Controller: 'ProfileWsController',
				Method: 'GetLegalPerson',
				Callback: (response: WsResponseModelT<ModelLegalPerson>) => {
					const isGeneric = typeof response.IsSuccess == 'boolean'
					const result = response.IsSuccess ? response.Value : response

					if (!isGeneric || response.IsSuccess) resolve(result as ModelLegalPerson)
					else reject(result)
				}
			})

			webApiService.send(sendRequest);
		});
	}

	async getNaturalPersonAsync(): Promise<ModelNaturalPerson> {
		return new Promise<ModelNaturalPerson>(async (resolve, reject) => {
			const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
			const sendRequest = new SendRequestModel({
				ProjectName: 'Main',
				Controller: 'ProfileWsController',
				Method: 'GetNaturalPerson',
				Callback: (response: WsResponseModelT<ModelNaturalPerson>) => {
					const isGeneric = typeof response.IsSuccess == 'boolean'
					const result = response.IsSuccess ? response.Value : response

					if (!isGeneric || response.IsSuccess) resolve(result as ModelNaturalPerson)
					else reject(result)
				}
			})

			webApiService.send(sendRequest);
		});
	}

	async getSystemMessagesAsync(): Promise<Array<ModelSystemMessages>> {
		return new Promise<Array<ModelSystemMessages>>(async (resolve, reject) => {
			const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
			const sendRequest = new SendRequestModel({
				ProjectName: 'Main',
				Controller: 'ProfileWsController',
				Method: 'GetSystemMessages',
				Callback: (response: WsResponseModelT<Array<ModelSystemMessages>>) => {
					const isGeneric = typeof response.IsSuccess == 'boolean'
					const result = response.IsSuccess ? response.Value : response

					if (!isGeneric || response.IsSuccess) resolve(result as Array<ModelSystemMessages>)
					else reject(result)
				}
			})

			webApiService.send(sendRequest);
		});
	}

	async addMessageAsync(text: string): Promise<Boolean> {
		return new Promise<Boolean>(async (resolve, reject) => {
			const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
			const sendRequest = new SendRequestModel({
				ProjectName: 'Main',
				Controller: 'ProfileWsController',
				Method: 'AddMessage',
				Value: typeof(text) === 'object' ? JSON.stringify(text) : text,
				Callback: (response: WsResponseModelT<Boolean>) => {
					const isGeneric = typeof response.IsSuccess == 'boolean'
					const result = response.IsSuccess ? response.Value : response

					if (!isGeneric || response.IsSuccess) resolve(result as Boolean)
					else reject(result)
				}
			})

			webApiService.send(sendRequest);
		});
	}

	async getMessagesAsync(): Promise<Array<ModelMessage>> {
		return new Promise<Array<ModelMessage>>(async (resolve, reject) => {
			const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
			const sendRequest = new SendRequestModel({
				ProjectName: 'Main',
				Controller: 'ProfileWsController',
				Method: 'GetMessages',
				Callback: (response: WsResponseModelT<Array<ModelMessage>>) => {
					const isGeneric = typeof response.IsSuccess == 'boolean'
					const result = response.IsSuccess ? response.Value : response

					if (!isGeneric || response.IsSuccess) resolve(result as Array<ModelMessage>)
					else reject(result)
				}
			})

			webApiService.send(sendRequest);
		});
	}

	async readingSystemMessagesAllAsync(): Promise<Boolean> {
		return new Promise<Boolean>(async (resolve, reject) => {
			const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
			const sendRequest = new SendRequestModel({
				ProjectName: 'Main',
				Controller: 'ProfileWsController',
				Method: 'ReadingSystemMessagesAll',
				Callback: (response: WsResponseModelT<Boolean>) => {
					const isGeneric = typeof response.IsSuccess == 'boolean'
					const result = response.IsSuccess ? response.Value : response

					if (!isGeneric || response.IsSuccess) resolve(result as Boolean)
					else reject(result)
				}
			})

			webApiService.send(sendRequest);
		});
	}

	async getRefillRequisitesAsync(): Promise<GetRefillRequisitesResponse> {
		return new Promise<GetRefillRequisitesResponse>(async (resolve, reject) => {
			const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
			const sendRequest = new SendRequestModel({
				ProjectName: 'Main',
				Controller: 'ProfileWsController',
				Method: 'GetRefillRequisites',
				Callback: (response: WsResponseModelT<GetRefillRequisitesResponse>) => {
					const isGeneric = typeof response.IsSuccess == 'boolean'
					const result = response.IsSuccess ? response.Value : response

					if (!isGeneric || response.IsSuccess) resolve(result as GetRefillRequisitesResponse)
					else reject(result)
				}
			})

			webApiService.send(sendRequest);
		});
	}

	async getPhotosAsync(): Promise<GetPhotosResponse> {
		return new Promise<GetPhotosResponse>(async (resolve, reject) => {
			const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
			const sendRequest = new SendRequestModel({
				ProjectName: 'Main',
				Controller: 'ProfileWsController',
				Method: 'GetPhotos',
				Callback: (response: WsResponseModelT<GetPhotosResponse>) => {
					const isGeneric = typeof response.IsSuccess == 'boolean'
					const result = response.IsSuccess ? response.Value : response

					if (!isGeneric || response.IsSuccess) resolve(result as GetPhotosResponse)
					else reject(result)
				}
			})

			webApiService.send(sendRequest);
		});
	}

	async checkIdentifiedAsync(): Promise<Boolean> {
		return new Promise<Boolean>(async (resolve, reject) => {
			const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
			const sendRequest = new SendRequestModel({
				ProjectName: 'Main',
				Controller: 'ProfileWsController',
				Method: 'CheckIdentified',
				Callback: (response: WsResponseModelT<Boolean>) => {
					const isGeneric = typeof response.IsSuccess == 'boolean'
					const result = response.IsSuccess ? response.Value : response

					if (!isGeneric || response.IsSuccess) resolve(result as Boolean)
					else reject(result)
				}
			})

			webApiService.send(sendRequest);
		});
	}

	async orderCardAsync(model: ModelUserApi): Promise<Boolean> {
		return new Promise<Boolean>(async (resolve, reject) => {
			const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
			const sendRequest = new SendRequestModel({
				ProjectName: 'Main',
				Controller: 'ProfileWsController',
				Method: 'OrderCard',
				Value: typeof(model) === 'object' ? JSON.stringify(model) : model,
				Callback: (response: WsResponseModelT<Boolean>) => {
					const isGeneric = typeof response.IsSuccess == 'boolean'
					const result = response.IsSuccess ? response.Value : response

					if (!isGeneric || response.IsSuccess) resolve(result as Boolean)
					else reject(result)
				}
			})

			webApiService.send(sendRequest);
		});
	}

	async savePersonalIdentificationDataAsync(model: PersonalIdentificationData): Promise<Boolean> {
		return new Promise<Boolean>(async (resolve, reject) => {
			const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
			const sendRequest = new SendRequestModel({
				ProjectName: 'Main',
				Controller: 'ProfileWsController',
				Method: 'SavePersonalIdentificationData',
				Value: typeof(model) === 'object' ? JSON.stringify(model) : model,
				Callback: (response: WsResponseModelT<Boolean>) => {
					const isGeneric = typeof response.IsSuccess == 'boolean'
					const result = response.IsSuccess ? response.Value : response

					if (!isGeneric || response.IsSuccess) resolve(result as Boolean)
					else reject(result)
				}
			})

			webApiService.send(sendRequest);
		});
	}

	async savePassportIdentificationDataAsync(model: PassportIdentificationData): Promise<Boolean> {
		return new Promise<Boolean>(async (resolve, reject) => {
			const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
			const sendRequest = new SendRequestModel({
				ProjectName: 'Main',
				Controller: 'ProfileWsController',
				Method: 'SavePassportIdentificationData',
				Value: typeof(model) === 'object' ? JSON.stringify(model) : model,
				Callback: (response: WsResponseModelT<Boolean>) => {
					const isGeneric = typeof response.IsSuccess == 'boolean'
					const result = response.IsSuccess ? response.Value : response

					if (!isGeneric || response.IsSuccess) resolve(result as Boolean)
					else reject(result)
				}
			})

			webApiService.send(sendRequest);
		});
	}

	async getUserIdentificationAsync(): Promise<ModelPersonIdentification> {
		return new Promise<ModelPersonIdentification>(async (resolve, reject) => {
			const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
			const sendRequest = new SendRequestModel({
				ProjectName: 'Main',
				Controller: 'ProfileWsController',
				Method: 'GetUserIdentification',
				Callback: (response: WsResponseModelT<ModelPersonIdentification>) => {
					const isGeneric = typeof response.IsSuccess == 'boolean'
					const result = response.IsSuccess ? response.Value : response

					if (!isGeneric || response.IsSuccess) resolve(result as ModelPersonIdentification)
					else reject(result)
				}
			})

			webApiService.send(sendRequest);
		});
	}

	async getLastCompleteStageAsync(): Promise<EnumLegalIdentificationStageType> {
		return new Promise<EnumLegalIdentificationStageType>(async (resolve, reject) => {
			const webApiService = await WebApiService.getInstance(this.url, this.cookieName, this.appWebSettingsPath, this.apiUrlProperty);
			const sendRequest = new SendRequestModel({
				ProjectName: 'Main',
				Controller: 'ProfileWsController',
				Method: 'GetLastCompleteStage',
				Callback: (response: WsResponseModelT<EnumLegalIdentificationStageType>) => {
					const isGeneric = typeof response.IsSuccess == 'boolean'
					const result = response.IsSuccess ? response.Value : response

					if (!isGeneric || response.IsSuccess) resolve(result as EnumLegalIdentificationStageType)
					else reject(result)
				}
			})

			webApiService.send(sendRequest);
		});
	}

}