

export default class UserModel {
  FirstName: string;
  LastName!: string;
  CountyState!: string;
  City!: string;
  Address1!: string;
  PostCode!: string;
  constructor(obj?: Partial<UserModel>) {
    if (obj) {
      Object.assign(this, obj);
    }
  }
}
