import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["ui-table-body-item", [
      {
        sele: _ctx.selectInner,
        'border-left': _ctx.borderLeft,
        'border-right': _ctx.borderRight,
        borderY: _ctx.borderY,
      },
    ]]),
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.ClickRow && _ctx.ClickRow(...args)))
  }, [
    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
  ], 2))
}